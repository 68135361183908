.uppy-FileInput-container {
  margin-bottom: 0;
}

.uppy-FileInput-btn {
  @extend .btn;
  @extend .btn-primary;
  font-family: 'SofiaPro', sans-serif;

  &:not(:disabled):not(.disabled):active,
  &:active {
    box-shadow: none;
    color: theme('colors.white');
    background-color: theme('colors.orange.DEFAULT');
  }
}

.uppy-Informer-custom {
  .uppy-Informer {
    top: 60px;
    bottom: auto;

    p {
      background-color: $ss-red-color;
    }
  }
}
