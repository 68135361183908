// Any variable which has not ss prefix is bootstrap variable
// Please name variable with prefix ss strictly. This is convention in our css code base

$ss-font-family: 'SofiaPro', sans-serif;
$ss-blue-lightest-color: #effbfd;
$ss-blue-color: #007bfb;
$ss-blue-dark-color: #00f;
$ss-blue-darker-color: #26527f;
$ss-blue-darkest-color: #3b60b8;

$ss-green-color: #219653;
$ss-green-light-color: lighten($ss-green-color, 55%);
$ss-green-dark-color: #006400;

$ss-orange-light-color: #f9f2ee;

$ss-yellow-color: #fdaa00;
$ss-yellow-light-color: #f1c46d;
$ss-yellow-lightest-color: #fcf8f6;

$ss-grey-most-lightest-color: #fdfcfc;
$ss-grey-lightest-color: #f3f3f3;
$ss-grey-lighter-color: #dfdfdf;
$ss-grey-light-color: #9f9f9f;
$ss-grey-color: #6a6a6a;

$ss-black-color: #414141;
$ss-true-black-color: #000;

$ss-border-color: $ss-grey-lighter-color;

$ss-red-color: #ff5055;

$ss-teal-color: #5f9ea0;
$ss-purple-color: #4a5ced;

$ss-cyan-lighter-color: #effbfd;
$ss-cyan-light-color: #d5f3f8;
$ss-cyan-color: #2ec4dd;
$ss-cyan-dark-color: #25a9bf;

$ss-font-size-xl: 20px;
$ss-font-size-lg: 18px;
$ss-font-size-base: 16px;
$ss-font-size-sm: 14px;
$ss-font-size-xs: 12px;
$ss-font-size-xxs: 10px;
$ss-font-size-xxxs: 8px;

$ss-font-size-title-1: 22px;
$ss-font-size-title-2: 24px;
$ss-font-size-title-3: 28px;
$ss-font-size-title-4: 32px;
$ss-font-size-title-5: 36px;
$ss-font-size-title-6: 48px;

$ss-font-weight-light: 300;
$ss-font-weight-base: 400;
$ss-font-weight-bold: 600;

$ss-zindex-10: 10;
$ss-zindex-20: 20;
$ss-zindex-30: 30;
$ss-zindex-40: 40;
$ss-zindex-50: 50;
$ss-zindex-60: 60;
$ss-zindex-70: 70;
$ss-zindex-80: 80;
$ss-zindex-90: 90;
$ss-zindex-100: 100;
$ss-zindex-200: 200;
$ss-zindex-1000: 1000;
$ss-zindex-1200: 1200;
$ss-zindex-1500: 1500;
$ss-zindex-2000: 2000;

$ss-breakpoint-xs: 0;
$ss-breakpoint-ip6: 375px;
$ss-breakpoint-sm: 576px;
$ss-breakpoint-md: 769px;
$ss-breakpoint-lg: 992px;
$ss-breakpoint-xl: 1200px;

$ss-border-radius-xs: 1px;
$ss-border-radius-sm: 2px;
$ss-border-radius-base: 4px;
$ss-border-radius-lg: 8px;
$ss-border-radius-xl: 16px;

// override bootstrap color
$blue: $ss-blue-color;
$tooltip-arrow-color: #f4f4f4;
$light: $ss-grey-lighter-color;

$body-color: $ss-black-color;
$font-weight-bold: $ss-font-weight-bold;
$primary: $ss-cyan-color;
$border-radius: 1px;
$input-border-radius: 1px;
$border-color: $ss-border-color;
$input-border-color: $ss-border-color;
$input-placeholder-color: $ss-grey-color;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 600px,
  md: 800px,
  lg: 960px,
  xl: 1140px
);

$spacer: 16px;
$spacers:  (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  12: 12px,
  15: 15px,
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 4),
  7: ($spacer * 5),
  32: ($spacer * 2)
);

$transition-collapse: height .15s ease;

$breadcrumb-divider: quote(">");

$whatsapp-color: #25d366;
$facebook-color: #1877f2;
