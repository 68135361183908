.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0,0,0,0.08);
}
.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-months {
  display: flex;
}
.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0,0,0,0.9);
  fill: rgba(0,0,0,0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  overflow: hidden;
  flex: 1;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0,0,0,0.9);
  fill: rgba(0,0,0,0.9);
}
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
/*
      /*rtl:begin:ignore*/
/*
      */
  left: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
/*
      /*rtl:begin:ignore*/
/*
      */
  right: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57,57,57,0.15);
  box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0,0,0,0.1);
}
.numInputWrapper span:active {
  background: rgba(0,0,0,0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57,57,57,0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57,57,57,0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(0,0,0,0.5);
}
.numInputWrapper:hover {
  background: rgba(0,0,0,0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0,0,0,0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0,0,0,0.9);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0,0,0,0.9);
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0,0,0,0.5);
  background: transparent;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0,0,0,0.05);
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0,0,0,0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #e6e6e6;
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.flatpickr-day.today {
  border-color: #959ea9;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #569ff7;
}
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57,57,57,0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57,57,57,0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #e6e6e6;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57,57,57,0.3);
  background: transparent;
  cursor: default;
  border: none;
}
.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  touch-action: none;
  -moz-user-select: none;
  user-select: none;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}
.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  bottom: -17px;
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #3FB8AF;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}
.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}
/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.noUi-horizontal .noUi-origin > .noUi-tooltip {
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}
.noUi-vertical .noUi-origin > .noUi-tooltip {
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}
@font-face{
  font-family:"Kobe";

  src:url("/fonts/Kobe11-BlackOblique.eot");

  src:url("/fonts/Kobe11-BlackOblique.eot?#iefix") format("embedded-opentype"),url("/fonts/Kobe11-BlackOblique.woff2") format("woff2"),url("/fonts/Kobe11-BlackOblique.woff") format("woff"),url("/fonts/Kobe11-BlackOblique.ttf") format("truetype");

  font-weight:900;

  font-style:italic;

  font-display:swap
}
@font-face{
  font-family:"Kobe";

  src:url("/fonts/Kobe11-Regular.eot");

  src:url("/fonts/Kobe11-Regular.eot?#iefix") format("embedded-opentype"),url("/fonts/Kobe11-Regular.woff2") format("woff2"),url("/fonts/Kobe11-Regular.woff") format("woff"),url("/fonts/Kobe11-Regular.ttf") format("truetype");

  font-weight:normal;

  font-style:normal;

  font-display:swap
}
@font-face{
  font-family:"Kobe";

  src:url("/fonts/Kobe11-Oblique.eot");

  src:url("/fonts/Kobe11-Oblique.eot?#iefix") format("embedded-opentype"),url("/fonts/Kobe11-Oblique.woff2") format("woff2"),url("/fonts/Kobe11-Oblique.woff") format("woff"),url("/fonts/Kobe11-Oblique.ttf") format("truetype");

  font-weight:normal;

  font-style:italic;

  font-display:swap
}
@font-face{
  font-family:"Kobe Bold Oblique";

  src:url("/fonts/Kobe11-BoldOblique.eot");

  src:url("/fonts/Kobe11-BoldOblique.eot?#iefix") format("embedded-opentype"),url("/fonts/Kobe11-BoldOblique.woff2") format("woff2"),url("/fonts/Kobe11-BoldOblique.woff") format("woff"),url("/fonts/Kobe11-BoldOblique.ttf") format("truetype");

  font-weight:600;

  font-style:italic;

  font-display:swap
}
@font-face{
  font-family:"Kobe";

  src:url("/fonts/Kobe11-Bold.eot");

  src:url("/fonts/Kobe11-Bold.eot?#iefix") format("embedded-opentype"),url("/fonts/Kobe11-Bold.woff2") format("woff2"),url("/fonts/Kobe11-Bold.woff") format("woff"),url("/fonts/Kobe11-Bold.ttf") format("truetype");

  font-weight:600;

  font-style:normal;

  font-display:swap
}
@font-face{
  font-family:"Kobe";

  src:url("/fonts/Kobe11-Black.eot");

  src:url("/fonts/Kobe11-Black.eot?#iefix") format("embedded-opentype"),url("/fonts/Kobe11-Black.woff2") format("woff2"),url("/fonts/Kobe11-Black.woff") format("woff"),url("/fonts/Kobe11-Black.ttf") format("truetype");

  font-weight:900;

  font-style:normal;

  font-display:swap
}
@font-face{
  font-family:"Outfit";

  src:url("/fonts/Outfit-Thin.eot");

  src:url("/fonts/Outfit-Thin.eot?#iefix") format("embedded-opentype"),url("/fonts/Outfit-Thin.woff2") format("woff2"),url("/fonts/Outfit-Thin.woff") format("woff"),url("/fonts/Outfit-Thin.ttf") format("truetype");

  font-weight:100;

  font-style:normal;

  font-display:swap
}
@font-face{
  font-family:"Outfit";

  src:url("/fonts/Outfit-SemiBold.eot");

  src:url("/fonts/Outfit-SemiBold.eot?#iefix") format("embedded-opentype"),url("/fonts/Outfit-SemiBold.woff2") format("woff2"),url("/fonts/Outfit-SemiBold.woff") format("woff"),url("/fonts/Outfit-SemiBold.ttf") format("truetype");

  font-weight:600;

  font-style:normal;

  font-display:swap
}
@font-face{
  font-family:"Outfit";

  src:url("/fonts/Outfit-Black.eot");

  src:url("/fonts/Outfit-Black.eot?#iefix") format("embedded-opentype"),url("/fonts/Outfit-Black.woff2") format("woff2"),url("/fonts/Outfit-Black.woff") format("woff"),url("/fonts/Outfit-Black.ttf") format("truetype");

  font-weight:900;

  font-style:normal;

  font-display:swap
}
@font-face{
  font-family:"Outfit";

  src:url("/fonts/Outfit-ExtraLight.eot");

  src:url("/fonts/Outfit-ExtraLight.eot?#iefix") format("embedded-opentype"),url("/fonts/Outfit-ExtraLight.woff2") format("woff2"),url("/fonts/Outfit-ExtraLight.woff") format("woff"),url("/fonts/Outfit-ExtraLight.ttf") format("truetype");

  font-weight:200;

  font-style:normal;

  font-display:swap
}
@font-face{
  font-family:"Outfit";

  src:url("/fonts/Outfit-Light.eot");

  src:url("/fonts/Outfit-Light.eot?#iefix") format("embedded-opentype"),url("/fonts/Outfit-Light.woff2") format("woff2"),url("/fonts/Outfit-Light.woff") format("woff"),url("/fonts/Outfit-Light.ttf") format("truetype");

  font-weight:300;

  font-style:normal;

  font-display:swap
}
@font-face{
  font-family:"Outfit";

  src:url("/fonts/Outfit-ExtraBold.eot");

  src:url("/fonts/Outfit-ExtraBold.eot?#iefix") format("embedded-opentype"),url("/fonts/Outfit-ExtraBold.woff2") format("woff2"),url("/fonts/Outfit-ExtraBold.woff") format("woff"),url("/fonts/Outfit-ExtraBold.ttf") format("truetype");

  font-weight:800;

  font-style:normal;

  font-display:swap
}
@font-face{
  font-family:"Outfit";

  src:url("/fonts/Outfit-Regular.eot");

  src:url("/fonts/Outfit-Regular.eot?#iefix") format("embedded-opentype"),url("/fonts/Outfit-Regular.woff2") format("woff2"),url("/fonts/Outfit-Regular.woff") format("woff"),url("/fonts/Outfit-Regular.ttf") format("truetype");

  font-weight:normal;

  font-style:normal;

  font-display:swap
}
@font-face{
  font-family:"Outfit";

  src:url("/fonts/Outfit-Bold.eot");

  src:url("/fonts/Outfit-Bold.eot?#iefix") format("embedded-opentype"),url("/fonts/Outfit-Bold.woff2") format("woff2"),url("/fonts/Outfit-Bold.woff") format("woff"),url("/fonts/Outfit-Bold.ttf") format("truetype");

  font-weight:700;

  font-style:normal;

  font-display:swap
}
@font-face{
  font-family:"Outfit";

  src:url("/fonts/Outfit-Medium.eot");

  src:url("/fonts/Outfit-Medium.eot?#iefix") format("embedded-opentype"),url("/fonts/Outfit-Medium.woff2") format("woff2"),url("/fonts/Outfit-Medium.woff") format("woff"),url("/fonts/Outfit-Medium.ttf") format("truetype");

  font-weight:500;

  font-style:normal;

  font-display:swap
}
.slick-slider{
  position:relative;
  display:block;
  box-sizing:border-box;
  -webkit-user-select:none;
  -moz-user-select:none;
  user-select:none;
  -webkit-touch-callout:none;
  -khtml-user-select:none;
  touch-action:pan-y;
  -webkit-tap-highlight-color:transparent
}
.slick-list{
  position:relative;
  display:block;
  overflow:hidden;
  margin:0;
  padding:0
}
.slick-list:focus{
  outline:none
}
.slick-list.dragging{
  cursor:pointer;
  cursor:hand
}
.slick-slider .slick-track,.slick-slider .slick-list{
  transform:translate3d(0, 0, 0)
}
.slick-track{
  position:relative;
  top:0;
  left:0;
  display:block
}
.slick-track:before,.slick-track:after{
  display:table;
  content:""
}
.slick-track:after{
  clear:both
}
.slick-loading .slick-track{
  visibility:hidden
}
.slick-slide{
  display:none;
  float:left;
  height:100%;
  min-height:1px
}
[dir=rtl] .slick-slide{
  float:right
}
.slick-slide img{
  display:block
}
.slick-slide.slick-loading img{
  display:none
}
.slick-slide.dragging img{
  pointer-events:none
}
.slick-initialized .slick-slide{
  display:block
}
.slick-loading .slick-slide{
  visibility:hidden
}
.slick-vertical .slick-slide{
  display:block;
  height:auto;
  border:1px solid transparent
}
.slick-arrow.slick-hidden{
  display:none
}
:root{
  --iti-hover-color: rgba(0, 0, 0, 0.05);
  --iti-border-color: #ccc;
  --iti-dialcode-color: #999;
  --iti-dropdown-bg: white;
  --iti-spacer-horizontal: 8px;
  --iti-flag-height: 12px;
  --iti-flag-width: 16px;
  --iti-border-width: 1px;
  --iti-arrow-height: 4px;
  --iti-arrow-width: 6px;
  --iti-triangle-border: calc(var(--iti-arrow-width) / 2);
  --iti-arrow-padding: 6px;
  --iti-arrow-color: #555;
  --iti-path-flags-1x: url(/_next/static/media/flags.b2133e94.webp);
  --iti-path-flags-2x: url(/_next/static/media/flags@2x.9e947ca1.webp);
  --iti-path-globe-1x: url(/_next/static/media/globe.98e105ca.webp);
  --iti-path-globe-2x: url(/_next/static/media/globe@2x.974df6f8.webp);
  --iti-flag-sprite-width: 3904px;
  --iti-flag-sprite-height: 12px;
  --iti-mobile-popup-margin: 30px
}
.iti{
  position:relative;
  display:inline-block
}
.iti *{
  box-sizing:border-box
}
.iti__hide{
  display:none
}
.iti__v-hide{
  visibility:hidden
}
.iti__a11y-text{
  width:1px;
  height:1px;
  clip:rect(1px, 1px, 1px, 1px);
  overflow:hidden;
  position:absolute
}
.iti input.iti__tel-input,.iti input.iti__tel-input[type=text],.iti input.iti__tel-input[type=tel]{
  position:relative;
  z-index:0;
  margin:0 !important
}
.iti__country-container{
  position:absolute;
  top:0;
  bottom:0;
  padding:var(--iti-border-width)
}
.iti__selected-country{
  z-index:1;
  position:relative;
  display:flex;
  align-items:center;
  height:100%;
  background:none;
  border:0;
  margin:0;
  padding:0;
  font-family:inherit;
  font-size:inherit;
  color:inherit;
  border-radius:0;
  font-weight:inherit;
  line-height:inherit;
  text-decoration:none
}
.iti__selected-country-primary{
  display:flex;
  align-items:center;
  height:100%;
  padding:0 var(--iti-arrow-padding) 0 var(--iti-spacer-horizontal)
}
.iti__arrow{
  margin-left:var(--iti-arrow-padding);
  width:0;
  height:0;
  border-left:var(--iti-triangle-border) solid transparent;
  border-right:var(--iti-triangle-border) solid transparent;
  border-top:var(--iti-arrow-height) solid var(--iti-arrow-color)
}
[dir=rtl] .iti__arrow{
  margin-right:var(--iti-arrow-padding);
  margin-left:0
}
.iti__arrow--up{
  border-top:none;
  border-bottom:var(--iti-arrow-height) solid var(--iti-arrow-color)
}
.iti__dropdown-content{
  border-radius:3px;
  background-color:var(--iti-dropdown-bg)
}
.iti--inline-dropdown .iti__dropdown-content{
  position:absolute;
  z-index:2;
  margin-top:3px;
  margin-left:calc(var(--iti-border-width)*-1);
  border:var(--iti-border-width) solid var(--iti-border-color);
  box-shadow:1px 1px 4px rgba(0, 0, 0, 0.2)
}
.iti__search-input{
  width:100%;
  border-width:0;
  border-radius:3px
}
.iti__search-input+.iti__country-list{
  border-top:1px solid var(--iti-border-color)
}
.iti__country-list{
  list-style:none;
  padding:0;
  margin:0;
  overflow-y:scroll;
  -webkit-overflow-scrolling:touch
}
.iti--inline-dropdown .iti__country-list{
  max-height:185px
}
.iti--flexible-dropdown-width .iti__country-list{
  white-space:nowrap
}
@media(max-width: 500px){
  .iti--flexible-dropdown-width .iti__country-list{
    white-space:normal
  }
}
.iti__country{
  display:flex;
  align-items:center;
  padding:8px var(--iti-spacer-horizontal);
  outline:none
}
.iti__dial-code{
  color:var(--iti-dialcode-color)
}
.iti__country.iti__highlight{
  background-color:var(--iti-hover-color)
}
.iti__country-list .iti__flag,.iti__country-name{
  margin-right:var(--iti-spacer-horizontal)
}
[dir=rtl] .iti__country-list .iti__flag,[dir=rtl] .iti__country-name{
  margin-right:0;
  margin-left:var(--iti-spacer-horizontal)
}
.iti--allow-dropdown .iti__country-container:not(:has(+input[disabled])):not(:has(+input[readonly])):hover,.iti--allow-dropdown .iti__country-container:not(:has(+input[disabled])):not(:has(+input[readonly])):hover button{
  cursor:pointer
}
.iti--allow-dropdown .iti__country-container:not(:has(+input[disabled])):not(:has(+input[readonly])) .iti__selected-country-primary:hover,.iti--allow-dropdown .iti__country-container:not(:has(+input[disabled])):not(:has(+input[readonly])) .iti__selected-country:has(+.iti__dropdown-content:hover) .iti__selected-country-primary{
  background-color:var(--iti-hover-color)
}
.iti .iti__selected-dial-code{
  margin-left:4px
}
[dir=rtl] .iti .iti__selected-dial-code{
  margin-left:0;
  margin-right:4px
}
.iti--container{
  position:fixed;
  top:-1000px;
  left:-1000px;
  z-index:1060;
  padding:var(--iti-border-width)
}
.iti--container:hover{
  cursor:pointer
}
.iti--fullscreen-popup.iti--container{
  background-color:rgba(0, 0, 0, 0.5);
  top:0;
  bottom:0;
  left:0;
  right:0;
  position:fixed;
  padding:var(--iti-mobile-popup-margin);
  display:flex;
  flex-direction:column;
  justify-content:flex-start
}
.iti--fullscreen-popup .iti__dropdown-content{
  display:flex;
  flex-direction:column;
  max-height:100%;
  position:relative
}
.iti--fullscreen-popup .iti__country{
  padding:10px 10px;
  line-height:1.5em
}
.iti__flag{
  --iti-flag-offset: 100px;
  height:var(--iti-flag-height);
  width:var(--iti-flag-width);
  border-radius:1px;
  box-shadow:0px 0px 1px 0px #888;
  background-image:var(--iti-path-flags-1x);
  background-repeat:no-repeat;
  background-position:var(--iti-flag-offset) 0;
  background-size:var(--iti-flag-sprite-width) var(--iti-flag-sprite-height)
}
.iti__ac{
  --iti-flag-offset: 0px
}
.iti__ad{
  --iti-flag-offset: -16px
}
.iti__ae{
  --iti-flag-offset: -32px
}
.iti__af{
  --iti-flag-offset: -48px
}
.iti__ag{
  --iti-flag-offset: -64px
}
.iti__ai{
  --iti-flag-offset: -80px
}
.iti__al{
  --iti-flag-offset: -96px
}
.iti__am{
  --iti-flag-offset: -112px
}
.iti__ao{
  --iti-flag-offset: -128px
}
.iti__ar{
  --iti-flag-offset: -144px
}
.iti__as{
  --iti-flag-offset: -160px
}
.iti__at{
  --iti-flag-offset: -176px
}
.iti__au{
  --iti-flag-offset: -192px
}
.iti__aw{
  --iti-flag-offset: -208px
}
.iti__ax{
  --iti-flag-offset: -224px
}
.iti__az{
  --iti-flag-offset: -240px
}
.iti__ba{
  --iti-flag-offset: -256px
}
.iti__bb{
  --iti-flag-offset: -272px
}
.iti__bd{
  --iti-flag-offset: -288px
}
.iti__be{
  --iti-flag-offset: -304px
}
.iti__bf{
  --iti-flag-offset: -320px
}
.iti__bg{
  --iti-flag-offset: -336px
}
.iti__bh{
  --iti-flag-offset: -352px
}
.iti__bi{
  --iti-flag-offset: -368px
}
.iti__bj{
  --iti-flag-offset: -384px
}
.iti__bl{
  --iti-flag-offset: -400px
}
.iti__bm{
  --iti-flag-offset: -416px
}
.iti__bn{
  --iti-flag-offset: -432px
}
.iti__bo{
  --iti-flag-offset: -448px
}
.iti__bq{
  --iti-flag-offset: -464px
}
.iti__br{
  --iti-flag-offset: -480px
}
.iti__bs{
  --iti-flag-offset: -496px
}
.iti__bt{
  --iti-flag-offset: -512px
}
.iti__bw{
  --iti-flag-offset: -528px
}
.iti__by{
  --iti-flag-offset: -544px
}
.iti__bz{
  --iti-flag-offset: -560px
}
.iti__ca{
  --iti-flag-offset: -576px
}
.iti__cc{
  --iti-flag-offset: -592px
}
.iti__cd{
  --iti-flag-offset: -608px
}
.iti__cf{
  --iti-flag-offset: -624px
}
.iti__cg{
  --iti-flag-offset: -640px
}
.iti__ch{
  --iti-flag-offset: -656px
}
.iti__ci{
  --iti-flag-offset: -672px
}
.iti__ck{
  --iti-flag-offset: -688px
}
.iti__cl{
  --iti-flag-offset: -704px
}
.iti__cm{
  --iti-flag-offset: -720px
}
.iti__cn{
  --iti-flag-offset: -736px
}
.iti__co{
  --iti-flag-offset: -752px
}
.iti__cr{
  --iti-flag-offset: -768px
}
.iti__cu{
  --iti-flag-offset: -784px
}
.iti__cv{
  --iti-flag-offset: -800px
}
.iti__cw{
  --iti-flag-offset: -816px
}
.iti__cx{
  --iti-flag-offset: -832px
}
.iti__cy{
  --iti-flag-offset: -848px
}
.iti__cz{
  --iti-flag-offset: -864px
}
.iti__de{
  --iti-flag-offset: -880px
}
.iti__dj{
  --iti-flag-offset: -896px
}
.iti__dk{
  --iti-flag-offset: -912px
}
.iti__dm{
  --iti-flag-offset: -928px
}
.iti__do{
  --iti-flag-offset: -944px
}
.iti__dz{
  --iti-flag-offset: -960px
}
.iti__ec{
  --iti-flag-offset: -976px
}
.iti__ee{
  --iti-flag-offset: -992px
}
.iti__eg{
  --iti-flag-offset: -1008px
}
.iti__eh{
  --iti-flag-offset: -1024px
}
.iti__er{
  --iti-flag-offset: -1040px
}
.iti__es{
  --iti-flag-offset: -1056px
}
.iti__et{
  --iti-flag-offset: -1072px
}
.iti__fi{
  --iti-flag-offset: -1088px
}
.iti__fj{
  --iti-flag-offset: -1104px
}
.iti__fk{
  --iti-flag-offset: -1120px
}
.iti__fm{
  --iti-flag-offset: -1136px
}
.iti__fo{
  --iti-flag-offset: -1152px
}
.iti__fr{
  --iti-flag-offset: -1168px
}
.iti__ga{
  --iti-flag-offset: -1184px
}
.iti__gb{
  --iti-flag-offset: -1200px
}
.iti__gd{
  --iti-flag-offset: -1216px
}
.iti__ge{
  --iti-flag-offset: -1232px
}
.iti__gf{
  --iti-flag-offset: -1248px
}
.iti__gg{
  --iti-flag-offset: -1264px
}
.iti__gh{
  --iti-flag-offset: -1280px
}
.iti__gi{
  --iti-flag-offset: -1296px
}
.iti__gl{
  --iti-flag-offset: -1312px
}
.iti__gm{
  --iti-flag-offset: -1328px
}
.iti__gn{
  --iti-flag-offset: -1344px
}
.iti__gp{
  --iti-flag-offset: -1360px
}
.iti__gq{
  --iti-flag-offset: -1376px
}
.iti__gr{
  --iti-flag-offset: -1392px
}
.iti__gt{
  --iti-flag-offset: -1408px
}
.iti__gu{
  --iti-flag-offset: -1424px
}
.iti__gw{
  --iti-flag-offset: -1440px
}
.iti__gy{
  --iti-flag-offset: -1456px
}
.iti__hk{
  --iti-flag-offset: -1472px
}
.iti__hn{
  --iti-flag-offset: -1488px
}
.iti__hr{
  --iti-flag-offset: -1504px
}
.iti__ht{
  --iti-flag-offset: -1520px
}
.iti__hu{
  --iti-flag-offset: -1536px
}
.iti__id{
  --iti-flag-offset: -1552px
}
.iti__ie{
  --iti-flag-offset: -1568px
}
.iti__il{
  --iti-flag-offset: -1584px
}
.iti__im{
  --iti-flag-offset: -1600px
}
.iti__in{
  --iti-flag-offset: -1616px
}
.iti__io{
  --iti-flag-offset: -1632px
}
.iti__iq{
  --iti-flag-offset: -1648px
}
.iti__ir{
  --iti-flag-offset: -1664px
}
.iti__is{
  --iti-flag-offset: -1680px
}
.iti__it{
  --iti-flag-offset: -1696px
}
.iti__je{
  --iti-flag-offset: -1712px
}
.iti__jm{
  --iti-flag-offset: -1728px
}
.iti__jo{
  --iti-flag-offset: -1744px
}
.iti__jp{
  --iti-flag-offset: -1760px
}
.iti__ke{
  --iti-flag-offset: -1776px
}
.iti__kg{
  --iti-flag-offset: -1792px
}
.iti__kh{
  --iti-flag-offset: -1808px
}
.iti__ki{
  --iti-flag-offset: -1824px
}
.iti__km{
  --iti-flag-offset: -1840px
}
.iti__kn{
  --iti-flag-offset: -1856px
}
.iti__kp{
  --iti-flag-offset: -1872px
}
.iti__kr{
  --iti-flag-offset: -1888px
}
.iti__kw{
  --iti-flag-offset: -1904px
}
.iti__ky{
  --iti-flag-offset: -1920px
}
.iti__kz{
  --iti-flag-offset: -1936px
}
.iti__la{
  --iti-flag-offset: -1952px
}
.iti__lb{
  --iti-flag-offset: -1968px
}
.iti__lc{
  --iti-flag-offset: -1984px
}
.iti__li{
  --iti-flag-offset: -2000px
}
.iti__lk{
  --iti-flag-offset: -2016px
}
.iti__lr{
  --iti-flag-offset: -2032px
}
.iti__ls{
  --iti-flag-offset: -2048px
}
.iti__lt{
  --iti-flag-offset: -2064px
}
.iti__lu{
  --iti-flag-offset: -2080px
}
.iti__lv{
  --iti-flag-offset: -2096px
}
.iti__ly{
  --iti-flag-offset: -2112px
}
.iti__ma{
  --iti-flag-offset: -2128px
}
.iti__mc{
  --iti-flag-offset: -2144px
}
.iti__md{
  --iti-flag-offset: -2160px
}
.iti__me{
  --iti-flag-offset: -2176px
}
.iti__mf{
  --iti-flag-offset: -2192px
}
.iti__mg{
  --iti-flag-offset: -2208px
}
.iti__mh{
  --iti-flag-offset: -2224px
}
.iti__mk{
  --iti-flag-offset: -2240px
}
.iti__ml{
  --iti-flag-offset: -2256px
}
.iti__mm{
  --iti-flag-offset: -2272px
}
.iti__mn{
  --iti-flag-offset: -2288px
}
.iti__mo{
  --iti-flag-offset: -2304px
}
.iti__mp{
  --iti-flag-offset: -2320px
}
.iti__mq{
  --iti-flag-offset: -2336px
}
.iti__mr{
  --iti-flag-offset: -2352px
}
.iti__ms{
  --iti-flag-offset: -2368px
}
.iti__mt{
  --iti-flag-offset: -2384px
}
.iti__mu{
  --iti-flag-offset: -2400px
}
.iti__mv{
  --iti-flag-offset: -2416px
}
.iti__mw{
  --iti-flag-offset: -2432px
}
.iti__mx{
  --iti-flag-offset: -2448px
}
.iti__my{
  --iti-flag-offset: -2464px
}
.iti__mz{
  --iti-flag-offset: -2480px
}
.iti__na{
  --iti-flag-offset: -2496px
}
.iti__nc{
  --iti-flag-offset: -2512px
}
.iti__ne{
  --iti-flag-offset: -2528px
}
.iti__nf{
  --iti-flag-offset: -2544px
}
.iti__ng{
  --iti-flag-offset: -2560px
}
.iti__ni{
  --iti-flag-offset: -2576px
}
.iti__nl{
  --iti-flag-offset: -2592px
}
.iti__no{
  --iti-flag-offset: -2608px
}
.iti__np{
  --iti-flag-offset: -2624px
}
.iti__nr{
  --iti-flag-offset: -2640px
}
.iti__nu{
  --iti-flag-offset: -2656px
}
.iti__nz{
  --iti-flag-offset: -2672px
}
.iti__om{
  --iti-flag-offset: -2688px
}
.iti__pa{
  --iti-flag-offset: -2704px
}
.iti__pe{
  --iti-flag-offset: -2720px
}
.iti__pf{
  --iti-flag-offset: -2736px
}
.iti__pg{
  --iti-flag-offset: -2752px
}
.iti__ph{
  --iti-flag-offset: -2768px
}
.iti__pk{
  --iti-flag-offset: -2784px
}
.iti__pl{
  --iti-flag-offset: -2800px
}
.iti__pm{
  --iti-flag-offset: -2816px
}
.iti__pr{
  --iti-flag-offset: -2832px
}
.iti__ps{
  --iti-flag-offset: -2848px
}
.iti__pt{
  --iti-flag-offset: -2864px
}
.iti__pw{
  --iti-flag-offset: -2880px
}
.iti__py{
  --iti-flag-offset: -2896px
}
.iti__qa{
  --iti-flag-offset: -2912px
}
.iti__re{
  --iti-flag-offset: -2928px
}
.iti__ro{
  --iti-flag-offset: -2944px
}
.iti__rs{
  --iti-flag-offset: -2960px
}
.iti__ru{
  --iti-flag-offset: -2976px
}
.iti__rw{
  --iti-flag-offset: -2992px
}
.iti__sa{
  --iti-flag-offset: -3008px
}
.iti__sb{
  --iti-flag-offset: -3024px
}
.iti__sc{
  --iti-flag-offset: -3040px
}
.iti__sd{
  --iti-flag-offset: -3056px
}
.iti__se{
  --iti-flag-offset: -3072px
}
.iti__sg{
  --iti-flag-offset: -3088px
}
.iti__sh{
  --iti-flag-offset: -3104px
}
.iti__si{
  --iti-flag-offset: -3120px
}
.iti__sj{
  --iti-flag-offset: -3136px
}
.iti__sk{
  --iti-flag-offset: -3152px
}
.iti__sl{
  --iti-flag-offset: -3168px
}
.iti__sm{
  --iti-flag-offset: -3184px
}
.iti__sn{
  --iti-flag-offset: -3200px
}
.iti__so{
  --iti-flag-offset: -3216px
}
.iti__sr{
  --iti-flag-offset: -3232px
}
.iti__ss{
  --iti-flag-offset: -3248px
}
.iti__st{
  --iti-flag-offset: -3264px
}
.iti__sv{
  --iti-flag-offset: -3280px
}
.iti__sx{
  --iti-flag-offset: -3296px
}
.iti__sy{
  --iti-flag-offset: -3312px
}
.iti__sz{
  --iti-flag-offset: -3328px
}
.iti__tc{
  --iti-flag-offset: -3344px
}
.iti__td{
  --iti-flag-offset: -3360px
}
.iti__tg{
  --iti-flag-offset: -3376px
}
.iti__th{
  --iti-flag-offset: -3392px
}
.iti__tj{
  --iti-flag-offset: -3408px
}
.iti__tk{
  --iti-flag-offset: -3424px
}
.iti__tl{
  --iti-flag-offset: -3440px
}
.iti__tm{
  --iti-flag-offset: -3456px
}
.iti__tn{
  --iti-flag-offset: -3472px
}
.iti__to{
  --iti-flag-offset: -3488px
}
.iti__tr{
  --iti-flag-offset: -3504px
}
.iti__tt{
  --iti-flag-offset: -3520px
}
.iti__tv{
  --iti-flag-offset: -3536px
}
.iti__tw{
  --iti-flag-offset: -3552px
}
.iti__tz{
  --iti-flag-offset: -3568px
}
.iti__ua{
  --iti-flag-offset: -3584px
}
.iti__ug{
  --iti-flag-offset: -3600px
}
.iti__us{
  --iti-flag-offset: -3616px
}
.iti__uy{
  --iti-flag-offset: -3632px
}
.iti__uz{
  --iti-flag-offset: -3648px
}
.iti__va{
  --iti-flag-offset: -3664px
}
.iti__vc{
  --iti-flag-offset: -3680px
}
.iti__ve{
  --iti-flag-offset: -3696px
}
.iti__vg{
  --iti-flag-offset: -3712px
}
.iti__vi{
  --iti-flag-offset: -3728px
}
.iti__vn{
  --iti-flag-offset: -3744px
}
.iti__vu{
  --iti-flag-offset: -3760px
}
.iti__wf{
  --iti-flag-offset: -3776px
}
.iti__ws{
  --iti-flag-offset: -3792px
}
.iti__xk{
  --iti-flag-offset: -3808px
}
.iti__ye{
  --iti-flag-offset: -3824px
}
.iti__yt{
  --iti-flag-offset: -3840px
}
.iti__za{
  --iti-flag-offset: -3856px
}
.iti__zm{
  --iti-flag-offset: -3872px
}
.iti__zw{
  --iti-flag-offset: -3888px
}
.iti__globe{
  background-image:var(--iti-path-globe-1x);
  background-size:contain;
  background-position:right;
  box-shadow:none;
  height:19px
}
@media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2x){
  .iti__flag{
    background-image:var(--iti-path-flags-2x)
  }

  .iti__globe{
    background-image:var(--iti-path-globe-2x)
  }
}
:root{
  --blue: #007bfb;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2ec4dd;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #dfdfdf;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 769px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}
*,*::before,*::after{
  box-sizing:border-box
}
html{
  font-family:sans-serif;
  line-height:1.15;
  -webkit-text-size-adjust:100%;
  -webkit-tap-highlight-color:rgba(0,0,0,0)
}
article,aside,figcaption,figure,footer,header,hgroup,main,nav,section{
  display:block
}
body{
  margin:0;
  font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#414141;
  text-align:left;
  background-color:#fff
}
[tabindex="-1"]:focus:not(:focus-visible){
  outline:0 !important
}
hr{
  box-sizing:content-box;
  height:0;
  overflow:visible
}
h1,h2,h3,h4,h5,h6{
  margin-top:0;
  margin-bottom:8px
}
p{
  margin-top:0;
  margin-bottom:1rem
}
abbr[title],abbr[data-original-title]{
  text-decoration:underline;
  -webkit-text-decoration:underline dotted;
          text-decoration:underline dotted;
  cursor:help;
  border-bottom:0;
  -webkit-text-decoration-skip-ink:none;
          text-decoration-skip-ink:none
}
address{
  margin-bottom:1rem;
  font-style:normal;
  line-height:inherit
}
ol,ul,dl{
  margin-top:0;
  margin-bottom:1rem
}
ol ol,ul ul,ol ul,ul ol{
  margin-bottom:0
}
dt{
  font-weight:600
}
dd{
  margin-bottom:.5rem;
  margin-left:0
}
blockquote{
  margin:0 0 1rem
}
b,strong{
  font-weight:bolder
}
small{
  font-size:80%
}
sub,sup{
  position:relative;
  font-size:75%;
  line-height:0;
  vertical-align:baseline
}
sub{
  bottom:-0.25em
}
sup{
  top:-0.5em
}
a{
  color:#2ec4dd;
  text-decoration:none;
  background-color:rgba(0,0,0,0)
}
a:hover{
  color:#1b90a4;
  text-decoration:underline
}
a:not([href]):not([class]){
  color:inherit;
  text-decoration:none
}
a:not([href]):not([class]):hover{
  color:inherit;
  text-decoration:none
}
pre,code,kbd,samp{
  font-family:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size:1em
}
pre{
  margin-top:0;
  margin-bottom:1rem;
  overflow:auto;
  -ms-overflow-style:scrollbar
}
figure{
  margin:0 0 1rem
}
img{
  vertical-align:middle;
  border-style:none
}
svg{
  overflow:hidden;
  vertical-align:middle
}
table{
  border-collapse:collapse
}
caption{
  padding-top:.75rem;
  padding-bottom:.75rem;
  color:#6c757d;
  text-align:left;
  caption-side:bottom
}
th{
  text-align:inherit;
  text-align:-webkit-match-parent
}
label{
  display:inline-block;
  margin-bottom:.5rem
}
button{
  border-radius:0
}
button:focus:not(:focus-visible){
  outline:0
}
input,button,select,optgroup,textarea{
  margin:0;
  font-family:inherit;
  font-size:inherit;
  line-height:inherit
}
button,input{
  overflow:visible
}
button,select{
  text-transform:none
}
[role=button]{
  cursor:pointer
}
select{
  word-wrap:normal
}
button,[type=button],[type=reset],[type=submit]{
  -webkit-appearance:button
}
button:not(:disabled),[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled){
  cursor:pointer
}
button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner{
  padding:0;
  border-style:none
}
input[type=radio],input[type=checkbox]{
  box-sizing:border-box;
  padding:0
}
textarea{
  overflow:auto;
  resize:vertical
}
fieldset{
  min-width:0;
  padding:0;
  margin:0;
  border:0
}
legend{
  display:block;
  width:100%;
  max-width:100%;
  padding:0;
  margin-bottom:.5rem;
  font-size:1.5rem;
  line-height:inherit;
  color:inherit;
  white-space:normal
}
progress{
  vertical-align:baseline
}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{
  height:auto
}
[type=search]{
  outline-offset:-2px;
  -webkit-appearance:none
}
[type=search]::-webkit-search-decoration{
  -webkit-appearance:none
}
::-webkit-file-upload-button{
  font:inherit;
  -webkit-appearance:button
}
output{
  display:inline-block
}
summary{
  display:list-item;
  cursor:pointer
}
template{
  display:none
}
[hidden]{
  display:none !important
}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
  margin-bottom:8px;
  font-weight:500;
  line-height:1.2
}
h1,.h1{
  font-size:2.5rem
}
h2,.h2{
  font-size:2rem
}
h3,.h3{
  font-size:1.75rem
}
h4,.h4{
  font-size:1.5rem
}
h5,.h5{
  font-size:1.25rem
}
h6,.h6{
  font-size:1rem
}
.lead{
  font-size:1.25rem;
  font-weight:300
}
.display-1{
  font-size:6rem;
  font-weight:300;
  line-height:1.2
}
.display-2{
  font-size:5.5rem;
  font-weight:300;
  line-height:1.2
}
.display-3{
  font-size:4.5rem;
  font-weight:300;
  line-height:1.2
}
.display-4{
  font-size:3.5rem;
  font-weight:300;
  line-height:1.2
}
hr{
  margin-top:16px;
  margin-bottom:16px;
  border:0;
  border-top:1px solid rgba(0,0,0,.1)
}
small,.small{
  font-size:80%;
  font-weight:400
}
mark,.mark{
  padding:.2em;
  background-color:#fcf8e3
}
.list-unstyled{
  padding-left:0;
  list-style:none
}
.list-inline{
  padding-left:0;
  list-style:none
}
.list-inline-item{
  display:inline-block
}
.list-inline-item:not(:last-child){
  margin-right:.5rem
}
.initialism{
  font-size:90%;
  text-transform:uppercase
}
.blockquote{
  margin-bottom:16px;
  font-size:1.25rem
}
.blockquote-footer{
  display:block;
  font-size:80%;
  color:#6c757d
}
.blockquote-footer::before{
  content:"— "
}
.img-fluid{
  max-width:100%;
  height:auto
}
.img-thumbnail{
  padding:.25rem;
  background-color:#fff;
  border:1px solid #dee2e6;
  border-radius:1px;
  max-width:100%;
  height:auto
}
.figure{
  display:inline-block
}
.figure-img{
  margin-bottom:8px;
  line-height:1
}
.figure-caption{
  font-size:90%;
  color:#6c757d
}
.container,.container-fluid,.container-xl,.container-lg,.container-md,.container-sm{
  width:100%;
  padding-right:15px;
  padding-left:15px;
  margin-right:auto;
  margin-left:auto
}
@media(min-width: 576px){
  .container-sm,.container{
    max-width:600px
  }
}
@media(min-width: 769px){
  .container-md,.container-sm,.container{
    max-width:800px
  }
}
@media(min-width: 992px){
  .container-lg,.container-md,.container-sm,.container{
    max-width:960px
  }
}
@media(min-width: 1200px){
  .container-xl,.container-lg,.container-md,.container-sm,.container{
    max-width:1140px
  }
}
.row{
  display:flex;
  flex-wrap:wrap;
  margin-right:-15px;
  margin-left:-15px
}
.no-gutters{
  margin-right:0;
  margin-left:0
}
.no-gutters>.col,.no-gutters>[class*=col-]{
  padding-right:0;
  padding-left:0
}
.col-xl,.col-xl-auto,.col-xl-12,.col-xl-11,.col-xl-10,.col-xl-9,.col-xl-8,.col-xl-7,.col-xl-6,.col-xl-5,.col-xl-4,.col-xl-3,.col-xl-2,.col-xl-1,.col-lg,.col-lg-auto,.col-lg-12,.col-lg-11,.col-lg-10,.col-lg-9,.col-lg-8,.col-lg-7,.col-lg-6,.col-lg-5,.col-lg-4,.col-lg-3,.col-lg-2,.col-lg-1,.col-md,.col-md-auto,.col-md-12,.col-md-11,.col-md-10,.col-md-9,.col-md-8,.col-md-7,.col-md-6,.col-md-5,.col-md-4,.col-md-3,.col-md-2,.col-md-1,.col-sm,.col-sm-auto,.col-sm-12,.col-sm-11,.col-sm-10,.col-sm-9,.col-sm-8,.col-sm-7,.col-sm-6,.col-sm-5,.col-sm-4,.col-sm-3,.col-sm-2,.col-sm-1,.col,.col-auto,.col-12,.col-11,.col-10,.col-9,.col-8,.col-7,.col-6,.col-5,.col-4,.col-3,.col-2,.col-1{
  position:relative;
  width:100%;
  padding-right:15px;
  padding-left:15px
}
.col{
  flex-basis:0;
  flex-grow:1;
  max-width:100%
}
.row-cols-1>*{
  flex:0 0 100%;
  max-width:100%
}
.row-cols-2>*{
  flex:0 0 50%;
  max-width:50%
}
.row-cols-3>*{
  flex:0 0 33.3333333333%;
  max-width:33.3333333333%
}
.row-cols-4>*{
  flex:0 0 25%;
  max-width:25%
}
.row-cols-5>*{
  flex:0 0 20%;
  max-width:20%
}
.row-cols-6>*{
  flex:0 0 16.6666666667%;
  max-width:16.6666666667%
}
.col-auto{
  flex:0 0 auto;
  width:auto;
  max-width:100%
}
.col-1{
  flex:0 0 8.3333333333%;
  max-width:8.3333333333%
}
.col-2{
  flex:0 0 16.6666666667%;
  max-width:16.6666666667%
}
.col-3{
  flex:0 0 25%;
  max-width:25%
}
.col-4{
  flex:0 0 33.3333333333%;
  max-width:33.3333333333%
}
.col-5{
  flex:0 0 41.6666666667%;
  max-width:41.6666666667%
}
.col-6{
  flex:0 0 50%;
  max-width:50%
}
.col-7{
  flex:0 0 58.3333333333%;
  max-width:58.3333333333%
}
.col-8{
  flex:0 0 66.6666666667%;
  max-width:66.6666666667%
}
.col-9{
  flex:0 0 75%;
  max-width:75%
}
.col-10{
  flex:0 0 83.3333333333%;
  max-width:83.3333333333%
}
.col-11{
  flex:0 0 91.6666666667%;
  max-width:91.6666666667%
}
.col-12{
  flex:0 0 100%;
  max-width:100%
}
.order-first{
  order:-1
}
.order-last{
  order:13
}
.order-0{
  order:0
}
.order-1{
  order:1
}
.order-2{
  order:2
}
.order-3{
  order:3
}
.order-4{
  order:4
}
.order-5{
  order:5
}
.order-6{
  order:6
}
.order-7{
  order:7
}
.order-8{
  order:8
}
.order-9{
  order:9
}
.order-10{
  order:10
}
.order-11{
  order:11
}
.order-12{
  order:12
}
.offset-1{
  margin-left:8.3333333333%
}
.offset-2{
  margin-left:16.6666666667%
}
.offset-3{
  margin-left:25%
}
.offset-4{
  margin-left:33.3333333333%
}
.offset-5{
  margin-left:41.6666666667%
}
.offset-6{
  margin-left:50%
}
.offset-7{
  margin-left:58.3333333333%
}
.offset-8{
  margin-left:66.6666666667%
}
.offset-9{
  margin-left:75%
}
.offset-10{
  margin-left:83.3333333333%
}
.offset-11{
  margin-left:91.6666666667%
}
@media(min-width: 576px){
  .col-sm{
    flex-basis:0;
    flex-grow:1;
    max-width:100%
  }

  .row-cols-sm-1>*{
    flex:0 0 100%;
    max-width:100%
  }

  .row-cols-sm-2>*{
    flex:0 0 50%;
    max-width:50%
  }

  .row-cols-sm-3>*{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .row-cols-sm-4>*{
    flex:0 0 25%;
    max-width:25%
  }

  .row-cols-sm-5>*{
    flex:0 0 20%;
    max-width:20%
  }

  .row-cols-sm-6>*{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-sm-auto{
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-sm-1{
    flex:0 0 8.3333333333%;
    max-width:8.3333333333%
  }

  .col-sm-2{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-sm-3{
    flex:0 0 25%;
    max-width:25%
  }

  .col-sm-4{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .col-sm-5{
    flex:0 0 41.6666666667%;
    max-width:41.6666666667%
  }

  .col-sm-6{
    flex:0 0 50%;
    max-width:50%
  }

  .col-sm-7{
    flex:0 0 58.3333333333%;
    max-width:58.3333333333%
  }

  .col-sm-8{
    flex:0 0 66.6666666667%;
    max-width:66.6666666667%
  }

  .col-sm-9{
    flex:0 0 75%;
    max-width:75%
  }

  .col-sm-10{
    flex:0 0 83.3333333333%;
    max-width:83.3333333333%
  }

  .col-sm-11{
    flex:0 0 91.6666666667%;
    max-width:91.6666666667%
  }

  .col-sm-12{
    flex:0 0 100%;
    max-width:100%
  }

  .order-sm-first{
    order:-1
  }

  .order-sm-last{
    order:13
  }

  .order-sm-0{
    order:0
  }

  .order-sm-1{
    order:1
  }

  .order-sm-2{
    order:2
  }

  .order-sm-3{
    order:3
  }

  .order-sm-4{
    order:4
  }

  .order-sm-5{
    order:5
  }

  .order-sm-6{
    order:6
  }

  .order-sm-7{
    order:7
  }

  .order-sm-8{
    order:8
  }

  .order-sm-9{
    order:9
  }

  .order-sm-10{
    order:10
  }

  .order-sm-11{
    order:11
  }

  .order-sm-12{
    order:12
  }

  .offset-sm-0{
    margin-left:0
  }

  .offset-sm-1{
    margin-left:8.3333333333%
  }

  .offset-sm-2{
    margin-left:16.6666666667%
  }

  .offset-sm-3{
    margin-left:25%
  }

  .offset-sm-4{
    margin-left:33.3333333333%
  }

  .offset-sm-5{
    margin-left:41.6666666667%
  }

  .offset-sm-6{
    margin-left:50%
  }

  .offset-sm-7{
    margin-left:58.3333333333%
  }

  .offset-sm-8{
    margin-left:66.6666666667%
  }

  .offset-sm-9{
    margin-left:75%
  }

  .offset-sm-10{
    margin-left:83.3333333333%
  }

  .offset-sm-11{
    margin-left:91.6666666667%
  }
}
@media(min-width: 769px){
  .col-md{
    flex-basis:0;
    flex-grow:1;
    max-width:100%
  }

  .row-cols-md-1>*{
    flex:0 0 100%;
    max-width:100%
  }

  .row-cols-md-2>*{
    flex:0 0 50%;
    max-width:50%
  }

  .row-cols-md-3>*{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .row-cols-md-4>*{
    flex:0 0 25%;
    max-width:25%
  }

  .row-cols-md-5>*{
    flex:0 0 20%;
    max-width:20%
  }

  .row-cols-md-6>*{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-md-auto{
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-md-1{
    flex:0 0 8.3333333333%;
    max-width:8.3333333333%
  }

  .col-md-2{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-md-3{
    flex:0 0 25%;
    max-width:25%
  }

  .col-md-4{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .col-md-5{
    flex:0 0 41.6666666667%;
    max-width:41.6666666667%
  }

  .col-md-6{
    flex:0 0 50%;
    max-width:50%
  }

  .col-md-7{
    flex:0 0 58.3333333333%;
    max-width:58.3333333333%
  }

  .col-md-8{
    flex:0 0 66.6666666667%;
    max-width:66.6666666667%
  }

  .col-md-9{
    flex:0 0 75%;
    max-width:75%
  }

  .col-md-10{
    flex:0 0 83.3333333333%;
    max-width:83.3333333333%
  }

  .col-md-11{
    flex:0 0 91.6666666667%;
    max-width:91.6666666667%
  }

  .col-md-12{
    flex:0 0 100%;
    max-width:100%
  }

  .order-md-first{
    order:-1
  }

  .order-md-last{
    order:13
  }

  .order-md-0{
    order:0
  }

  .order-md-1{
    order:1
  }

  .order-md-2{
    order:2
  }

  .order-md-3{
    order:3
  }

  .order-md-4{
    order:4
  }

  .order-md-5{
    order:5
  }

  .order-md-6{
    order:6
  }

  .order-md-7{
    order:7
  }

  .order-md-8{
    order:8
  }

  .order-md-9{
    order:9
  }

  .order-md-10{
    order:10
  }

  .order-md-11{
    order:11
  }

  .order-md-12{
    order:12
  }

  .offset-md-0{
    margin-left:0
  }

  .offset-md-1{
    margin-left:8.3333333333%
  }

  .offset-md-2{
    margin-left:16.6666666667%
  }

  .offset-md-3{
    margin-left:25%
  }

  .offset-md-4{
    margin-left:33.3333333333%
  }

  .offset-md-5{
    margin-left:41.6666666667%
  }

  .offset-md-6{
    margin-left:50%
  }

  .offset-md-7{
    margin-left:58.3333333333%
  }

  .offset-md-8{
    margin-left:66.6666666667%
  }

  .offset-md-9{
    margin-left:75%
  }

  .offset-md-10{
    margin-left:83.3333333333%
  }

  .offset-md-11{
    margin-left:91.6666666667%
  }
}
@media(min-width: 992px){
  .col-lg{
    flex-basis:0;
    flex-grow:1;
    max-width:100%
  }

  .row-cols-lg-1>*{
    flex:0 0 100%;
    max-width:100%
  }

  .row-cols-lg-2>*{
    flex:0 0 50%;
    max-width:50%
  }

  .row-cols-lg-3>*{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .row-cols-lg-4>*{
    flex:0 0 25%;
    max-width:25%
  }

  .row-cols-lg-5>*{
    flex:0 0 20%;
    max-width:20%
  }

  .row-cols-lg-6>*{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-lg-auto{
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-lg-1{
    flex:0 0 8.3333333333%;
    max-width:8.3333333333%
  }

  .col-lg-2{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-lg-3{
    flex:0 0 25%;
    max-width:25%
  }

  .col-lg-4{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .col-lg-5{
    flex:0 0 41.6666666667%;
    max-width:41.6666666667%
  }

  .col-lg-6{
    flex:0 0 50%;
    max-width:50%
  }

  .col-lg-7{
    flex:0 0 58.3333333333%;
    max-width:58.3333333333%
  }

  .col-lg-8{
    flex:0 0 66.6666666667%;
    max-width:66.6666666667%
  }

  .col-lg-9{
    flex:0 0 75%;
    max-width:75%
  }

  .col-lg-10{
    flex:0 0 83.3333333333%;
    max-width:83.3333333333%
  }

  .col-lg-11{
    flex:0 0 91.6666666667%;
    max-width:91.6666666667%
  }

  .col-lg-12{
    flex:0 0 100%;
    max-width:100%
  }

  .order-lg-first{
    order:-1
  }

  .order-lg-last{
    order:13
  }

  .order-lg-0{
    order:0
  }

  .order-lg-1{
    order:1
  }

  .order-lg-2{
    order:2
  }

  .order-lg-3{
    order:3
  }

  .order-lg-4{
    order:4
  }

  .order-lg-5{
    order:5
  }

  .order-lg-6{
    order:6
  }

  .order-lg-7{
    order:7
  }

  .order-lg-8{
    order:8
  }

  .order-lg-9{
    order:9
  }

  .order-lg-10{
    order:10
  }

  .order-lg-11{
    order:11
  }

  .order-lg-12{
    order:12
  }

  .offset-lg-0{
    margin-left:0
  }

  .offset-lg-1{
    margin-left:8.3333333333%
  }

  .offset-lg-2{
    margin-left:16.6666666667%
  }

  .offset-lg-3{
    margin-left:25%
  }

  .offset-lg-4{
    margin-left:33.3333333333%
  }

  .offset-lg-5{
    margin-left:41.6666666667%
  }

  .offset-lg-6{
    margin-left:50%
  }

  .offset-lg-7{
    margin-left:58.3333333333%
  }

  .offset-lg-8{
    margin-left:66.6666666667%
  }

  .offset-lg-9{
    margin-left:75%
  }

  .offset-lg-10{
    margin-left:83.3333333333%
  }

  .offset-lg-11{
    margin-left:91.6666666667%
  }
}
@media(min-width: 1200px){
  .col-xl{
    flex-basis:0;
    flex-grow:1;
    max-width:100%
  }

  .row-cols-xl-1>*{
    flex:0 0 100%;
    max-width:100%
  }

  .row-cols-xl-2>*{
    flex:0 0 50%;
    max-width:50%
  }

  .row-cols-xl-3>*{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .row-cols-xl-4>*{
    flex:0 0 25%;
    max-width:25%
  }

  .row-cols-xl-5>*{
    flex:0 0 20%;
    max-width:20%
  }

  .row-cols-xl-6>*{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-xl-auto{
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-xl-1{
    flex:0 0 8.3333333333%;
    max-width:8.3333333333%
  }

  .col-xl-2{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-xl-3{
    flex:0 0 25%;
    max-width:25%
  }

  .col-xl-4{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .col-xl-5{
    flex:0 0 41.6666666667%;
    max-width:41.6666666667%
  }

  .col-xl-6{
    flex:0 0 50%;
    max-width:50%
  }

  .col-xl-7{
    flex:0 0 58.3333333333%;
    max-width:58.3333333333%
  }

  .col-xl-8{
    flex:0 0 66.6666666667%;
    max-width:66.6666666667%
  }

  .col-xl-9{
    flex:0 0 75%;
    max-width:75%
  }

  .col-xl-10{
    flex:0 0 83.3333333333%;
    max-width:83.3333333333%
  }

  .col-xl-11{
    flex:0 0 91.6666666667%;
    max-width:91.6666666667%
  }

  .col-xl-12{
    flex:0 0 100%;
    max-width:100%
  }

  .order-xl-first{
    order:-1
  }

  .order-xl-last{
    order:13
  }

  .order-xl-0{
    order:0
  }

  .order-xl-1{
    order:1
  }

  .order-xl-2{
    order:2
  }

  .order-xl-3{
    order:3
  }

  .order-xl-4{
    order:4
  }

  .order-xl-5{
    order:5
  }

  .order-xl-6{
    order:6
  }

  .order-xl-7{
    order:7
  }

  .order-xl-8{
    order:8
  }

  .order-xl-9{
    order:9
  }

  .order-xl-10{
    order:10
  }

  .order-xl-11{
    order:11
  }

  .order-xl-12{
    order:12
  }

  .offset-xl-0{
    margin-left:0
  }

  .offset-xl-1{
    margin-left:8.3333333333%
  }

  .offset-xl-2{
    margin-left:16.6666666667%
  }

  .offset-xl-3{
    margin-left:25%
  }

  .offset-xl-4{
    margin-left:33.3333333333%
  }

  .offset-xl-5{
    margin-left:41.6666666667%
  }

  .offset-xl-6{
    margin-left:50%
  }

  .offset-xl-7{
    margin-left:58.3333333333%
  }

  .offset-xl-8{
    margin-left:66.6666666667%
  }

  .offset-xl-9{
    margin-left:75%
  }

  .offset-xl-10{
    margin-left:83.3333333333%
  }

  .offset-xl-11{
    margin-left:91.6666666667%
  }
}
.form-control{
  display:block;
  width:100%;
  height:calc(1.5em + 0.75rem + 2px);
  padding:.375rem .75rem;
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#495057;
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid #dfdfdf;
  border-radius:1px;
  transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
@media(prefers-reduced-motion: reduce){
  .form-control{
    transition:none
  }
}
.form-control::-ms-expand{
  background-color:rgba(0,0,0,0);
  border:0
}
.form-control:-moz-focusring{
  color:rgba(0,0,0,0);
  text-shadow:0 0 0 #495057
}
.form-control:focus{
  color:#495057;
  background-color:#fff;
  border-color:#9ce3ef;
  outline:0;
  box-shadow:0 0 0 .2rem rgba(46,196,221,.25)
}
.form-control::-moz-placeholder{
  color:#6a6a6a;
  opacity:1
}
.form-control::placeholder{
  color:#6a6a6a;
  opacity:1
}
.form-control:disabled,.form-control[readonly]{
  background-color:#e9ecef;
  opacity:1
}
input[type=date].form-control,input[type=time].form-control,input[type=datetime-local].form-control,input[type=month].form-control{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none
}
select.form-control:focus::-ms-value{
  color:#495057;
  background-color:#fff
}
.form-control-file,.form-control-range{
  display:block;
  width:100%
}
.col-form-label{
  padding-top:calc(0.375rem + 1px);
  padding-bottom:calc(0.375rem + 1px);
  margin-bottom:0;
  font-size:inherit;
  line-height:1.5
}
.col-form-label-lg{
  padding-top:calc(0.5rem + 1px);
  padding-bottom:calc(0.5rem + 1px);
  font-size:1.25rem;
  line-height:1.5
}
.col-form-label-sm{
  padding-top:calc(0.25rem + 1px);
  padding-bottom:calc(0.25rem + 1px);
  font-size:0.875rem;
  line-height:1.5
}
.form-control-plaintext{
  display:block;
  width:100%;
  padding:.375rem 0;
  margin-bottom:0;
  font-size:1rem;
  line-height:1.5;
  color:#414141;
  background-color:rgba(0,0,0,0);
  border:solid rgba(0,0,0,0);
  border-width:1px 0
}
.form-control-plaintext.form-control-sm,.form-control-plaintext.form-control-lg{
  padding-right:0;
  padding-left:0
}
.form-control-sm{
  height:calc(1.5em + 0.5rem + 2px);
  padding:.25rem .5rem;
  font-size:0.875rem;
  line-height:1.5;
  border-radius:.2rem
}
.form-control-lg{
  height:calc(1.5em + 1rem + 2px);
  padding:.5rem 1rem;
  font-size:1.25rem;
  line-height:1.5;
  border-radius:.3rem
}
select.form-control[size],select.form-control[multiple]{
  height:auto
}
textarea.form-control{
  height:auto
}
.form-group{
  margin-bottom:1rem
}
.form-text{
  display:block;
  margin-top:.25rem
}
.form-row{
  display:flex;
  flex-wrap:wrap;
  margin-right:-5px;
  margin-left:-5px
}
.form-row>.col,.form-row>[class*=col-]{
  padding-right:5px;
  padding-left:5px
}
.form-check{
  position:relative;
  display:block;
  padding-left:1.25rem
}
.form-check-input{
  position:absolute;
  margin-top:.3rem;
  margin-left:-1.25rem
}
.form-check-input[disabled]~.form-check-label,.form-check-input:disabled~.form-check-label{
  color:#6c757d
}
.form-check-label{
  margin-bottom:0
}
.form-check-inline{
  display:inline-flex;
  align-items:center;
  padding-left:0;
  margin-right:.75rem
}
.form-check-inline .form-check-input{
  position:static;
  margin-top:0;
  margin-right:.3125rem;
  margin-left:0
}
.valid-feedback{
  display:none;
  width:100%;
  margin-top:.25rem;
  font-size:80%;
  color:#28a745
}
.valid-tooltip{
  position:absolute;
  top:100%;
  left:0;
  z-index:5;
  display:none;
  max-width:100%;
  padding:.25rem .5rem;
  margin-top:.1rem;
  font-size:0.875rem;
  line-height:1.5;
  color:#fff;
  background-color:rgba(40,167,69,.9);
  border-radius:1px
}
.form-row>.col>.valid-tooltip,.form-row>[class*=col-]>.valid-tooltip{
  left:5px
}
.was-validated :valid~.valid-feedback,.was-validated :valid~.valid-tooltip,.is-valid~.valid-feedback,.is-valid~.valid-tooltip{
  display:block
}
.was-validated .form-control:valid,.form-control.is-valid{
  border-color:#28a745;
  padding-right:calc(1.5em + 0.75rem);
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat:no-repeat;
  background-position:right calc(0.375em + 0.1875rem) center;
  background-size:calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}
.was-validated .form-control:valid:focus,.form-control.is-valid:focus{
  border-color:#28a745;
  box-shadow:0 0 0 .2rem rgba(40,167,69,.25)
}
.was-validated textarea.form-control:valid,textarea.form-control.is-valid{
  padding-right:calc(1.5em + 0.75rem);
  background-position:top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}
.was-validated .custom-select:valid,.custom-select.is-valid{
  border-color:#28a745;
  padding-right:calc(0.75em + 2.3125rem);
  background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat
}
.was-validated .custom-select:valid:focus,.custom-select.is-valid:focus{
  border-color:#28a745;
  box-shadow:0 0 0 .2rem rgba(40,167,69,.25)
}
.was-validated .form-check-input:valid~.form-check-label,.form-check-input.is-valid~.form-check-label{
  color:#28a745
}
.was-validated .form-check-input:valid~.valid-feedback,.was-validated .form-check-input:valid~.valid-tooltip,.form-check-input.is-valid~.valid-feedback,.form-check-input.is-valid~.valid-tooltip{
  display:block
}
.was-validated .custom-control-input:valid~.custom-control-label,.custom-control-input.is-valid~.custom-control-label{
  color:#28a745
}
.was-validated .custom-control-input:valid~.custom-control-label::before,.custom-control-input.is-valid~.custom-control-label::before{
  border-color:#28a745
}
.was-validated .custom-control-input:valid:checked~.custom-control-label::before,.custom-control-input.is-valid:checked~.custom-control-label::before{
  border-color:#34ce57;
  background-color:#34ce57
}
.was-validated .custom-control-input:valid:focus~.custom-control-label::before,.custom-control-input.is-valid:focus~.custom-control-label::before{
  box-shadow:0 0 0 .2rem rgba(40,167,69,.25)
}
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before{
  border-color:#28a745
}
.was-validated .custom-file-input:valid~.custom-file-label,.custom-file-input.is-valid~.custom-file-label{
  border-color:#28a745
}
.was-validated .custom-file-input:valid:focus~.custom-file-label,.custom-file-input.is-valid:focus~.custom-file-label{
  border-color:#28a745;
  box-shadow:0 0 0 .2rem rgba(40,167,69,.25)
}
.invalid-feedback{
  display:none;
  width:100%;
  margin-top:.25rem;
  font-size:80%;
  color:#dc3545
}
.invalid-tooltip{
  position:absolute;
  top:100%;
  left:0;
  z-index:5;
  display:none;
  max-width:100%;
  padding:.25rem .5rem;
  margin-top:.1rem;
  font-size:0.875rem;
  line-height:1.5;
  color:#fff;
  background-color:rgba(220,53,69,.9);
  border-radius:1px
}
.form-row>.col>.invalid-tooltip,.form-row>[class*=col-]>.invalid-tooltip{
  left:5px
}
.was-validated :invalid~.invalid-feedback,.was-validated :invalid~.invalid-tooltip,.is-invalid~.invalid-feedback,.is-invalid~.invalid-tooltip{
  display:block
}
.was-validated .form-control:invalid,.form-control.is-invalid{
  border-color:#dc3545;
  padding-right:calc(1.5em + 0.75rem);
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat:no-repeat;
  background-position:right calc(0.375em + 0.1875rem) center;
  background-size:calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}
.was-validated .form-control:invalid:focus,.form-control.is-invalid:focus{
  border-color:#dc3545;
  box-shadow:0 0 0 .2rem rgba(220,53,69,.25)
}
.was-validated textarea.form-control:invalid,textarea.form-control.is-invalid{
  padding-right:calc(1.5em + 0.75rem);
  background-position:top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}
.was-validated .custom-select:invalid,.custom-select.is-invalid{
  border-color:#dc3545;
  padding-right:calc(0.75em + 2.3125rem);
  background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat
}
.was-validated .custom-select:invalid:focus,.custom-select.is-invalid:focus{
  border-color:#dc3545;
  box-shadow:0 0 0 .2rem rgba(220,53,69,.25)
}
.was-validated .form-check-input:invalid~.form-check-label,.form-check-input.is-invalid~.form-check-label{
  color:#dc3545
}
.was-validated .form-check-input:invalid~.invalid-feedback,.was-validated .form-check-input:invalid~.invalid-tooltip,.form-check-input.is-invalid~.invalid-feedback,.form-check-input.is-invalid~.invalid-tooltip{
  display:block
}
.was-validated .custom-control-input:invalid~.custom-control-label,.custom-control-input.is-invalid~.custom-control-label{
  color:#dc3545
}
.was-validated .custom-control-input:invalid~.custom-control-label::before,.custom-control-input.is-invalid~.custom-control-label::before{
  border-color:#dc3545
}
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,.custom-control-input.is-invalid:checked~.custom-control-label::before{
  border-color:#e4606d;
  background-color:#e4606d
}
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,.custom-control-input.is-invalid:focus~.custom-control-label::before{
  box-shadow:0 0 0 .2rem rgba(220,53,69,.25)
}
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before{
  border-color:#dc3545
}
.was-validated .custom-file-input:invalid~.custom-file-label,.custom-file-input.is-invalid~.custom-file-label{
  border-color:#dc3545
}
.was-validated .custom-file-input:invalid:focus~.custom-file-label,.custom-file-input.is-invalid:focus~.custom-file-label{
  border-color:#dc3545;
  box-shadow:0 0 0 .2rem rgba(220,53,69,.25)
}
.form-inline{
  display:flex;
  flex-flow:row wrap;
  align-items:center
}
.form-inline .form-check{
  width:100%
}
@media(min-width: 576px){
  .form-inline label{
    display:flex;
    align-items:center;
    justify-content:center;
    margin-bottom:0
  }

  .form-inline .form-group{
    display:flex;
    flex:0 0 auto;
    flex-flow:row wrap;
    align-items:center;
    margin-bottom:0
  }

  .form-inline .form-control{
    display:inline-block;
    width:auto;
    vertical-align:middle
  }

  .form-inline .form-control-plaintext{
    display:inline-block
  }

  .form-inline .input-group,.form-inline .custom-select{
    width:auto
  }

  .form-inline .form-check{
    display:flex;
    align-items:center;
    justify-content:center;
    width:auto;
    padding-left:0
  }

  .form-inline .form-check-input{
    position:relative;
    flex-shrink:0;
    margin-top:0;
    margin-right:.25rem;
    margin-left:0
  }

  .form-inline .custom-control{
    align-items:center;
    justify-content:center
  }

  .form-inline .custom-control-label{
    margin-bottom:0
  }
}
.btn,.uppy-FileInput-btn{
  display:inline-block;
  font-weight:400;
  color:#414141;
  text-align:center;
  vertical-align:middle;
  -webkit-user-select:none;
     -moz-user-select:none;
          user-select:none;
  background-color:rgba(0,0,0,0);
  border:1px solid rgba(0,0,0,0);
  padding:.375rem .75rem;
  font-size:1rem;
  line-height:1.5;
  border-radius:1px;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
@media(prefers-reduced-motion: reduce){
  .btn,.uppy-FileInput-btn{
    transition:none
  }
}
.btn:hover,.uppy-FileInput-btn:hover{
  color:#414141;
  text-decoration:none
}
.btn:focus,.uppy-FileInput-btn:focus,.btn.focus,.focus.uppy-FileInput-btn{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(46,196,221,.25)
}
.btn.disabled,.disabled.uppy-FileInput-btn,.btn:disabled,.uppy-FileInput-btn:disabled{
  opacity:.65
}
.btn:not(:disabled):not(.disabled),.uppy-FileInput-btn:not(:disabled):not(.disabled){
  cursor:pointer
}
a.btn.disabled,a.disabled.uppy-FileInput-btn,fieldset:disabled a.btn,fieldset:disabled a.uppy-FileInput-btn{
  pointer-events:none
}
.btn-primary,.uppy-FileInput-btn{
  color:#212529;
  background-color:#2ec4dd;
  border-color:#2ec4dd
}
.btn-primary:hover,.uppy-FileInput-btn:hover{
  color:#fff;
  background-color:#20adc5;
  border-color:#1ea4ba
}
.btn-primary:focus,.uppy-FileInput-btn:focus,.btn-primary.focus,.focus.uppy-FileInput-btn{
  color:#fff;
  background-color:#20adc5;
  border-color:#1ea4ba;
  box-shadow:0 0 0 .2rem rgba(44,172,194,.5)
}
.btn-primary.disabled,.disabled.uppy-FileInput-btn,.btn-primary:disabled,.uppy-FileInput-btn:disabled{
  color:#212529;
  background-color:#2ec4dd;
  border-color:#2ec4dd
}
.btn-primary:not(:disabled):not(.disabled):active,.uppy-FileInput-btn:not(:disabled):not(.disabled):active,.btn-primary:not(:disabled):not(.disabled).active,.uppy-FileInput-btn:not(:disabled):not(.disabled).active,.show>.btn-primary.dropdown-toggle,.show>.dropdown-toggle.uppy-FileInput-btn{
  color:#fff;
  background-color:#1ea4ba;
  border-color:#1c9aaf
}
.btn-primary:not(:disabled):not(.disabled):active:focus,.uppy-FileInput-btn:not(:disabled):not(.disabled):active:focus,.btn-primary:not(:disabled):not(.disabled).active:focus,.uppy-FileInput-btn:not(:disabled):not(.disabled).active:focus,.show>.btn-primary.dropdown-toggle:focus,.show>.dropdown-toggle.uppy-FileInput-btn:focus{
  box-shadow:0 0 0 .2rem rgba(44,172,194,.5)
}
.btn-secondary{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}
.btn-secondary:hover{
  color:#fff;
  background-color:#5a6268;
  border-color:#545b62
}
.btn-secondary:focus,.btn-secondary.focus{
  color:#fff;
  background-color:#5a6268;
  border-color:#545b62;
  box-shadow:0 0 0 .2rem rgba(130,138,145,.5)
}
.btn-secondary.disabled,.btn-secondary:disabled{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}
.btn-secondary:not(:disabled):not(.disabled):active,.btn-secondary:not(:disabled):not(.disabled).active,.show>.btn-secondary.dropdown-toggle{
  color:#fff;
  background-color:#545b62;
  border-color:#4e555b
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,.btn-secondary:not(:disabled):not(.disabled).active:focus,.show>.btn-secondary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(130,138,145,.5)
}
.btn-success{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}
.btn-success:hover{
  color:#fff;
  background-color:#218838;
  border-color:#1e7e34
}
.btn-success:focus,.btn-success.focus{
  color:#fff;
  background-color:#218838;
  border-color:#1e7e34;
  box-shadow:0 0 0 .2rem rgba(72,180,97,.5)
}
.btn-success.disabled,.btn-success:disabled{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}
.btn-success:not(:disabled):not(.disabled):active,.btn-success:not(:disabled):not(.disabled).active,.show>.btn-success.dropdown-toggle{
  color:#fff;
  background-color:#1e7e34;
  border-color:#1c7430
}
.btn-success:not(:disabled):not(.disabled):active:focus,.btn-success:not(:disabled):not(.disabled).active:focus,.show>.btn-success.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(72,180,97,.5)
}
.btn-info{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}
.btn-info:hover{
  color:#fff;
  background-color:#138496;
  border-color:#117a8b
}
.btn-info:focus,.btn-info.focus{
  color:#fff;
  background-color:#138496;
  border-color:#117a8b;
  box-shadow:0 0 0 .2rem rgba(58,176,195,.5)
}
.btn-info.disabled,.btn-info:disabled{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}
.btn-info:not(:disabled):not(.disabled):active,.btn-info:not(:disabled):not(.disabled).active,.show>.btn-info.dropdown-toggle{
  color:#fff;
  background-color:#117a8b;
  border-color:#10707f
}
.btn-info:not(:disabled):not(.disabled):active:focus,.btn-info:not(:disabled):not(.disabled).active:focus,.show>.btn-info.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(58,176,195,.5)
}
.btn-warning{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}
.btn-warning:hover{
  color:#212529;
  background-color:#e0a800;
  border-color:#d39e00
}
.btn-warning:focus,.btn-warning.focus{
  color:#212529;
  background-color:#e0a800;
  border-color:#d39e00;
  box-shadow:0 0 0 .2rem rgba(222,170,12,.5)
}
.btn-warning.disabled,.btn-warning:disabled{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}
.btn-warning:not(:disabled):not(.disabled):active,.btn-warning:not(:disabled):not(.disabled).active,.show>.btn-warning.dropdown-toggle{
  color:#212529;
  background-color:#d39e00;
  border-color:#c69500
}
.btn-warning:not(:disabled):not(.disabled):active:focus,.btn-warning:not(:disabled):not(.disabled).active:focus,.show>.btn-warning.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(222,170,12,.5)
}
.btn-danger{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}
.btn-danger:hover{
  color:#fff;
  background-color:#c82333;
  border-color:#bd2130
}
.btn-danger:focus,.btn-danger.focus{
  color:#fff;
  background-color:#c82333;
  border-color:#bd2130;
  box-shadow:0 0 0 .2rem rgba(225,83,97,.5)
}
.btn-danger.disabled,.btn-danger:disabled{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}
.btn-danger:not(:disabled):not(.disabled):active,.btn-danger:not(:disabled):not(.disabled).active,.show>.btn-danger.dropdown-toggle{
  color:#fff;
  background-color:#bd2130;
  border-color:#b21f2d
}
.btn-danger:not(:disabled):not(.disabled):active:focus,.btn-danger:not(:disabled):not(.disabled).active:focus,.show>.btn-danger.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(225,83,97,.5)
}
.btn-light{
  color:#212529;
  background-color:#dfdfdf;
  border-color:#dfdfdf
}
.btn-light:hover{
  color:#212529;
  background-color:#ccc;
  border-color:#c6c6c6
}
.btn-light:focus,.btn-light.focus{
  color:#212529;
  background-color:#ccc;
  border-color:#c6c6c6;
  box-shadow:0 0 0 .2rem rgba(195,195,196,.5)
}
.btn-light.disabled,.btn-light:disabled{
  color:#212529;
  background-color:#dfdfdf;
  border-color:#dfdfdf
}
.btn-light:not(:disabled):not(.disabled):active,.btn-light:not(:disabled):not(.disabled).active,.show>.btn-light.dropdown-toggle{
  color:#212529;
  background-color:#c6c6c6;
  border-color:#bfbfbf
}
.btn-light:not(:disabled):not(.disabled):active:focus,.btn-light:not(:disabled):not(.disabled).active:focus,.show>.btn-light.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(195,195,196,.5)
}
.btn-dark{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}
.btn-dark:hover{
  color:#fff;
  background-color:#23272b;
  border-color:#1d2124
}
.btn-dark:focus,.btn-dark.focus{
  color:#fff;
  background-color:#23272b;
  border-color:#1d2124;
  box-shadow:0 0 0 .2rem rgba(82,88,93,.5)
}
.btn-dark.disabled,.btn-dark:disabled{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}
.btn-dark:not(:disabled):not(.disabled):active,.btn-dark:not(:disabled):not(.disabled).active,.show>.btn-dark.dropdown-toggle{
  color:#fff;
  background-color:#1d2124;
  border-color:#171a1d
}
.btn-dark:not(:disabled):not(.disabled):active:focus,.btn-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-dark.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(82,88,93,.5)
}
.btn-outline-primary{
  color:#2ec4dd;
  border-color:#2ec4dd
}
.btn-outline-primary:hover{
  color:#212529;
  background-color:#2ec4dd;
  border-color:#2ec4dd
}
.btn-outline-primary:focus,.btn-outline-primary.focus{
  box-shadow:0 0 0 .2rem rgba(46,196,221,.5)
}
.btn-outline-primary.disabled,.btn-outline-primary:disabled{
  color:#2ec4dd;
  background-color:rgba(0,0,0,0)
}
.btn-outline-primary:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active,.show>.btn-outline-primary.dropdown-toggle{
  color:#212529;
  background-color:#2ec4dd;
  border-color:#2ec4dd
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,.btn-outline-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-primary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(46,196,221,.5)
}
.btn-outline-secondary{
  color:#6c757d;
  border-color:#6c757d
}
.btn-outline-secondary:hover{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}
.btn-outline-secondary:focus,.btn-outline-secondary.focus{
  box-shadow:0 0 0 .2rem rgba(108,117,125,.5)
}
.btn-outline-secondary.disabled,.btn-outline-secondary:disabled{
  color:#6c757d;
  background-color:rgba(0,0,0,0)
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,.btn-outline-secondary:not(:disabled):not(.disabled).active,.show>.btn-outline-secondary.dropdown-toggle{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-secondary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(108,117,125,.5)
}
.btn-outline-success{
  color:#28a745;
  border-color:#28a745
}
.btn-outline-success:hover{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}
.btn-outline-success:focus,.btn-outline-success.focus{
  box-shadow:0 0 0 .2rem rgba(40,167,69,.5)
}
.btn-outline-success.disabled,.btn-outline-success:disabled{
  color:#28a745;
  background-color:rgba(0,0,0,0)
}
.btn-outline-success:not(:disabled):not(.disabled):active,.btn-outline-success:not(:disabled):not(.disabled).active,.show>.btn-outline-success.dropdown-toggle{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,.btn-outline-success:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-success.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(40,167,69,.5)
}
.btn-outline-info{
  color:#17a2b8;
  border-color:#17a2b8
}
.btn-outline-info:hover{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}
.btn-outline-info:focus,.btn-outline-info.focus{
  box-shadow:0 0 0 .2rem rgba(23,162,184,.5)
}
.btn-outline-info.disabled,.btn-outline-info:disabled{
  color:#17a2b8;
  background-color:rgba(0,0,0,0)
}
.btn-outline-info:not(:disabled):not(.disabled):active,.btn-outline-info:not(:disabled):not(.disabled).active,.show>.btn-outline-info.dropdown-toggle{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,.btn-outline-info:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-info.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(23,162,184,.5)
}
.btn-outline-warning{
  color:#ffc107;
  border-color:#ffc107
}
.btn-outline-warning:hover{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}
.btn-outline-warning:focus,.btn-outline-warning.focus{
  box-shadow:0 0 0 .2rem rgba(255,193,7,.5)
}
.btn-outline-warning.disabled,.btn-outline-warning:disabled{
  color:#ffc107;
  background-color:rgba(0,0,0,0)
}
.btn-outline-warning:not(:disabled):not(.disabled):active,.btn-outline-warning:not(:disabled):not(.disabled).active,.show>.btn-outline-warning.dropdown-toggle{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,.btn-outline-warning:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-warning.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(255,193,7,.5)
}
.btn-outline-danger{
  color:#dc3545;
  border-color:#dc3545
}
.btn-outline-danger:hover{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}
.btn-outline-danger:focus,.btn-outline-danger.focus{
  box-shadow:0 0 0 .2rem rgba(220,53,69,.5)
}
.btn-outline-danger.disabled,.btn-outline-danger:disabled{
  color:#dc3545;
  background-color:rgba(0,0,0,0)
}
.btn-outline-danger:not(:disabled):not(.disabled):active,.btn-outline-danger:not(:disabled):not(.disabled).active,.show>.btn-outline-danger.dropdown-toggle{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,.btn-outline-danger:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-danger.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(220,53,69,.5)
}
.btn-outline-light{
  color:#dfdfdf;
  border-color:#dfdfdf
}
.btn-outline-light:hover{
  color:#212529;
  background-color:#dfdfdf;
  border-color:#dfdfdf
}
.btn-outline-light:focus,.btn-outline-light.focus{
  box-shadow:0 0 0 .2rem rgba(223,223,223,.5)
}
.btn-outline-light.disabled,.btn-outline-light:disabled{
  color:#dfdfdf;
  background-color:rgba(0,0,0,0)
}
.btn-outline-light:not(:disabled):not(.disabled):active,.btn-outline-light:not(:disabled):not(.disabled).active,.show>.btn-outline-light.dropdown-toggle{
  color:#212529;
  background-color:#dfdfdf;
  border-color:#dfdfdf
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,.btn-outline-light:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-light.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(223,223,223,.5)
}
.btn-outline-dark{
  color:#343a40;
  border-color:#343a40
}
.btn-outline-dark:hover{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}
.btn-outline-dark:focus,.btn-outline-dark.focus{
  box-shadow:0 0 0 .2rem rgba(52,58,64,.5)
}
.btn-outline-dark.disabled,.btn-outline-dark:disabled{
  color:#343a40;
  background-color:rgba(0,0,0,0)
}
.btn-outline-dark:not(:disabled):not(.disabled):active,.btn-outline-dark:not(:disabled):not(.disabled).active,.show>.btn-outline-dark.dropdown-toggle{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,.btn-outline-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-dark.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(52,58,64,.5)
}
.btn-link{
  font-weight:400;
  color:#2ec4dd;
  text-decoration:none
}
.btn-link:hover{
  color:#1b90a4;
  text-decoration:underline
}
.btn-link:focus,.btn-link.focus{
  text-decoration:underline
}
.btn-link:disabled,.btn-link.disabled{
  color:#6c757d;
  pointer-events:none
}
.btn-lg,.btn-group-lg>.btn,.btn-group-lg>.uppy-FileInput-btn{
  padding:.5rem 1rem;
  font-size:1.25rem;
  line-height:1.5;
  border-radius:.3rem
}
.btn-sm,.btn-group-sm>.btn,.btn-group-sm>.uppy-FileInput-btn{
  padding:.25rem .5rem;
  font-size:0.875rem;
  line-height:1.5;
  border-radius:.2rem
}
.btn-block{
  display:block;
  width:100%
}
.btn-block+.btn-block{
  margin-top:.5rem
}
input[type=submit].btn-block,input[type=reset].btn-block,input[type=button].btn-block{
  width:100%
}
.fade{
  transition:opacity .15s linear
}
@media(prefers-reduced-motion: reduce){
  .fade{
    transition:none
  }
}
.fade:not(.show){
  opacity:0
}
.collapse:not(.show){
  display:none
}
.collapsing{
  position:relative;
  height:0;
  overflow:hidden;
  transition:height .15s ease
}
@media(prefers-reduced-motion: reduce){
  .collapsing{
    transition:none
  }
}
.dropup,.dropright,.dropdown,.dropleft{
  position:relative
}
.dropdown-toggle{
  white-space:nowrap
}
.dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:"";
  border-top:.3em solid;
  border-right:.3em solid rgba(0,0,0,0);
  border-bottom:0;
  border-left:.3em solid rgba(0,0,0,0)
}
.dropdown-toggle:empty::after{
  margin-left:0
}
.dropdown-menu{
  position:absolute;
  top:100%;
  left:0;
  z-index:1000;
  display:none;
  float:left;
  min-width:10rem;
  padding:.5rem 0;
  margin:.125rem 0 0;
  font-size:1rem;
  color:#414141;
  text-align:left;
  list-style:none;
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid rgba(0,0,0,.15);
  border-radius:1px
}
.dropdown-menu-left{
  right:auto;
  left:0
}
.dropdown-menu-right{
  right:0;
  left:auto
}
@media(min-width: 576px){
  .dropdown-menu-sm-left{
    right:auto;
    left:0
  }

  .dropdown-menu-sm-right{
    right:0;
    left:auto
  }
}
@media(min-width: 769px){
  .dropdown-menu-md-left{
    right:auto;
    left:0
  }

  .dropdown-menu-md-right{
    right:0;
    left:auto
  }
}
@media(min-width: 992px){
  .dropdown-menu-lg-left{
    right:auto;
    left:0
  }

  .dropdown-menu-lg-right{
    right:0;
    left:auto
  }
}
@media(min-width: 1200px){
  .dropdown-menu-xl-left{
    right:auto;
    left:0
  }

  .dropdown-menu-xl-right{
    right:0;
    left:auto
  }
}
.dropup .dropdown-menu{
  top:auto;
  bottom:100%;
  margin-top:0;
  margin-bottom:.125rem
}
.dropup .dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:"";
  border-top:0;
  border-right:.3em solid rgba(0,0,0,0);
  border-bottom:.3em solid;
  border-left:.3em solid rgba(0,0,0,0)
}
.dropup .dropdown-toggle:empty::after{
  margin-left:0
}
.dropright .dropdown-menu{
  top:0;
  right:auto;
  left:100%;
  margin-top:0;
  margin-left:.125rem
}
.dropright .dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:"";
  border-top:.3em solid rgba(0,0,0,0);
  border-right:0;
  border-bottom:.3em solid rgba(0,0,0,0);
  border-left:.3em solid
}
.dropright .dropdown-toggle:empty::after{
  margin-left:0
}
.dropright .dropdown-toggle::after{
  vertical-align:0
}
.dropleft .dropdown-menu{
  top:0;
  right:100%;
  left:auto;
  margin-top:0;
  margin-right:.125rem
}
.dropleft .dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:""
}
.dropleft .dropdown-toggle::after{
  display:none
}
.dropleft .dropdown-toggle::before{
  display:inline-block;
  margin-right:.255em;
  vertical-align:.255em;
  content:"";
  border-top:.3em solid rgba(0,0,0,0);
  border-right:.3em solid;
  border-bottom:.3em solid rgba(0,0,0,0)
}
.dropleft .dropdown-toggle:empty::after{
  margin-left:0
}
.dropleft .dropdown-toggle::before{
  vertical-align:0
}
.dropdown-menu[x-placement^=top],.dropdown-menu[x-placement^=right],.dropdown-menu[x-placement^=bottom],.dropdown-menu[x-placement^=left]{
  right:auto;
  bottom:auto
}
.dropdown-divider{
  height:0;
  margin:8px 0;
  overflow:hidden;
  border-top:1px solid #e9ecef
}
.dropdown-item{
  display:block;
  width:100%;
  padding:.25rem 1.5rem;
  clear:both;
  font-weight:400;
  color:#212529;
  text-align:inherit;
  white-space:nowrap;
  background-color:rgba(0,0,0,0);
  border:0
}
.dropdown-item:hover,.dropdown-item:focus{
  color:#16181b;
  text-decoration:none;
  background-color:#e9ecef
}
.dropdown-item.active,.dropdown-item:active{
  color:#fff;
  text-decoration:none;
  background-color:#2ec4dd
}
.dropdown-item.disabled,.dropdown-item:disabled{
  color:#adb5bd;
  pointer-events:none;
  background-color:rgba(0,0,0,0)
}
.dropdown-menu.show{
  display:block
}
.dropdown-header{
  display:block;
  padding:.5rem 1.5rem;
  margin-bottom:0;
  font-size:0.875rem;
  color:#6c757d;
  white-space:nowrap
}
.dropdown-item-text{
  display:block;
  padding:.25rem 1.5rem;
  color:#212529
}
.btn-group,.btn-group-vertical{
  position:relative;
  display:inline-flex;
  vertical-align:middle
}
.btn-group>.btn,.btn-group>.uppy-FileInput-btn,.btn-group-vertical>.btn,.btn-group-vertical>.uppy-FileInput-btn{
  position:relative;
  flex:1 1 auto
}
.btn-group>.btn:hover,.btn-group>.uppy-FileInput-btn:hover,.btn-group-vertical>.btn:hover,.btn-group-vertical>.uppy-FileInput-btn:hover{
  z-index:1
}
.btn-group>.btn:focus,.btn-group>.uppy-FileInput-btn:focus,.btn-group>.btn:active,.btn-group>.uppy-FileInput-btn:active,.btn-group>.btn.active,.btn-group>.active.uppy-FileInput-btn,.btn-group-vertical>.btn:focus,.btn-group-vertical>.uppy-FileInput-btn:focus,.btn-group-vertical>.btn:active,.btn-group-vertical>.uppy-FileInput-btn:active,.btn-group-vertical>.btn.active,.btn-group-vertical>.active.uppy-FileInput-btn{
  z-index:1
}
.btn-toolbar{
  display:flex;
  flex-wrap:wrap;
  justify-content:flex-start
}
.btn-toolbar .input-group{
  width:auto
}
.btn-group>.btn:not(:first-child),.btn-group>.uppy-FileInput-btn:not(:first-child),.btn-group>.btn-group:not(:first-child){
  margin-left:-1px
}
.btn-group>.btn:not(:last-child):not(.dropdown-toggle),.btn-group>.uppy-FileInput-btn:not(:last-child):not(.dropdown-toggle),.btn-group>.btn-group:not(:last-child)>.btn,.btn-group>.btn-group:not(:last-child)>.uppy-FileInput-btn{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}
.btn-group>.btn:not(:first-child),.btn-group>.uppy-FileInput-btn:not(:first-child),.btn-group>.btn-group:not(:first-child)>.btn,.btn-group>.btn-group:not(:first-child)>.uppy-FileInput-btn{
  border-top-left-radius:0;
  border-bottom-left-radius:0
}
.dropdown-toggle-split{
  padding-right:.5625rem;
  padding-left:.5625rem
}
.dropdown-toggle-split::after,.dropup .dropdown-toggle-split::after,.dropright .dropdown-toggle-split::after{
  margin-left:0
}
.dropleft .dropdown-toggle-split::before{
  margin-right:0
}
.btn-sm+.dropdown-toggle-split,.btn-group-sm>.btn+.dropdown-toggle-split,.btn-group-sm>.uppy-FileInput-btn+.dropdown-toggle-split{
  padding-right:.375rem;
  padding-left:.375rem
}
.btn-lg+.dropdown-toggle-split,.btn-group-lg>.btn+.dropdown-toggle-split,.btn-group-lg>.uppy-FileInput-btn+.dropdown-toggle-split{
  padding-right:.75rem;
  padding-left:.75rem
}
.btn-group-vertical{
  flex-direction:column;
  align-items:flex-start;
  justify-content:center
}
.btn-group-vertical>.btn,.btn-group-vertical>.uppy-FileInput-btn,.btn-group-vertical>.btn-group{
  width:100%
}
.btn-group-vertical>.btn:not(:first-child),.btn-group-vertical>.uppy-FileInput-btn:not(:first-child),.btn-group-vertical>.btn-group:not(:first-child){
  margin-top:-1px
}
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),.btn-group-vertical>.uppy-FileInput-btn:not(:last-child):not(.dropdown-toggle),.btn-group-vertical>.btn-group:not(:last-child)>.btn,.btn-group-vertical>.btn-group:not(:last-child)>.uppy-FileInput-btn{
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}
.btn-group-vertical>.btn:not(:first-child),.btn-group-vertical>.uppy-FileInput-btn:not(:first-child),.btn-group-vertical>.btn-group:not(:first-child)>.btn,.btn-group-vertical>.btn-group:not(:first-child)>.uppy-FileInput-btn{
  border-top-left-radius:0;
  border-top-right-radius:0
}
.btn-group-toggle>.btn,.btn-group-toggle>.uppy-FileInput-btn,.btn-group-toggle>.btn-group>.btn,.btn-group-toggle>.btn-group>.uppy-FileInput-btn{
  margin-bottom:0
}
.btn-group-toggle>.btn input[type=radio],.btn-group-toggle>.uppy-FileInput-btn input[type=radio],.btn-group-toggle>.btn input[type=checkbox],.btn-group-toggle>.uppy-FileInput-btn input[type=checkbox],.btn-group-toggle>.btn-group>.btn input[type=radio],.btn-group-toggle>.btn-group>.uppy-FileInput-btn input[type=radio],.btn-group-toggle>.btn-group>.btn input[type=checkbox],.btn-group-toggle>.btn-group>.uppy-FileInput-btn input[type=checkbox]{
  position:absolute;
  clip:rect(0, 0, 0, 0);
  pointer-events:none
}
.input-group{
  position:relative;
  display:flex;
  flex-wrap:wrap;
  align-items:stretch;
  width:100%
}
.input-group>.form-control,.input-group>.form-control-plaintext,.input-group>.custom-select,.input-group>.custom-file{
  position:relative;
  flex:1 1 auto;
  width:1%;
  min-width:0;
  margin-bottom:0
}
.input-group>.form-control+.form-control,.input-group>.form-control+.custom-select,.input-group>.form-control+.custom-file,.input-group>.form-control-plaintext+.form-control,.input-group>.form-control-plaintext+.custom-select,.input-group>.form-control-plaintext+.custom-file,.input-group>.custom-select+.form-control,.input-group>.custom-select+.custom-select,.input-group>.custom-select+.custom-file,.input-group>.custom-file+.form-control,.input-group>.custom-file+.custom-select,.input-group>.custom-file+.custom-file{
  margin-left:-1px
}
.input-group>.form-control:focus,.input-group>.custom-select:focus,.input-group>.custom-file .custom-file-input:focus~.custom-file-label{
  z-index:3
}
.input-group>.custom-file .custom-file-input:focus{
  z-index:4
}
.input-group>.form-control:not(:first-child),.input-group>.custom-select:not(:first-child){
  border-top-left-radius:0;
  border-bottom-left-radius:0
}
.input-group>.custom-file{
  display:flex;
  align-items:center
}
.input-group>.custom-file:not(:last-child) .custom-file-label,.input-group>.custom-file:not(:first-child) .custom-file-label{
  border-top-left-radius:0;
  border-bottom-left-radius:0
}
.input-group:not(.has-validation)>.form-control:not(:last-child),.input-group:not(.has-validation)>.custom-select:not(:last-child),.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label::after{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}
.input-group.has-validation>.form-control:nth-last-child(n+3),.input-group.has-validation>.custom-select:nth-last-child(n+3),.input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label::after{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}
.input-group-prepend,.input-group-append{
  display:flex
}
.input-group-prepend .btn,.input-group-prepend .uppy-FileInput-btn,.input-group-append .btn,.input-group-append .uppy-FileInput-btn{
  position:relative;
  z-index:2
}
.input-group-prepend .btn:focus,.input-group-prepend .uppy-FileInput-btn:focus,.input-group-append .btn:focus,.input-group-append .uppy-FileInput-btn:focus{
  z-index:3
}
.input-group-prepend .btn+.btn,.input-group-prepend .uppy-FileInput-btn+.btn,.input-group-prepend .btn+.uppy-FileInput-btn,.input-group-prepend .uppy-FileInput-btn+.uppy-FileInput-btn,.input-group-prepend .btn+.input-group-text,.input-group-prepend .uppy-FileInput-btn+.input-group-text,.input-group-prepend .input-group-text+.input-group-text,.input-group-prepend .input-group-text+.btn,.input-group-prepend .input-group-text+.uppy-FileInput-btn,.input-group-append .btn+.btn,.input-group-append .uppy-FileInput-btn+.btn,.input-group-append .btn+.uppy-FileInput-btn,.input-group-append .uppy-FileInput-btn+.uppy-FileInput-btn,.input-group-append .btn+.input-group-text,.input-group-append .uppy-FileInput-btn+.input-group-text,.input-group-append .input-group-text+.input-group-text,.input-group-append .input-group-text+.btn,.input-group-append .input-group-text+.uppy-FileInput-btn{
  margin-left:-1px
}
.input-group-prepend{
  margin-right:-1px
}
.input-group-append{
  margin-left:-1px
}
.input-group-text{
  display:flex;
  align-items:center;
  padding:.375rem .75rem;
  margin-bottom:0;
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#495057;
  text-align:center;
  white-space:nowrap;
  background-color:#e9ecef;
  border:1px solid #dfdfdf;
  border-radius:1px
}
.input-group-text input[type=radio],.input-group-text input[type=checkbox]{
  margin-top:0
}
.input-group-lg>.form-control:not(textarea),.input-group-lg>.custom-select{
  height:calc(1.5em + 1rem + 2px)
}
.input-group-lg>.form-control,.input-group-lg>.custom-select,.input-group-lg>.input-group-prepend>.input-group-text,.input-group-lg>.input-group-append>.input-group-text,.input-group-lg>.input-group-prepend>.btn,.input-group-lg>.input-group-prepend>.uppy-FileInput-btn,.input-group-lg>.input-group-append>.btn,.input-group-lg>.input-group-append>.uppy-FileInput-btn{
  padding:.5rem 1rem;
  font-size:1.25rem;
  line-height:1.5;
  border-radius:.3rem
}
.input-group-sm>.form-control:not(textarea),.input-group-sm>.custom-select{
  height:calc(1.5em + 0.5rem + 2px)
}
.input-group-sm>.form-control,.input-group-sm>.custom-select,.input-group-sm>.input-group-prepend>.input-group-text,.input-group-sm>.input-group-append>.input-group-text,.input-group-sm>.input-group-prepend>.btn,.input-group-sm>.input-group-prepend>.uppy-FileInput-btn,.input-group-sm>.input-group-append>.btn,.input-group-sm>.input-group-append>.uppy-FileInput-btn{
  padding:.25rem .5rem;
  font-size:0.875rem;
  line-height:1.5;
  border-radius:.2rem
}
.input-group-lg>.custom-select,.input-group-sm>.custom-select{
  padding-right:1.75rem
}
.input-group>.input-group-prepend>.btn,.input-group>.input-group-prepend>.uppy-FileInput-btn,.input-group>.input-group-prepend>.input-group-text,.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn,.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.uppy-FileInput-btn,.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text,.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn,.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.uppy-FileInput-btn,.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text,.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),.input-group>.input-group-append:last-child>.uppy-FileInput-btn:not(:last-child):not(.dropdown-toggle),.input-group>.input-group-append:last-child>.input-group-text:not(:last-child){
  border-top-right-radius:0;
  border-bottom-right-radius:0
}
.input-group>.input-group-append>.btn,.input-group>.input-group-append>.uppy-FileInput-btn,.input-group>.input-group-append>.input-group-text,.input-group>.input-group-prepend:not(:first-child)>.btn,.input-group>.input-group-prepend:not(:first-child)>.uppy-FileInput-btn,.input-group>.input-group-prepend:not(:first-child)>.input-group-text,.input-group>.input-group-prepend:first-child>.btn:not(:first-child),.input-group>.input-group-prepend:first-child>.uppy-FileInput-btn:not(:first-child),.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child){
  border-top-left-radius:0;
  border-bottom-left-radius:0
}
.custom-control{
  position:relative;
  z-index:1;
  display:block;
  min-height:1.5rem;
  padding-left:1.5rem;
  -webkit-print-color-adjust:exact;
          color-adjust:exact
}
.custom-control-inline{
  display:inline-flex;
  margin-right:1rem
}
.custom-control-input{
  position:absolute;
  left:0;
  z-index:-1;
  width:1rem;
  height:1.25rem;
  opacity:0
}
.custom-control-input:checked~.custom-control-label::before{
  color:#fff;
  border-color:#2ec4dd;
  background-color:#2ec4dd
}
.custom-control-input:focus~.custom-control-label::before{
  box-shadow:0 0 0 .2rem rgba(46,196,221,.25)
}
.custom-control-input:focus:not(:checked)~.custom-control-label::before{
  border-color:#9ce3ef
}
.custom-control-input:not(:disabled):active~.custom-control-label::before{
  color:#fff;
  background-color:#c8eff6;
  border-color:#c8eff6
}
.custom-control-input[disabled]~.custom-control-label,.custom-control-input:disabled~.custom-control-label{
  color:#6c757d
}
.custom-control-input[disabled]~.custom-control-label::before,.custom-control-input:disabled~.custom-control-label::before{
  background-color:#e9ecef
}
.custom-control-label{
  position:relative;
  margin-bottom:0;
  vertical-align:top
}
.custom-control-label::before{
  position:absolute;
  top:.25rem;
  left:-1.5rem;
  display:block;
  width:1rem;
  height:1rem;
  pointer-events:none;
  content:"";
  background-color:#fff;
  border:#adb5bd solid 1px
}
.custom-control-label::after{
  position:absolute;
  top:.25rem;
  left:-1.5rem;
  display:block;
  width:1rem;
  height:1rem;
  content:"";
  background:50%/50% 50% no-repeat
}
.custom-checkbox .custom-control-label::before{
  border-radius:1px
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e")
}
.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before{
  border-color:#2ec4dd;
  background-color:#2ec4dd
}
.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e")
}
.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before{
  background-color:rgba(46,196,221,.5)
}
.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before{
  background-color:rgba(46,196,221,.5)
}
.custom-radio .custom-control-label::before{
  border-radius:50%
}
.custom-radio .custom-control-input:checked~.custom-control-label::after{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}
.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before{
  background-color:rgba(46,196,221,.5)
}
.custom-switch{
  padding-left:2.25rem
}
.custom-switch .custom-control-label::before{
  left:-2.25rem;
  width:1.75rem;
  pointer-events:all;
  border-radius:.5rem
}
.custom-switch .custom-control-label::after{
  top:calc(0.25rem + 2px);
  left:calc(-2.25rem + 2px);
  width:calc(1rem - 4px);
  height:calc(1rem - 4px);
  background-color:#adb5bd;
  border-radius:.5rem;
  transition:transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
@media(prefers-reduced-motion: reduce){
  .custom-switch .custom-control-label::after{
    transition:none
  }
}
.custom-switch .custom-control-input:checked~.custom-control-label::after{
  background-color:#fff;
  transform:translateX(0.75rem)
}
.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before{
  background-color:rgba(46,196,221,.5)
}
.custom-select{
  display:inline-block;
  width:100%;
  height:calc(1.5em + 0.75rem + 2px);
  padding:.375rem 1.75rem .375rem .75rem;
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#495057;
  vertical-align:middle;
  background:#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat;
  border:1px solid #dfdfdf;
  border-radius:1px;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none
}
.custom-select:focus{
  border-color:#9ce3ef;
  outline:0;
  box-shadow:0 0 0 .2rem rgba(46,196,221,.25)
}
.custom-select:focus::-ms-value{
  color:#495057;
  background-color:#fff
}
.custom-select[multiple],.custom-select[size]:not([size="1"]){
  height:auto;
  padding-right:.75rem;
  background-image:none
}
.custom-select:disabled{
  color:#6c757d;
  background-color:#e9ecef
}
.custom-select::-ms-expand{
  display:none
}
.custom-select:-moz-focusring{
  color:rgba(0,0,0,0);
  text-shadow:0 0 0 #495057
}
.custom-select-sm{
  height:calc(1.5em + 0.5rem + 2px);
  padding-top:.25rem;
  padding-bottom:.25rem;
  padding-left:.5rem;
  font-size:0.875rem
}
.custom-select-lg{
  height:calc(1.5em + 1rem + 2px);
  padding-top:.5rem;
  padding-bottom:.5rem;
  padding-left:1rem;
  font-size:1.25rem
}
.custom-file{
  position:relative;
  display:inline-block;
  width:100%;
  height:calc(1.5em + 0.75rem + 2px);
  margin-bottom:0
}
.custom-file-input{
  position:relative;
  z-index:2;
  width:100%;
  height:calc(1.5em + 0.75rem + 2px);
  margin:0;
  overflow:hidden;
  opacity:0
}
.custom-file-input:focus~.custom-file-label{
  border-color:#9ce3ef;
  box-shadow:0 0 0 .2rem rgba(46,196,221,.25)
}
.custom-file-input[disabled]~.custom-file-label,.custom-file-input:disabled~.custom-file-label{
  background-color:#e9ecef
}
.custom-file-input:lang(en)~.custom-file-label::after{
  content:"Browse"
}
.custom-file-input~.custom-file-label[data-browse]::after{
  content:attr(data-browse)
}
.custom-file-label{
  position:absolute;
  top:0;
  right:0;
  left:0;
  z-index:1;
  height:calc(1.5em + 0.75rem + 2px);
  padding:.375rem .75rem;
  overflow:hidden;
  font-weight:400;
  line-height:1.5;
  color:#495057;
  background-color:#fff;
  border:1px solid #dfdfdf;
  border-radius:1px
}
.custom-file-label::after{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  z-index:3;
  display:block;
  height:calc(1.5em + 0.75rem);
  padding:.375rem .75rem;
  line-height:1.5;
  color:#495057;
  content:"Browse";
  background-color:#e9ecef;
  border-left:inherit;
  border-radius:0 1px 1px 0
}
.custom-range{
  width:100%;
  height:1.4rem;
  padding:0;
  background-color:rgba(0,0,0,0);
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none
}
.custom-range:focus{
  outline:0
}
.custom-range:focus::-webkit-slider-thumb{
  box-shadow:0 0 0 1px #fff,0 0 0 .2rem rgba(46,196,221,.25)
}
.custom-range:focus::-moz-range-thumb{
  box-shadow:0 0 0 1px #fff,0 0 0 .2rem rgba(46,196,221,.25)
}
.custom-range:focus::-ms-thumb{
  box-shadow:0 0 0 1px #fff,0 0 0 .2rem rgba(46,196,221,.25)
}
.custom-range::-moz-focus-outer{
  border:0
}
.custom-range::-webkit-slider-thumb{
  width:1rem;
  height:1rem;
  margin-top:-0.25rem;
  background-color:#2ec4dd;
  border:0;
  border-radius:1rem;
  -webkit-transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance:none;
          appearance:none
}
@media(prefers-reduced-motion: reduce){
  .custom-range::-webkit-slider-thumb{
    -webkit-transition:none;
    transition:none
  }
}
.custom-range::-webkit-slider-thumb:active{
  background-color:#c8eff6
}
.custom-range::-webkit-slider-runnable-track{
  width:100%;
  height:.5rem;
  color:rgba(0,0,0,0);
  cursor:pointer;
  background-color:#dee2e6;
  border-color:rgba(0,0,0,0);
  border-radius:1rem
}
.custom-range::-moz-range-thumb{
  width:1rem;
  height:1rem;
  background-color:#2ec4dd;
  border:0;
  border-radius:1rem;
  -moz-transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -moz-appearance:none;
       appearance:none
}
@media(prefers-reduced-motion: reduce){
  .custom-range::-moz-range-thumb{
    -moz-transition:none;
    transition:none
  }
}
.custom-range::-moz-range-thumb:active{
  background-color:#c8eff6
}
.custom-range::-moz-range-track{
  width:100%;
  height:.5rem;
  color:rgba(0,0,0,0);
  cursor:pointer;
  background-color:#dee2e6;
  border-color:rgba(0,0,0,0);
  border-radius:1rem
}
.custom-range::-ms-thumb{
  width:1rem;
  height:1rem;
  margin-top:0;
  margin-right:.2rem;
  margin-left:.2rem;
  background-color:#2ec4dd;
  border:0;
  border-radius:1rem;
  -ms-transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance:none
}
@media(prefers-reduced-motion: reduce){
  .custom-range::-ms-thumb{
    -ms-transition:none;
    transition:none
  }
}
.custom-range::-ms-thumb:active{
  background-color:#c8eff6
}
.custom-range::-ms-track{
  width:100%;
  height:.5rem;
  color:rgba(0,0,0,0);
  cursor:pointer;
  background-color:rgba(0,0,0,0);
  border-color:rgba(0,0,0,0);
  border-width:.5rem
}
.custom-range::-ms-fill-lower{
  background-color:#dee2e6;
  border-radius:1rem
}
.custom-range::-ms-fill-upper{
  margin-right:15px;
  background-color:#dee2e6;
  border-radius:1rem
}
.custom-range:disabled::-webkit-slider-thumb{
  background-color:#adb5bd
}
.custom-range:disabled::-webkit-slider-runnable-track{
  cursor:default
}
.custom-range:disabled::-moz-range-thumb{
  background-color:#adb5bd
}
.custom-range:disabled::-moz-range-track{
  cursor:default
}
.custom-range:disabled::-ms-thumb{
  background-color:#adb5bd
}
.custom-control-label::before,.custom-file-label,.custom-select{
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
@media(prefers-reduced-motion: reduce){
  .custom-control-label::before,.custom-file-label,.custom-select{
    transition:none
  }
}
.nav{
  display:flex;
  flex-wrap:wrap;
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.nav-link{
  display:block;
  padding:.5rem 1rem
}
.nav-link:hover,.nav-link:focus{
  text-decoration:none
}
.nav-link.disabled{
  color:#6c757d;
  pointer-events:none;
  cursor:default
}
.nav-tabs{
  border-bottom:1px solid #dee2e6
}
.nav-tabs .nav-link{
  margin-bottom:-1px;
  border:1px solid rgba(0,0,0,0);
  border-top-left-radius:1px;
  border-top-right-radius:1px
}
.nav-tabs .nav-link:hover,.nav-tabs .nav-link:focus{
  border-color:#e9ecef #e9ecef #dee2e6
}
.nav-tabs .nav-link.disabled{
  color:#6c757d;
  background-color:rgba(0,0,0,0);
  border-color:rgba(0,0,0,0)
}
.nav-tabs .nav-link.active,.nav-tabs .nav-item.show .nav-link{
  color:#495057;
  background-color:#fff;
  border-color:#dee2e6 #dee2e6 #fff
}
.nav-tabs .dropdown-menu{
  margin-top:-1px;
  border-top-left-radius:0;
  border-top-right-radius:0
}
.nav-pills .nav-link{
  border-radius:1px
}
.nav-pills .nav-link.active,.nav-pills .show>.nav-link{
  color:#fff;
  background-color:#2ec4dd
}
.nav-fill>.nav-link,.nav-fill .nav-item{
  flex:1 1 auto;
  text-align:center
}
.nav-justified>.nav-link,.nav-justified .nav-item{
  flex-basis:0;
  flex-grow:1;
  text-align:center
}
.tab-content>.tab-pane{
  display:none
}
.tab-content>.active{
  display:block
}
.navbar{
  position:relative;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:space-between;
  padding:8px 16px
}
.navbar .container,.navbar .container-fluid,.navbar .container-sm,.navbar .container-md,.navbar .container-lg,.navbar .container-xl{
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:space-between
}
.navbar-brand{
  display:inline-block;
  padding-top:.3125rem;
  padding-bottom:.3125rem;
  margin-right:16px;
  font-size:1.25rem;
  line-height:inherit;
  white-space:nowrap
}
.navbar-brand:hover,.navbar-brand:focus{
  text-decoration:none
}
.navbar-nav{
  display:flex;
  flex-direction:column;
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.navbar-nav .nav-link{
  padding-right:0;
  padding-left:0
}
.navbar-nav .dropdown-menu{
  position:static;
  float:none
}
.navbar-text{
  display:inline-block;
  padding-top:.5rem;
  padding-bottom:.5rem
}
.navbar-collapse{
  flex-basis:100%;
  flex-grow:1;
  align-items:center
}
.navbar-toggler{
  padding:.25rem .75rem;
  font-size:1.25rem;
  line-height:1;
  background-color:rgba(0,0,0,0);
  border:1px solid rgba(0,0,0,0);
  border-radius:1px
}
.navbar-toggler:hover,.navbar-toggler:focus{
  text-decoration:none
}
.navbar-toggler-icon{
  display:inline-block;
  width:1.5em;
  height:1.5em;
  vertical-align:middle;
  content:"";
  background:50%/100% 100% no-repeat
}
.navbar-nav-scroll{
  max-height:75vh;
  overflow-y:auto
}
@media(max-width: 575.98px){
  .navbar-expand-sm>.container,.navbar-expand-sm>.container-fluid,.navbar-expand-sm>.container-sm,.navbar-expand-sm>.container-md,.navbar-expand-sm>.container-lg,.navbar-expand-sm>.container-xl{
    padding-right:0;
    padding-left:0
  }
}
@media(min-width: 576px){
  .navbar-expand-sm{
    flex-flow:row nowrap;
    justify-content:flex-start
  }

  .navbar-expand-sm .navbar-nav{
    flex-direction:row
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu{
    position:absolute
  }

  .navbar-expand-sm .navbar-nav .nav-link{
    padding-right:.5rem;
    padding-left:.5rem
  }

  .navbar-expand-sm>.container,.navbar-expand-sm>.container-fluid,.navbar-expand-sm>.container-sm,.navbar-expand-sm>.container-md,.navbar-expand-sm>.container-lg,.navbar-expand-sm>.container-xl{
    flex-wrap:nowrap
  }

  .navbar-expand-sm .navbar-nav-scroll{
    overflow:visible
  }

  .navbar-expand-sm .navbar-collapse{
    display:flex !important;
    flex-basis:auto
  }

  .navbar-expand-sm .navbar-toggler{
    display:none
  }
}
@media(max-width: 768.98px){
  .navbar-expand-md>.container,.navbar-expand-md>.container-fluid,.navbar-expand-md>.container-sm,.navbar-expand-md>.container-md,.navbar-expand-md>.container-lg,.navbar-expand-md>.container-xl{
    padding-right:0;
    padding-left:0
  }
}
@media(min-width: 769px){
  .navbar-expand-md{
    flex-flow:row nowrap;
    justify-content:flex-start
  }

  .navbar-expand-md .navbar-nav{
    flex-direction:row
  }

  .navbar-expand-md .navbar-nav .dropdown-menu{
    position:absolute
  }

  .navbar-expand-md .navbar-nav .nav-link{
    padding-right:.5rem;
    padding-left:.5rem
  }

  .navbar-expand-md>.container,.navbar-expand-md>.container-fluid,.navbar-expand-md>.container-sm,.navbar-expand-md>.container-md,.navbar-expand-md>.container-lg,.navbar-expand-md>.container-xl{
    flex-wrap:nowrap
  }

  .navbar-expand-md .navbar-nav-scroll{
    overflow:visible
  }

  .navbar-expand-md .navbar-collapse{
    display:flex !important;
    flex-basis:auto
  }

  .navbar-expand-md .navbar-toggler{
    display:none
  }
}
@media(max-width: 991.98px){
  .navbar-expand-lg>.container,.navbar-expand-lg>.container-fluid,.navbar-expand-lg>.container-sm,.navbar-expand-lg>.container-md,.navbar-expand-lg>.container-lg,.navbar-expand-lg>.container-xl{
    padding-right:0;
    padding-left:0
  }
}
@media(min-width: 992px){
  .navbar-expand-lg{
    flex-flow:row nowrap;
    justify-content:flex-start
  }

  .navbar-expand-lg .navbar-nav{
    flex-direction:row
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu{
    position:absolute
  }

  .navbar-expand-lg .navbar-nav .nav-link{
    padding-right:.5rem;
    padding-left:.5rem
  }

  .navbar-expand-lg>.container,.navbar-expand-lg>.container-fluid,.navbar-expand-lg>.container-sm,.navbar-expand-lg>.container-md,.navbar-expand-lg>.container-lg,.navbar-expand-lg>.container-xl{
    flex-wrap:nowrap
  }

  .navbar-expand-lg .navbar-nav-scroll{
    overflow:visible
  }

  .navbar-expand-lg .navbar-collapse{
    display:flex !important;
    flex-basis:auto
  }

  .navbar-expand-lg .navbar-toggler{
    display:none
  }
}
@media(max-width: 1199.98px){
  .navbar-expand-xl>.container,.navbar-expand-xl>.container-fluid,.navbar-expand-xl>.container-sm,.navbar-expand-xl>.container-md,.navbar-expand-xl>.container-lg,.navbar-expand-xl>.container-xl{
    padding-right:0;
    padding-left:0
  }
}
@media(min-width: 1200px){
  .navbar-expand-xl{
    flex-flow:row nowrap;
    justify-content:flex-start
  }

  .navbar-expand-xl .navbar-nav{
    flex-direction:row
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu{
    position:absolute
  }

  .navbar-expand-xl .navbar-nav .nav-link{
    padding-right:.5rem;
    padding-left:.5rem
  }

  .navbar-expand-xl>.container,.navbar-expand-xl>.container-fluid,.navbar-expand-xl>.container-sm,.navbar-expand-xl>.container-md,.navbar-expand-xl>.container-lg,.navbar-expand-xl>.container-xl{
    flex-wrap:nowrap
  }

  .navbar-expand-xl .navbar-nav-scroll{
    overflow:visible
  }

  .navbar-expand-xl .navbar-collapse{
    display:flex !important;
    flex-basis:auto
  }

  .navbar-expand-xl .navbar-toggler{
    display:none
  }
}
.navbar-expand{
  flex-flow:row nowrap;
  justify-content:flex-start
}
.navbar-expand>.container,.navbar-expand>.container-fluid,.navbar-expand>.container-sm,.navbar-expand>.container-md,.navbar-expand>.container-lg,.navbar-expand>.container-xl{
  padding-right:0;
  padding-left:0
}
.navbar-expand .navbar-nav{
  flex-direction:row
}
.navbar-expand .navbar-nav .dropdown-menu{
  position:absolute
}
.navbar-expand .navbar-nav .nav-link{
  padding-right:.5rem;
  padding-left:.5rem
}
.navbar-expand>.container,.navbar-expand>.container-fluid,.navbar-expand>.container-sm,.navbar-expand>.container-md,.navbar-expand>.container-lg,.navbar-expand>.container-xl{
  flex-wrap:nowrap
}
.navbar-expand .navbar-nav-scroll{
  overflow:visible
}
.navbar-expand .navbar-collapse{
  display:flex !important;
  flex-basis:auto
}
.navbar-expand .navbar-toggler{
  display:none
}
.navbar-light .navbar-brand{
  color:rgba(0,0,0,.9)
}
.navbar-light .navbar-brand:hover,.navbar-light .navbar-brand:focus{
  color:rgba(0,0,0,.9)
}
.navbar-light .navbar-nav .nav-link{
  color:rgba(0,0,0,.5)
}
.navbar-light .navbar-nav .nav-link:hover,.navbar-light .navbar-nav .nav-link:focus{
  color:rgba(0,0,0,.7)
}
.navbar-light .navbar-nav .nav-link.disabled{
  color:rgba(0,0,0,.3)
}
.navbar-light .navbar-nav .show>.nav-link,.navbar-light .navbar-nav .active>.nav-link,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .nav-link.active{
  color:rgba(0,0,0,.9)
}
.navbar-light .navbar-toggler{
  color:rgba(0,0,0,.5);
  border-color:rgba(0,0,0,.1)
}
.navbar-light .navbar-toggler-icon{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}
.navbar-light .navbar-text{
  color:rgba(0,0,0,.5)
}
.navbar-light .navbar-text a{
  color:rgba(0,0,0,.9)
}
.navbar-light .navbar-text a:hover,.navbar-light .navbar-text a:focus{
  color:rgba(0,0,0,.9)
}
.navbar-dark .navbar-brand{
  color:#fff
}
.navbar-dark .navbar-brand:hover,.navbar-dark .navbar-brand:focus{
  color:#fff
}
.navbar-dark .navbar-nav .nav-link{
  color:rgba(255,255,255,.5)
}
.navbar-dark .navbar-nav .nav-link:hover,.navbar-dark .navbar-nav .nav-link:focus{
  color:rgba(255,255,255,.75)
}
.navbar-dark .navbar-nav .nav-link.disabled{
  color:rgba(255,255,255,.25)
}
.navbar-dark .navbar-nav .show>.nav-link,.navbar-dark .navbar-nav .active>.nav-link,.navbar-dark .navbar-nav .nav-link.show,.navbar-dark .navbar-nav .nav-link.active{
  color:#fff
}
.navbar-dark .navbar-toggler{
  color:rgba(255,255,255,.5);
  border-color:rgba(255,255,255,.1)
}
.navbar-dark .navbar-toggler-icon{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}
.navbar-dark .navbar-text{
  color:rgba(255,255,255,.5)
}
.navbar-dark .navbar-text a{
  color:#fff
}
.navbar-dark .navbar-text a:hover,.navbar-dark .navbar-text a:focus{
  color:#fff
}
.card{
  position:relative;
  display:flex;
  flex-direction:column;
  min-width:0;
  word-wrap:break-word;
  background-color:#fff;
  background-clip:border-box;
  border:1px solid rgba(0,0,0,.125);
  border-radius:1px
}
.card>hr{
  margin-right:0;
  margin-left:0
}
.card>.list-group{
  border-top:inherit;
  border-bottom:inherit
}
.card>.list-group:first-child{
  border-top-width:0;
  border-top-left-radius:0px;
  border-top-right-radius:0px
}
.card>.list-group:last-child{
  border-bottom-width:0;
  border-bottom-right-radius:0px;
  border-bottom-left-radius:0px
}
.card>.card-header+.list-group,.card>.list-group+.card-footer{
  border-top:0
}
.card-body{
  flex:1 1 auto;
  min-height:1px;
  padding:1.25rem
}
.card-title{
  margin-bottom:.75rem
}
.card-subtitle{
  margin-top:-0.375rem;
  margin-bottom:0
}
.card-text:last-child{
  margin-bottom:0
}
.card-link:hover{
  text-decoration:none
}
.card-link+.card-link{
  margin-left:1.25rem
}
.card-header{
  padding:.75rem 1.25rem;
  margin-bottom:0;
  background-color:rgba(0,0,0,.03);
  border-bottom:1px solid rgba(0,0,0,.125)
}
.card-header:first-child{
  border-radius:0px 0px 0 0
}
.card-footer{
  padding:.75rem 1.25rem;
  background-color:rgba(0,0,0,.03);
  border-top:1px solid rgba(0,0,0,.125)
}
.card-footer:last-child{
  border-radius:0 0 0px 0px
}
.card-header-tabs{
  margin-right:-0.625rem;
  margin-bottom:-0.75rem;
  margin-left:-0.625rem;
  border-bottom:0
}
.card-header-pills{
  margin-right:-0.625rem;
  margin-left:-0.625rem
}
.card-img-overlay{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  padding:1.25rem;
  border-radius:0px
}
.card-img,.card-img-top,.card-img-bottom{
  flex-shrink:0;
  width:100%
}
.card-img,.card-img-top{
  border-top-left-radius:0px;
  border-top-right-radius:0px
}
.card-img,.card-img-bottom{
  border-bottom-right-radius:0px;
  border-bottom-left-radius:0px
}
.card-deck .card{
  margin-bottom:15px
}
@media(min-width: 576px){
  .card-deck{
    display:flex;
    flex-flow:row wrap;
    margin-right:-15px;
    margin-left:-15px
  }

  .card-deck .card{
    flex:1 0 0%;
    margin-right:15px;
    margin-bottom:0;
    margin-left:15px
  }
}
.card-group>.card{
  margin-bottom:15px
}
@media(min-width: 576px){
  .card-group{
    display:flex;
    flex-flow:row wrap
  }

  .card-group>.card{
    flex:1 0 0%;
    margin-bottom:0
  }

  .card-group>.card+.card{
    margin-left:0;
    border-left:0
  }

  .card-group>.card:not(:last-child){
    border-top-right-radius:0;
    border-bottom-right-radius:0
  }

  .card-group>.card:not(:last-child) .card-img-top,.card-group>.card:not(:last-child) .card-header{
    border-top-right-radius:0
  }

  .card-group>.card:not(:last-child) .card-img-bottom,.card-group>.card:not(:last-child) .card-footer{
    border-bottom-right-radius:0
  }

  .card-group>.card:not(:first-child){
    border-top-left-radius:0;
    border-bottom-left-radius:0
  }

  .card-group>.card:not(:first-child) .card-img-top,.card-group>.card:not(:first-child) .card-header{
    border-top-left-radius:0
  }

  .card-group>.card:not(:first-child) .card-img-bottom,.card-group>.card:not(:first-child) .card-footer{
    border-bottom-left-radius:0
  }
}
.card-columns .card{
  margin-bottom:.75rem
}
@media(min-width: 576px){
  .card-columns{
    -moz-column-count:3;
         column-count:3;
    -moz-column-gap:1.25rem;
         column-gap:1.25rem;
    orphans:1;
    widows:1
  }

  .card-columns .card{
    display:inline-block;
    width:100%
  }
}
.accordion{
  overflow-anchor:none
}
.accordion>.card{
  overflow:hidden
}
.accordion>.card:not(:last-of-type){
  border-bottom:0;
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}
.accordion>.card:not(:first-of-type){
  border-top-left-radius:0;
  border-top-right-radius:0
}
.accordion>.card>.card-header{
  border-radius:0;
  margin-bottom:-1px
}
.breadcrumb{
  display:flex;
  flex-wrap:wrap;
  padding:.75rem 1rem;
  margin-bottom:1rem;
  list-style:none;
  background-color:#e9ecef;
  border-radius:1px
}
.breadcrumb-item+.breadcrumb-item{
  padding-left:.5rem
}
.breadcrumb-item+.breadcrumb-item::before{
  float:left;
  padding-right:.5rem;
  color:#6c757d;
  content:">"
}
.breadcrumb-item+.breadcrumb-item:hover::before{
  text-decoration:underline
}
.breadcrumb-item+.breadcrumb-item:hover::before{
  text-decoration:none
}
.breadcrumb-item.active{
  color:#6c757d
}
.pagination{
  display:flex;
  padding-left:0;
  list-style:none;
  border-radius:1px
}
.page-link{
  position:relative;
  display:block;
  padding:.5rem .75rem;
  margin-left:-1px;
  line-height:1.25;
  color:#2ec4dd;
  background-color:#fff;
  border:1px solid #dee2e6
}
.page-link:hover{
  z-index:2;
  color:#1b90a4;
  text-decoration:none;
  background-color:#e9ecef;
  border-color:#dee2e6
}
.page-link:focus{
  z-index:3;
  outline:0;
  box-shadow:0 0 0 .2rem rgba(46,196,221,.25)
}
.page-item:first-child .page-link{
  margin-left:0;
  border-top-left-radius:1px;
  border-bottom-left-radius:1px
}
.page-item:last-child .page-link{
  border-top-right-radius:1px;
  border-bottom-right-radius:1px
}
.page-item.active .page-link{
  z-index:3;
  color:#fff;
  background-color:#2ec4dd;
  border-color:#2ec4dd
}
.page-item.disabled .page-link{
  color:#6c757d;
  pointer-events:none;
  cursor:auto;
  background-color:#fff;
  border-color:#dee2e6
}
.pagination-lg .page-link{
  padding:.75rem 1.5rem;
  font-size:1.25rem;
  line-height:1.5
}
.pagination-lg .page-item:first-child .page-link{
  border-top-left-radius:.3rem;
  border-bottom-left-radius:.3rem
}
.pagination-lg .page-item:last-child .page-link{
  border-top-right-radius:.3rem;
  border-bottom-right-radius:.3rem
}
.pagination-sm .page-link{
  padding:.25rem .5rem;
  font-size:0.875rem;
  line-height:1.5
}
.pagination-sm .page-item:first-child .page-link{
  border-top-left-radius:.2rem;
  border-bottom-left-radius:.2rem
}
.pagination-sm .page-item:last-child .page-link{
  border-top-right-radius:.2rem;
  border-bottom-right-radius:.2rem
}
.badge{
  display:inline-block;
  padding:.25em .4em;
  font-size:75%;
  font-weight:600;
  line-height:1;
  text-align:center;
  white-space:nowrap;
  vertical-align:baseline;
  border-radius:1px;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
@media(prefers-reduced-motion: reduce){
  .badge{
    transition:none
  }
}
a.badge:hover,a.badge:focus{
  text-decoration:none
}
.badge:empty{
  display:none
}
.btn .badge,.uppy-FileInput-btn .badge{
  position:relative;
  top:-1px
}
.badge-pill{
  padding-right:.6em;
  padding-left:.6em;
  border-radius:10rem
}
.badge-primary{
  color:#212529;
  background-color:#2ec4dd
}
a.badge-primary:hover,a.badge-primary:focus{
  color:#212529;
  background-color:#1ea4ba
}
a.badge-primary:focus,a.badge-primary.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(46,196,221,.5)
}
.badge-secondary{
  color:#fff;
  background-color:#6c757d
}
a.badge-secondary:hover,a.badge-secondary:focus{
  color:#fff;
  background-color:#545b62
}
a.badge-secondary:focus,a.badge-secondary.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(108,117,125,.5)
}
.badge-success{
  color:#fff;
  background-color:#28a745
}
a.badge-success:hover,a.badge-success:focus{
  color:#fff;
  background-color:#1e7e34
}
a.badge-success:focus,a.badge-success.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(40,167,69,.5)
}
.badge-info{
  color:#fff;
  background-color:#17a2b8
}
a.badge-info:hover,a.badge-info:focus{
  color:#fff;
  background-color:#117a8b
}
a.badge-info:focus,a.badge-info.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(23,162,184,.5)
}
.badge-warning{
  color:#212529;
  background-color:#ffc107
}
a.badge-warning:hover,a.badge-warning:focus{
  color:#212529;
  background-color:#d39e00
}
a.badge-warning:focus,a.badge-warning.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(255,193,7,.5)
}
.badge-danger{
  color:#fff;
  background-color:#dc3545
}
a.badge-danger:hover,a.badge-danger:focus{
  color:#fff;
  background-color:#bd2130
}
a.badge-danger:focus,a.badge-danger.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(220,53,69,.5)
}
.badge-light{
  color:#212529;
  background-color:#dfdfdf
}
a.badge-light:hover,a.badge-light:focus{
  color:#212529;
  background-color:#c6c6c6
}
a.badge-light:focus,a.badge-light.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(223,223,223,.5)
}
.badge-dark{
  color:#fff;
  background-color:#343a40
}
a.badge-dark:hover,a.badge-dark:focus{
  color:#fff;
  background-color:#1d2124
}
a.badge-dark:focus,a.badge-dark.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(52,58,64,.5)
}
.alert{
  position:relative;
  padding:.75rem 1.25rem;
  margin-bottom:1rem;
  border:1px solid rgba(0,0,0,0);
  border-radius:1px
}
.alert-heading{
  color:inherit
}
.alert-link{
  font-weight:600
}
.alert-dismissible{
  padding-right:4rem
}
.alert-dismissible .close{
  position:absolute;
  top:0;
  right:0;
  z-index:2;
  padding:.75rem 1.25rem;
  color:inherit
}
.alert-primary{
  color:#186673;
  background-color:#d5f3f8;
  border-color:#c4eef5
}
.alert-primary hr{
  border-top-color:#aee8f1
}
.alert-primary .alert-link{
  color:#0f4149
}
.alert-secondary{
  color:#383d41;
  background-color:#e2e3e5;
  border-color:#d6d8db
}
.alert-secondary hr{
  border-top-color:#c8cbcf
}
.alert-secondary .alert-link{
  color:#202326
}
.alert-success{
  color:#155724;
  background-color:#d4edda;
  border-color:#c3e6cb
}
.alert-success hr{
  border-top-color:#b1dfbb
}
.alert-success .alert-link{
  color:#0b2e13
}
.alert-info{
  color:#0c5460;
  background-color:#d1ecf1;
  border-color:#bee5eb
}
.alert-info hr{
  border-top-color:#abdde5
}
.alert-info .alert-link{
  color:#062c33
}
.alert-warning{
  color:#856404;
  background-color:#fff3cd;
  border-color:#ffeeba
}
.alert-warning hr{
  border-top-color:#ffe8a1
}
.alert-warning .alert-link{
  color:#533f03
}
.alert-danger{
  color:#721c24;
  background-color:#f8d7da;
  border-color:#f5c6cb
}
.alert-danger hr{
  border-top-color:#f1b0b7
}
.alert-danger .alert-link{
  color:#491217
}
.alert-light{
  color:#747474;
  background-color:#f9f9f9;
  border-color:#f6f6f6
}
.alert-light hr{
  border-top-color:#e9e9e9
}
.alert-light .alert-link{
  color:#5b5b5b
}
.alert-dark{
  color:#1b1e21;
  background-color:#d6d8d9;
  border-color:#c6c8ca
}
.alert-dark hr{
  border-top-color:#b9bbbe
}
.alert-dark .alert-link{
  color:#040505
}
.media{
  display:flex;
  align-items:flex-start
}
.media-body{
  flex:1
}
.close{
  float:right;
  font-size:1.5rem;
  font-weight:600;
  line-height:1;
  color:#000;
  text-shadow:0 1px 0 #fff;
  opacity:.5
}
.close:hover{
  color:#000;
  text-decoration:none
}
.close:not(:disabled):not(.disabled):hover,.close:not(:disabled):not(.disabled):focus{
  opacity:.75
}
button.close{
  padding:0;
  background-color:rgba(0,0,0,0);
  border:0
}
a.close.disabled{
  pointer-events:none
}
.modal-open{
  overflow:hidden
}
.modal-open .modal{
  overflow-x:hidden;
  overflow-y:auto
}
.modal{
  position:fixed;
  top:0;
  left:0;
  z-index:1050;
  display:none;
  width:100%;
  height:100%;
  overflow:hidden;
  outline:0
}
.modal-dialog{
  position:relative;
  width:auto;
  margin:.5rem;
  pointer-events:none
}
.modal.fade .modal-dialog{
  transition:transform .3s ease-out;
  transform:translate(0, -50px)
}
@media(prefers-reduced-motion: reduce){
  .modal.fade .modal-dialog{
    transition:none
  }
}
.modal.show .modal-dialog{
  transform:none
}
.modal.modal-static .modal-dialog{
  transform:scale(1.02)
}
.modal-dialog-scrollable{
  display:flex;
  max-height:calc(100% - 1rem)
}
.modal-dialog-scrollable .modal-content{
  max-height:calc(100vh - 1rem);
  overflow:hidden
}
.modal-dialog-scrollable .modal-header,.modal-dialog-scrollable .modal-footer{
  flex-shrink:0
}
.modal-dialog-scrollable .modal-body{
  overflow-y:auto
}
.modal-dialog-centered{
  display:flex;
  align-items:center;
  min-height:calc(100% - 1rem)
}
.modal-dialog-centered::before{
  display:block;
  height:calc(100vh - 1rem);
  height:-moz-min-content;
  height:min-content;
  content:""
}
.modal-dialog-centered.modal-dialog-scrollable{
  flex-direction:column;
  justify-content:center;
  height:100%
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content{
  max-height:none
}
.modal-dialog-centered.modal-dialog-scrollable::before{
  content:none
}
.modal-content{
  position:relative;
  display:flex;
  flex-direction:column;
  width:100%;
  pointer-events:auto;
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid rgba(0,0,0,.2);
  border-radius:.3rem;
  outline:0
}
.modal-backdrop{
  position:fixed;
  top:0;
  left:0;
  z-index:1040;
  width:100vw;
  height:100vh;
  background-color:#000
}
.modal-backdrop.fade{
  opacity:0
}
.modal-backdrop.show{
  opacity:.5
}
.modal-header{
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  padding:1rem 1rem;
  border-bottom:1px solid #dfdfdf;
  border-top-left-radius:calc(0.3rem - 1px);
  border-top-right-radius:calc(0.3rem - 1px)
}
.modal-header .close{
  padding:1rem 1rem;
  margin:-1rem -1rem -1rem auto
}
.modal-title{
  margin-bottom:0;
  line-height:1.5
}
.modal-body{
  position:relative;
  flex:1 1 auto;
  padding:1rem
}
.modal-footer{
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:flex-end;
  padding:.75rem;
  border-top:1px solid #dfdfdf;
  border-bottom-right-radius:calc(0.3rem - 1px);
  border-bottom-left-radius:calc(0.3rem - 1px)
}
.modal-footer>*{
  margin:.25rem
}
.modal-scrollbar-measure{
  position:absolute;
  top:-9999px;
  width:50px;
  height:50px;
  overflow:scroll
}
@media(min-width: 576px){
  .modal-dialog{
    max-width:500px;
    margin:1.75rem auto
  }

  .modal-dialog-scrollable{
    max-height:calc(100% - 3.5rem)
  }

  .modal-dialog-scrollable .modal-content{
    max-height:calc(100vh - 3.5rem)
  }

  .modal-dialog-centered{
    min-height:calc(100% - 3.5rem)
  }

  .modal-dialog-centered::before{
    height:calc(100vh - 3.5rem);
    height:-moz-min-content;
    height:min-content
  }

  .modal-sm{
    max-width:300px
  }
}
@media(min-width: 992px){
  .modal-lg,.modal-xl{
    max-width:800px
  }
}
@media(min-width: 1200px){
  .modal-xl{
    max-width:1140px
  }
}
.tooltip{
  position:absolute;
  z-index:1070;
  display:block;
  margin:0;
  font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style:normal;
  font-weight:400;
  line-height:1.5;
  text-align:left;
  text-align:start;
  text-decoration:none;
  text-shadow:none;
  text-transform:none;
  letter-spacing:normal;
  word-break:normal;
  word-spacing:normal;
  white-space:normal;
  line-break:auto;
  font-size:0.875rem;
  word-wrap:break-word;
  opacity:0
}
.tooltip.show{
  opacity:.9
}
.tooltip .arrow{
  position:absolute;
  display:block;
  width:.8rem;
  height:.4rem
}
.tooltip .arrow::before{
  position:absolute;
  content:"";
  border-color:rgba(0,0,0,0);
  border-style:solid
}
.bs-tooltip-top,.bs-tooltip-auto[data-popper-placement^=top],.bs-tooltip-auto[x-placement^=top]{
  padding:.4rem 0
}
.bs-tooltip-top .arrow,.bs-tooltip-auto[data-popper-placement^=top] .arrow,.bs-tooltip-auto[x-placement^=top] .arrow{
  bottom:0
}
.bs-tooltip-top .arrow::before,.bs-tooltip-auto[data-popper-placement^=top] .arrow::before,.bs-tooltip-auto[x-placement^=top] .arrow::before{
  top:0;
  border-width:.4rem .4rem 0;
  border-top-color:#f4f4f4
}
.bs-tooltip-right,.bs-tooltip-auto[x-placement^=right]{
  padding:0 .4rem
}
.bs-tooltip-right .arrow,.bs-tooltip-auto[x-placement^=right] .arrow{
  left:0;
  width:.4rem;
  height:.8rem
}
.bs-tooltip-right .arrow::before,.bs-tooltip-auto[x-placement^=right] .arrow::before{
  right:0;
  border-width:.4rem .4rem .4rem 0;
  border-right-color:#f4f4f4
}
.bs-tooltip-bottom,.bs-tooltip-auto[data-popper-placement^=bottom],.bs-tooltip-auto[x-placement^=bottom]{
  padding:.4rem 0
}
.bs-tooltip-bottom .arrow,.bs-tooltip-auto[data-popper-placement^=bottom] .arrow,.bs-tooltip-auto[x-placement^=bottom] .arrow{
  top:0
}
.bs-tooltip-bottom .arrow::before,.bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before,.bs-tooltip-auto[x-placement^=bottom] .arrow::before{
  bottom:0;
  border-width:0 .4rem .4rem;
  border-bottom-color:#f4f4f4
}
.bs-tooltip-left,.bs-tooltip-auto[x-placement^=left]{
  padding:0 .4rem
}
.bs-tooltip-left .arrow,.bs-tooltip-auto[x-placement^=left] .arrow{
  right:0;
  width:.4rem;
  height:.8rem
}
.bs-tooltip-left .arrow::before,.bs-tooltip-auto[x-placement^=left] .arrow::before{
  left:0;
  border-width:.4rem 0 .4rem .4rem;
  border-left-color:#f4f4f4
}
.tooltip-inner{
  max-width:200px;
  padding:.25rem .5rem;
  color:#fff;
  text-align:center;
  background-color:#000;
  border-radius:1px
}
.popover{
  position:absolute;
  top:0;
  left:0;
  z-index:1060;
  display:block;
  max-width:276px;
  font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style:normal;
  font-weight:400;
  line-height:1.5;
  text-align:left;
  text-align:start;
  text-decoration:none;
  text-shadow:none;
  text-transform:none;
  letter-spacing:normal;
  word-break:normal;
  word-spacing:normal;
  white-space:normal;
  line-break:auto;
  font-size:0.875rem;
  word-wrap:break-word;
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid rgba(0,0,0,.2);
  border-radius:.3rem
}
.popover .arrow{
  position:absolute;
  display:block;
  width:1rem;
  height:.5rem;
  margin:0 .3rem
}
.popover .arrow::before,.popover .arrow::after{
  position:absolute;
  display:block;
  content:"";
  border-color:rgba(0,0,0,0);
  border-style:solid
}
.bs-popover-top,.bs-popover-auto[x-placement^=top]{
  margin-bottom:.5rem
}
.bs-popover-top>.arrow,.bs-popover-auto[x-placement^=top]>.arrow{
  bottom:calc(-0.5rem - 1px)
}
.bs-popover-top>.arrow::before,.bs-popover-auto[x-placement^=top]>.arrow::before{
  bottom:0;
  border-width:.5rem .5rem 0;
  border-top-color:rgba(0,0,0,.25)
}
.bs-popover-top>.arrow::after,.bs-popover-auto[x-placement^=top]>.arrow::after{
  bottom:1px;
  border-width:.5rem .5rem 0;
  border-top-color:#fff
}
.bs-popover-right,.bs-popover-auto[x-placement^=right]{
  margin-left:.5rem
}
.bs-popover-right>.arrow,.bs-popover-auto[x-placement^=right]>.arrow{
  left:calc(-0.5rem - 1px);
  width:.5rem;
  height:1rem;
  margin:.3rem 0
}
.bs-popover-right>.arrow::before,.bs-popover-auto[x-placement^=right]>.arrow::before{
  left:0;
  border-width:.5rem .5rem .5rem 0;
  border-right-color:rgba(0,0,0,.25)
}
.bs-popover-right>.arrow::after,.bs-popover-auto[x-placement^=right]>.arrow::after{
  left:1px;
  border-width:.5rem .5rem .5rem 0;
  border-right-color:#fff
}
.bs-popover-bottom,.bs-popover-auto[x-placement^=bottom]{
  margin-top:.5rem
}
.bs-popover-bottom>.arrow,.bs-popover-auto[x-placement^=bottom]>.arrow{
  top:calc(-0.5rem - 1px)
}
.bs-popover-bottom>.arrow::before,.bs-popover-auto[x-placement^=bottom]>.arrow::before{
  top:0;
  border-width:0 .5rem .5rem .5rem;
  border-bottom-color:rgba(0,0,0,.25)
}
.bs-popover-bottom>.arrow::after,.bs-popover-auto[x-placement^=bottom]>.arrow::after{
  top:1px;
  border-width:0 .5rem .5rem .5rem;
  border-bottom-color:#fff
}
.bs-popover-bottom .popover-header::before,.bs-popover-auto[x-placement^=bottom] .popover-header::before{
  position:absolute;
  top:0;
  left:50%;
  display:block;
  width:1rem;
  margin-left:-0.5rem;
  content:"";
  border-bottom:1px solid #f7f7f7
}
.bs-popover-left,.bs-popover-auto[x-placement^=left]{
  margin-right:.5rem
}
.bs-popover-left>.arrow,.bs-popover-auto[x-placement^=left]>.arrow{
  right:calc(-0.5rem - 1px);
  width:.5rem;
  height:1rem;
  margin:.3rem 0
}
.bs-popover-left>.arrow::before,.bs-popover-auto[x-placement^=left]>.arrow::before{
  right:0;
  border-width:.5rem 0 .5rem .5rem;
  border-left-color:rgba(0,0,0,.25)
}
.bs-popover-left>.arrow::after,.bs-popover-auto[x-placement^=left]>.arrow::after{
  right:1px;
  border-width:.5rem 0 .5rem .5rem;
  border-left-color:#fff
}
.popover-header{
  padding:.5rem .75rem;
  margin-bottom:0;
  font-size:1rem;
  background-color:#f7f7f7;
  border-bottom:1px solid #ebebeb;
  border-top-left-radius:calc(0.3rem - 1px);
  border-top-right-radius:calc(0.3rem - 1px)
}
.popover-header:empty{
  display:none
}
.popover-body{
  padding:.5rem .75rem;
  color:#414141
}
.carousel{
  position:relative
}
.carousel.pointer-event{
  touch-action:pan-y
}
.carousel-inner{
  position:relative;
  width:100%;
  overflow:hidden
}
.carousel-inner::after{
  display:block;
  clear:both;
  content:""
}
.carousel-item{
  position:relative;
  display:none;
  float:left;
  width:100%;
  margin-right:-100%;
  -webkit-backface-visibility:hidden;
          backface-visibility:hidden;
  transition:transform .6s ease-in-out
}
@media(prefers-reduced-motion: reduce){
  .carousel-item{
    transition:none
  }
}
.carousel-item.active,.carousel-item-next,.carousel-item-prev{
  display:block
}
.carousel-item-next:not(.carousel-item-left),.active.carousel-item-right{
  transform:translateX(100%)
}
.carousel-item-prev:not(.carousel-item-right),.active.carousel-item-left{
  transform:translateX(-100%)
}
.carousel-fade .carousel-item{
  opacity:0;
  transition-property:opacity;
  transform:none
}
.carousel-fade .carousel-item.active,.carousel-fade .carousel-item-next.carousel-item-left,.carousel-fade .carousel-item-prev.carousel-item-right{
  z-index:1;
  opacity:1
}
.carousel-fade .active.carousel-item-left,.carousel-fade .active.carousel-item-right{
  z-index:0;
  opacity:0;
  transition:opacity 0s .6s
}
@media(prefers-reduced-motion: reduce){
  .carousel-fade .active.carousel-item-left,.carousel-fade .active.carousel-item-right{
    transition:none
  }
}
.carousel-control-prev,.carousel-control-next{
  position:absolute;
  top:0;
  bottom:0;
  z-index:1;
  display:flex;
  align-items:center;
  justify-content:center;
  width:15%;
  color:#fff;
  text-align:center;
  opacity:.5;
  transition:opacity .15s ease
}
@media(prefers-reduced-motion: reduce){
  .carousel-control-prev,.carousel-control-next{
    transition:none
  }
}
.carousel-control-prev:hover,.carousel-control-prev:focus,.carousel-control-next:hover,.carousel-control-next:focus{
  color:#fff;
  text-decoration:none;
  outline:0;
  opacity:.9
}
.carousel-control-prev{
  left:0
}
.carousel-control-next{
  right:0
}
.carousel-control-prev-icon,.carousel-control-next-icon{
  display:inline-block;
  width:20px;
  height:20px;
  background:50%/100% 100% no-repeat
}
.carousel-control-prev-icon{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}
.carousel-control-next-icon{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}
.carousel-indicators{
  position:absolute;
  right:0;
  bottom:0;
  left:0;
  z-index:15;
  display:flex;
  justify-content:center;
  padding-left:0;
  margin-right:15%;
  margin-left:15%;
  list-style:none
}
.carousel-indicators li{
  box-sizing:content-box;
  flex:0 1 auto;
  width:30px;
  height:3px;
  margin-right:3px;
  margin-left:3px;
  text-indent:-999px;
  cursor:pointer;
  background-color:#fff;
  background-clip:padding-box;
  border-top:10px solid rgba(0,0,0,0);
  border-bottom:10px solid rgba(0,0,0,0);
  opacity:.5;
  transition:opacity .6s ease
}
@media(prefers-reduced-motion: reduce){
  .carousel-indicators li{
    transition:none
  }
}
.carousel-indicators .active{
  opacity:1
}
.carousel-caption{
  position:absolute;
  right:15%;
  bottom:20px;
  left:15%;
  z-index:10;
  padding-top:20px;
  padding-bottom:20px;
  color:#fff;
  text-align:center
}
@keyframes spinner-border{
  to{
    transform:rotate(360deg)
  }
}
.spinner-border{
  display:inline-block;
  width:2rem;
  height:2rem;
  vertical-align:text-bottom;
  border:.25em solid currentColor;
  border-right-color:rgba(0,0,0,0);
  border-radius:50%;
  animation:.75s linear infinite spinner-border
}
.spinner-border-sm{
  width:1rem;
  height:1rem;
  border-width:.2em
}
@keyframes spinner-grow{
  0%{
    transform:scale(0)
  }

  50%{
    opacity:1;
    transform:none
  }
}
.spinner-grow{
  display:inline-block;
  width:2rem;
  height:2rem;
  vertical-align:text-bottom;
  background-color:currentColor;
  border-radius:50%;
  opacity:0;
  animation:.75s linear infinite spinner-grow
}
.spinner-grow-sm{
  width:1rem;
  height:1rem
}
@media(prefers-reduced-motion: reduce){
  .spinner-border,.spinner-grow{
    animation-duration:1.5s
  }
}
.align-baseline{
  vertical-align:baseline !important
}
.align-top{
  vertical-align:top !important
}
.align-middle{
  vertical-align:middle !important
}
.align-bottom{
  vertical-align:bottom !important
}
.align-text-bottom{
  vertical-align:text-bottom !important
}
.align-text-top{
  vertical-align:text-top !important
}
.bg-primary{
  background-color:#2ec4dd !important
}
a.bg-primary:hover,a.bg-primary:focus,button.bg-primary:hover,button.bg-primary:focus{
  background-color:#1ea4ba !important
}
.bg-secondary{
  background-color:#6c757d !important
}
a.bg-secondary:hover,a.bg-secondary:focus,button.bg-secondary:hover,button.bg-secondary:focus{
  background-color:#545b62 !important
}
.bg-success{
  background-color:#28a745 !important
}
a.bg-success:hover,a.bg-success:focus,button.bg-success:hover,button.bg-success:focus{
  background-color:#1e7e34 !important
}
.bg-info{
  background-color:#17a2b8 !important
}
a.bg-info:hover,a.bg-info:focus,button.bg-info:hover,button.bg-info:focus{
  background-color:#117a8b !important
}
.bg-warning{
  background-color:#ffc107 !important
}
a.bg-warning:hover,a.bg-warning:focus,button.bg-warning:hover,button.bg-warning:focus{
  background-color:#d39e00 !important
}
.bg-danger{
  background-color:#dc3545 !important
}
a.bg-danger:hover,a.bg-danger:focus,button.bg-danger:hover,button.bg-danger:focus{
  background-color:#bd2130 !important
}
.bg-light{
  background-color:#dfdfdf !important
}
a.bg-light:hover,a.bg-light:focus,button.bg-light:hover,button.bg-light:focus{
  background-color:#c6c6c6 !important
}
.bg-dark{
  background-color:#343a40 !important
}
a.bg-dark:hover,a.bg-dark:focus,button.bg-dark:hover,button.bg-dark:focus{
  background-color:#1d2124 !important
}
.bg-white{
  background-color:#fff !important
}
.bg-transparent{
  background-color:rgba(0,0,0,0) !important
}
.border{
  border:1px solid #dfdfdf !important
}
.border-top{
  border-top:1px solid #dfdfdf !important
}
.border-right{
  border-right:1px solid #dfdfdf !important
}
.border-bottom{
  border-bottom:1px solid #dfdfdf !important
}
.border-left{
  border-left:1px solid #dfdfdf !important
}
.border-0{
  border:0 !important
}
.border-top-0{
  border-top:0 !important
}
.border-right-0{
  border-right:0 !important
}
.border-bottom-0{
  border-bottom:0 !important
}
.border-left-0{
  border-left:0 !important
}
.border-primary{
  border-color:#2ec4dd !important
}
.border-secondary{
  border-color:#6c757d !important
}
.border-success{
  border-color:#28a745 !important
}
.border-info{
  border-color:#17a2b8 !important
}
.border-warning{
  border-color:#ffc107 !important
}
.border-danger{
  border-color:#dc3545 !important
}
.border-light{
  border-color:#dfdfdf !important
}
.border-dark{
  border-color:#343a40 !important
}
.border-white{
  border-color:#fff !important
}
.rounded-sm{
  border-radius:.2rem !important
}
.rounded{
  border-radius:1px !important
}
.rounded-top{
  border-top-left-radius:1px !important;
  border-top-right-radius:1px !important
}
.rounded-right{
  border-top-right-radius:1px !important;
  border-bottom-right-radius:1px !important
}
.rounded-bottom{
  border-bottom-right-radius:1px !important;
  border-bottom-left-radius:1px !important
}
.rounded-left{
  border-top-left-radius:1px !important;
  border-bottom-left-radius:1px !important
}
.rounded-lg{
  border-radius:.3rem !important
}
.rounded-circle{
  border-radius:50% !important
}
.rounded-pill{
  border-radius:50rem !important
}
.rounded-0{
  border-radius:0 !important
}
.clearfix::after{
  display:block;
  clear:both;
  content:""
}
.d-none{
  display:none !important
}
.d-inline{
  display:inline !important
}
.d-inline-block{
  display:inline-block !important
}
.d-block{
  display:block !important
}
.d-table{
  display:table !important
}
.d-table-row{
  display:table-row !important
}
.d-table-cell{
  display:table-cell !important
}
.d-flex{
  display:flex !important
}
.d-inline-flex{
  display:inline-flex !important
}
@media(min-width: 576px){
  .d-sm-none{
    display:none !important
  }

  .d-sm-inline{
    display:inline !important
  }

  .d-sm-inline-block{
    display:inline-block !important
  }

  .d-sm-block{
    display:block !important
  }

  .d-sm-table{
    display:table !important
  }

  .d-sm-table-row{
    display:table-row !important
  }

  .d-sm-table-cell{
    display:table-cell !important
  }

  .d-sm-flex{
    display:flex !important
  }

  .d-sm-inline-flex{
    display:inline-flex !important
  }
}
@media(min-width: 769px){
  .d-md-none{
    display:none !important
  }

  .d-md-inline{
    display:inline !important
  }

  .d-md-inline-block{
    display:inline-block !important
  }

  .d-md-block{
    display:block !important
  }

  .d-md-table{
    display:table !important
  }

  .d-md-table-row{
    display:table-row !important
  }

  .d-md-table-cell{
    display:table-cell !important
  }

  .d-md-flex{
    display:flex !important
  }

  .d-md-inline-flex{
    display:inline-flex !important
  }
}
@media(min-width: 992px){
  .d-lg-none{
    display:none !important
  }

  .d-lg-inline{
    display:inline !important
  }

  .d-lg-inline-block{
    display:inline-block !important
  }

  .d-lg-block{
    display:block !important
  }

  .d-lg-table{
    display:table !important
  }

  .d-lg-table-row{
    display:table-row !important
  }

  .d-lg-table-cell{
    display:table-cell !important
  }

  .d-lg-flex{
    display:flex !important
  }

  .d-lg-inline-flex{
    display:inline-flex !important
  }
}
@media(min-width: 1200px){
  .d-xl-none{
    display:none !important
  }

  .d-xl-inline{
    display:inline !important
  }

  .d-xl-inline-block{
    display:inline-block !important
  }

  .d-xl-block{
    display:block !important
  }

  .d-xl-table{
    display:table !important
  }

  .d-xl-table-row{
    display:table-row !important
  }

  .d-xl-table-cell{
    display:table-cell !important
  }

  .d-xl-flex{
    display:flex !important
  }

  .d-xl-inline-flex{
    display:inline-flex !important
  }
}
@media print{
  .d-print-none{
    display:none !important
  }

  .d-print-inline{
    display:inline !important
  }

  .d-print-inline-block{
    display:inline-block !important
  }

  .d-print-block{
    display:block !important
  }

  .d-print-table{
    display:table !important
  }

  .d-print-table-row{
    display:table-row !important
  }

  .d-print-table-cell{
    display:table-cell !important
  }

  .d-print-flex{
    display:flex !important
  }

  .d-print-inline-flex{
    display:inline-flex !important
  }
}
.embed-responsive{
  position:relative;
  display:block;
  width:100%;
  padding:0;
  overflow:hidden
}
.embed-responsive::before{
  display:block;
  content:""
}
.embed-responsive .embed-responsive-item,.embed-responsive iframe,.embed-responsive embed,.embed-responsive object,.embed-responsive video{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  width:100%;
  height:100%;
  border:0
}
.embed-responsive-21by9::before{
  padding-top:42.8571428571%
}
.embed-responsive-16by9::before{
  padding-top:56.25%
}
.embed-responsive-4by3::before{
  padding-top:75%
}
.embed-responsive-1by1::before{
  padding-top:100%
}
.flex-row{
  flex-direction:row !important
}
.flex-column{
  flex-direction:column !important
}
.flex-row-reverse{
  flex-direction:row-reverse !important
}
.flex-column-reverse{
  flex-direction:column-reverse !important
}
.flex-wrap{
  flex-wrap:wrap !important
}
.flex-nowrap{
  flex-wrap:nowrap !important
}
.flex-wrap-reverse{
  flex-wrap:wrap-reverse !important
}
.flex-fill{
  flex:1 1 auto !important
}
.flex-grow-0{
  flex-grow:0 !important
}
.flex-grow-1{
  flex-grow:1 !important
}
.flex-shrink-0{
  flex-shrink:0 !important
}
.flex-shrink-1{
  flex-shrink:1 !important
}
.justify-content-start{
  justify-content:flex-start !important
}
.justify-content-end{
  justify-content:flex-end !important
}
.justify-content-center{
  justify-content:center !important
}
.justify-content-between{
  justify-content:space-between !important
}
.justify-content-around{
  justify-content:space-around !important
}
.align-items-start{
  align-items:flex-start !important
}
.align-items-end{
  align-items:flex-end !important
}
.align-items-center{
  align-items:center !important
}
.align-items-baseline{
  align-items:baseline !important
}
.align-items-stretch{
  align-items:stretch !important
}
.align-content-start{
  align-content:flex-start !important
}
.align-content-end{
  align-content:flex-end !important
}
.align-content-center{
  align-content:center !important
}
.align-content-between{
  align-content:space-between !important
}
.align-content-around{
  align-content:space-around !important
}
.align-content-stretch{
  align-content:stretch !important
}
.align-self-auto{
  align-self:auto !important
}
.align-self-start{
  align-self:flex-start !important
}
.align-self-end{
  align-self:flex-end !important
}
.align-self-center{
  align-self:center !important
}
.align-self-baseline{
  align-self:baseline !important
}
.align-self-stretch{
  align-self:stretch !important
}
@media(min-width: 576px){
  .flex-sm-row{
    flex-direction:row !important
  }

  .flex-sm-column{
    flex-direction:column !important
  }

  .flex-sm-row-reverse{
    flex-direction:row-reverse !important
  }

  .flex-sm-column-reverse{
    flex-direction:column-reverse !important
  }

  .flex-sm-wrap{
    flex-wrap:wrap !important
  }

  .flex-sm-nowrap{
    flex-wrap:nowrap !important
  }

  .flex-sm-wrap-reverse{
    flex-wrap:wrap-reverse !important
  }

  .flex-sm-fill{
    flex:1 1 auto !important
  }

  .flex-sm-grow-0{
    flex-grow:0 !important
  }

  .flex-sm-grow-1{
    flex-grow:1 !important
  }

  .flex-sm-shrink-0{
    flex-shrink:0 !important
  }

  .flex-sm-shrink-1{
    flex-shrink:1 !important
  }

  .justify-content-sm-start{
    justify-content:flex-start !important
  }

  .justify-content-sm-end{
    justify-content:flex-end !important
  }

  .justify-content-sm-center{
    justify-content:center !important
  }

  .justify-content-sm-between{
    justify-content:space-between !important
  }

  .justify-content-sm-around{
    justify-content:space-around !important
  }

  .align-items-sm-start{
    align-items:flex-start !important
  }

  .align-items-sm-end{
    align-items:flex-end !important
  }

  .align-items-sm-center{
    align-items:center !important
  }

  .align-items-sm-baseline{
    align-items:baseline !important
  }

  .align-items-sm-stretch{
    align-items:stretch !important
  }

  .align-content-sm-start{
    align-content:flex-start !important
  }

  .align-content-sm-end{
    align-content:flex-end !important
  }

  .align-content-sm-center{
    align-content:center !important
  }

  .align-content-sm-between{
    align-content:space-between !important
  }

  .align-content-sm-around{
    align-content:space-around !important
  }

  .align-content-sm-stretch{
    align-content:stretch !important
  }

  .align-self-sm-auto{
    align-self:auto !important
  }

  .align-self-sm-start{
    align-self:flex-start !important
  }

  .align-self-sm-end{
    align-self:flex-end !important
  }

  .align-self-sm-center{
    align-self:center !important
  }

  .align-self-sm-baseline{
    align-self:baseline !important
  }

  .align-self-sm-stretch{
    align-self:stretch !important
  }
}
@media(min-width: 769px){
  .flex-md-row{
    flex-direction:row !important
  }

  .flex-md-column{
    flex-direction:column !important
  }

  .flex-md-row-reverse{
    flex-direction:row-reverse !important
  }

  .flex-md-column-reverse{
    flex-direction:column-reverse !important
  }

  .flex-md-wrap{
    flex-wrap:wrap !important
  }

  .flex-md-nowrap{
    flex-wrap:nowrap !important
  }

  .flex-md-wrap-reverse{
    flex-wrap:wrap-reverse !important
  }

  .flex-md-fill{
    flex:1 1 auto !important
  }

  .flex-md-grow-0{
    flex-grow:0 !important
  }

  .flex-md-grow-1{
    flex-grow:1 !important
  }

  .flex-md-shrink-0{
    flex-shrink:0 !important
  }

  .flex-md-shrink-1{
    flex-shrink:1 !important
  }

  .justify-content-md-start{
    justify-content:flex-start !important
  }

  .justify-content-md-end{
    justify-content:flex-end !important
  }

  .justify-content-md-center{
    justify-content:center !important
  }

  .justify-content-md-between{
    justify-content:space-between !important
  }

  .justify-content-md-around{
    justify-content:space-around !important
  }

  .align-items-md-start{
    align-items:flex-start !important
  }

  .align-items-md-end{
    align-items:flex-end !important
  }

  .align-items-md-center{
    align-items:center !important
  }

  .align-items-md-baseline{
    align-items:baseline !important
  }

  .align-items-md-stretch{
    align-items:stretch !important
  }

  .align-content-md-start{
    align-content:flex-start !important
  }

  .align-content-md-end{
    align-content:flex-end !important
  }

  .align-content-md-center{
    align-content:center !important
  }

  .align-content-md-between{
    align-content:space-between !important
  }

  .align-content-md-around{
    align-content:space-around !important
  }

  .align-content-md-stretch{
    align-content:stretch !important
  }

  .align-self-md-auto{
    align-self:auto !important
  }

  .align-self-md-start{
    align-self:flex-start !important
  }

  .align-self-md-end{
    align-self:flex-end !important
  }

  .align-self-md-center{
    align-self:center !important
  }

  .align-self-md-baseline{
    align-self:baseline !important
  }

  .align-self-md-stretch{
    align-self:stretch !important
  }
}
@media(min-width: 992px){
  .flex-lg-row{
    flex-direction:row !important
  }

  .flex-lg-column{
    flex-direction:column !important
  }

  .flex-lg-row-reverse{
    flex-direction:row-reverse !important
  }

  .flex-lg-column-reverse{
    flex-direction:column-reverse !important
  }

  .flex-lg-wrap{
    flex-wrap:wrap !important
  }

  .flex-lg-nowrap{
    flex-wrap:nowrap !important
  }

  .flex-lg-wrap-reverse{
    flex-wrap:wrap-reverse !important
  }

  .flex-lg-fill{
    flex:1 1 auto !important
  }

  .flex-lg-grow-0{
    flex-grow:0 !important
  }

  .flex-lg-grow-1{
    flex-grow:1 !important
  }

  .flex-lg-shrink-0{
    flex-shrink:0 !important
  }

  .flex-lg-shrink-1{
    flex-shrink:1 !important
  }

  .justify-content-lg-start{
    justify-content:flex-start !important
  }

  .justify-content-lg-end{
    justify-content:flex-end !important
  }

  .justify-content-lg-center{
    justify-content:center !important
  }

  .justify-content-lg-between{
    justify-content:space-between !important
  }

  .justify-content-lg-around{
    justify-content:space-around !important
  }

  .align-items-lg-start{
    align-items:flex-start !important
  }

  .align-items-lg-end{
    align-items:flex-end !important
  }

  .align-items-lg-center{
    align-items:center !important
  }

  .align-items-lg-baseline{
    align-items:baseline !important
  }

  .align-items-lg-stretch{
    align-items:stretch !important
  }

  .align-content-lg-start{
    align-content:flex-start !important
  }

  .align-content-lg-end{
    align-content:flex-end !important
  }

  .align-content-lg-center{
    align-content:center !important
  }

  .align-content-lg-between{
    align-content:space-between !important
  }

  .align-content-lg-around{
    align-content:space-around !important
  }

  .align-content-lg-stretch{
    align-content:stretch !important
  }

  .align-self-lg-auto{
    align-self:auto !important
  }

  .align-self-lg-start{
    align-self:flex-start !important
  }

  .align-self-lg-end{
    align-self:flex-end !important
  }

  .align-self-lg-center{
    align-self:center !important
  }

  .align-self-lg-baseline{
    align-self:baseline !important
  }

  .align-self-lg-stretch{
    align-self:stretch !important
  }
}
@media(min-width: 1200px){
  .flex-xl-row{
    flex-direction:row !important
  }

  .flex-xl-column{
    flex-direction:column !important
  }

  .flex-xl-row-reverse{
    flex-direction:row-reverse !important
  }

  .flex-xl-column-reverse{
    flex-direction:column-reverse !important
  }

  .flex-xl-wrap{
    flex-wrap:wrap !important
  }

  .flex-xl-nowrap{
    flex-wrap:nowrap !important
  }

  .flex-xl-wrap-reverse{
    flex-wrap:wrap-reverse !important
  }

  .flex-xl-fill{
    flex:1 1 auto !important
  }

  .flex-xl-grow-0{
    flex-grow:0 !important
  }

  .flex-xl-grow-1{
    flex-grow:1 !important
  }

  .flex-xl-shrink-0{
    flex-shrink:0 !important
  }

  .flex-xl-shrink-1{
    flex-shrink:1 !important
  }

  .justify-content-xl-start{
    justify-content:flex-start !important
  }

  .justify-content-xl-end{
    justify-content:flex-end !important
  }

  .justify-content-xl-center{
    justify-content:center !important
  }

  .justify-content-xl-between{
    justify-content:space-between !important
  }

  .justify-content-xl-around{
    justify-content:space-around !important
  }

  .align-items-xl-start{
    align-items:flex-start !important
  }

  .align-items-xl-end{
    align-items:flex-end !important
  }

  .align-items-xl-center{
    align-items:center !important
  }

  .align-items-xl-baseline{
    align-items:baseline !important
  }

  .align-items-xl-stretch{
    align-items:stretch !important
  }

  .align-content-xl-start{
    align-content:flex-start !important
  }

  .align-content-xl-end{
    align-content:flex-end !important
  }

  .align-content-xl-center{
    align-content:center !important
  }

  .align-content-xl-between{
    align-content:space-between !important
  }

  .align-content-xl-around{
    align-content:space-around !important
  }

  .align-content-xl-stretch{
    align-content:stretch !important
  }

  .align-self-xl-auto{
    align-self:auto !important
  }

  .align-self-xl-start{
    align-self:flex-start !important
  }

  .align-self-xl-end{
    align-self:flex-end !important
  }

  .align-self-xl-center{
    align-self:center !important
  }

  .align-self-xl-baseline{
    align-self:baseline !important
  }

  .align-self-xl-stretch{
    align-self:stretch !important
  }
}
.float-left{
  float:left !important
}
.float-right{
  float:right !important
}
.float-none{
  float:none !important
}
@media(min-width: 576px){
  .float-sm-left{
    float:left !important
  }

  .float-sm-right{
    float:right !important
  }

  .float-sm-none{
    float:none !important
  }
}
@media(min-width: 769px){
  .float-md-left{
    float:left !important
  }

  .float-md-right{
    float:right !important
  }

  .float-md-none{
    float:none !important
  }
}
@media(min-width: 992px){
  .float-lg-left{
    float:left !important
  }

  .float-lg-right{
    float:right !important
  }

  .float-lg-none{
    float:none !important
  }
}
@media(min-width: 1200px){
  .float-xl-left{
    float:left !important
  }

  .float-xl-right{
    float:right !important
  }

  .float-xl-none{
    float:none !important
  }
}
.user-select-all{
  -webkit-user-select:all !important;
     -moz-user-select:all !important;
          user-select:all !important
}
.user-select-auto{
  -webkit-user-select:auto !important;
     -moz-user-select:auto !important;
          user-select:auto !important
}
.user-select-none{
  -webkit-user-select:none !important;
     -moz-user-select:none !important;
          user-select:none !important
}
.overflow-auto{
  overflow:auto !important
}
.overflow-hidden{
  overflow:hidden !important
}
.position-static{
  position:static !important
}
.position-relative{
  position:relative !important
}
.position-absolute{
  position:absolute !important
}
.position-fixed{
  position:fixed !important
}
.position-sticky{
  position:sticky !important
}
.fixed-top{
  position:fixed;
  top:0;
  right:0;
  left:0;
  z-index:1030
}
.fixed-bottom{
  position:fixed;
  right:0;
  bottom:0;
  left:0;
  z-index:1030
}
@supports(position: sticky){
  .sticky-top{
    position:sticky;
    top:0;
    z-index:1020
  }
}
.sr-only{
  position:absolute;
  width:1px;
  height:1px;
  padding:0;
  margin:-1px;
  overflow:hidden;
  clip:rect(0, 0, 0, 0);
  white-space:nowrap;
  border:0
}
.sr-only-focusable:active,.sr-only-focusable:focus{
  position:static;
  width:auto;
  height:auto;
  overflow:visible;
  clip:auto;
  white-space:normal
}
.shadow-sm{
  box-shadow:0 .125rem .25rem rgba(0,0,0,.075) !important
}
.shadow{
  box-shadow:0 .5rem 1rem rgba(0,0,0,.15) !important
}
.shadow-lg{
  box-shadow:0 1rem 3rem rgba(0,0,0,.175) !important
}
.shadow-none{
  box-shadow:none !important
}
.w-25{
  width:25% !important
}
.w-50{
  width:50% !important
}
.w-75{
  width:75% !important
}
.w-100{
  width:100% !important
}
.w-auto{
  width:auto !important
}
.h-25{
  height:25% !important
}
.h-50{
  height:50% !important
}
.h-75{
  height:75% !important
}
.h-100{
  height:100% !important
}
.h-auto{
  height:auto !important
}
.mw-100{
  max-width:100% !important
}
.mh-100{
  max-height:100% !important
}
.min-vw-100{
  min-width:100vw !important
}
.min-vh-100{
  min-height:100vh !important
}
.vw-100{
  width:100vw !important
}
.vh-100{
  height:100vh !important
}
.m-0{
  margin:0 !important
}
.mt-0,.my-0{
  margin-top:0 !important
}
.mr-0,.mx-0{
  margin-right:0 !important
}
.mb-0,.my-0{
  margin-bottom:0 !important
}
.ml-0,.mx-0{
  margin-left:0 !important
}
.m-1{
  margin:4px !important
}
.mt-1,.my-1{
  margin-top:4px !important
}
.mr-1,.mx-1{
  margin-right:4px !important
}
.mb-1,.my-1{
  margin-bottom:4px !important
}
.ml-1,.mx-1{
  margin-left:4px !important
}
.m-2{
  margin:8px !important
}
.mt-2,.my-2{
  margin-top:8px !important
}
.mr-2,.mx-2{
  margin-right:8px !important
}
.mb-2,.my-2{
  margin-bottom:8px !important
}
.ml-2,.mx-2{
  margin-left:8px !important
}
.m-3{
  margin:16px !important
}
.mt-3,.my-3{
  margin-top:16px !important
}
.mr-3,.mx-3{
  margin-right:16px !important
}
.mb-3,.my-3{
  margin-bottom:16px !important
}
.ml-3,.mx-3{
  margin-left:16px !important
}
.m-4{
  margin:24px !important
}
.mt-4,.my-4{
  margin-top:24px !important
}
.mr-4,.mx-4{
  margin-right:24px !important
}
.mb-4,.my-4{
  margin-bottom:24px !important
}
.ml-4,.mx-4{
  margin-left:24px !important
}
.m-5{
  margin:48px !important
}
.mt-5,.my-5{
  margin-top:48px !important
}
.mr-5,.mx-5{
  margin-right:48px !important
}
.mb-5,.my-5{
  margin-bottom:48px !important
}
.ml-5,.mx-5{
  margin-left:48px !important
}
.m-12{
  margin:12px !important
}
.mt-12,.my-12{
  margin-top:12px !important
}
.mr-12,.mx-12{
  margin-right:12px !important
}
.mb-12,.my-12{
  margin-bottom:12px !important
}
.ml-12,.mx-12{
  margin-left:12px !important
}
.m-15{
  margin:15px !important
}
.mt-15,.my-15{
  margin-top:15px !important
}
.mr-15,.mx-15{
  margin-right:15px !important
}
.mb-15,.my-15{
  margin-bottom:15px !important
}
.ml-15,.mx-15{
  margin-left:15px !important
}
.m-6{
  margin:64px !important
}
.mt-6,.my-6{
  margin-top:64px !important
}
.mr-6,.mx-6{
  margin-right:64px !important
}
.mb-6,.my-6{
  margin-bottom:64px !important
}
.ml-6,.mx-6{
  margin-left:64px !important
}
.m-7{
  margin:80px !important
}
.mt-7,.my-7{
  margin-top:80px !important
}
.mr-7,.mx-7{
  margin-right:80px !important
}
.mb-7,.my-7{
  margin-bottom:80px !important
}
.ml-7,.mx-7{
  margin-left:80px !important
}
.m-32{
  margin:32px !important
}
.mt-32,.my-32{
  margin-top:32px !important
}
.mr-32,.mx-32{
  margin-right:32px !important
}
.mb-32,.my-32{
  margin-bottom:32px !important
}
.ml-32,.mx-32{
  margin-left:32px !important
}
.p-0{
  padding:0 !important
}
.pt-0,.py-0{
  padding-top:0 !important
}
.pr-0,.px-0{
  padding-right:0 !important
}
.pb-0,.py-0{
  padding-bottom:0 !important
}
.pl-0,.px-0{
  padding-left:0 !important
}
.p-1{
  padding:4px !important
}
.pt-1,.py-1{
  padding-top:4px !important
}
.pr-1,.px-1{
  padding-right:4px !important
}
.pb-1,.py-1{
  padding-bottom:4px !important
}
.pl-1,.px-1{
  padding-left:4px !important
}
.p-2{
  padding:8px !important
}
.pt-2,.py-2{
  padding-top:8px !important
}
.pr-2,.px-2{
  padding-right:8px !important
}
.pb-2,.py-2{
  padding-bottom:8px !important
}
.pl-2,.px-2{
  padding-left:8px !important
}
.p-3{
  padding:16px !important
}
.pt-3,.py-3{
  padding-top:16px !important
}
.pr-3,.px-3{
  padding-right:16px !important
}
.pb-3,.py-3{
  padding-bottom:16px !important
}
.pl-3,.px-3{
  padding-left:16px !important
}
.p-4{
  padding:24px !important
}
.pt-4,.py-4{
  padding-top:24px !important
}
.pr-4,.px-4{
  padding-right:24px !important
}
.pb-4,.py-4{
  padding-bottom:24px !important
}
.pl-4,.px-4{
  padding-left:24px !important
}
.p-5{
  padding:48px !important
}
.pt-5,.py-5{
  padding-top:48px !important
}
.pr-5,.px-5{
  padding-right:48px !important
}
.pb-5,.py-5{
  padding-bottom:48px !important
}
.pl-5,.px-5{
  padding-left:48px !important
}
.p-12{
  padding:12px !important
}
.pt-12,.py-12{
  padding-top:12px !important
}
.pr-12,.px-12{
  padding-right:12px !important
}
.pb-12,.py-12{
  padding-bottom:12px !important
}
.pl-12,.px-12{
  padding-left:12px !important
}
.p-15{
  padding:15px !important
}
.pt-15,.py-15{
  padding-top:15px !important
}
.pr-15,.px-15{
  padding-right:15px !important
}
.pb-15,.py-15{
  padding-bottom:15px !important
}
.pl-15,.px-15{
  padding-left:15px !important
}
.p-6{
  padding:64px !important
}
.pt-6,.py-6{
  padding-top:64px !important
}
.pr-6,.px-6{
  padding-right:64px !important
}
.pb-6,.py-6{
  padding-bottom:64px !important
}
.pl-6,.px-6{
  padding-left:64px !important
}
.p-7{
  padding:80px !important
}
.pt-7,.py-7{
  padding-top:80px !important
}
.pr-7,.px-7{
  padding-right:80px !important
}
.pb-7,.py-7{
  padding-bottom:80px !important
}
.pl-7,.px-7{
  padding-left:80px !important
}
.p-32{
  padding:32px !important
}
.pt-32,.py-32{
  padding-top:32px !important
}
.pr-32,.px-32{
  padding-right:32px !important
}
.pb-32,.py-32{
  padding-bottom:32px !important
}
.pl-32,.px-32{
  padding-left:32px !important
}
.m-n1{
  margin:-4px !important
}
.mt-n1,.my-n1{
  margin-top:-4px !important
}
.mr-n1,.mx-n1{
  margin-right:-4px !important
}
.mb-n1,.my-n1{
  margin-bottom:-4px !important
}
.ml-n1,.mx-n1{
  margin-left:-4px !important
}
.m-n2{
  margin:-8px !important
}
.mt-n2,.my-n2{
  margin-top:-8px !important
}
.mr-n2,.mx-n2{
  margin-right:-8px !important
}
.mb-n2,.my-n2{
  margin-bottom:-8px !important
}
.ml-n2,.mx-n2{
  margin-left:-8px !important
}
.m-n3{
  margin:-16px !important
}
.mt-n3,.my-n3{
  margin-top:-16px !important
}
.mr-n3,.mx-n3{
  margin-right:-16px !important
}
.mb-n3,.my-n3{
  margin-bottom:-16px !important
}
.ml-n3,.mx-n3{
  margin-left:-16px !important
}
.m-n4{
  margin:-24px !important
}
.mt-n4,.my-n4{
  margin-top:-24px !important
}
.mr-n4,.mx-n4{
  margin-right:-24px !important
}
.mb-n4,.my-n4{
  margin-bottom:-24px !important
}
.ml-n4,.mx-n4{
  margin-left:-24px !important
}
.m-n5{
  margin:-48px !important
}
.mt-n5,.my-n5{
  margin-top:-48px !important
}
.mr-n5,.mx-n5{
  margin-right:-48px !important
}
.mb-n5,.my-n5{
  margin-bottom:-48px !important
}
.ml-n5,.mx-n5{
  margin-left:-48px !important
}
.m-n12{
  margin:-12px !important
}
.mt-n12,.my-n12{
  margin-top:-12px !important
}
.mr-n12,.mx-n12{
  margin-right:-12px !important
}
.mb-n12,.my-n12{
  margin-bottom:-12px !important
}
.ml-n12,.mx-n12{
  margin-left:-12px !important
}
.m-n15{
  margin:-15px !important
}
.mt-n15,.my-n15{
  margin-top:-15px !important
}
.mr-n15,.mx-n15{
  margin-right:-15px !important
}
.mb-n15,.my-n15{
  margin-bottom:-15px !important
}
.ml-n15,.mx-n15{
  margin-left:-15px !important
}
.m-n6{
  margin:-64px !important
}
.mt-n6,.my-n6{
  margin-top:-64px !important
}
.mr-n6,.mx-n6{
  margin-right:-64px !important
}
.mb-n6,.my-n6{
  margin-bottom:-64px !important
}
.ml-n6,.mx-n6{
  margin-left:-64px !important
}
.m-n7{
  margin:-80px !important
}
.mt-n7,.my-n7{
  margin-top:-80px !important
}
.mr-n7,.mx-n7{
  margin-right:-80px !important
}
.mb-n7,.my-n7{
  margin-bottom:-80px !important
}
.ml-n7,.mx-n7{
  margin-left:-80px !important
}
.m-n32{
  margin:-32px !important
}
.mt-n32,.my-n32{
  margin-top:-32px !important
}
.mr-n32,.mx-n32{
  margin-right:-32px !important
}
.mb-n32,.my-n32{
  margin-bottom:-32px !important
}
.ml-n32,.mx-n32{
  margin-left:-32px !important
}
.m-auto{
  margin:auto !important
}
.mt-auto,.my-auto{
  margin-top:auto !important
}
.mr-auto,.mx-auto{
  margin-right:auto !important
}
.mb-auto,.my-auto{
  margin-bottom:auto !important
}
.ml-auto,.mx-auto{
  margin-left:auto !important
}
@media(min-width: 576px){
  .m-sm-0{
    margin:0 !important
  }

  .mt-sm-0,.my-sm-0{
    margin-top:0 !important
  }

  .mr-sm-0,.mx-sm-0{
    margin-right:0 !important
  }

  .mb-sm-0,.my-sm-0{
    margin-bottom:0 !important
  }

  .ml-sm-0,.mx-sm-0{
    margin-left:0 !important
  }

  .m-sm-1{
    margin:4px !important
  }

  .mt-sm-1,.my-sm-1{
    margin-top:4px !important
  }

  .mr-sm-1,.mx-sm-1{
    margin-right:4px !important
  }

  .mb-sm-1,.my-sm-1{
    margin-bottom:4px !important
  }

  .ml-sm-1,.mx-sm-1{
    margin-left:4px !important
  }

  .m-sm-2{
    margin:8px !important
  }

  .mt-sm-2,.my-sm-2{
    margin-top:8px !important
  }

  .mr-sm-2,.mx-sm-2{
    margin-right:8px !important
  }

  .mb-sm-2,.my-sm-2{
    margin-bottom:8px !important
  }

  .ml-sm-2,.mx-sm-2{
    margin-left:8px !important
  }

  .m-sm-3{
    margin:16px !important
  }

  .mt-sm-3,.my-sm-3{
    margin-top:16px !important
  }

  .mr-sm-3,.mx-sm-3{
    margin-right:16px !important
  }

  .mb-sm-3,.my-sm-3{
    margin-bottom:16px !important
  }

  .ml-sm-3,.mx-sm-3{
    margin-left:16px !important
  }

  .m-sm-4{
    margin:24px !important
  }

  .mt-sm-4,.my-sm-4{
    margin-top:24px !important
  }

  .mr-sm-4,.mx-sm-4{
    margin-right:24px !important
  }

  .mb-sm-4,.my-sm-4{
    margin-bottom:24px !important
  }

  .ml-sm-4,.mx-sm-4{
    margin-left:24px !important
  }

  .m-sm-5{
    margin:48px !important
  }

  .mt-sm-5,.my-sm-5{
    margin-top:48px !important
  }

  .mr-sm-5,.mx-sm-5{
    margin-right:48px !important
  }

  .mb-sm-5,.my-sm-5{
    margin-bottom:48px !important
  }

  .ml-sm-5,.mx-sm-5{
    margin-left:48px !important
  }

  .m-sm-12{
    margin:12px !important
  }

  .mt-sm-12,.my-sm-12{
    margin-top:12px !important
  }

  .mr-sm-12,.mx-sm-12{
    margin-right:12px !important
  }

  .mb-sm-12,.my-sm-12{
    margin-bottom:12px !important
  }

  .ml-sm-12,.mx-sm-12{
    margin-left:12px !important
  }

  .m-sm-15{
    margin:15px !important
  }

  .mt-sm-15,.my-sm-15{
    margin-top:15px !important
  }

  .mr-sm-15,.mx-sm-15{
    margin-right:15px !important
  }

  .mb-sm-15,.my-sm-15{
    margin-bottom:15px !important
  }

  .ml-sm-15,.mx-sm-15{
    margin-left:15px !important
  }

  .m-sm-6{
    margin:64px !important
  }

  .mt-sm-6,.my-sm-6{
    margin-top:64px !important
  }

  .mr-sm-6,.mx-sm-6{
    margin-right:64px !important
  }

  .mb-sm-6,.my-sm-6{
    margin-bottom:64px !important
  }

  .ml-sm-6,.mx-sm-6{
    margin-left:64px !important
  }

  .m-sm-7{
    margin:80px !important
  }

  .mt-sm-7,.my-sm-7{
    margin-top:80px !important
  }

  .mr-sm-7,.mx-sm-7{
    margin-right:80px !important
  }

  .mb-sm-7,.my-sm-7{
    margin-bottom:80px !important
  }

  .ml-sm-7,.mx-sm-7{
    margin-left:80px !important
  }

  .m-sm-32{
    margin:32px !important
  }

  .mt-sm-32,.my-sm-32{
    margin-top:32px !important
  }

  .mr-sm-32,.mx-sm-32{
    margin-right:32px !important
  }

  .mb-sm-32,.my-sm-32{
    margin-bottom:32px !important
  }

  .ml-sm-32,.mx-sm-32{
    margin-left:32px !important
  }

  .p-sm-0{
    padding:0 !important
  }

  .pt-sm-0,.py-sm-0{
    padding-top:0 !important
  }

  .pr-sm-0,.px-sm-0{
    padding-right:0 !important
  }

  .pb-sm-0,.py-sm-0{
    padding-bottom:0 !important
  }

  .pl-sm-0,.px-sm-0{
    padding-left:0 !important
  }

  .p-sm-1{
    padding:4px !important
  }

  .pt-sm-1,.py-sm-1{
    padding-top:4px !important
  }

  .pr-sm-1,.px-sm-1{
    padding-right:4px !important
  }

  .pb-sm-1,.py-sm-1{
    padding-bottom:4px !important
  }

  .pl-sm-1,.px-sm-1{
    padding-left:4px !important
  }

  .p-sm-2{
    padding:8px !important
  }

  .pt-sm-2,.py-sm-2{
    padding-top:8px !important
  }

  .pr-sm-2,.px-sm-2{
    padding-right:8px !important
  }

  .pb-sm-2,.py-sm-2{
    padding-bottom:8px !important
  }

  .pl-sm-2,.px-sm-2{
    padding-left:8px !important
  }

  .p-sm-3{
    padding:16px !important
  }

  .pt-sm-3,.py-sm-3{
    padding-top:16px !important
  }

  .pr-sm-3,.px-sm-3{
    padding-right:16px !important
  }

  .pb-sm-3,.py-sm-3{
    padding-bottom:16px !important
  }

  .pl-sm-3,.px-sm-3{
    padding-left:16px !important
  }

  .p-sm-4{
    padding:24px !important
  }

  .pt-sm-4,.py-sm-4{
    padding-top:24px !important
  }

  .pr-sm-4,.px-sm-4{
    padding-right:24px !important
  }

  .pb-sm-4,.py-sm-4{
    padding-bottom:24px !important
  }

  .pl-sm-4,.px-sm-4{
    padding-left:24px !important
  }

  .p-sm-5{
    padding:48px !important
  }

  .pt-sm-5,.py-sm-5{
    padding-top:48px !important
  }

  .pr-sm-5,.px-sm-5{
    padding-right:48px !important
  }

  .pb-sm-5,.py-sm-5{
    padding-bottom:48px !important
  }

  .pl-sm-5,.px-sm-5{
    padding-left:48px !important
  }

  .p-sm-12{
    padding:12px !important
  }

  .pt-sm-12,.py-sm-12{
    padding-top:12px !important
  }

  .pr-sm-12,.px-sm-12{
    padding-right:12px !important
  }

  .pb-sm-12,.py-sm-12{
    padding-bottom:12px !important
  }

  .pl-sm-12,.px-sm-12{
    padding-left:12px !important
  }

  .p-sm-15{
    padding:15px !important
  }

  .pt-sm-15,.py-sm-15{
    padding-top:15px !important
  }

  .pr-sm-15,.px-sm-15{
    padding-right:15px !important
  }

  .pb-sm-15,.py-sm-15{
    padding-bottom:15px !important
  }

  .pl-sm-15,.px-sm-15{
    padding-left:15px !important
  }

  .p-sm-6{
    padding:64px !important
  }

  .pt-sm-6,.py-sm-6{
    padding-top:64px !important
  }

  .pr-sm-6,.px-sm-6{
    padding-right:64px !important
  }

  .pb-sm-6,.py-sm-6{
    padding-bottom:64px !important
  }

  .pl-sm-6,.px-sm-6{
    padding-left:64px !important
  }

  .p-sm-7{
    padding:80px !important
  }

  .pt-sm-7,.py-sm-7{
    padding-top:80px !important
  }

  .pr-sm-7,.px-sm-7{
    padding-right:80px !important
  }

  .pb-sm-7,.py-sm-7{
    padding-bottom:80px !important
  }

  .pl-sm-7,.px-sm-7{
    padding-left:80px !important
  }

  .p-sm-32{
    padding:32px !important
  }

  .pt-sm-32,.py-sm-32{
    padding-top:32px !important
  }

  .pr-sm-32,.px-sm-32{
    padding-right:32px !important
  }

  .pb-sm-32,.py-sm-32{
    padding-bottom:32px !important
  }

  .pl-sm-32,.px-sm-32{
    padding-left:32px !important
  }

  .m-sm-n1{
    margin:-4px !important
  }

  .mt-sm-n1,.my-sm-n1{
    margin-top:-4px !important
  }

  .mr-sm-n1,.mx-sm-n1{
    margin-right:-4px !important
  }

  .mb-sm-n1,.my-sm-n1{
    margin-bottom:-4px !important
  }

  .ml-sm-n1,.mx-sm-n1{
    margin-left:-4px !important
  }

  .m-sm-n2{
    margin:-8px !important
  }

  .mt-sm-n2,.my-sm-n2{
    margin-top:-8px !important
  }

  .mr-sm-n2,.mx-sm-n2{
    margin-right:-8px !important
  }

  .mb-sm-n2,.my-sm-n2{
    margin-bottom:-8px !important
  }

  .ml-sm-n2,.mx-sm-n2{
    margin-left:-8px !important
  }

  .m-sm-n3{
    margin:-16px !important
  }

  .mt-sm-n3,.my-sm-n3{
    margin-top:-16px !important
  }

  .mr-sm-n3,.mx-sm-n3{
    margin-right:-16px !important
  }

  .mb-sm-n3,.my-sm-n3{
    margin-bottom:-16px !important
  }

  .ml-sm-n3,.mx-sm-n3{
    margin-left:-16px !important
  }

  .m-sm-n4{
    margin:-24px !important
  }

  .mt-sm-n4,.my-sm-n4{
    margin-top:-24px !important
  }

  .mr-sm-n4,.mx-sm-n4{
    margin-right:-24px !important
  }

  .mb-sm-n4,.my-sm-n4{
    margin-bottom:-24px !important
  }

  .ml-sm-n4,.mx-sm-n4{
    margin-left:-24px !important
  }

  .m-sm-n5{
    margin:-48px !important
  }

  .mt-sm-n5,.my-sm-n5{
    margin-top:-48px !important
  }

  .mr-sm-n5,.mx-sm-n5{
    margin-right:-48px !important
  }

  .mb-sm-n5,.my-sm-n5{
    margin-bottom:-48px !important
  }

  .ml-sm-n5,.mx-sm-n5{
    margin-left:-48px !important
  }

  .m-sm-n12{
    margin:-12px !important
  }

  .mt-sm-n12,.my-sm-n12{
    margin-top:-12px !important
  }

  .mr-sm-n12,.mx-sm-n12{
    margin-right:-12px !important
  }

  .mb-sm-n12,.my-sm-n12{
    margin-bottom:-12px !important
  }

  .ml-sm-n12,.mx-sm-n12{
    margin-left:-12px !important
  }

  .m-sm-n15{
    margin:-15px !important
  }

  .mt-sm-n15,.my-sm-n15{
    margin-top:-15px !important
  }

  .mr-sm-n15,.mx-sm-n15{
    margin-right:-15px !important
  }

  .mb-sm-n15,.my-sm-n15{
    margin-bottom:-15px !important
  }

  .ml-sm-n15,.mx-sm-n15{
    margin-left:-15px !important
  }

  .m-sm-n6{
    margin:-64px !important
  }

  .mt-sm-n6,.my-sm-n6{
    margin-top:-64px !important
  }

  .mr-sm-n6,.mx-sm-n6{
    margin-right:-64px !important
  }

  .mb-sm-n6,.my-sm-n6{
    margin-bottom:-64px !important
  }

  .ml-sm-n6,.mx-sm-n6{
    margin-left:-64px !important
  }

  .m-sm-n7{
    margin:-80px !important
  }

  .mt-sm-n7,.my-sm-n7{
    margin-top:-80px !important
  }

  .mr-sm-n7,.mx-sm-n7{
    margin-right:-80px !important
  }

  .mb-sm-n7,.my-sm-n7{
    margin-bottom:-80px !important
  }

  .ml-sm-n7,.mx-sm-n7{
    margin-left:-80px !important
  }

  .m-sm-n32{
    margin:-32px !important
  }

  .mt-sm-n32,.my-sm-n32{
    margin-top:-32px !important
  }

  .mr-sm-n32,.mx-sm-n32{
    margin-right:-32px !important
  }

  .mb-sm-n32,.my-sm-n32{
    margin-bottom:-32px !important
  }

  .ml-sm-n32,.mx-sm-n32{
    margin-left:-32px !important
  }

  .m-sm-auto{
    margin:auto !important
  }

  .mt-sm-auto,.my-sm-auto{
    margin-top:auto !important
  }

  .mr-sm-auto,.mx-sm-auto{
    margin-right:auto !important
  }

  .mb-sm-auto,.my-sm-auto{
    margin-bottom:auto !important
  }

  .ml-sm-auto,.mx-sm-auto{
    margin-left:auto !important
  }
}
@media(min-width: 769px){
  .m-md-0{
    margin:0 !important
  }

  .mt-md-0,.my-md-0{
    margin-top:0 !important
  }

  .mr-md-0,.mx-md-0{
    margin-right:0 !important
  }

  .mb-md-0,.my-md-0{
    margin-bottom:0 !important
  }

  .ml-md-0,.mx-md-0{
    margin-left:0 !important
  }

  .m-md-1{
    margin:4px !important
  }

  .mt-md-1,.my-md-1{
    margin-top:4px !important
  }

  .mr-md-1,.mx-md-1{
    margin-right:4px !important
  }

  .mb-md-1,.my-md-1{
    margin-bottom:4px !important
  }

  .ml-md-1,.mx-md-1{
    margin-left:4px !important
  }

  .m-md-2{
    margin:8px !important
  }

  .mt-md-2,.my-md-2{
    margin-top:8px !important
  }

  .mr-md-2,.mx-md-2{
    margin-right:8px !important
  }

  .mb-md-2,.my-md-2{
    margin-bottom:8px !important
  }

  .ml-md-2,.mx-md-2{
    margin-left:8px !important
  }

  .m-md-3{
    margin:16px !important
  }

  .mt-md-3,.my-md-3{
    margin-top:16px !important
  }

  .mr-md-3,.mx-md-3{
    margin-right:16px !important
  }

  .mb-md-3,.my-md-3{
    margin-bottom:16px !important
  }

  .ml-md-3,.mx-md-3{
    margin-left:16px !important
  }

  .m-md-4{
    margin:24px !important
  }

  .mt-md-4,.my-md-4{
    margin-top:24px !important
  }

  .mr-md-4,.mx-md-4{
    margin-right:24px !important
  }

  .mb-md-4,.my-md-4{
    margin-bottom:24px !important
  }

  .ml-md-4,.mx-md-4{
    margin-left:24px !important
  }

  .m-md-5{
    margin:48px !important
  }

  .mt-md-5,.my-md-5{
    margin-top:48px !important
  }

  .mr-md-5,.mx-md-5{
    margin-right:48px !important
  }

  .mb-md-5,.my-md-5{
    margin-bottom:48px !important
  }

  .ml-md-5,.mx-md-5{
    margin-left:48px !important
  }

  .m-md-12{
    margin:12px !important
  }

  .mt-md-12,.my-md-12{
    margin-top:12px !important
  }

  .mr-md-12,.mx-md-12{
    margin-right:12px !important
  }

  .mb-md-12,.my-md-12{
    margin-bottom:12px !important
  }

  .ml-md-12,.mx-md-12{
    margin-left:12px !important
  }

  .m-md-15{
    margin:15px !important
  }

  .mt-md-15,.my-md-15{
    margin-top:15px !important
  }

  .mr-md-15,.mx-md-15{
    margin-right:15px !important
  }

  .mb-md-15,.my-md-15{
    margin-bottom:15px !important
  }

  .ml-md-15,.mx-md-15{
    margin-left:15px !important
  }

  .m-md-6{
    margin:64px !important
  }

  .mt-md-6,.my-md-6{
    margin-top:64px !important
  }

  .mr-md-6,.mx-md-6{
    margin-right:64px !important
  }

  .mb-md-6,.my-md-6{
    margin-bottom:64px !important
  }

  .ml-md-6,.mx-md-6{
    margin-left:64px !important
  }

  .m-md-7{
    margin:80px !important
  }

  .mt-md-7,.my-md-7{
    margin-top:80px !important
  }

  .mr-md-7,.mx-md-7{
    margin-right:80px !important
  }

  .mb-md-7,.my-md-7{
    margin-bottom:80px !important
  }

  .ml-md-7,.mx-md-7{
    margin-left:80px !important
  }

  .m-md-32{
    margin:32px !important
  }

  .mt-md-32,.my-md-32{
    margin-top:32px !important
  }

  .mr-md-32,.mx-md-32{
    margin-right:32px !important
  }

  .mb-md-32,.my-md-32{
    margin-bottom:32px !important
  }

  .ml-md-32,.mx-md-32{
    margin-left:32px !important
  }

  .p-md-0{
    padding:0 !important
  }

  .pt-md-0,.py-md-0{
    padding-top:0 !important
  }

  .pr-md-0,.px-md-0{
    padding-right:0 !important
  }

  .pb-md-0,.py-md-0{
    padding-bottom:0 !important
  }

  .pl-md-0,.px-md-0{
    padding-left:0 !important
  }

  .p-md-1{
    padding:4px !important
  }

  .pt-md-1,.py-md-1{
    padding-top:4px !important
  }

  .pr-md-1,.px-md-1{
    padding-right:4px !important
  }

  .pb-md-1,.py-md-1{
    padding-bottom:4px !important
  }

  .pl-md-1,.px-md-1{
    padding-left:4px !important
  }

  .p-md-2{
    padding:8px !important
  }

  .pt-md-2,.py-md-2{
    padding-top:8px !important
  }

  .pr-md-2,.px-md-2{
    padding-right:8px !important
  }

  .pb-md-2,.py-md-2{
    padding-bottom:8px !important
  }

  .pl-md-2,.px-md-2{
    padding-left:8px !important
  }

  .p-md-3{
    padding:16px !important
  }

  .pt-md-3,.py-md-3{
    padding-top:16px !important
  }

  .pr-md-3,.px-md-3{
    padding-right:16px !important
  }

  .pb-md-3,.py-md-3{
    padding-bottom:16px !important
  }

  .pl-md-3,.px-md-3{
    padding-left:16px !important
  }

  .p-md-4{
    padding:24px !important
  }

  .pt-md-4,.py-md-4{
    padding-top:24px !important
  }

  .pr-md-4,.px-md-4{
    padding-right:24px !important
  }

  .pb-md-4,.py-md-4{
    padding-bottom:24px !important
  }

  .pl-md-4,.px-md-4{
    padding-left:24px !important
  }

  .p-md-5{
    padding:48px !important
  }

  .pt-md-5,.py-md-5{
    padding-top:48px !important
  }

  .pr-md-5,.px-md-5{
    padding-right:48px !important
  }

  .pb-md-5,.py-md-5{
    padding-bottom:48px !important
  }

  .pl-md-5,.px-md-5{
    padding-left:48px !important
  }

  .p-md-12{
    padding:12px !important
  }

  .pt-md-12,.py-md-12{
    padding-top:12px !important
  }

  .pr-md-12,.px-md-12{
    padding-right:12px !important
  }

  .pb-md-12,.py-md-12{
    padding-bottom:12px !important
  }

  .pl-md-12,.px-md-12{
    padding-left:12px !important
  }

  .p-md-15{
    padding:15px !important
  }

  .pt-md-15,.py-md-15{
    padding-top:15px !important
  }

  .pr-md-15,.px-md-15{
    padding-right:15px !important
  }

  .pb-md-15,.py-md-15{
    padding-bottom:15px !important
  }

  .pl-md-15,.px-md-15{
    padding-left:15px !important
  }

  .p-md-6{
    padding:64px !important
  }

  .pt-md-6,.py-md-6{
    padding-top:64px !important
  }

  .pr-md-6,.px-md-6{
    padding-right:64px !important
  }

  .pb-md-6,.py-md-6{
    padding-bottom:64px !important
  }

  .pl-md-6,.px-md-6{
    padding-left:64px !important
  }

  .p-md-7{
    padding:80px !important
  }

  .pt-md-7,.py-md-7{
    padding-top:80px !important
  }

  .pr-md-7,.px-md-7{
    padding-right:80px !important
  }

  .pb-md-7,.py-md-7{
    padding-bottom:80px !important
  }

  .pl-md-7,.px-md-7{
    padding-left:80px !important
  }

  .p-md-32{
    padding:32px !important
  }

  .pt-md-32,.py-md-32{
    padding-top:32px !important
  }

  .pr-md-32,.px-md-32{
    padding-right:32px !important
  }

  .pb-md-32,.py-md-32{
    padding-bottom:32px !important
  }

  .pl-md-32,.px-md-32{
    padding-left:32px !important
  }

  .m-md-n1{
    margin:-4px !important
  }

  .mt-md-n1,.my-md-n1{
    margin-top:-4px !important
  }

  .mr-md-n1,.mx-md-n1{
    margin-right:-4px !important
  }

  .mb-md-n1,.my-md-n1{
    margin-bottom:-4px !important
  }

  .ml-md-n1,.mx-md-n1{
    margin-left:-4px !important
  }

  .m-md-n2{
    margin:-8px !important
  }

  .mt-md-n2,.my-md-n2{
    margin-top:-8px !important
  }

  .mr-md-n2,.mx-md-n2{
    margin-right:-8px !important
  }

  .mb-md-n2,.my-md-n2{
    margin-bottom:-8px !important
  }

  .ml-md-n2,.mx-md-n2{
    margin-left:-8px !important
  }

  .m-md-n3{
    margin:-16px !important
  }

  .mt-md-n3,.my-md-n3{
    margin-top:-16px !important
  }

  .mr-md-n3,.mx-md-n3{
    margin-right:-16px !important
  }

  .mb-md-n3,.my-md-n3{
    margin-bottom:-16px !important
  }

  .ml-md-n3,.mx-md-n3{
    margin-left:-16px !important
  }

  .m-md-n4{
    margin:-24px !important
  }

  .mt-md-n4,.my-md-n4{
    margin-top:-24px !important
  }

  .mr-md-n4,.mx-md-n4{
    margin-right:-24px !important
  }

  .mb-md-n4,.my-md-n4{
    margin-bottom:-24px !important
  }

  .ml-md-n4,.mx-md-n4{
    margin-left:-24px !important
  }

  .m-md-n5{
    margin:-48px !important
  }

  .mt-md-n5,.my-md-n5{
    margin-top:-48px !important
  }

  .mr-md-n5,.mx-md-n5{
    margin-right:-48px !important
  }

  .mb-md-n5,.my-md-n5{
    margin-bottom:-48px !important
  }

  .ml-md-n5,.mx-md-n5{
    margin-left:-48px !important
  }

  .m-md-n12{
    margin:-12px !important
  }

  .mt-md-n12,.my-md-n12{
    margin-top:-12px !important
  }

  .mr-md-n12,.mx-md-n12{
    margin-right:-12px !important
  }

  .mb-md-n12,.my-md-n12{
    margin-bottom:-12px !important
  }

  .ml-md-n12,.mx-md-n12{
    margin-left:-12px !important
  }

  .m-md-n15{
    margin:-15px !important
  }

  .mt-md-n15,.my-md-n15{
    margin-top:-15px !important
  }

  .mr-md-n15,.mx-md-n15{
    margin-right:-15px !important
  }

  .mb-md-n15,.my-md-n15{
    margin-bottom:-15px !important
  }

  .ml-md-n15,.mx-md-n15{
    margin-left:-15px !important
  }

  .m-md-n6{
    margin:-64px !important
  }

  .mt-md-n6,.my-md-n6{
    margin-top:-64px !important
  }

  .mr-md-n6,.mx-md-n6{
    margin-right:-64px !important
  }

  .mb-md-n6,.my-md-n6{
    margin-bottom:-64px !important
  }

  .ml-md-n6,.mx-md-n6{
    margin-left:-64px !important
  }

  .m-md-n7{
    margin:-80px !important
  }

  .mt-md-n7,.my-md-n7{
    margin-top:-80px !important
  }

  .mr-md-n7,.mx-md-n7{
    margin-right:-80px !important
  }

  .mb-md-n7,.my-md-n7{
    margin-bottom:-80px !important
  }

  .ml-md-n7,.mx-md-n7{
    margin-left:-80px !important
  }

  .m-md-n32{
    margin:-32px !important
  }

  .mt-md-n32,.my-md-n32{
    margin-top:-32px !important
  }

  .mr-md-n32,.mx-md-n32{
    margin-right:-32px !important
  }

  .mb-md-n32,.my-md-n32{
    margin-bottom:-32px !important
  }

  .ml-md-n32,.mx-md-n32{
    margin-left:-32px !important
  }

  .m-md-auto{
    margin:auto !important
  }

  .mt-md-auto,.my-md-auto{
    margin-top:auto !important
  }

  .mr-md-auto,.mx-md-auto{
    margin-right:auto !important
  }

  .mb-md-auto,.my-md-auto{
    margin-bottom:auto !important
  }

  .ml-md-auto,.mx-md-auto{
    margin-left:auto !important
  }
}
@media(min-width: 992px){
  .m-lg-0{
    margin:0 !important
  }

  .mt-lg-0,.my-lg-0{
    margin-top:0 !important
  }

  .mr-lg-0,.mx-lg-0{
    margin-right:0 !important
  }

  .mb-lg-0,.my-lg-0{
    margin-bottom:0 !important
  }

  .ml-lg-0,.mx-lg-0{
    margin-left:0 !important
  }

  .m-lg-1{
    margin:4px !important
  }

  .mt-lg-1,.my-lg-1{
    margin-top:4px !important
  }

  .mr-lg-1,.mx-lg-1{
    margin-right:4px !important
  }

  .mb-lg-1,.my-lg-1{
    margin-bottom:4px !important
  }

  .ml-lg-1,.mx-lg-1{
    margin-left:4px !important
  }

  .m-lg-2{
    margin:8px !important
  }

  .mt-lg-2,.my-lg-2{
    margin-top:8px !important
  }

  .mr-lg-2,.mx-lg-2{
    margin-right:8px !important
  }

  .mb-lg-2,.my-lg-2{
    margin-bottom:8px !important
  }

  .ml-lg-2,.mx-lg-2{
    margin-left:8px !important
  }

  .m-lg-3{
    margin:16px !important
  }

  .mt-lg-3,.my-lg-3{
    margin-top:16px !important
  }

  .mr-lg-3,.mx-lg-3{
    margin-right:16px !important
  }

  .mb-lg-3,.my-lg-3{
    margin-bottom:16px !important
  }

  .ml-lg-3,.mx-lg-3{
    margin-left:16px !important
  }

  .m-lg-4{
    margin:24px !important
  }

  .mt-lg-4,.my-lg-4{
    margin-top:24px !important
  }

  .mr-lg-4,.mx-lg-4{
    margin-right:24px !important
  }

  .mb-lg-4,.my-lg-4{
    margin-bottom:24px !important
  }

  .ml-lg-4,.mx-lg-4{
    margin-left:24px !important
  }

  .m-lg-5{
    margin:48px !important
  }

  .mt-lg-5,.my-lg-5{
    margin-top:48px !important
  }

  .mr-lg-5,.mx-lg-5{
    margin-right:48px !important
  }

  .mb-lg-5,.my-lg-5{
    margin-bottom:48px !important
  }

  .ml-lg-5,.mx-lg-5{
    margin-left:48px !important
  }

  .m-lg-12{
    margin:12px !important
  }

  .mt-lg-12,.my-lg-12{
    margin-top:12px !important
  }

  .mr-lg-12,.mx-lg-12{
    margin-right:12px !important
  }

  .mb-lg-12,.my-lg-12{
    margin-bottom:12px !important
  }

  .ml-lg-12,.mx-lg-12{
    margin-left:12px !important
  }

  .m-lg-15{
    margin:15px !important
  }

  .mt-lg-15,.my-lg-15{
    margin-top:15px !important
  }

  .mr-lg-15,.mx-lg-15{
    margin-right:15px !important
  }

  .mb-lg-15,.my-lg-15{
    margin-bottom:15px !important
  }

  .ml-lg-15,.mx-lg-15{
    margin-left:15px !important
  }

  .m-lg-6{
    margin:64px !important
  }

  .mt-lg-6,.my-lg-6{
    margin-top:64px !important
  }

  .mr-lg-6,.mx-lg-6{
    margin-right:64px !important
  }

  .mb-lg-6,.my-lg-6{
    margin-bottom:64px !important
  }

  .ml-lg-6,.mx-lg-6{
    margin-left:64px !important
  }

  .m-lg-7{
    margin:80px !important
  }

  .mt-lg-7,.my-lg-7{
    margin-top:80px !important
  }

  .mr-lg-7,.mx-lg-7{
    margin-right:80px !important
  }

  .mb-lg-7,.my-lg-7{
    margin-bottom:80px !important
  }

  .ml-lg-7,.mx-lg-7{
    margin-left:80px !important
  }

  .m-lg-32{
    margin:32px !important
  }

  .mt-lg-32,.my-lg-32{
    margin-top:32px !important
  }

  .mr-lg-32,.mx-lg-32{
    margin-right:32px !important
  }

  .mb-lg-32,.my-lg-32{
    margin-bottom:32px !important
  }

  .ml-lg-32,.mx-lg-32{
    margin-left:32px !important
  }

  .p-lg-0{
    padding:0 !important
  }

  .pt-lg-0,.py-lg-0{
    padding-top:0 !important
  }

  .pr-lg-0,.px-lg-0{
    padding-right:0 !important
  }

  .pb-lg-0,.py-lg-0{
    padding-bottom:0 !important
  }

  .pl-lg-0,.px-lg-0{
    padding-left:0 !important
  }

  .p-lg-1{
    padding:4px !important
  }

  .pt-lg-1,.py-lg-1{
    padding-top:4px !important
  }

  .pr-lg-1,.px-lg-1{
    padding-right:4px !important
  }

  .pb-lg-1,.py-lg-1{
    padding-bottom:4px !important
  }

  .pl-lg-1,.px-lg-1{
    padding-left:4px !important
  }

  .p-lg-2{
    padding:8px !important
  }

  .pt-lg-2,.py-lg-2{
    padding-top:8px !important
  }

  .pr-lg-2,.px-lg-2{
    padding-right:8px !important
  }

  .pb-lg-2,.py-lg-2{
    padding-bottom:8px !important
  }

  .pl-lg-2,.px-lg-2{
    padding-left:8px !important
  }

  .p-lg-3{
    padding:16px !important
  }

  .pt-lg-3,.py-lg-3{
    padding-top:16px !important
  }

  .pr-lg-3,.px-lg-3{
    padding-right:16px !important
  }

  .pb-lg-3,.py-lg-3{
    padding-bottom:16px !important
  }

  .pl-lg-3,.px-lg-3{
    padding-left:16px !important
  }

  .p-lg-4{
    padding:24px !important
  }

  .pt-lg-4,.py-lg-4{
    padding-top:24px !important
  }

  .pr-lg-4,.px-lg-4{
    padding-right:24px !important
  }

  .pb-lg-4,.py-lg-4{
    padding-bottom:24px !important
  }

  .pl-lg-4,.px-lg-4{
    padding-left:24px !important
  }

  .p-lg-5{
    padding:48px !important
  }

  .pt-lg-5,.py-lg-5{
    padding-top:48px !important
  }

  .pr-lg-5,.px-lg-5{
    padding-right:48px !important
  }

  .pb-lg-5,.py-lg-5{
    padding-bottom:48px !important
  }

  .pl-lg-5,.px-lg-5{
    padding-left:48px !important
  }

  .p-lg-12{
    padding:12px !important
  }

  .pt-lg-12,.py-lg-12{
    padding-top:12px !important
  }

  .pr-lg-12,.px-lg-12{
    padding-right:12px !important
  }

  .pb-lg-12,.py-lg-12{
    padding-bottom:12px !important
  }

  .pl-lg-12,.px-lg-12{
    padding-left:12px !important
  }

  .p-lg-15{
    padding:15px !important
  }

  .pt-lg-15,.py-lg-15{
    padding-top:15px !important
  }

  .pr-lg-15,.px-lg-15{
    padding-right:15px !important
  }

  .pb-lg-15,.py-lg-15{
    padding-bottom:15px !important
  }

  .pl-lg-15,.px-lg-15{
    padding-left:15px !important
  }

  .p-lg-6{
    padding:64px !important
  }

  .pt-lg-6,.py-lg-6{
    padding-top:64px !important
  }

  .pr-lg-6,.px-lg-6{
    padding-right:64px !important
  }

  .pb-lg-6,.py-lg-6{
    padding-bottom:64px !important
  }

  .pl-lg-6,.px-lg-6{
    padding-left:64px !important
  }

  .p-lg-7{
    padding:80px !important
  }

  .pt-lg-7,.py-lg-7{
    padding-top:80px !important
  }

  .pr-lg-7,.px-lg-7{
    padding-right:80px !important
  }

  .pb-lg-7,.py-lg-7{
    padding-bottom:80px !important
  }

  .pl-lg-7,.px-lg-7{
    padding-left:80px !important
  }

  .p-lg-32{
    padding:32px !important
  }

  .pt-lg-32,.py-lg-32{
    padding-top:32px !important
  }

  .pr-lg-32,.px-lg-32{
    padding-right:32px !important
  }

  .pb-lg-32,.py-lg-32{
    padding-bottom:32px !important
  }

  .pl-lg-32,.px-lg-32{
    padding-left:32px !important
  }

  .m-lg-n1{
    margin:-4px !important
  }

  .mt-lg-n1,.my-lg-n1{
    margin-top:-4px !important
  }

  .mr-lg-n1,.mx-lg-n1{
    margin-right:-4px !important
  }

  .mb-lg-n1,.my-lg-n1{
    margin-bottom:-4px !important
  }

  .ml-lg-n1,.mx-lg-n1{
    margin-left:-4px !important
  }

  .m-lg-n2{
    margin:-8px !important
  }

  .mt-lg-n2,.my-lg-n2{
    margin-top:-8px !important
  }

  .mr-lg-n2,.mx-lg-n2{
    margin-right:-8px !important
  }

  .mb-lg-n2,.my-lg-n2{
    margin-bottom:-8px !important
  }

  .ml-lg-n2,.mx-lg-n2{
    margin-left:-8px !important
  }

  .m-lg-n3{
    margin:-16px !important
  }

  .mt-lg-n3,.my-lg-n3{
    margin-top:-16px !important
  }

  .mr-lg-n3,.mx-lg-n3{
    margin-right:-16px !important
  }

  .mb-lg-n3,.my-lg-n3{
    margin-bottom:-16px !important
  }

  .ml-lg-n3,.mx-lg-n3{
    margin-left:-16px !important
  }

  .m-lg-n4{
    margin:-24px !important
  }

  .mt-lg-n4,.my-lg-n4{
    margin-top:-24px !important
  }

  .mr-lg-n4,.mx-lg-n4{
    margin-right:-24px !important
  }

  .mb-lg-n4,.my-lg-n4{
    margin-bottom:-24px !important
  }

  .ml-lg-n4,.mx-lg-n4{
    margin-left:-24px !important
  }

  .m-lg-n5{
    margin:-48px !important
  }

  .mt-lg-n5,.my-lg-n5{
    margin-top:-48px !important
  }

  .mr-lg-n5,.mx-lg-n5{
    margin-right:-48px !important
  }

  .mb-lg-n5,.my-lg-n5{
    margin-bottom:-48px !important
  }

  .ml-lg-n5,.mx-lg-n5{
    margin-left:-48px !important
  }

  .m-lg-n12{
    margin:-12px !important
  }

  .mt-lg-n12,.my-lg-n12{
    margin-top:-12px !important
  }

  .mr-lg-n12,.mx-lg-n12{
    margin-right:-12px !important
  }

  .mb-lg-n12,.my-lg-n12{
    margin-bottom:-12px !important
  }

  .ml-lg-n12,.mx-lg-n12{
    margin-left:-12px !important
  }

  .m-lg-n15{
    margin:-15px !important
  }

  .mt-lg-n15,.my-lg-n15{
    margin-top:-15px !important
  }

  .mr-lg-n15,.mx-lg-n15{
    margin-right:-15px !important
  }

  .mb-lg-n15,.my-lg-n15{
    margin-bottom:-15px !important
  }

  .ml-lg-n15,.mx-lg-n15{
    margin-left:-15px !important
  }

  .m-lg-n6{
    margin:-64px !important
  }

  .mt-lg-n6,.my-lg-n6{
    margin-top:-64px !important
  }

  .mr-lg-n6,.mx-lg-n6{
    margin-right:-64px !important
  }

  .mb-lg-n6,.my-lg-n6{
    margin-bottom:-64px !important
  }

  .ml-lg-n6,.mx-lg-n6{
    margin-left:-64px !important
  }

  .m-lg-n7{
    margin:-80px !important
  }

  .mt-lg-n7,.my-lg-n7{
    margin-top:-80px !important
  }

  .mr-lg-n7,.mx-lg-n7{
    margin-right:-80px !important
  }

  .mb-lg-n7,.my-lg-n7{
    margin-bottom:-80px !important
  }

  .ml-lg-n7,.mx-lg-n7{
    margin-left:-80px !important
  }

  .m-lg-n32{
    margin:-32px !important
  }

  .mt-lg-n32,.my-lg-n32{
    margin-top:-32px !important
  }

  .mr-lg-n32,.mx-lg-n32{
    margin-right:-32px !important
  }

  .mb-lg-n32,.my-lg-n32{
    margin-bottom:-32px !important
  }

  .ml-lg-n32,.mx-lg-n32{
    margin-left:-32px !important
  }

  .m-lg-auto{
    margin:auto !important
  }

  .mt-lg-auto,.my-lg-auto{
    margin-top:auto !important
  }

  .mr-lg-auto,.mx-lg-auto{
    margin-right:auto !important
  }

  .mb-lg-auto,.my-lg-auto{
    margin-bottom:auto !important
  }

  .ml-lg-auto,.mx-lg-auto{
    margin-left:auto !important
  }
}
@media(min-width: 1200px){
  .m-xl-0{
    margin:0 !important
  }

  .mt-xl-0,.my-xl-0{
    margin-top:0 !important
  }

  .mr-xl-0,.mx-xl-0{
    margin-right:0 !important
  }

  .mb-xl-0,.my-xl-0{
    margin-bottom:0 !important
  }

  .ml-xl-0,.mx-xl-0{
    margin-left:0 !important
  }

  .m-xl-1{
    margin:4px !important
  }

  .mt-xl-1,.my-xl-1{
    margin-top:4px !important
  }

  .mr-xl-1,.mx-xl-1{
    margin-right:4px !important
  }

  .mb-xl-1,.my-xl-1{
    margin-bottom:4px !important
  }

  .ml-xl-1,.mx-xl-1{
    margin-left:4px !important
  }

  .m-xl-2{
    margin:8px !important
  }

  .mt-xl-2,.my-xl-2{
    margin-top:8px !important
  }

  .mr-xl-2,.mx-xl-2{
    margin-right:8px !important
  }

  .mb-xl-2,.my-xl-2{
    margin-bottom:8px !important
  }

  .ml-xl-2,.mx-xl-2{
    margin-left:8px !important
  }

  .m-xl-3{
    margin:16px !important
  }

  .mt-xl-3,.my-xl-3{
    margin-top:16px !important
  }

  .mr-xl-3,.mx-xl-3{
    margin-right:16px !important
  }

  .mb-xl-3,.my-xl-3{
    margin-bottom:16px !important
  }

  .ml-xl-3,.mx-xl-3{
    margin-left:16px !important
  }

  .m-xl-4{
    margin:24px !important
  }

  .mt-xl-4,.my-xl-4{
    margin-top:24px !important
  }

  .mr-xl-4,.mx-xl-4{
    margin-right:24px !important
  }

  .mb-xl-4,.my-xl-4{
    margin-bottom:24px !important
  }

  .ml-xl-4,.mx-xl-4{
    margin-left:24px !important
  }

  .m-xl-5{
    margin:48px !important
  }

  .mt-xl-5,.my-xl-5{
    margin-top:48px !important
  }

  .mr-xl-5,.mx-xl-5{
    margin-right:48px !important
  }

  .mb-xl-5,.my-xl-5{
    margin-bottom:48px !important
  }

  .ml-xl-5,.mx-xl-5{
    margin-left:48px !important
  }

  .m-xl-12{
    margin:12px !important
  }

  .mt-xl-12,.my-xl-12{
    margin-top:12px !important
  }

  .mr-xl-12,.mx-xl-12{
    margin-right:12px !important
  }

  .mb-xl-12,.my-xl-12{
    margin-bottom:12px !important
  }

  .ml-xl-12,.mx-xl-12{
    margin-left:12px !important
  }

  .m-xl-15{
    margin:15px !important
  }

  .mt-xl-15,.my-xl-15{
    margin-top:15px !important
  }

  .mr-xl-15,.mx-xl-15{
    margin-right:15px !important
  }

  .mb-xl-15,.my-xl-15{
    margin-bottom:15px !important
  }

  .ml-xl-15,.mx-xl-15{
    margin-left:15px !important
  }

  .m-xl-6{
    margin:64px !important
  }

  .mt-xl-6,.my-xl-6{
    margin-top:64px !important
  }

  .mr-xl-6,.mx-xl-6{
    margin-right:64px !important
  }

  .mb-xl-6,.my-xl-6{
    margin-bottom:64px !important
  }

  .ml-xl-6,.mx-xl-6{
    margin-left:64px !important
  }

  .m-xl-7{
    margin:80px !important
  }

  .mt-xl-7,.my-xl-7{
    margin-top:80px !important
  }

  .mr-xl-7,.mx-xl-7{
    margin-right:80px !important
  }

  .mb-xl-7,.my-xl-7{
    margin-bottom:80px !important
  }

  .ml-xl-7,.mx-xl-7{
    margin-left:80px !important
  }

  .m-xl-32{
    margin:32px !important
  }

  .mt-xl-32,.my-xl-32{
    margin-top:32px !important
  }

  .mr-xl-32,.mx-xl-32{
    margin-right:32px !important
  }

  .mb-xl-32,.my-xl-32{
    margin-bottom:32px !important
  }

  .ml-xl-32,.mx-xl-32{
    margin-left:32px !important
  }

  .p-xl-0{
    padding:0 !important
  }

  .pt-xl-0,.py-xl-0{
    padding-top:0 !important
  }

  .pr-xl-0,.px-xl-0{
    padding-right:0 !important
  }

  .pb-xl-0,.py-xl-0{
    padding-bottom:0 !important
  }

  .pl-xl-0,.px-xl-0{
    padding-left:0 !important
  }

  .p-xl-1{
    padding:4px !important
  }

  .pt-xl-1,.py-xl-1{
    padding-top:4px !important
  }

  .pr-xl-1,.px-xl-1{
    padding-right:4px !important
  }

  .pb-xl-1,.py-xl-1{
    padding-bottom:4px !important
  }

  .pl-xl-1,.px-xl-1{
    padding-left:4px !important
  }

  .p-xl-2{
    padding:8px !important
  }

  .pt-xl-2,.py-xl-2{
    padding-top:8px !important
  }

  .pr-xl-2,.px-xl-2{
    padding-right:8px !important
  }

  .pb-xl-2,.py-xl-2{
    padding-bottom:8px !important
  }

  .pl-xl-2,.px-xl-2{
    padding-left:8px !important
  }

  .p-xl-3{
    padding:16px !important
  }

  .pt-xl-3,.py-xl-3{
    padding-top:16px !important
  }

  .pr-xl-3,.px-xl-3{
    padding-right:16px !important
  }

  .pb-xl-3,.py-xl-3{
    padding-bottom:16px !important
  }

  .pl-xl-3,.px-xl-3{
    padding-left:16px !important
  }

  .p-xl-4{
    padding:24px !important
  }

  .pt-xl-4,.py-xl-4{
    padding-top:24px !important
  }

  .pr-xl-4,.px-xl-4{
    padding-right:24px !important
  }

  .pb-xl-4,.py-xl-4{
    padding-bottom:24px !important
  }

  .pl-xl-4,.px-xl-4{
    padding-left:24px !important
  }

  .p-xl-5{
    padding:48px !important
  }

  .pt-xl-5,.py-xl-5{
    padding-top:48px !important
  }

  .pr-xl-5,.px-xl-5{
    padding-right:48px !important
  }

  .pb-xl-5,.py-xl-5{
    padding-bottom:48px !important
  }

  .pl-xl-5,.px-xl-5{
    padding-left:48px !important
  }

  .p-xl-12{
    padding:12px !important
  }

  .pt-xl-12,.py-xl-12{
    padding-top:12px !important
  }

  .pr-xl-12,.px-xl-12{
    padding-right:12px !important
  }

  .pb-xl-12,.py-xl-12{
    padding-bottom:12px !important
  }

  .pl-xl-12,.px-xl-12{
    padding-left:12px !important
  }

  .p-xl-15{
    padding:15px !important
  }

  .pt-xl-15,.py-xl-15{
    padding-top:15px !important
  }

  .pr-xl-15,.px-xl-15{
    padding-right:15px !important
  }

  .pb-xl-15,.py-xl-15{
    padding-bottom:15px !important
  }

  .pl-xl-15,.px-xl-15{
    padding-left:15px !important
  }

  .p-xl-6{
    padding:64px !important
  }

  .pt-xl-6,.py-xl-6{
    padding-top:64px !important
  }

  .pr-xl-6,.px-xl-6{
    padding-right:64px !important
  }

  .pb-xl-6,.py-xl-6{
    padding-bottom:64px !important
  }

  .pl-xl-6,.px-xl-6{
    padding-left:64px !important
  }

  .p-xl-7{
    padding:80px !important
  }

  .pt-xl-7,.py-xl-7{
    padding-top:80px !important
  }

  .pr-xl-7,.px-xl-7{
    padding-right:80px !important
  }

  .pb-xl-7,.py-xl-7{
    padding-bottom:80px !important
  }

  .pl-xl-7,.px-xl-7{
    padding-left:80px !important
  }

  .p-xl-32{
    padding:32px !important
  }

  .pt-xl-32,.py-xl-32{
    padding-top:32px !important
  }

  .pr-xl-32,.px-xl-32{
    padding-right:32px !important
  }

  .pb-xl-32,.py-xl-32{
    padding-bottom:32px !important
  }

  .pl-xl-32,.px-xl-32{
    padding-left:32px !important
  }

  .m-xl-n1{
    margin:-4px !important
  }

  .mt-xl-n1,.my-xl-n1{
    margin-top:-4px !important
  }

  .mr-xl-n1,.mx-xl-n1{
    margin-right:-4px !important
  }

  .mb-xl-n1,.my-xl-n1{
    margin-bottom:-4px !important
  }

  .ml-xl-n1,.mx-xl-n1{
    margin-left:-4px !important
  }

  .m-xl-n2{
    margin:-8px !important
  }

  .mt-xl-n2,.my-xl-n2{
    margin-top:-8px !important
  }

  .mr-xl-n2,.mx-xl-n2{
    margin-right:-8px !important
  }

  .mb-xl-n2,.my-xl-n2{
    margin-bottom:-8px !important
  }

  .ml-xl-n2,.mx-xl-n2{
    margin-left:-8px !important
  }

  .m-xl-n3{
    margin:-16px !important
  }

  .mt-xl-n3,.my-xl-n3{
    margin-top:-16px !important
  }

  .mr-xl-n3,.mx-xl-n3{
    margin-right:-16px !important
  }

  .mb-xl-n3,.my-xl-n3{
    margin-bottom:-16px !important
  }

  .ml-xl-n3,.mx-xl-n3{
    margin-left:-16px !important
  }

  .m-xl-n4{
    margin:-24px !important
  }

  .mt-xl-n4,.my-xl-n4{
    margin-top:-24px !important
  }

  .mr-xl-n4,.mx-xl-n4{
    margin-right:-24px !important
  }

  .mb-xl-n4,.my-xl-n4{
    margin-bottom:-24px !important
  }

  .ml-xl-n4,.mx-xl-n4{
    margin-left:-24px !important
  }

  .m-xl-n5{
    margin:-48px !important
  }

  .mt-xl-n5,.my-xl-n5{
    margin-top:-48px !important
  }

  .mr-xl-n5,.mx-xl-n5{
    margin-right:-48px !important
  }

  .mb-xl-n5,.my-xl-n5{
    margin-bottom:-48px !important
  }

  .ml-xl-n5,.mx-xl-n5{
    margin-left:-48px !important
  }

  .m-xl-n12{
    margin:-12px !important
  }

  .mt-xl-n12,.my-xl-n12{
    margin-top:-12px !important
  }

  .mr-xl-n12,.mx-xl-n12{
    margin-right:-12px !important
  }

  .mb-xl-n12,.my-xl-n12{
    margin-bottom:-12px !important
  }

  .ml-xl-n12,.mx-xl-n12{
    margin-left:-12px !important
  }

  .m-xl-n15{
    margin:-15px !important
  }

  .mt-xl-n15,.my-xl-n15{
    margin-top:-15px !important
  }

  .mr-xl-n15,.mx-xl-n15{
    margin-right:-15px !important
  }

  .mb-xl-n15,.my-xl-n15{
    margin-bottom:-15px !important
  }

  .ml-xl-n15,.mx-xl-n15{
    margin-left:-15px !important
  }

  .m-xl-n6{
    margin:-64px !important
  }

  .mt-xl-n6,.my-xl-n6{
    margin-top:-64px !important
  }

  .mr-xl-n6,.mx-xl-n6{
    margin-right:-64px !important
  }

  .mb-xl-n6,.my-xl-n6{
    margin-bottom:-64px !important
  }

  .ml-xl-n6,.mx-xl-n6{
    margin-left:-64px !important
  }

  .m-xl-n7{
    margin:-80px !important
  }

  .mt-xl-n7,.my-xl-n7{
    margin-top:-80px !important
  }

  .mr-xl-n7,.mx-xl-n7{
    margin-right:-80px !important
  }

  .mb-xl-n7,.my-xl-n7{
    margin-bottom:-80px !important
  }

  .ml-xl-n7,.mx-xl-n7{
    margin-left:-80px !important
  }

  .m-xl-n32{
    margin:-32px !important
  }

  .mt-xl-n32,.my-xl-n32{
    margin-top:-32px !important
  }

  .mr-xl-n32,.mx-xl-n32{
    margin-right:-32px !important
  }

  .mb-xl-n32,.my-xl-n32{
    margin-bottom:-32px !important
  }

  .ml-xl-n32,.mx-xl-n32{
    margin-left:-32px !important
  }

  .m-xl-auto{
    margin:auto !important
  }

  .mt-xl-auto,.my-xl-auto{
    margin-top:auto !important
  }

  .mr-xl-auto,.mx-xl-auto{
    margin-right:auto !important
  }

  .mb-xl-auto,.my-xl-auto{
    margin-bottom:auto !important
  }

  .ml-xl-auto,.mx-xl-auto{
    margin-left:auto !important
  }
}
.stretched-link::after,.section-blog__title-link::after{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:1;
  pointer-events:auto;
  content:"";
  background-color:rgba(0,0,0,0)
}
.text-monospace{
  font-family:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important
}
.text-justify{
  text-align:justify !important
}
.text-wrap{
  white-space:normal !important
}
.text-nowrap{
  white-space:nowrap !important
}
.text-truncate{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
.text-left{
  text-align:left !important
}
.text-right{
  text-align:right !important
}
.text-center{
  text-align:center !important
}
@media(min-width: 576px){
  .text-sm-left{
    text-align:left !important
  }

  .text-sm-right{
    text-align:right !important
  }

  .text-sm-center{
    text-align:center !important
  }
}
@media(min-width: 769px){
  .text-md-left{
    text-align:left !important
  }

  .text-md-right{
    text-align:right !important
  }

  .text-md-center{
    text-align:center !important
  }
}
@media(min-width: 992px){
  .text-lg-left{
    text-align:left !important
  }

  .text-lg-right{
    text-align:right !important
  }

  .text-lg-center{
    text-align:center !important
  }
}
@media(min-width: 1200px){
  .text-xl-left{
    text-align:left !important
  }

  .text-xl-right{
    text-align:right !important
  }

  .text-xl-center{
    text-align:center !important
  }
}
.text-lowercase{
  text-transform:lowercase !important
}
.text-uppercase{
  text-transform:uppercase !important
}
.text-capitalize{
  text-transform:capitalize !important
}
.font-weight-light{
  font-weight:300 !important
}
.font-weight-lighter{
  font-weight:lighter !important
}
.font-weight-normal{
  font-weight:400 !important
}
.font-weight-bold{
  font-weight:600 !important
}
.font-weight-bolder{
  font-weight:bolder !important
}
.font-italic{
  font-style:italic !important
}
.text-white{
  color:#fff !important
}
.text-primary{
  color:#2ec4dd !important
}
a.text-primary:hover,a.text-primary:focus{
  color:#1b90a4 !important
}
.text-secondary{
  color:#6c757d !important
}
a.text-secondary:hover,a.text-secondary:focus{
  color:#494f54 !important
}
.text-success{
  color:#28a745 !important
}
a.text-success:hover,a.text-success:focus{
  color:#19692c !important
}
.text-info{
  color:#17a2b8 !important
}
a.text-info:hover,a.text-info:focus{
  color:#0f6674 !important
}
.text-warning{
  color:#ffc107 !important
}
a.text-warning:hover,a.text-warning:focus{
  color:#ba8b00 !important
}
.text-danger{
  color:#dc3545 !important
}
a.text-danger:hover,a.text-danger:focus{
  color:#a71d2a !important
}
.text-light{
  color:#dfdfdf !important
}
a.text-light:hover,a.text-light:focus{
  color:#b9b9b9 !important
}
.text-dark{
  color:#343a40 !important
}
a.text-dark:hover,a.text-dark:focus{
  color:#121416 !important
}
.text-body{
  color:#414141 !important
}
.text-muted{
  color:#6c757d !important
}
.text-black-50{
  color:rgba(0,0,0,.5) !important
}
.text-white-50{
  color:rgba(255,255,255,.5) !important
}
.text-hide{
  font:0/0 a;
  color:rgba(0,0,0,0);
  text-shadow:none;
  background-color:rgba(0,0,0,0);
  border:0
}
.text-decoration-none{
  text-decoration:none !important
}
.text-break{
  word-break:break-word !important;
  word-wrap:break-word !important
}
.text-reset{
  color:inherit !important
}
.visible{
  visibility:visible !important
}
.invisible{
  visibility:hidden !important
}
*,*:after,*:before{
  border:0 solid
}
@media print{
  button{
    display:none;
    text-indent:-9999px
  }
}
body{
  font-family:"Outfit", sans-serif;
  font-weight:400;
  color:#2D4C5D;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-rendering:optimizelegibility;
  line-height:1.4;
  letter-spacing:.03em
}
body.filter-popup-open{
  overflow:hidden
}
b,strong{
  font-weight:600
}
.row{
  margin-left:-8px;
  margin-right:-8px
}
@media(min-width: 769px){
  .row{
    margin-left:-15px;
    margin-right:-15px
  }
}
.row.row-narrow{
  margin-left:-8px;
  margin-right:-8px
}
.row.row-center{
  justify-content:center
}
.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12,.col,.col-auto,.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm,.col-sm-auto,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12,.col-md,.col-md-auto,.col-lg-1,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg,.col-lg-auto,.col-xl-1,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl,.col-xl-auto{
  padding-left:8px;
  padding-right:8px
}
@media(min-width: 769px){
  .col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12,.col,.col-auto,.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm,.col-sm-auto,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12,.col-md,.col-md-auto,.col-lg-1,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg,.col-lg-auto,.col-xl-1,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl,.col-xl-auto{
    padding-left:15px;
    padding-right:15px
  }
}
@media(min-width: 576px){
  .container{
    max-width:100%
  }
}
@media(min-width: 769px){
  .container{
    max-width:100%
  }
}
@media(min-width: 992px){
  .container{
    max-width:960px
  }
}
@media(min-width: 1200px){
  .container{
    max-width:1140px
  }
}
a{
  color:#2D4C5D
}
a:focus,a:hover{
  text-decoration:none
}
select,input{
  letter-spacing:.03em
}
select::-ms-expand{
  display:block
}
strong,b{
  font-weight:600
}
mark{
  padding:0;
  background:inherit;
  font-weight:600;
  color:#FF6B07
}
.map-responsive{
  width:100%;
  min-height:100%;
  position:relative
}
@media(max-width: 999px){
  .map-responsive{
    height:300px
  }
}
.map-responsive img{
  background-color:rgba(0,0,0,0)
}
.map-marker{
  position:absolute
}
.map-marker .map-marker-icon-day{
  position:relative;
  border-radius:60px;
  min-width:50px;
  width:auto;
  height:30px;
  padding:4px;
  cursor:pointer;
  color:#fff;
  background-color:#2D4C5D;
  font-family:"Kobe";
  text-align:center;
  font-size:0.875rem;
  font-weight:600;
  font-size:0.875rem;
  line-height:24px
}
@media(min-width: 769px){
  .map-marker .map-marker-icon-day{
    min-width:70px;
    width:auto;
    height:30px;
    padding:4px 8px 4px 8px
  }
}
.map-marker .map-marker-icon-day:after{
  position:absolute;
  content:"";
  width:0px;
  height:0px;
  bottom:-22px;
  border:10px solid rgba(0,0,0,0);
  border-top:17px solid #2D4C5D;
  left:50%;
  transform:translateX(-50%)
}
.map-marker .map-marker-icon{
  position:relative;
  border-radius:50%;
  width:24px;
  height:24px;
  cursor:pointer;
  color:#fff;
  font-family:"Kobe";
  background-color:#2D4C5D;
  text-align:center;
  font-weight:600;
  font-size:0.875rem;
  line-height:24px
}
.map-marker .map-marker-icon:after{
  position:absolute;
  content:"";
  width:0px;
  height:0px;
  bottom:-21px;
  left:2px;
  border:10px solid rgba(0,0,0,0);
  border-top:17px solid #2D4C5D
}
.map-marker.active .map-marker-icon{
  background-color:#25a9bf
}
.map-marker.active .map-marker-icon:after{
  border-top:17px solid #25a9bf
}
.custom-scrollbar{
  scrollbar-color:rgba(0,0,0,0) rgba(0,0,0,0);
  scrollbar-width:thin !important
}
.custom-scrollbar::-webkit-scrollbar{
  display:initial;
  width:6px;
  height:6px
}
.custom-scrollbar::-webkit-scrollbar-track{
  background-color:rgba(0,0,0,0)
}
.custom-scrollbar::-webkit-scrollbar-thumb{
  background-color:rgba(0,0,0,0)
}
.custom-scrollbar:hover{
  scrollbar-color:#D9D9D9 #fdfcfc
}
.custom-scrollbar:hover::-webkit-scrollbar-track{
  background-color:#f8f7f4;
  border-radius:8px
}
.custom-scrollbar:hover::-webkit-scrollbar-thumb{
  background-color:#D9D9D9;
  border-radius:8px
}
.custom-scrollbar-no-hover{
  scrollbar-color:#D9D9D9 #fdfcfc;
  scrollbar-width:thin !important
}
.custom-scrollbar-no-hover::-webkit-scrollbar{
  display:initial;
  width:6px;
  height:6px
}
.custom-scrollbar-no-hover::-webkit-scrollbar-track{
  background-color:#f8f7f4;
  border-radius:8px
}
.custom-scrollbar-no-hover::-webkit-scrollbar-thumb{
  background-color:#D9D9D9;
  border-radius:8px
}
.no-scrollbar{
  -ms-overflow-style:none;
  scrollbar-width:none
}
.no-scrollbar::-webkit-scrollbar{
  display:none
}
.alert{
  border:0;
  border-radius:0;
  font-weight:600;
  text-align:center;
  z-index:9999;
  position:fixed;
  top:72px;
  width:100%
}
.alert svg{
  stroke-width:2px
}
.alert .close{
  opacity:1
}
.alert-success{
  background-color:#F8F3E3;
  color:#2D4C5D
}
.alert-danger{
  background-color:#F8F3E3;
  color:#ff5055
}
.badge{
  font-size:12px;
  padding:8px;
  border-radius:4px
}
.badge-primary{
  color:#fff
}
.badge-blog{
  background-color:#f3f3f3;
  padding:8px 16px;
  font-size:14px;
  font-weight:400;
  border-radius:4px;
  margin-right:16px;
  margin-bottom:8px;
  cursor:pointer
}
.badge-blog a{
  color:#6a6a6a
}
.badge-blog a:hover{
  color:#6a6a6a
}
.badge-blog--arrow{
  cursor:initial
}
.badge-discount{
  display:inline-block;
  border-radius:4px;
  border:1px solid #dfdfdf;
  padding:4px;
  color:#6a6a6a;
  font-size:12px
}
.badge-discount svg{
  color:#6a6a6a
}
.badge-red{
  color:#fff;
  background-color:#ff5055
}
.badge-green-dark{
  color:#fff;
  background-color:#006400
}
.badge-teal{
  color:#fff;
  background-color:#5f9ea0
}
.tag-box{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  border:1px solid #dfdfdf;
  border-radius:4px;
  width:calc(33.3333333333% - 16px);
  height:68px;
  padding:8px;
  font-size:10px;
  margin-right:16px;
  margin-bottom:16px;
  text-align:center
}
@media(min-width: 769px){
  .tag-box{
    width:calc(25% - 16px);
    height:76px
  }
}
.tag-box img{
  margin-bottom:4px;
  width:20px;
  height:20px;
  -o-object-fit:cover;
     object-fit:cover
}
.booking-form__title{
  font-size:20px;
  font-weight:600;
  line-height:1;
  padding-bottom:10px;
  margin-bottom:16px;
  border-bottom:1px solid #dfdfdf
}
.booking-form__item{
  display:flex;
  justify-content:space-between;
  font-size:14px
}
.booking-form__item-price{
  flex-shrink:0;
  width:162px;
  text-align:right
}
.booking-form__item-price--auto{
  width:auto
}
.booking-form .form-control{
  font-size:14px
}
.booking-form .form-control:disabled,.booking-form .form-control[readonly]{
  background-color:#fff
}
.booking-form .form-group{
  margin-bottom:10px
}
.booking-form hr{
  margin-top:10px;
  margin-bottom:10px
}
.booking-form .input-group-text{
  border:1px solid #ABABAB;
  background-color:#fff;
  border-radius:10px;
  width:44px
}
.booking-form .input-group-text--middle{
  width:36px;
  padding-left:0;
  padding-right:12px
}
.booking-form .form-control{
  color:#ABABAB;
  border:1px solid #ABABAB;
  border-radius:10px;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  font-size:14px;
  background-color:#fff;
  transition:background-color .2s ease-in-out;
  cursor:pointer;
  height:40px;
  line-height:18px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
.booking-form .form-control::-moz-placeholder{
  color:#ABABAB;
  opacity:1
}
.booking-form .form-control::placeholder{
  color:#ABABAB;
  opacity:1
}
.booking-form .form-control::-ms-input-placeholder{
  color:#ABABAB;
  opacity:1
}
.booking-form .form-control.cyan{
  color:#2ec4dd !important
}
.booking-form .input-group{
  cursor:pointer
}
.booking-form .input-group>.input-group-prepend:not(:first-child)>.input-group-text{
  border-top-left-radius:1px;
  border-bottom-left-radius:1px
}
.booking-form .input-group svg{
  color:#6a6a6a
}
.booking-form .input-group select::-ms-expand{
  display:none
}
.booking-form .input-group:hover .input-group-text,.booking-form .input-group:hover .custom-select,.booking-form .input-group:hover .form-control{
  border-color:#FF6B07
}
.booking-form .input-group.error .input-group-text,.booking-form .input-group.error .custom-select,.booking-form .input-group.error .form-control{
  border-color:#ff5055
}
.booking-form .input-group.selected .input-group-text,.booking-form .input-group.selected .custom-select,.booking-form .input-group.selected .form-control{
  border-color:#2D4C5D;
  color:#2D4C5D
}
.booking-form .input-group.selected svg{
  color:#2D4C5D
}
.booking-form .input-group.selected-secondary .input-group-text,.booking-form .input-group.selected-secondary .custom-select,.booking-form .input-group.selected-secondary .form-control{
  border-color:#2D4C5D;
  color:#2D4C5D
}
.booking-form .input-group.selected-secondary .input-group-prepend svg{
  color:#2D4C5D
}
.booking-form .input-group.input-group-rtb:hover .input-group-text,.booking-form .input-group.input-group-rtb:hover .custom-select,.booking-form .input-group.input-group-rtb:hover .form-control{
  border-color:#2D4C5D
}
.booking-form .input-group.input-group-disabled .input-group-text,.booking-form .input-group.input-group-disabled .custom-select,.booking-form .input-group.input-group-disabled .form-control{
  color:#414141;
  background:#f3f3f3;
  border-color:#dfdfdf
}
.booking-form .input-group.input-group-disabled svg{
  color:#414141
}
.booking-form__datepicker-note{
  width:316px;
  margin:16px auto 8px;
  padding-left:25px;
  text-align:left
}
.booking-form__popup{
  cursor:default;
  position:absolute;
  z-index:1200;
  width:100%;
  background-color:#fff;
  top:calc(100% + 4px);
  padding:8px 0;
  border:1px solid #dfdfdf
}
.booking-form__popup-item{
  padding:8px 16px;
  cursor:pointer
}
.booking-form__popup-item.active,.booking-form__popup-item:hover{
  background-color:#effbfd
}
.booking-bottom-bar{
  display:none;
  padding:12px 24px;
  position:fixed;
  bottom:0;
  left:0;
  background-color:#F8F3E3;
  width:100%;
  font-size:16px;
  z-index:90
}
.booking-bottom-bar.active{
  display:flex
}
.booking-bottom-bar .btn,.booking-bottom-bar .uppy-FileInput-btn{
  padding-left:30px;
  padding-right:30px
}
.booking-timeslot{
  display:none;
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  z-index:2000
}
.booking-timeslot__mask{
  background:rgba(65,65,65,.6);
  width:100%;
  height:100%
}
.booking-timeslot__selection{
  position:fixed;
  width:176px;
  background:#fff;
  border-radius:4px;
  top:0;
  left:0
}
.booking-timeslot__inner{
  position:relative
}
.booking-timeslot__list{
  padding:8px 0;
  max-height:162px;
  overflow-y:auto
}
.booking-timeslot__triangle{
  top:-12px;
  left:80px;
  position:absolute;
  width:0;
  height:0;
  content:"";
  z-index:2;
  border-bottom:12px solid #fff;
  border-left:8px solid rgba(0,0,0,0);
  border-right:8px solid rgba(0,0,0,0)
}
.booking-timeslot__item{
  text-align:center;
  font-weight:600;
  cursor:pointer;
  padding:6px 0
}
.booking-timeslot__item:hover{
  background:#d5f3f8
}
.booking-form__modal{
  z-index:1200
}
.breadcrumb{
  padding-left:0;
  background-color:rgba(0,0,0,0);
  flex-wrap:nowrap;
  justify-items:center
}
@media(min-width: 769px){
  .breadcrumb{
    justify-items:flex-start
  }
}
.breadcrumb-item{
  text-transform:uppercase;
  font-weight:600;
  font-size:0.75rem;
  white-space:nowrap;
  margin-right:4px;
  display:flex;
  align-items:center;
  padding-left:10px
}
@media(min-width: 769px){
  .breadcrumb-item{
    font-size:0.875rem
  }
}
.breadcrumb-item:first-child{
  padding-left:0
}
.breadcrumb-item:last-child{
  margin-right:0
}
.breadcrumb-item svg{
  margin-left:4px;
  width:16px;
  height:16px
}
.breadcrumb-item.active{
  color:#2D4C5D;
  overflow:hidden;
  text-overflow:ellipsis
}
.breadcrumb-item a{
  color:#2D4C5D;
  line-height:1
}
.breadcrumb-item a:hover{
  color:#FF6B07;
  text-decoration:underline
}
.breadcrumb-item+.breadcrumb-item{
  padding-left:0
}
.breadcrumb-item+.breadcrumb-item::before{
  display:none
}
.btn-outline-primary:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active,.show>.btn-outline-primary.dropdown-toggle{
  color:#fff
}
.btn,.uppy-FileInput-btn{
  font-family:"Kobe", sans-serif;
  font-weight:600;
  border-radius:30px;
  border:0;
  padding:8px 24px;
  line-height:1;
  text-transform:lowercase;
  outline:none
}
.btn:not(:disabled):not(.disabled),.uppy-FileInput-btn:not(:disabled):not(.disabled){
  box-shadow:none
}
.btn:not(:disabled):not(.disabled):active:focus,.uppy-FileInput-btn:not(:disabled):not(.disabled):active:focus{
  box-shadow:none
}
@media(min-width: 769px){
  .btn,.uppy-FileInput-btn{
    padding:12px 32px
  }
}
.btn.btn-outline,.btn-outline.uppy-FileInput-btn{
  border:1px solid #2D4C5D;
  color:#2D4C5D;
  background-color:rgba(0,0,0,0)
}
.btn.btn-dots,.btn-dots.uppy-FileInput-btn{
  border-radius:50% !important;
  padding:8px;
  line-height:12px
}
.btn.btn-dots--cyan,.btn-dots--cyan.uppy-FileInput-btn{
  color:#2ec4dd;
  border-color:#2ec4dd
}
.btn--white{
  border-color:#2D4C5D;
  color:#2D4C5D;
  background-color:#fff
}
@media(hover: hover){
  .btn--white:hover{
    background-color:#2D4C5D;
    color:#E4E480
  }
}
.btn--white-corp{
  border-radius:8px;
  border-width:1px;
  font-weight:600
}
@media(min-width: 769px){
  .btn--white-corp{
    width:150px
  }
}
.btn--white-corp:hover{
  background-color:#414141;
  color:#fff
}
.btn--outline-white{
  border-color:#fff;
  color:#fff;
  background-color:rgba(0,0,0,0)
}
.btn--outline-white:hover{
  color:#fff
}
.btn.btn-black,.btn-black.uppy-FileInput-btn{
  border-color:#414141;
  color:#fff;
  background-color:#414141
}
@media(hover: hover){
  .btn.btn-black:hover,.btn-black.uppy-FileInput-btn:hover{
    opacity:.7
  }
}
.btn.btn-primary,.uppy-FileInput-btn{
  background-color:#FF6B07;
  color:#fff
}
.btn.btn-primary:not(:disabled):not(.disabled):active,.btn.btn-primary:focus,.uppy-FileInput-btn:focus,.btn.btn-primary:active,.uppy-FileInput-btn:active{
  color:#fff;
  background-color:#FF6B07
}
.btn.btn-secondary,.btn-secondary.uppy-FileInput-btn{
  background-color:#E4E480;
  color:#2D4C5D
}
.btn.btn-secondary:active,.btn-secondary.uppy-FileInput-btn:active{
  color:#2D4C5D
}
.btn.btn-warning,.btn-warning.uppy-FileInput-btn{
  background-color:#fdaa00;
  color:#fff
}
.btn.btn-warning:not(:disabled):not(.disabled):active,.btn.btn-warning:active,.btn-warning.uppy-FileInput-btn:active,.btn.btn-warning:focus,.btn-warning.uppy-FileInput-btn:focus{
  background-color:#fdaa00;
  color:#fff
}
.btn.btn-outline-primary,.btn-outline-primary.uppy-FileInput-btn{
  color:#FF6B07;
  border:1px solid #FF6B07
}
.btn.btn-outline-primary:active,.btn-outline-primary.uppy-FileInput-btn:active,.btn.btn-outline-primary:hover,.btn-outline-primary.uppy-FileInput-btn:hover{
  color:#FF6B07;
  border-color:#FF6B07;
  background-color:#fff
}
.btn.btn-outline-primary.disabled,.btn-outline-primary.disabled.uppy-FileInput-btn,.btn.btn-outline-primary:disabled,.btn-outline-primary.uppy-FileInput-btn:disabled{
  color:#dfdfdf;
  border-color:#dfdfdf
}
.btn.btn-outline-primary.disabled:hover,.btn-outline-primary.disabled.uppy-FileInput-btn:hover,.btn.btn-outline-primary:disabled:hover,.btn-outline-primary.uppy-FileInput-btn:disabled:hover{
  color:#dfdfdf
}
.btn.btn-outline-secondary,.btn-outline-secondary.uppy-FileInput-btn{
  border-color:#9f9f9f;
  color:#9f9f9f
}
.btn.btn-outline-secondary:active,.btn-outline-secondary.uppy-FileInput-btn:active,.btn.btn-outline-secondary:hover,.btn-outline-secondary.uppy-FileInput-btn:hover{
  background-color:#9f9f9f;
  color:#fff
}
.btn.btn-purple,.btn-purple.uppy-FileInput-btn{
  background-color:#4a5ced;
  color:#fff
}
.btn.btn-purple:hover,.btn-purple.uppy-FileInput-btn:hover{
  color:#fff
}
.btn.btn-outline-purple,.btn-outline-purple.uppy-FileInput-btn{
  border-color:#4a5ced;
  color:#4a5ced
}
.btn.btn-outline-purple:active,.btn-outline-purple.uppy-FileInput-btn:active,.btn.btn-outline-purple:hover,.btn-outline-purple.uppy-FileInput-btn:hover{
  background-color:#4a5ced;
  color:#fff
}
.btn.btn-operator,.btn-operator.uppy-FileInput-btn{
  padding:0;
  width:24px;
  height:24px;
  text-align:center;
  border-radius:50%;
  border:1px solid #2ec4dd;
  color:#2ec4dd;
  line-height:19px;
  outline:none;
  box-shadow:none
}
.btn.btn-operator svg,.btn-operator.uppy-FileInput-btn svg{
  color:#2ec4dd
}
.btn.btn-operator:disabled,.btn-operator.uppy-FileInput-btn:disabled{
  border-color:#9f9f9f
}
.btn.btn-operator:disabled svg,.btn-operator.uppy-FileInput-btn:disabled svg{
  color:#9f9f9f
}
.btn-chat{
  position:fixed;
  z-index:9999;
  right:8px;
  bottom:86px;
  background:#fff;
  width:60px;
  height:60px;
  line-height:60px;
  border-radius:50%;
  text-align:center;
  cursor:pointer;
  box-shadow:rgba(0,0,0,.3) 0px 4px 8px;
  -webkit-box-pack:center;
  color:#414141
}
@media(min-width: 769px){
  .btn-chat{
    right:8px;
    bottom:16px
  }
}
.btn-chat .spinner-border{
  display:none
}
.btn-chat.loading svg{
  display:none
}
.btn-chat.loading .spinner-border{
  display:inline-block
}
.btn-whatsapp{
  position:fixed;
  z-index:9999;
  background-color:#25d366;
  border-radius:9999px;
  width:64px;
  height:64px;
  bottom:16px;
  right:16px;
  display:block;
  text-align:center;
  padding:16px 0;
  cursor:pointer
}
.btn-whatsapp svg{
  width:32px;
  height:32px;
  color:#fff
}
.card{
  border:0
}
.card.card-boat{
  flex:none;
  width:300px;
  position:relative;
  cursor:pointer
}
.card.card-boat .card-img-top{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3;
  width:100%;
  height:200px;
  background-color:#9f9f9f;
  text-align:center
}
.card.card-boat .card-img-top svg{
  margin-top:68px
}
.card.card-boat .card-title{
  font-weight:600;
  margin-bottom:5px
}
.card.card-boat .card-body{
  border:1px solid #dfdfdf;
  padding-top:10px;
  padding-bottom:10px
}
.card.card-boat .card-text{
  font-size:14px
}
.card.card-boat .card-boat__icon{
  cursor:pointer;
  position:absolute;
  top:12px;
  right:12px
}
.card.card-highlight .card-img-top{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3;
  width:100%;
  height:150px;
  background-color:#9f9f9f;
  text-align:center;
  border-radius:0
}
.card.card-highlight .card-title{
  font-weight:600;
  margin-bottom:5px;
  text-align:center
}
.card.card-highlight .card-body{
  padding:5px 5px 10px 5px;
  text-align:center;
  border-width:0 1px 1px 1px;
  border-style:solid;
  border-color:#dfdfdf
}
.card.card-highlight .card-footer{
  background-color:#fff;
  padding:5px;
  border-width:0 1px 1px 1px;
  border-style:solid;
  border-color:#dfdfdf;
  display:flex;
  justify-content:space-between
}
.card.card-activity{
  box-shadow:none;
  margin-bottom:16px;
  border-radius:0;
  position:relative
}
@media(min-width: 769px){
  .card.card-activity:after{
    content:" ";
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:none;
    background-color:rgba(255,255,255,.3)
  }
}
.card.card-activity .card-activity__option-counter{
  background:#fff;
  font-size:14px;
  border:1px solid #414141;
  white-space:nowrap;
  border-radius:4px;
  padding:6px 12px 4px;
  line-height:1;
  align-self:start;
  display:inline-block
}
@media(min-width: 769px){
  .card.card-activity:hover:after{
    display:block
  }
}
.card.card-activity:hover a{
  text-decoration:underline
}
.card.card-activity .card-title{
  font-size:16px;
  margin-bottom:8px;
  font-weight:600
}
@media(min-width: 769px){
  .card.card-activity .card-title{
    font-size:20px;
    margin-bottom:0
  }
}
.card.card-activity .card-title a{
  color:#414141
}
.card.card-activity .card-title a:hover{
  text-decoration:none
}
.card.card-activity .card-img-top{
  height:210px;
  border-radius:4px
}
.card.card-activity .card-body{
  padding:10px 0
}
@media(min-width: 769px){
  .card.card-activity .card-body{
    padding-top:16px;
    padding-bottom:16px
  }
}
.card.card-activity .card-image__main{
  margin-bottom:1px
}
.card.card-activity .card-image__main img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:120px;
  border-top-left-radius:4px;
  border-top-right-radius:4px;
  background-color:#f3f3f3
}
@media(min-width: 769px){
  .card.card-activity .card-image__main img{
    height:185px
  }
}
.card.card-activity .card-image__sub{
  display:flex;
  align-items:center;
  justify-content:space-between
}
.card.card-activity .card-image__sub-img{
  width:33.3333333333%
}
.card.card-activity .card-image__sub-img img{
  background-color:#f3f3f3;
  flex-grow:1;
  width:100%;
  height:52px;
  -o-object-fit:cover;
     object-fit:cover
}
@media(min-width: 769px){
  .card.card-activity .card-image__sub-img img{
    height:80px
  }
}
.card.card-activity .card-image__sub-img:first-child img{
  border-bottom-left-radius:4px
}
.card.card-activity .card-image__sub-img:last-child img{
  border-bottom-right-radius:4px
}
.card.card-activity .card-image__sub-img+.card-image__sub-img{
  margin-left:1px
}
@media(min-width: 769px){
  .card.card-activity{
    margin-bottom:32px
  }
}
.card.card-activity .card-title{
  margin-bottom:4px
}
@media(min-width: 769px){
  .card.card-activity .card-title{
    margin-bottom:8px
  }
}
.card.card-activity .card-meta{
  margin-bottom:4px
}
@media(min-width: 769px){
  .card.card-activity .card-meta{
    margin-bottom:8px
  }
}
.card.card--border{
  border:1px solid #dfdfdf
}
.card.card-blog{
  box-shadow:none
}
.card.card-blog .card-img-top{
  margin-bottom:16px;
  opacity:1;
  transition:opacity .2s ease-in-out
}
.card.card-blog:hover .card-img-top{
  opacity:.7
}
.card.card-blog .card-title{
  margin-top:12px;
  margin-bottom:8px;
  font-size:18px;
  font-weight:600;
  line-height:1.2
}
.card.card-blog .card-title a{
  color:#414141
}
.card.card-blog .card-body{
  padding:0
}
.card.card-blog .card-blog__badge{
  padding-left:16px;
  line-height:1;
  position:relative;
  font-size:14px
}
.card.card-blog .card-blog__badge:before{
  content:" ";
  position:absolute;
  left:0;
  top:-2px;
  width:4px;
  height:15px;
  background-color:rgba(0,0,0,0)
}
.card.card-blog .card-blog__badge--cyan{
  color:#2ec4dd
}
.card.card-blog .card-blog__badge--cyan:before{
  background-color:#2ec4dd
}
.card.card-blog .card-blog__badge--purple{
  color:#4a5ced
}
.card.card-blog .card-blog__badge--purple:before{
  background-color:#4a5ced
}
.card.card-blog .card-blog__badge--yellow{
  color:#fdaa00
}
.card.card-blog .card-blog__badge--yellow:before{
  background-color:#fdaa00
}
.card.card-blog .card-blog__badge--green{
  color:#219653
}
.card.card-blog .card-blog__badge--green:before{
  background-color:#219653
}
.card.card-blog .card-blog__badge--blue{
  color:#007bfb
}
.card.card-blog .card-blog__badge--blue:before{
  background-color:#007bfb
}
.card.card-blog .card-img-top{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:240px
}
@media(min-width: 769px){
  .card.card-blog .card-img-top--big{
    height:320px
  }
}
.card.card-blog .card-desc{
  color:#6a6a6a;
  margin-bottom:8px
}
.card-accommodation{
  max-width:450px;
  width:100%;
  margin:0 auto
}
.card-accommodation__body{
  padding-bottom:15px
}
.card-accommodation__info{
  padding:0 8px
}
.card-accommodation .btn:focus,.card-accommodation .uppy-FileInput-btn:focus{
  box-shadow:none;
  outline:none
}
.card-accommodation .carousel-activity-sao-image .carousel-item img{
  height:240px
}
@media(min-width: 769px){
  .card-accommodation .carousel-activity-sao-image .carousel-item img{
    height:300px
  }
}
.card-accommodation--section{
  max-width:100%
}
.card-accommodation--section .carousel-activity-sao-image .carousel-item img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:200px
}
@media(min-width: 769px){
  .card-accommodation--section .carousel-activity-sao-image .carousel-item img{
    height:360px
  }
}
.card.card-overlay:hover{
  opacity:.7
}
.card.card-overlay img{
  height:301px
}
.card.card-overlay .card-img-overlay{
  color:#fff;
  font-size:24px;
  font-weight:600;
  text-align:center;
  display:flex;
  flex-direction:column;
  justify-content:center
}
.card-blank{
  height:260px;
  border-radius:8px;
  border:1px solid #dfdfdf;
  display:block;
  position:relative
}
@media(min-width: 769px){
  .card-blank{
    height:260px
  }
}
.card-blank--activity{
  height:173px
}
@media(min-width: 769px){
  .card-blank--activity{
    height:266px
  }
}
.card-blank--corp{
  height:248px;
  border-radius:8px
}
.card-blank--collection{
  height:285px
}
@media(min-width: 769px){
  .card-blank--collection{
    height:384px
  }
}
.card-blank--hero-country{
  padding:0 16px;
  width:33.3333333333%;
  border:0;
  height:auto
}
@media(min-width: 769px){
  .card-blank--hero-country{
    background:#fff;
    width:184px;
    height:200px;
    margin-left:8px;
    margin-right:8px;
    padding:0
  }
}
.card-blank__footer{
  color:#414141;
  position:absolute;
  width:100%;
  left:0;
  bottom:0;
  padding:16px;
  display:flex;
  justify-content:space-between;
  align-items:center
}
.card-blank--hero-country .card-blank__footer{
  color:#fff;
  position:static;
  justify-content:center;
  flex-direction:column;
  font-size:14px;
  border-radius:50%;
  width:80px;
  height:80px;
  background:#2ec4dd;
  border:1px solid #fff;
  margin:0 auto;
  text-align:center
}
@media(min-width: 769px){
  .card-blank--hero-country .card-blank__footer{
    color:#414141;
    position:absolute;
    width:100%;
    height:auto;
    background:rgba(0,0,0,0);
    border:0;
    left:0;
    bottom:0;
    padding:16px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    font-size:16px;
    text-align:left
  }
}
.card.card-admin{
  border:1px solid #dfdfdf
}
.card.card-admin .card-header{
  padding:16px;
  cursor:pointer
}
.card.card-admin .card-header__btn{
  font-weight:600;
  cursor:pointer
}
.card-admin+.card-admin{
  margin-top:32px
}
.card-option-scrollable{
  position:relative;
  flex-shrink:0;
  flex-grow:0;
  flex-basis:85%;
  max-width:85%;
  padding:0 6px
}
@media(min-width: 769px){
  .card-option-scrollable{
    padding:0 16px;
    flex-basis:33.33333%;
    max-width:33.33333%
  }
}
.card-option-scrollable-blank{
  height:260px
}
.carousel-sao-images{
  position:relative;
  margin:0 -15px
}
@media(min-width: 769px){
  .carousel-sao-images{
    margin:0
  }
}
.carousel-sao-images .slick-prev{
  left:0
}
.carousel-sao-images .slick-next{
  right:0
}
.carousel-sao-images .slick-arrow{
  width:50%;
  height:100%;
  background:rgba(0,0,0,0);
  border-radius:0;
  box-shadow:none
}
.carousel-sao-images .slick-slide{
  cursor:pointer
}
.carousel-sao-images .slick-dots{
  bottom:-28px
}
.carousel-sao-images .slick-dots li+li{
  margin-left:6px
}
.carousel-sao-images .slick-dots li{
  border-color:#6a6a6a;
  cursor:pointer;
  padding:3px
}
.carousel-sao-images .slick-dots li.slick-active{
  background-color:#6a6a6a
}
.carousel-sao-images__item-img{
  position:relative;
  width:100%;
  height:280px
}
@media(min-width: 375px){
  .carousel-sao-images__item-img{
    height:340px
  }
}
@media(min-width: 769px){
  .carousel-sao-images__item-img{
    height:380px
  }
}
.carousel-sao-images__caption{
  position:absolute;
  bottom:0;
  left:0;
  color:#fff;
  width:100%;
  padding:0 15px;
  height:70px;
  width:100%;
  padding:12px 15px 0;
  background:linear-gradient(0deg, rgba(65, 65, 65, 0.6) 40%, rgba(65, 65, 65, 0.15) 100%)
}
.slider-v2.carousel-itineraries .slick-next:before{
  top:3px;
  border-color:#2D4C5D
}
.slider-v2.carousel-city-highlights .slick-next:before{
  top:3px;
  border-color:#2D4C5D
}
.slider-v2.carousel-city-highlights .slick-track{
  margin:auto
}
.carousel-sao-images__item{
  display:none
}
.carousel-sao-images__item:first-child{
  display:block
}
.slick-initialized .carousel-sao-images__item{
  display:block
}
.carousel--rectangle-dots .slick-dots{
  bottom:-32px
}
.carousel--rectangle-dots .slick-dots li{
  border:0;
  cursor:pointer;
  border-radius:0;
  background-color:#9f9f9f;
  padding:3px 10px;
  transition:background-color .2s ease-in-out
}
.carousel--rectangle-dots .slick-dots li.slick-active{
  background-color:#414141
}
.carousel--rectangle-dots.carousel-country-magazine .slick-dots{
  bottom:0
}
.carousel-blog{
  position:relative
}
.carousel-blog__item{
  position:relative;
  height:480px
}
@media(min-width: 769px){
  .carousel-blog__item{
    height:600px
  }
}
.carousel-blog__item img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:100%
}
.carousel-blog__item-body{
  width:100%;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  padding-left:15px
}
.carousel-blog__item-body-inner{
  max-width:600px
}
.carousel-blog__item-text{
  text-transform:uppercase;
  color:#fff;
  font-size:18px;
  font-weight:600;
  line-height:1;
  margin-bottom:16px
}
@media(min-width: 769px){
  .carousel-blog__item-text{
    font-size:20px
  }
}
.carousel-blog__item-title{
  font-size:32px;
  color:#fff;
  line-height:1.2;
  font-weight:600;
  margin-bottom:16px
}
@media(min-width: 769px){
  .carousel-blog__item-title{
    font-size:48px;
    margin-bottom:24px
  }
}
.carousel-blog__item-btn{
  display:inline-block;
  color:#414141;
  background-color:#fff;
  border:1px solid #fff;
  padding:8px 32px;
  transition:all .2s ease-in-out
}
.carousel-blog__item-btn:hover{
  color:#414141;
  font-weight:600
}
.carousel-blog .slick-prev{
  left:182px
}
.carousel-blog .slick-next{
  right:182px
}
.carousel-blog__item{
  display:none
}
.carousel-blog__item:first-child{
  display:block
}
.slick-initialized .carousel-blog__item{
  display:block
}
.carousel-accommodation-room__btn-inner,.carousel-activity-sao-image__btn-inner{
  width:28px;
  height:28px;
  line-height:24px;
  background-color:#D9D9D9;
  display:inline-block;
  border-radius:50%;
  box-shadow:0 3px 6px rgba(0,0,0,.1),0 5px 6px rgba(0,0,0,.2);
  text-align:center
}
.carousel-accommodation-room .carousel-control-prev,.carousel-accommodation-room .carousel-control-next,.carousel-accommodation-room__btn-prev,.carousel-accommodation-room__btn-next,.carousel-activity-sao-image .carousel-control-prev,.carousel-activity-sao-image .carousel-control-next,.carousel-activity-sao-image__btn-prev,.carousel-activity-sao-image__btn-next{
  display:block;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  z-index:80;
  cursor:pointer;
  width:60px;
  padding:16px 0
}
@media(min-width: 769px){
  .carousel-accommodation-room .carousel-control-prev,.carousel-accommodation-room .carousel-control-next,.carousel-accommodation-room__btn-prev,.carousel-accommodation-room__btn-next,.carousel-activity-sao-image .carousel-control-prev,.carousel-activity-sao-image .carousel-control-next,.carousel-activity-sao-image__btn-prev,.carousel-activity-sao-image__btn-next{
    visibility:hidden
  }
}
.carousel-accommodation-room .carousel-control-prev svg,.carousel-accommodation-room .carousel-control-next svg,.carousel-accommodation-room__btn-prev svg,.carousel-accommodation-room__btn-next svg,.carousel-activity-sao-image .carousel-control-prev svg,.carousel-activity-sao-image .carousel-control-next svg,.carousel-activity-sao-image__btn-prev svg,.carousel-activity-sao-image__btn-next svg{
  width:20px;
  height:20px;
  color:#414141
}
.carousel-accommodation-room .carousel-control-prev,.carousel-accommodation-room .carousel-control-next,.carousel-activity-sao-image .carousel-control-prev,.carousel-activity-sao-image .carousel-control-next{
  height:28px;
  padding:0;
  opacity:1
}
.carousel-accommodation-room:hover .carousel-control-prev,.carousel-accommodation-room:hover .carousel-control-next,.carousel-activity-sao-image:hover .carousel-control-prev,.carousel-activity-sao-image:hover .carousel-control-next{
  visibility:visible;
  opacity:1
}
.carousel-accommodation-room .carousel-control-prev,.carousel-accommodation-room__btn-prev,.carousel-activity-sao-image .carousel-control-prev,.carousel-activity-sao-image__btn-prev{
  left:8px;
  text-align:left
}
.carousel-accommodation-room .carousel-control-next,.carousel-accommodation-room__btn-next,.carousel-activity-sao-image .carousel-control-next,.carousel-activity-sao-image__btn-next{
  right:8px;
  text-align:right
}
.carousel-accommodation-room:hover .carousel-accommodation-room__btn-prev,.carousel-accommodation-room:hover .carousel-accommodation-room__btn-next,.carousel-accommodation-room:hover .carousel-activity-sao-image__btn-prev,.carousel-accommodation-room:hover .carousel-activity-sao-image__btn-next,.carousel-activity-sao-image:hover .carousel-accommodation-room__btn-prev,.carousel-activity-sao-image:hover .carousel-accommodation-room__btn-next,.carousel-activity-sao-image:hover .carousel-activity-sao-image__btn-prev,.carousel-activity-sao-image:hover .carousel-activity-sao-image__btn-next{
  visibility:visible
}
.carousel-accommodation-room .carousel-indicators,.carousel-activity-sao-image .carousel-indicators{
  position:absolute;
  width:100%;
  left:0;
  bottom:15px;
  padding:0;
  margin:0;
  text-align:center;
  display:block
}
.carousel-accommodation-room .carousel-indicators li,.carousel-activity-sao-image .carousel-indicators li{
  font-size:0;
  display:inline-block;
  border:0;
  border-radius:50%;
  background-color:#D9D9D9;
  padding:0;
  transition:all .2s ease-in-out;
  width:8px;
  height:8px;
  margin-right:0
}
.carousel-accommodation-room .carousel-indicators li.active,.carousel-activity-sao-image .carousel-indicators li.active{
  background-color:#A9B3D3
}
.carousel-accommodation-room .carousel-indicators li+li,.carousel-activity-sao-image .carousel-indicators li+li{
  margin-left:5px
}
.carousel-accommodation-room .carousel-item,.carousel-activity-sao-image .carousel-item{
  transition:transform .3s ease-in-out;
  width:100%;
  height:260px
}
.carousel-accommodation-room__item img,.carousel-activity-sao-image__item img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:260px;
  border-radius:8px
}
@media(min-width: 769px){
  .carousel-accommodation-room__item img,.carousel-activity-sao-image__item img{
    height:260px
  }
}
.carousel-accommodation-room .slick-prev,.carousel-activity-sao-image .slick-prev{
  left:-12px
}
.carousel-accommodation-room .slick-next,.carousel-activity-sao-image .slick-next{
  right:-12px
}
.carousel-accommodation-room .slick-prev,.carousel-accommodation-room .slick-next,.carousel-activity-sao-image .slick-prev,.carousel-activity-sao-image .slick-next{
  width:24px;
  height:24px;
  line-height:24px
}
.carousel-accommodation-room .slick-prev svg,.carousel-accommodation-room .slick-next svg,.carousel-activity-sao-image .slick-prev svg,.carousel-activity-sao-image .slick-next svg{
  width:20px;
  height:20px
}
.carousel-accommodation-room .slick-arrow,.carousel-activity-sao-image .slick-arrow{
  visibility:hidden
}
.carousel-accommodation-room .slick-dots li,.carousel-activity-sao-image .slick-dots li{
  padding:3px
}
.carousel-accommodation-room:hover .slick-arrow,.carousel-activity-sao-image:hover .slick-arrow{
  visibility:visible
}
.carousel-accommodation-room .carousel-item{
  height:320px
}
.carousel-activity-sao-image--corp .carousel-activity-sao-image__btn-prev{
  left:0;
  text-align:center
}
.carousel-activity-sao-image--corp .carousel-activity-sao-image__btn-next{
  right:0;
  text-align:center
}
.carousel-activity-sao-image--corp .carousel-indicators{
  bottom:6px
}
.carousel-activity-sao-image--corp .carousel-indicators li{
  width:4px;
  height:4px
}
.carousel-activity-sao-image--corp .carousel-item img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:248px;
  border-radius:8px
}
@media(min-width: 769px){
  .carousel-activity-sao-image--corp .carousel-item img{
    height:248px
  }
}
.carousel-activity-sao-image__item{
  display:none
}
.carousel-activity-sao-image__item:first-child{
  display:block
}
.slick-initialized .carousel-activity-sao-image__item{
  display:block
}
.option-comparison__carousel{
  margin-bottom:16px
}
.option-comparison__carousel-item img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:120px;
  border-radius:2px
}
@media(min-width: 769px){
  .option-comparison__carousel-item img{
    height:320px
  }
}
.option-comparison__carousel-icon-prev,.option-comparison__carousel-icon-next{
  position:absolute;
  z-index:100;
  width:24px;
  height:24px;
  line-height:24px;
  border-radius:50%;
  background-color:#fff;
  top:50%;
  transform:translateY(-50%);
  text-align:center;
  cursor:pointer;
  box-shadow:0 3px 6px rgba(0,0,0,.1),0 5px 6px rgba(0,0,0,.2)
}
.option-comparison__carousel-icon-prev svg,.option-comparison__carousel-icon-next svg{
  width:20px;
  height:20px
}
.option-comparison__carousel-icon-prev{
  left:-12px
}
.option-comparison__carousel-icon-next{
  right:-12px
}
.option-comparison__carousel-item{
  display:none
}
.option-comparison__carousel-item:first-child{
  display:block
}
.slick-initialized .option-comparison__carousel-item{
  display:block
}
.carousel-tried-and-tested{
  margin-left:-16px;
  margin-right:-16px
}
.carousel-tried-and-tested__item{
  padding-left:4px;
  padding-right:4px;
  position:relative
}
@media(min-width: 769px){
  .carousel-tried-and-tested__item{
    padding-left:16px;
    padding-right:16px
  }
}
.carousel-tried-and-tested__item img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:170px
}
@media(min-width: 769px){
  .carousel-tried-and-tested__item img{
    height:195
  }
}
.carousel-tried-and-tested__item{
  display:none
}
.carousel-tried-and-tested__item:first-child{
  display:block
}
.slick-initialized .carousel-tried-and-tested__item{
  display:block
}
.carousel-marker-popup__item img{
  background-color:#dfdfdf;
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:150px
}
.carousel-marker-popup .slick-prev{
  left:8px
}
.carousel-marker-popup .slick-next{
  right:8px
}
.carousel-marker-popup .slick-prev,.carousel-marker-popup .slick-next{
  width:24px;
  height:24px;
  line-height:24px
}
.carousel-marker-popup .slick-prev svg,.carousel-marker-popup .slick-next svg{
  width:20px;
  height:20px
}
.carousel-marker-popup__item{
  display:none
}
.carousel-marker-popup__item:first-child{
  display:block
}
.slick-initialized .carousel-marker-popup__item{
  display:block
}
.hero-country__carousel{
  padding-top:0;
  flex:0 0 auto;
  width:100%
}
@media(min-width: 769px){
  .hero-country__carousel{
    padding-left:0;
    width:800px
  }
}
@media(min-width: 769px){
  .hero-country__carousel .slick-slide{
    padding:0 8px
  }
}
.hero-country__carousel-item{
  cursor:pointer;
  position:relative;
  padding:0 16px;
  text-align:center;
  width:33.3333333333%;
  flex-shrink:0;
  margin-bottom:12px
}
@media(min-width: 769px){
  .hero-country__carousel-item{
    width:auto;
    padding:0;
    text-align:left;
    margin-bottom:0
  }
}
.hero-country__carousel-img{
  margin:0 auto;
  margin-bottom:4px;
  position:relative;
  width:80px;
  height:80px
}
@media(min-width: 769px){
  .hero-country__carousel-img{
    margin-bottom:0;
    width:100%;
    height:204px
  }
}
@media(min-width: 769px){
  .hero-country__carousel-overlay{
    display:block;
    position:absolute;
    left:0;
    bottom:0;
    width:100%;
    padding:16px;
    background:linear-gradient(0deg, rgba(65, 65, 65, 0.6) 40%, rgba(65, 65, 65, 0.15) 100%);
    height:80px;
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px
  }
}
.hero-country__carousel-title{
  color:#fff;
  font-weight:600;
  font-size:12px
}
@media(min-width: 769px){
  .hero-country__carousel-title{
    font-size:16px
  }
}
.hero-country__carousel .slick-prev{
  left:5px
}
@media(min-width: 769px){
  .hero-country__carousel .slick-prev{
    left:8px;
    right:auto
  }
}
.hero-country__carousel .slick-prev.slick-disabled{
  display:block !important;
  opacity:.5
}
.hero-country__carousel .slick-next{
  left:49px
}
@media(min-width: 769px){
  .hero-country__carousel .slick-next{
    left:64px;
    right:auto
  }
}
.hero-country__carousel .slick-next.slick-disabled{
  display:block !important;
  opacity:.5
}
.hero-country__carousel .slick-prev,.hero-country__carousel .slick-next{
  top:auto;
  bottom:-75px;
  background-color:rgba(0,0,0,0);
  box-shadow:none
}
@media(min-width: 769px){
  .hero-country__carousel .slick-prev,.hero-country__carousel .slick-next{
    border:1px solid #fff
  }
}
.hero-country__carousel .slick-prev:hover svg,.hero-country__carousel .slick-next:hover svg{
  color:#fff
}
.hero-country__carousel .slick-prev svg,.hero-country__carousel .slick-next svg{
  color:#fff
}
.hero-country__carousel .slick-dots{
  bottom:-28px
}
.hero-country__carousel .slick-dots li{
  padding:4px
}
.hero-country__carousel .slick-dots li.slick-active{
  background-color:#2ec4dd
}
.hero-country__carousel-item{
  display:none
}
.hero-country__carousel-item:first-child{
  display:block
}
.slick-initialized .hero-country__carousel-item{
  display:block
}
.carousel-country-collection{
  margin-left:-8px;
  margin-right:-8px;
  display:flex;
  flex-wrap:wrap
}
.carousel-country-collection .slick-list{
  width:100%
}
@media(min-width: 769px){
  .carousel-country-collection{
    margin-left:-15px;
    margin-right:-15px;
    display:block
  }
}
.carousel-country-collection__item{
  padding-left:8px;
  padding-right:8px;
  width:50%;
  margin-bottom:16px
}
@media(min-width: 769px){
  .carousel-country-collection__item{
    width:auto;
    padding-left:15px;
    padding-right:15px;
    display:none;
    margin-bottom:0px
  }

  .carousel-country-collection__item:first-child{
    display:block
  }

  .carousel-country-collection__item .slick-initialized j{
    display:block
  }
}
.carousel-country-collection .slick-next,.carousel-country-collection .slick-prev{
  width:32px;
  height:32px;
  line-height:32px
}
.carousel-country-collection .slick-next svg,.carousel-country-collection .slick-prev svg{
  width:20px;
  height:20px
}
.carousel-country-collection .slick-prev{
  left:auto;
  right:64px
}
.carousel-country-collection .slick-next{
  right:16px
}
.carousel-country-collection .slick-arrow{
  top:-60px;
  transform:translateY(0)
}
.carousel-country-collection .slick-prev.slick-disabled,.carousel-country-collection .slick-next.slick-disabled{
  display:block !important;
  opacity:.5;
  cursor:default
}
.carousel-country-collection .slick-prev.slick-disabled:hover svg,.carousel-country-collection .slick-next.slick-disabled:hover svg{
  color:#414141
}
.carousel-country-magazine{
  margin-left:-15px;
  margin-right:-15px
}
@media(min-width: 769px){
  .carousel-country-magazine{
    margin-left:0;
    margin-right:0
  }
}
.carousel-country-magazine__item.slick-slide{
  display:block
}
@media(min-width: 769px){
  .carousel-country-magazine__item.slick-slide{
    display:flex;
    align-items:center;
    width:50%
  }
}
.carousel-country-magazine__item-panel{
  width:100%
}
@media(min-width: 769px){
  .carousel-country-magazine__item-panel{
    width:50%
  }
}
.carousel-country-magazine__img{
  height:320px
}
.carousel-country-magazine .slick-next,.carousel-country-magazine .slick-prev{
  width:32px;
  height:32px;
  line-height:32px
}
.carousel-country-magazine .slick-next svg,.carousel-country-magazine .slick-prev svg{
  width:20px;
  height:20px
}
.carousel-country-magazine .slick-prev{
  left:auto;
  right:64px
}
.carousel-country-magazine .slick-next{
  right:16px
}
.carousel-country-magazine .slick-arrow{
  top:-60px;
  transform:translateY(0)
}
.carousel-country-magazine .slick-arrow{
  top:-2px
}
.carousel-counter{
  display:none;
  position:absolute;
  top:0;
  right:96px
}
@media(min-width: 769px){
  .carousel-counter{
    display:block
  }
}
.carousel-counter--magazine{
  top:8px;
  right:110px
}
.carousel-counter--collection{
  top:-52px
}
.carousel-counter--other-options{
  top:-38px
}
.carousel-counter--category{
  top:-56px
}
.carousel-other-options{
  margin-left:-15px;
  margin-right:-15px
}
.carousel-other-options>.slick-next,.carousel-other-options>.slick-prev{
  width:32px;
  height:32px;
  line-height:32px
}
.carousel-other-options>.slick-next svg,.carousel-other-options>.slick-prev svg{
  width:20px;
  height:20px
}
.carousel-other-options>.slick-prev{
  left:auto;
  right:64px
}
.carousel-other-options>.slick-next{
  right:16px
}
.carousel-other-options>.slick-arrow{
  top:-60px;
  transform:translateY(0)
}
.carousel-other-options>.slick-arrow{
  top:-48px
}
.carousel-other-options.slick-slider .slick-track{
  min-width:100%
}
.carousel-collection-options{
  margin-left:-8px;
  margin-right:-8px
}
@media(min-width: 769px){
  .carousel-collection-options{
    margin-left:-15px;
    margin-right:-15px
  }
}
.carousel-collection-options>.slick-next,.carousel-collection-options>.slick-prev{
  width:32px;
  height:32px;
  line-height:32px
}
.carousel-collection-options>.slick-next svg,.carousel-collection-options>.slick-prev svg{
  width:20px;
  height:20px
}
.carousel-collection-options>.slick-prev{
  left:auto;
  right:64px
}
.carousel-collection-options>.slick-next{
  right:16px
}
.carousel-collection-options>.slick-arrow{
  top:-60px;
  transform:translateY(0)
}
.carousel-collection-options.slick-slider .slick-track{
  min-width:100%;
  overflow-y:hidden
}
.carousel-collection-options--grid.slick-slider .slick-track{
  max-height:453px
}
.carousel-collection-options--simple.slick-slider .slick-track{
  max-height:396px
}
.carousel-collection-options--list{
  padding-left:15px;
  padding-right:15px
}
.carousel-collection-options--list.slick-slider .slick-track{
  max-height:900px;
  padding-top:1px
}
.carousel-collection-options .slick-prev.slick-disabled,.carousel-collection-options .slick-next.slick-disabled{
  display:block !important;
  opacity:.5;
  cursor:default
}
.carousel-collection-options .slick-prev.slick-disabled:hover svg,.carousel-collection-options .slick-next.slick-disabled:hover svg{
  color:#414141
}
.carousel-collection-options--category>.slick-arrow{
  top:-72px
}
.carousel-collection-options__item:focus{
  outline:none !important
}
.carousel-collection-options__item{
  display:none
}
.carousel-collection-options__item:first-child{
  display:block
}
.slick-initialized .carousel-collection-options__item{
  display:block
}
.carousel-video__item iframe{
  position:absolute;
  width:100%;
  height:100%;
  background:#000;
  top:0;
  left:0
}
@media(min-width: 769px){
  .carousel-video__item iframe{
    height:400px;
    position:static
  }
}
.carousel-video__item-inner{
  position:relative;
  padding:177% 0 0 0
}
@media(min-width: 769px){
  .carousel-video__item-inner{
    padding:0
  }
}
.see-more-collection{
  padding-right:0;
  font-size:12px
}
@media(min-width: 769px){
  .see-more-collection{
    padding-right:100px;
    font-size:16px
  }
}
.carousel-gift-card .slick-slide:focus,.carousel-gift-card .slick-slide:active{
  outline:none
}
.carousel-gift-card .slick-arrow{
  top:-28px;
  color:#2D4C5D
}
.carousel-gift-card .slick-prev{
  left:auto;
  right:60px
}
.carousel-gift-card .slick-next{
  right:12px
}
.carousel-gift-card .slick-next:before{
  content:" ";
  position:absolute;
  left:-7px;
  top:1px;
  display:inline-block;
  height:32px;
  border-left:2px solid #2D4C5D;
  transform:rotate(35deg)
}
.carousel-gift-card .slick-next,.carousel-gift-card .slick-prev{
  width:32px;
  height:32px;
  line-height:32px;
  background-color:rgba(0,0,0,0)
}
.carousel-gift-card .slick-next.slick-disabled,.carousel-gift-card .slick-prev.slick-disabled{
  opacity:.6;
  cursor:default;
  display:block !important
}
.carousel-why-gift .slick-dots{
  bottom:-24px
}
.carousel-why-gift .slick-dots li.slick-active{
  background-color:#414141
}
.carousel-why-gift .slick-dots li{
  padding:4.5px;
  border-color:#414141
}
.carousel-our-pick-option .slick-prev{
  top:-26px;
  right:40px;
  left:auto
}
.carousel-our-pick-option .slick-next{
  top:-26px;
  right:0;
  left:auto
}
.carousel-our-pick-option .slick-prev,.carousel-our-pick-option .slick-next{
  width:32px;
  height:32px;
  line-height:32px;
  display:none !important
}
@media(min-width: 769px){
  .carousel-our-pick-option .slick-prev,.carousel-our-pick-option .slick-next{
    display:block !important
  }
}
.carousel-our-pick-option .slick-prev svg,.carousel-our-pick-option .slick-next svg{
  width:20px;
  height:20px
}
.carousel-our-pick-option .slick-slide.slick-current{
  z-index:10
}
.carousel-our-pick-option .slick-dots{
  bottom:-24px
}
.carousel-our-pick-option .slick-dots li.slick-active{
  background-color:#414141
}
.carousel-our-pick-option .slick-dots li{
  padding:4.5px;
  border-color:#414141
}
.carousel-collection-activity-review .slick-arrow{
  top:-28px
}
.carousel-collection-activity-review .slick-prev{
  left:auto;
  right:32px
}
.carousel-collection-activity-review .slick-next{
  right:-4px
}
.carousel-collection-activity-review .slick-next,.carousel-collection-activity-review .slick-prev{
  width:24px;
  height:24px;
  line-height:24px
}
.carousel-collection-activity-review .slick-next svg,.carousel-collection-activity-review .slick-prev svg{
  width:16px;
  height:16px
}
.carousel-collection-activity-review .slick-next.slick-disabled,.carousel-collection-activity-review .slick-prev.slick-disabled{
  opacity:.6;
  cursor:default;
  display:block !important
}
.carousel-homework .slick-dots{
  bottom:-32px
}
.carousel-top-collection .slick-dots{
  bottom:-10px
}
@media(min-width: 769px){
  .carousel-top-collection .slick-dots{
    bottom:-32px
  }
}
.slider-v2.carousel-field-notes .slick-arrow{
  top:-80px;
  bottom:auto;
  transform:none
}
.slider-v2.carousel-field-notes .slick-arrow svg{
  width:32px;
  height:32px
}
.slider-v2.carousel-field-notes .slick-prev{
  left:auto;
  right:77px
}
.slider-v2.carousel-field-notes .slick-next{
  left:auto;
  right:28px
}
.slider-v2.carousel-field-notes .slick-next:before{
  top:3px;
  border-color:#2D4C5D
}
.slider-v2.carousel-featured-blog .slick-list,.slider-v2.carousel-featured-blog .slick-track{
  padding-top:20px
}
.slider-v2.carousel-featured-blog .slick-arrow{
  bottom:-40px
}
@media(min-width: 769px){
  .slider-v2.carousel-featured-blog .slick-arrow{
    bottom:48px
  }
}
.slider-v2.carousel-featured-blog .slick-arrow svg{
  width:28px;
  height:28px
}
.slider-v2.carousel-featured-blog .slick-prev{
  left:50%
}
@media(min-width: 769px){
  .slider-v2.carousel-featured-blog .slick-prev{
    left:25%
  }
}
.slider-v2.carousel-featured-blog .slick-next{
  left:50%
}
@media(min-width: 769px){
  .slider-v2.carousel-featured-blog .slick-next{
    left:25%
  }
}
.slider-v2.carousel-featured-blog .slick-next:before{
  border-left:2px solid #2D4C5D
}
.slider-v2.carousel-featured-guide .slick-dots{
  bottom:-36px
}
.slider-v2.carousel-featured-guide .slick-arrow{
  left:auto;
  right:0;
  top:-114px;
  bottom:auto
}
.slider-v2.carousel-featured-guide .slick-prev{
  right:56px
}
.slider-v2.carousel-featured-guide .slick-next{
  right:16px
}
.slider-v2.carousel-featured-guide .slick-next:before{
  border-left:2px solid #2D4C5D
}
.chapter__img{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3;
  height:280px;
  margin:0 -15px
}
@media(min-width: 769px){
  .chapter__img{
    height:380px;
    margin:0
  }
}
.chapter__title-main{
  line-height:1;
  font-size:20px;
  font-weight:600
}
@media(min-width: 992px){
  .chapter__title-main{
    font-size:24px
  }
}
.chapter__title-side{
  line-height:1;
  font-size:32px;
  font-weight:600;
  display:none
}
@media(min-width: 992px){
  .chapter__title-side{
    display:block
  }
}
.chapter__title{
  line-height:1.2;
  font-size:20px;
  font-weight:600;
  margin-bottom:20px;
  display:block
}
@media(min-width: 992px){
  .chapter__title{
    display:none
  }
}
.chapter__list+.chapter__list{
  margin-top:15px
}
.chapter__list-item{
  display:inline-block;
  font-size:14px;
  margin-right:15px;
  margin-bottom:5px;
  line-height:1
}
.chapter__list-item i{
  color:#2ec4dd;
  margin-right:5px
}
.chapter__list-item .badge{
  font-size:14px;
  font-weight:400
}
.chapter__list-item .badge i{
  color:#fff
}
.chapter__list-item--line+.chapter__list-item--line{
  padding-left:15px;
  border-left:1px solid #414141
}
.chapter__desc{
  margin-top:16px;
  line-height:1.8
}
@media(min-width: 992px){
  .chapter__desc{
    margin-top:0
  }
}
.chapter__subtitle{
  color:#9f9f9f;
  font-size:18px;
  font-weight:600;
  margin-bottom:0
}
@media(min-width: 769px){
  .chapter__subtitle{
    font-size:20px
  }
}
.chapter__divider{
  border-top:4px solid #dfdfdf;
  margin:32px -15px
}
@media(min-width: 769px){
  .chapter__divider{
    border-top:1px solid #dfdfdf;
    margin:48px 0
  }
}
.circle{
  display:inline-block;
  border-radius:50%;
  border-style:solid;
  border-width:1px;
  border-color:rgba(0,0,0,0);
  width:16px;
  height:16px;
  line-height:16px;
  font-size:12px;
  text-align:center
}
.circle--red{
  background:#ff5055;
  color:#fff
}
.circle--white{
  background:#fff;
  border-color:#9f9f9f
}
.circle--cyan{
  background:#2ec4dd;
  border-color:#9f9f9f
}
.circle--yellow{
  background:#fdaa00
}
.circle--purple{
  background:#4a5ced;
  border-color:#9f9f9f
}
.circle--black{
  background:#414141
}
.circle--whatsapp{
  background:#25d366
}
.circle--outline-blue{
  border-color:#2ec4dd;
  background:#fff
}
.circle--outline-black{
  border-color:#000;
  background:#fff
}
.circle--outline-navy{
  border-color:#2D4C5D;
  background:#fff
}
.circle--gray{
  background:#9f9f9f;
  color:#fff
}
.circle--gray.active{
  background:#2ec4dd
}
.circle--teal{
  background:#5f9ea0
}
.circle--4{
  width:4px;
  height:4px
}
.circle--8{
  width:8px;
  height:8px
}
.circle--10{
  width:10px;
  height:10px
}
.circle--sm{
  width:12px;
  height:12px
}
.circle--48{
  width:48px;
  height:48px;
  line-height:45px
}
.activity-faq{
  margin-top:32px
}
.activity-faq__content{
  display:flex;
  align-items:top
}
.activity-faq__content+.activity-faq__content{
  margin-top:12px
}
.activity-faq__item+.activity-faq__item{
  margin-top:32px
}
.activity-faq__content-icon{
  flex:0 0 auto;
  width:32px;
  height:32px;
  text-align:center;
  line-height:32px;
  font-weight:600;
  margin-right:32px;
  color:#fff;
  display:none
}
@media(min-width: 769px){
  .activity-faq__content-icon{
    display:block
  }
}
.activity-faq__content-icon--question{
  background-color:#2ec4dd
}
.activity-faq__content-icon--answer{
  background-color:#6a6a6a
}
.activity-faq__content-body{
  flex-grow:1;
  align-self:center
}
.activity-faq__content-meta{
  color:#9f9f9f;
  font-size:14px
}
.activity-faq__content-title--big{
  font-weight:600;
  font-size:20px
}
input[type=number]{
  -moz-appearance:textfield
}
input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button{
  -webkit-appearance:none;
          appearance:none;
  margin:0
}
label+.form-text{
  margin-bottom:8px;
  font-size:14px;
  color:#6a6a6a
}
.custom-select,.form-control{
  color:#2D4C5D;
  border:1px solid #D9D9D9;
  border-radius:10px;
  height:40px;
  line-height:18px;
  padding:10px 12px
}
.custom-select::-moz-placeholder, .form-control::-moz-placeholder{
  color:#ABABAB
}
.custom-select::placeholder,.form-control::placeholder{
  color:#ABABAB
}
.custom-select::-ms-input-placeholder,.form-control::-ms-input-placeholder{
  color:#ABABAB
}
.custom-select:focus,.form-control:focus{
  border-color:#D9D9D9;
  outline:none;
  box-shadow:none
}
.input-group-text{
  background-color:#fff;
  border-radius:10px;
  height:40px
}
.input-group>.form-control:not(:first-child),.input-group>.custom-select:not(:first-child){
  border-left:0
}
.input-group.is-invalid .input-group-text,.input-group.is-invalid .form-control{
  border-color:#ff5055
}
.input-group.is-invalid .invalid-feedback{
  display:block
}
.input-group button:focus{
  outline:none
}
.form-check{
  padding-left:0
}
.form-check-label{
  position:relative;
  cursor:pointer;
  margin-bottom:0
}
.form-check-label .form-check-label-desc{
  display:inline-block;
  padding-top:8px;
  color:#2D4C5D;
  padding-left:30px;
  white-space:initial
}
.form-check-label .form-check-label-desc--sm{
  font-size:14px
}
.form-check-label:before{
  content:"";
  -webkit-appearance:none;
  background-color:#fff;
  border:1px solid #2D4C5D;
  padding:8px;
  display:inline-block;
  position:relative;
  vertical-align:middle;
  cursor:pointer;
  margin-right:4px;
  margin-bottom:4px
}
.form-check-input:checked+.form-check-label:before{
  background-color:#FF6B07;
  border-color:#FF6B07
}
.form-check-input{
  padding:0;
  height:initial;
  width:initial;
  margin-bottom:0;
  display:none;
  cursor:pointer
}
.form-check-input[type=checkbox]+.form-check-label:before{
  border-radius:2px
}
.form-check-input[type=checkbox]+.form-check-sm:before{
  transform:scale(0.8)
}
.form-check-input[type=checkbox]:checked+.form-check-label:after{
  content:"";
  display:block;
  position:absolute;
  top:4px;
  left:7px;
  width:5px;
  height:11px;
  border:solid #fff;
  border-width:0 2px 2px 0;
  transform:rotate(45deg)
}
.form-check-input[type=checkbox]:indeterminate+.form-check-label:before{
  background-color:#FF6B07;
  border-color:#FF6B07
}
.form-check-input[type=checkbox]:indeterminate+.form-check-label:after{
  content:"";
  display:block;
  position:absolute;
  top:9px;
  left:2px;
  width:14px;
  border:solid #fff;
  border-width:0 2px 2px 0
}
.form-check-input[type=radio]+.form-check-label:before{
  border-radius:50%
}
.input-modal-upload{
  width:100%
}
@media(min-width: 992px){
  .input-modal-upload{
    width:310px
  }
}
.gift-card:focus{
  outline:none
}
.gift-card a:focus{
  outline:none
}
.gift-card__img{
  position:relative
}
.gift-card__img img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:100px;
  border-top-left-radius:10px;
  border-top-right-radius:10px
}
@media(min-width: 769px){
  .gift-card__img img{
    height:170px
  }
}
.gift-card__img--checkout img{
  height:200px
}
.gift-card__overlay{
  padding:4px;
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index:10;
  background-color:rgba(65,65,65,.2);
  border-radius:4px
}
@media(min-width: 769px){
  .gift-card__overlay{
    padding:16px
  }
}
.gift-card__border{
  border:2px solid #fff;
  height:100%;
  color:#fff;
  padding-left:12px;
  padding-top:12px;
  font-weight:600;
  text-transform:uppercase;
  font-size:14px
}
@media(min-width: 769px){
  .gift-card__border{
    border-width:4px
  }
}
.gift-card__value{
  position:absolute;
  z-index:10;
  width:100%;
  left:0;
  bottom:16px;
  line-height:1;
  color:#fff;
  font-size:28px;
  font-weight:600;
  text-transform:uppercase;
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:0 16px
}
@media(min-width: 769px){
  .gift-card__value{
    font-size:32px
  }
}
.gift-card__body{
  padding:12px 0
}
@media(min-width: 769px){
  .gift-card__body{
    padding:24px 0
  }
}
.gift-card__name{
  font-size:16px;
  font-weight:600;
  color:#414141;
  margin-bottom:8px;
  line-height:1.3
}
.gift-card__price{
  color:#2ec4dd;
  font-size:16px;
  font-weight:600
}
.gift-card__badge{
  background-color:#fdaa00;
  border-radius:4px;
  padding:4px 8px;
  color:#fff;
  font-weight:600;
  font-size:14px;
  text-align:center;
  display:inline-block;
  vertical-align:middle;
  margin-top:4px
}
@media(min-width: 769px){
  .gift-card__badge{
    font-size:16px
  }
}
.gift-card__badge--big{
  padding:6px 8px;
  font-size:16px
}
@media(min-width: 769px){
  .gift-card__badge--big{
    font-size:20px;
    padding:8px 16px
  }
}
.hero-country{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3;
  width:100%;
  height:320px;
  overflow:hidden
}
@media(min-width: 769px){
  .hero-country{
    height:400px
  }
}
.hero-country--sm{
  height:250px
}
.hero-country--lg{
  height:510px
}
.hero-country__content{
  background:rgba(0,0,0,.5);
  height:100%
}
@media(min-width: 769px){
  .hero-country__content{
    display:flex;
    flex-direction:column;
    justify-content:center
  }
}
.hero-country__content .container{
  height:100%
}
@media(min-width: 769px){
  .hero-country__content .container{
    height:auto
  }
}
.hero-country__content .row{
  height:100%
}
@media(min-width: 769px){
  .hero-country__content .row{
    height:auto
  }
}
.hero-country__center{
  align-self:center
}
.hero-country__right{
  margin-top:32px
}
@media(min-width: 992px){
  .hero-country__right{
    margin-top:0
  }
}
.hero-country__dash{
  width:32px;
  border-bottom:4px solid #fff;
  margin-bottom:24px
}
.hero-country__type{
  color:#fff;
  font-weight:600;
  text-transform:uppercase;
  margin-bottom:16px;
  font-size:14px
}
@media(min-width: 769px){
  .hero-country__type{
    font-size:16px;
    margin-bottom:24px;
    display:block
  }
}
.hero-country__type--pc{
  display:none
}
.hero-country__type--normal{
  text-transform:none;
  font-size:18px;
  line-height:1
}
.hero-country__title{
  line-height:1;
  font-size:32px;
  color:#fff;
  font-weight:600;
  margin-bottom:8px
}
@media(min-width: 769px){
  .hero-country__title{
    margin-bottom:16px
  }
}
.hero-country__title--xl{
  line-height:1
}
@media(min-width: 769px){
  .hero-country__title--xl{
    margin-bottom:16px;
    font-size:36px
  }
}
.hero-country__desc{
  color:#fff;
  line-height:1.4;
  margin-bottom:16px;
  font-size:14px;
  text-align:center
}
@media(min-width: 769px){
  .hero-country__desc{
    font-size:16px;
    margin-bottom:32px;
    text-align:left
  }
}
.hero-country__btn{
  color:#fff;
  font-size:16px;
  font-weight:600;
  cursor:pointer
}
@media(min-width: 769px){
  .hero-country__btn{
    font-size:20px
  }
}
.hero-country__btn .btn,.hero-country__btn .uppy-FileInput-btn{
  border:0;
  text-decoration:underline;
  padding-left:0
}
@media(min-width: 769px){
  .hero-country__btn .btn,.hero-country__btn .uppy-FileInput-btn{
    text-decoration:inherit;
    border:1px solid #fff;
    padding-left:12px
  }
}
.hero-country__btn span{
  text-decoration:underline
}
.hero-country__link{
  font-size:16px;
  color:#fff;
  font-weight:600;
  text-decoration:underline
}
.hero-country__link:hover{
  text-decoration:none;
  color:#fff
}
.hero-country__link--sm{
  font-size:14px
}
.hero-country__counter{
  flex:0 0 auto;
  display:block;
  width:52px;
  height:48px;
  background-color:#fff;
  border-radius:4px;
  line-height:48px;
  text-align:center;
  font-size:18px;
  font-weight:600;
  margin-left:4px
}
@media(min-width: 769px){
  .hero-country__counter{
    display:none
  }
}
.hero-country__box{
  display:flex
}
@media(min-width: 769px){
  .hero-country__box{
    background-color:inherit;
    border-radius:none;
    padding:0
  }
}
.img-grid{
  position:relative;
  cursor:pointer;
  height:280px
}
@media(min-width: 769px){
  .img-grid{
    height:380px
  }
}
.img-grid__item{
  position:relative;
  height:280px;
  width:100%;
  transition:transform .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-top:1px solid #fff;
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3
}
.img-grid__item:hover{
  transform:scale(1.05)
}
@media(min-width: 769px){
  .img-grid__item{
    height:380px
  }
}
.img-grid__item--sm{
  height:190px
}
.img-grid__row{
  overflow:hidden
}
.img-grid__row .col-md-6{
  overflow:hidden
}
.img-grid__row .col-md-6+.col-md-6{
  border-left:1px solid #fff
}
.img-grid__btn-carousel{
  position:absolute;
  bottom:16px;
  right:32px
}
.lightbox{
  position:fixed;
  width:100%;
  top:0;
  left:0;
  z-index:1500;
  background-color:rgba(0,0,0,.9);
  height:100%
}
.lightbox__inner{
  position:relative;
  height:100%
}
.lightbox__nav,.lightbox__main{
  max-width:750px;
  margin:0 auto;
  width:100%
}
.lightbox__nav{
  margin-top:15px;
  padding:0 15px
}
.lightbox__main{
  padding:80px 15px 0;
  height:calc(100% - 90px)
}
@media(min-width: 769px){
  .lightbox__main{
    height:initial
  }
}
.lightbox__main img{
  width:100%;
  height:auto
}
.lightbox__main-carousel{
  height:100%
}
@media(min-width: 769px){
  .lightbox__main-carousel{
    height:auto
  }
}
.lightbox__main-carousel .slick-list,.lightbox__main-carousel .slick-track,.lightbox__main-carousel .slick-slide>div,.lightbox__main-carousel .slick-slider{
  height:100%
}
.lightbox__main-carousel .slick-slide.slick-active{
  z-index:1000
}
.lightbox__main-carousel-item{
  height:100%
}
@media(min-width: 769px){
  .lightbox__main-carousel-item{
    height:auto
  }
}
.lightbox__main-carousel-item-img{
  position:relative;
  text-align:center;
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:center
}
.lightbox__main-carousel-item-img iframe{
  width:100%;
  height:100%
}
@media(min-width: 769px){
  .lightbox__main-carousel-item-img{
    display:block;
    height:384px
  }

  .lightbox__main-carousel-item-img iframe{
    height:100%;
    width:100%
  }
}
.lightbox__main-carousel-item-inner{
  height:100%
}
.lightbox__nav-carousel{
  display:none
}
@media(min-width: 576px){
  .lightbox__nav-carousel{
    display:block
  }
}
.lightbox__nav-carousel-item{
  padding:0 4px
}
.lightbox__nav-carousel-item-img{
  height:94px;
  position:relative;
  border:2px solid rgba(0,0,0,0);
  border-radius:10px
}
.lightbox__nav-carousel-item-img iframe{
  width:100%;
  height:90px;
  display:block;
  border-radius:10px
}
.lightbox__nav-carousel-item-img img{
  border-radius:10px
}
.slick-current .lightbox__nav-carousel-item-img{
  border:2px solid #E4E480;
  border-radius:10px
}
.lightbox__btn-next,.lightbox__btn-prev,.lightbox__btn-close{
  position:absolute;
  display:block;
  cursor:pointer;
  color:#fff
}
.lightbox__btn-next.slick-arrow,.lightbox__btn-prev.slick-arrow{
  width:28px;
  height:28px;
  line-height:24px;
  top:50%;
  transform:translateY(-50%);
  background-color:#D9D9D9;
  color:#2D4C5D;
  border-radius:50%;
  text-align:center
}
.lightbox__btn-next.slick-arrow svg,.lightbox__btn-prev.slick-arrow svg{
  stroke-width:2px;
  width:20px;
  height:20px
}
@media(min-width: 769px){
  .lightbox__btn-next.slick-arrow,.lightbox__btn-prev.slick-arrow{
    width:48px;
    height:48px;
    line-height:48px;
    background-color:rgba(0,0,0,0);
    color:#fff;
    border-radius:none
  }

  .lightbox__btn-next.slick-arrow svg,.lightbox__btn-prev.slick-arrow svg{
    width:48px;
    height:48px
  }
}
.lightbox__btn-prev{
  left:20px
}
@media(min-width: 769px){
  .lightbox__btn-prev{
    left:30px
  }
}
.lightbox__btn-next{
  right:20px
}
@media(min-width: 769px){
  .lightbox__btn-next{
    right:30px
  }
}
.lightbox__btn-close{
  top:15px;
  right:5px;
  width:48px;
  height:48px;
  line-height:48px
}
@media(min-width: 769px){
  .lightbox__btn-close{
    top:30px;
    right:30px
  }
}
.lightbox-video-container{
  position:relative;
  width:100%;
  height:100%
}
.list-horizontal-scroll{
  display:flex;
  flex-wrap:nowrap;
  overflow-x:auto;
  overflow-y:hidden;
  margin-left:-15px;
  margin-right:-15px;
  padding-left:15px;
  padding-right:15px;
  scrollbar-width:none
}
.list-horizontal-scroll::-webkit-scrollbar{
  width:0px;
  background:rgba(0,0,0,0)
}
.list-horizontal-scroll__item{
  flex:0 0 auto
}
.list-horizontal-scroll__item+.list-horizontal-scroll__item{
  margin-left:15px
}
.list-horizontal-scroll__item-text{
  font-family:"Kobe", sans-serif;
  font-weight:600;
  cursor:pointer;
  color:#2D4C5D;
  padding:4px 0;
  display:block;
  position:relative
}
@media(min-width: 769px){
  .list-horizontal-scroll__item-text{
    font-size:1.25rem;
    padding:8px 0
  }
}
.list-horizontal-scroll__item-text:before{
  content:" ";
  border:2px solid #FF6B07;
  width:100%;
  position:absolute;
  bottom:-1px;
  left:0;
  display:none
}
.list-horizontal-scroll__item-text.active:before{
  display:block
}
@media(min-width: 769px){
  .list-horizontal-scroll.list-boat-icons{
    margin-left:15px;
    margin-right:15px
  }
}
.list-horizontal-scroll--order{
  margin:0;
  padding-left:0;
  padding-right:0
}
.list-horizontal-scroll--order .list-horizontal-scroll__item+.list-horizontal-scroll__item{
  margin-left:24px
}
.list-horizontal-scroll--order .list-horizontal-scroll__item-text{
  font-size:16px
}
@media(min-width: 769px){
  .list-horizontal-scroll--order .list-horizontal-scroll__item-text{
    font-size:18px
  }
}
.list-horizontal-scroll--order .list-horizontal-scroll__item-text.active{
  border-bottom:2px solid #2ec4dd
}
.list-horizontal-scroll--tab{
  margin:0;
  padding-left:0;
  padding-right:0
}
.list-horizontal-scroll--tab .list-horizontal-scroll__item+.list-horizontal-scroll__item{
  margin-left:0
}
.list-horizontal-scroll--tab .list-horizontal-scroll__item-text{
  padding-left:12px;
  padding-right:12px;
  border-bottom:1px solid #dfdfdf;
  color:#2D4C5D;
  font-family:"Kobe", sans-serif
}
.list-horizontal-scroll--country{
  margin-bottom:-1px;
  padding-left:8px;
  padding-right:8px
}
@media(min-width: 769px){
  .list-horizontal-scroll--country{
    padding-left:15px;
    padding-right:15px
  }
}
.list-horizontal-scroll--country .list-horizontal-scroll__item{
  width:52px;
  text-align:center
}
@media(min-width: 769px){
  .list-horizontal-scroll--country .list-horizontal-scroll__item{
    width:auto
  }
}
.list-horizontal-scroll--country .list-horizontal-scroll__item+.list-horizontal-scroll__item{
  margin-left:4px
}
@media(min-width: 769px){
  .list-horizontal-scroll--country .list-horizontal-scroll__item+.list-horizontal-scroll__item{
    margin-left:32px
  }
}
.list-horizontal-scroll--country .list-horizontal-scroll__item-text{
  color:#6a6a6a;
  font-size:10px;
  width:54px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
@media(min-width: 769px){
  .list-horizontal-scroll--country .list-horizontal-scroll__item-text{
    font-size:14px;
    width:auto;
    overflow:visible;
    text-overflow:unset;
    white-space:normal
  }
}
.list-horizontal-scroll--country .list-horizontal-scroll__item-text:hover{
  color:#414141
}
.list-horizontal-scroll--country .list-horizontal-scroll__item-text:focus{
  outline:none
}
.list-horizontal-scroll--country .list-horizontal-scroll__item-text.active{
  color:#2ec4dd;
  border:0
}
.list-horizontal-scroll--country .list-horizontal-scroll__img{
  -o-object-fit:cover;
     object-fit:cover;
  width:36px;
  height:36px;
  display:inline-block;
  border-radius:4px
}
@media(min-width: 769px){
  .list-horizontal-scroll--country .list-horizontal-scroll__img{
    display:none
  }
}
.list-horizontal-scroll--country .list-horizontal-scroll__default-img{
  display:inline-block;
  width:36px;
  height:36px;
  border-radius:4px;
  background:#2ec4dd;
  text-transform:uppercase;
  color:#fff;
  font-weight:600;
  line-height:37px;
  text-align:center
}
@media(min-width: 769px){
  .list-horizontal-scroll--country .list-horizontal-scroll__default-img{
    display:none
  }
}
.list-horizontal-scroll--fixed-padding-bottom{
  margin-bottom:-16px
}
.list-horizontal-scroll--corporate .list-horizontal-scroll__item-text:hover{
  color:#2ec4dd;
  text-decoration:none
}
.list-horizontal-scroll--corporate .list-horizontal-scroll__item-text.active{
  color:#2ec4dd;
  font-weight:600;
  border-bottom:0
}
.list-blog{
  display:flex;
  flex-wrap:nowrap;
  overflow-x:auto;
  overflow-y:hidden;
  margin-left:-15px;
  margin-right:-15px;
  padding:0 7px;
  scrollbar-width:none
}
.list-blog::-webkit-scrollbar{
  width:0px;
  background:rgba(0,0,0,0)
}
@media(min-width: 769px){
  .list-blog{
    padding:0
  }
}
.list-blog__item{
  flex:0 0 auto;
  width:220px;
  height:280px;
  padding:0 8px
}
@media(min-width: 769px){
  .list-blog__item{
    padding:0 16px;
    width:260px;
    height:320px
  }
}
.list-blog__item-inner{
  position:relative;
  height:100%
}
.list-blog__img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:100%;
  opacity:1;
  transition:opacity .2s ease-in-out;
  border-radius:4px
}
.list-blog__item:hover .list-blog__img{
  opacity:.7
}
.list-blog__overlay{
  position:absolute;
  width:100%;
  padding:16px 12px;
  background-color:rgba(65,65,65,.4);
  left:0;
  bottom:0;
  border-bottom-left-radius:4px;
  border-bottom-right-radius:4px
}
.list-blog__type{
  text-transform:uppercase;
  font-size:14px;
  font-weight:600;
  line-height:1;
  margin-bottom:8px
}
.list-blog__title{
  font-weight:600;
  font-size:14px;
  color:#fff;
  margin-bottom:0
}
@media(min-width: 769px){
  .list-blog__title{
    font-size:16px
  }
}
.start-point-map .modal-body{
  overflow-y:auto;
  height:100%
}
@media(min-width: 769px){
  .start-point-map .modal-body{
    height:465px;
    overflow-y:visible
  }
}
.start-point-map img{
  background-color:rgba(0,0,0,0)
}
.start-point-map__detail{
  padding-top:64px
}
@media(min-width: 769px){
  .start-point-map__detail{
    padding-top:0;
    padding-left:32px;
    padding-right:32px;
    overflow-y:auto;
    height:100%
  }
}
.accommodation-map__box,.start-point-map__box{
  position:absolute;
  z-index:30;
  top:-12px;
  left:50%;
  transform:translateX(-50%);
  max-width:79%;
  width:100%;
  text-align:center;
  margin:0 auto;
  padding:8px;
  background:#fff;
  border:2px solid #E4E480;
  border-radius:30px
}
@media(min-width: 769px){
  .accommodation-map__box,.start-point-map__box{
    left:17px;
    transform:none;
    top:auto;
    bottom:23px
  }
}
@media(min-width: 769px){
  .start-point-map__row{
    height:100%
  }
}
.accommodation-map__container{
  height:320px
}
@media(min-width: 769px){
  .accommodation-map__container{
    height:480px
  }
}
@media(min-width: 769px){
  .accommodation-map__box{
    left:50%;
    transform:translateX(-50%);
    bottom:16px
  }
}
.start-point-map__block{
  position:relative;
  border:2px solid #E4E480;
  border-radius:10px;
  height:260px
}
@media(min-width: 769px){
  .start-point-map__block{
    height:400px
  }
}
.start-point-map__block .start-point-map__box{
  top:auto;
  bottom:20px
}
@media(min-width: 769px){
  .start-point-map__block .start-point-map__box{
    left:50%;
    transform:translateX(-50%)
  }
}
.media .media-trip-img{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3;
  width:120px;
  min-height:100%;
  margin-right:8px
}
@media(min-width: 769px){
  .media .media-trip-img{
    width:300px;
    margin-right:16px
  }
}
.media .media-boat{
  margin-right:10px
}
@media(min-width: 769px){
  .media .media-boat{
    align-self:center;
    margin-right:20px
  }
}
.minimal-option .navbar.navbar-primary{
  top:0;
  border-bottom:1px solid #dfdfdf
}
.minimal-option #content{
  padding-top:66px
}
.modal-content{
  border-radius:20px;
  background-color:#F8F3E3
}
.modal-header,.modal-footer{
  padding:16px 24px;
  border-color:#D9D9D9
}
.modal-body{
  padding:24px
}
@media(min-width: 576px){
  .modal-dialog.modal-dialog--lg{
    max-width:560px
  }
}
@media(min-width: 992px){
  .modal-dialog.modal-dialog--xl{
    max-width:992px
  }
}
.modal-dialog.modal-full-width{
  margin:0;
  max-width:100%;
  max-height:100%
}
@media(min-width: 992px){
  .modal-dialog.modal-full-width{
    margin:28px;
    max-height:calc(100% - 3.5rem)
  }
}
.modal-dialog.modal-full-width .modal-content{
  border-radius:0;
  max-height:100%
}
@media(min-width: 992px){
  .modal-dialog.modal-full-width .modal-content{
    border-radius:20px;
    max-height:calc(100% - 3.5rem)
  }
}
@media(max-width: 768px){
  .modal-dialog.modal-full-width--sp{
    margin:0;
    max-width:100%;
    max-height:100%;
    height:100%
  }

  .modal-dialog.modal-full-width--sp .modal-content{
    border-radius:0;
    height:100%
  }
}
.modal-dialog.modal-full-width--sp .modal-content{
  max-height:100%
}
.modal-dialog.modal-dialog-bottom{
  display:flex;
  flex-direction:column;
  justify-content:end;
  min-height:100%;
  height:100%
}
@media(max-width: 768px){
  .modal-dialog.modal-almost-full-height--sp{
    margin:0;
    max-width:100%;
    max-height:80%
  }

  .modal-dialog.modal-almost-full-height--sp .modal-content{
    border-radius:0;
    max-height:80%
  }
}
.modal.modal-styled{
  padding:0 !important
}
@media(min-width: 769px){
  .modal.modal-styled{
    padding:0 15px !important
  }
}
.modal.modal-styled .modal-dialog{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:flex-end;
  max-width:100%;
  height:100%;
  margin:0
}
@media(min-width: 769px){
  .modal.modal-styled .modal-dialog{
    justify-content:center;
    max-width:800px;
    height:calc(100vh - 56px);
    margin:28px auto
  }
}
.modal.modal-styled .modal-content{
  max-height:calc(100vh - 140px);
  border-top-right-radius:10px;
  border-top-left-radius:10px;
  border-bottom-left-radius:0;
  border-bottom-right-radius:0
}
@media(min-width: 769px){
  .modal.modal-styled .modal-content{
    max-height:calc(100vh - 56px);
    border-radius:10px
  }
}
.modal.modal-styled .modal-header{
  border:0;
  padding:16px 24px;
  border-bottom:1px solid #D9D9D9;
  margin-bottom:0
}
@media(min-width: 769px){
  .modal.modal-styled .modal-header{
    padding:24px 40px
  }
}
.modal.modal-styled .modal-footer{
  padding:16px 20px
}
@media(min-width: 769px){
  .modal.modal-styled .modal-footer{
    padding:24px 40px
  }
}
.modal.modal-styled .modal-body{
  padding:24px 20px;
  overflow-x:hidden;
  overflow-y:auto
}
@media(min-width: 769px){
  .modal.modal-styled .modal-body{
    padding:32px 40px
  }
}
@media(min-width: 769px){
  .modal.modal-styled.modal-styled-sm .modal-dialog{
    max-width:600px
  }
}
.modal.modal-styled.modal-styled-sm .modal-content{
  max-height:600px
}
.modal.modal-styled.modal-styled-center .modal-dialog{
  justify-content:center;
  margin:0 20px
}
@media(min-width: 769px){
  .modal.modal-styled.modal-styled-center .modal-dialog{
    justify-content:center;
    margin:28px auto
  }
}
.modal.modal-styled.modal-styled-center .modal-content{
  border-radius:10px
}
@media(min-width: 769px){
  .modal.modal-styled.modal-styled-center .modal-content{
    border-radius:10px
  }
}
.modal.modal-styled.modal-styled-center .modal-header{
  border-bottom:0
}
@media(min-width: 769px){
  .modal.modal-styled.modal-styled-center .modal-header{
    border-bottom:0
  }
}
.modal-zindex-1051{
  z-index:1051
}
.navbar{
  padding:8px
}
@media(min-width: 769px){
  .navbar{
    padding:8px 16px
  }
}
.navbar.navbar-primary{
  font-size:16px;
  width:100%
}
.sidenav{
  position:fixed;
  z-index:100;
  top:52px;
  left:0;
  width:100%;
  height:calc(100% - 52px);
  background-color:rgba(0,0,0,0);
  display:none
}
.sidenav.active{
  display:block
}
.sidenav__inner{
  position:relative;
  height:100%
}
.sidenav__content{
  width:100%;
  height:100%;
  background-color:#F8F3E3;
  overflow-y:auto
}
@media(min-width: 375px){
  .sidenav__content{
    width:100%
  }
}
@media(min-width: 576px){
  .sidenav__content{
    width:100%
  }
}
.sidenav__header{
  position:absolute;
  z-index:10;
  top:0;
  left:0;
  background-color:#fff;
  width:280px
}
@media(min-width: 375px){
  .sidenav__header{
    width:320px
  }
}
@media(min-width: 576px){
  .sidenav__header{
    width:calc(100% - 54px)
  }
}
.sidenav__header .nav-tabs .nav-item{
  width:50%;
  text-align:center;
  position:relative
}
.sidenav__header .nav-tabs .nav-item a{
  color:#9f9f9f;
  text-transform:uppercase
}
.sidenav__header .nav-tabs .nav-item:first-child:after{
  content:" ";
  position:absolute;
  top:10px;
  right:0;
  height:16px;
  width:1px;
  background-color:#dfdfdf
}
.sidenav__header .nav-tabs .nav-link.active,.sidenav__header .nav-tabs .nav-item.show .nav-link{
  border-color:rgba(0,0,0,0);
  border-bottom-color:#414141;
  color:#414141
}
.sidenav__footer{
  position:absolute;
  z-index:10;
  bottom:0;
  left:0;
  background-color:#fff;
  width:280px
}
@media(min-width: 375px){
  .sidenav__footer{
    width:320px
  }
}
@media(min-width: 576px){
  .sidenav__footer{
    width:calc(100% - 54px)
  }
}
.sidenav__footer-text{
  font-weight:600;
  font-size:14px;
  margin-right:4px
}
@media(min-width: 375px){
  .sidenav__footer-text{
    font-size:16px;
    margin-right:8px
  }
}
.sidenav__footer span{
  white-space:nowrap
}
.sidenav__footer select{
  width:80px;
  border:1px solid #dfdfdf
}
.sidenav__media{
  display:flex;
  background:#f3f3f3;
  border-radius:4px;
  align-items:center
}
.sidenav__media img{
  flex-shrink:0;
  -o-object-fit:cover;
     object-fit:cover;
  width:110px;
  height:70px;
  border-top-right-radius:4px;
  border-bottom-right-radius:4px
}
.sidenav__media--white{
  background:#fff;
  border:1px solid #dfdfdf
}
.sidenav__media--reverse img{
  width:160px;
  height:100px;
  border-top-right-radius:0;
  border-top-left-radius:4px;
  border-bottom-right-radius:0;
  border-bottom-left-radius:4px
}
.sidenav__media+.sidenav__media{
  margin-top:16px
}
.sidenav__media-title{
  flex-grow:1;
  text-align:center;
  font-weight:600;
  color:#414141
}
.sidenav.sidenav-rtl{
  left:auto;
  right:0;
  top:0;
  transform:translateX(100%);
  height:100%;
  z-index:1051
}
.sidenav.sidenav-rtl.active{
  transform:translateX(0);
  background-color:rgba(65,65,65,.5)
}
.sidenav.sidenav-rtl .sidenav__btn{
  position:absolute;
  top:16px;
  left:8px
}
@media(min-width: 375px){
  .sidenav.sidenav-rtl .sidenav__btn{
    left:12px
  }
}
.sidenav.sidenav-rtl .sidenav__content{
  width:300px;
  position:absolute;
  top:0;
  right:0
}
.corp-nav-link{
  font-size:16px;
  color:#414141
}
.corp-nav-link.active,.corp-nav-link:hover{
  color:#2ec4dd
}
.corp-nav-link.active{
  font-weight:600
}
.corp-nav-link+.corp-nav-link{
  margin-left:32px
}
.navbar.navbar-primary.corp-sub-nav{
  top:66px;
  height:48px
}
@media(min-width: 769px){
  .navbar.navbar-primary.corp-sub-nav{
    display:none
  }
}
.notice-box{
  padding:24px;
  background-color:#effbfd;
  border-top:4px solid #fdaa00;
  border-left:0;
  border-radius:4px
}
@media(min-width: 769px){
  .notice-box{
    border-top:0;
    border-left:4px solid #fdaa00;
    border-radius:0
  }
}
.notice-box--grey{
  background-color:#f3f3f3
}
.notice-box--red{
  background-color:#fff;
  border-top-color:#ff5055
}
@media(min-width: 769px){
  .notice-box--red{
    border-left:4px solid #ff5055
  }
}
.option-datepicker{
  width:100%
}
@media(min-width: 769px){
  .option-datepicker{
    width:462px
  }
}
.option-datepicker__input .form-control{
  font-size:14px;
  height:40px;
  line-height:20px;
  border-radius:10px
}
.option-datepicker__input .input-group-text{
  border-radius:10px;
  width:44px
}
.option-datepicker__btn-clear{
  padding-top:4px;
  margin-left:16px;
  font-size:14px;
  text-decoration:underline;
  line-height:1;
  cursor:pointer
}
.option-datepicker__footer{
  border-top:1px solid #dfdfdf;
  position:absolute;
  z-index:10;
  bottom:0;
  left:0;
  width:100%;
  padding:8px 16px
}
.page-nav{
  background-color:#fff;
  display:none;
  height:0;
  padding:0;
  border-bottom:0
}
.page-nav__list{
  padding-left:0;
  list-style:none;
  margin-bottom:0;
  padding:0;
  display:flex;
  justify-content:start;
  white-space:nowrap;
  overflow-x:auto
}
@media(min-width: 769px){
  .page-nav__list{
    justify-content:center
  }
}
.page-nav__item{
  display:inline-block;
  position:relative
}
.page-nav__item+.page-nav__item{
  margin-left:32px
}
.page-nav__item+.page-nav__item:before{
  content:"";
  border-radius:50%;
  width:4px;
  height:4px;
  background-color:#fff;
  position:absolute;
  top:11px;
  left:-17px
}
.page-nav__link{
  cursor:pointer;
  font-family:"Kobe", sans-serif;
  color:#fff;
  font-size:1rem;
  font-weight:600
}
.page-nav__link:hover,.page-nav__link.active{
  color:#FF6B07
}
.pagination{
  align-items:center
}
.pagination__item{
  display:inline-block;
  color:#fff;
  font-size:16px;
  line-height:1;
  width:32px;
  height:32px;
  line-height:32px;
  text-align:center
}
.pagination__item i{
  font-size:20px
}
.pagination__item.current{
  background-color:#2D4C5D;
  color:#fff;
  border-radius:50%
}
.pagination__item.gap{
  color:#2D4C5D
}
.pagination__item.prev,.pagination__item.next{
  border:1px solid #2D4C5D;
  border-radius:50%;
  color:#2D4C5D
}
.pagination__item.prev svg,.pagination__item.next svg{
  height:100%;
  vertical-align:top
}
.pagination__item+.pagination__item{
  margin-left:15px
}
.pagination--big-font-size .pagination__item{
  font-size:20px
}
.popup-action{
  background:#fff;
  width:180px;
  position:absolute;
  z-index:100;
  top:49px;
  right:-20px;
  left:auto;
  transform:none;
  padding:12px 0;
  border:1px solid #dfdfdf;
  border-radius:4px;
  display:none
}
.popup-action:before{
  width:0;
  height:0;
  content:"";
  z-index:2;
  border-bottom:15px solid #dfdfdf;
  border-left:10px solid rgba(0,0,0,0);
  border-right:10px solid rgba(0,0,0,0);
  position:absolute;
  right:27px;
  left:auto;
  transform:none;
  top:-15px
}
.popup-action:after{
  width:0;
  height:0;
  content:"";
  z-index:2;
  border-bottom:13.5px solid #fff;
  border-left:9px solid rgba(0,0,0,0);
  border-right:9px solid rgba(0,0,0,0);
  position:absolute;
  right:28px;
  left:auto;
  transform:none;
  top:-14px
}
@media(min-width: 992px){
  .popup-action{
    top:53px;
    right:auto;
    left:50%;
    transform:translateX(-50%)
  }

  .popup-action:before{
    right:auto;
    left:50%;
    transform:translateX(-50%);
    top:-15px
  }

  .popup-action:after{
    right:auto;
    left:50%;
    transform:translateX(-50%);
    top:-14px
  }
}
.popup-action.popup-action--edge-right{
  left:auto;
  transform:none;
  right:0
}
.popup-action.popup-action--edge-right:before{
  right:7px;
  left:auto;
  top:-15px;
  transform:none
}
.popup-action.popup-action--edge-right:after{
  right:8px;
  left:auto;
  top:-13px;
  transform:none
}
@media(min-width: 992px){
  .popup-action.popup-action--edge-right:after{
    top:-14px
  }
}
.popup-action.active{
  display:block
}
.popup-action a{
  padding:4px 16px;
  display:inline-block
}
.popup-action a:hover{
  background-color:#f3f3f3
}
.popover{
  background-color:#F8F3E3;
  color:#2D4C5D;
  border-radius:10px;
  box-shadow:2px 2px 2px 0px rgba(0,0,0,.25)
}
.popover-body{
  color:#2D4C5D
}
.popover-no-arrow .arrow{
  display:none !important
}
.bs-popover-top>.arrow::after,.bs-popover-auto[x-placement^=top]>.arrow::after{
  border-top-color:#F8F3E3
}
.referral-link{
  display:flex;
  align-items:center;
  background-color:#fff;
  max-width:600px;
  margin:0 auto 24px
}
.referral-link__input{
  display:block;
  background-color:#fff;
  outline:none;
  padding:16px;
  color:#414141;
  font-size:14px;
  border:0;
  flex-grow:1
}
@media(min-width: 576px){
  .referral-link__input{
    font-size:16px
  }
}
.referral-link__btn{
  font-weight:600;
  color:#414141;
  padding-right:16px;
  margin-left:auto;
  cursor:pointer
}
.referral-link__icon{
  color:#414141
}
.referral-link--light{
  background-color:#f3f3f3
}
.referral-link--light .referral-link__input{
  background-color:#f3f3f3;
  color:#414141
}
.referral-link--white{
  background-color:#fff
}
.referral-link--white .referral-link__input{
  background-color:#fff;
  color:#414141
}
.referral-link--yellow .referral-link__btn,.referral-link--light .referral-link__btn{
  color:#414141
}
.referral-link--yellow .referral-link__icon,.referral-link--light .referral-link__icon{
  color:#414141
}
.review__header{
  margin-bottom:16px
}
@media(min-width: 769px){
  .review__header{
    margin-bottom:24px
  }
}
.review__score{
  font-size:18px;
  font-weight:600;
  color:#414141;
  line-height:1;
  margin-bottom:10px;
  vertical-align:top
}
@media(min-width: 769px){
  .review__score{
    font-size:20px
  }
}
.review__body{
  padding:15px 0;
  margin:15px 0;
  border-bottom:1px solid #dfdfdf
}
.review__item+.review__item{
  margin-top:32px;
  padding-top:32px;
  border-top:1px solid #D9D9D9
}
@media(min-width: 769px){
  .review__item+.review__item{
    margin-top:32px;
    padding-top:0;
    border-top:0
  }
}
.review__item--google{
  border-top:1px solid #dfdfdf;
  padding-top:20px
}
.review__item--google:first-child{
  padding-top:24px
}
@media(min-width: 769px){
  .review__item--google{
    padding-top:30px
  }

  .review__item--google:first-child{
    padding-top:48px
  }
}
.review__item+.review__item--google{
  margin-top:20px
}
@media(min-width: 769px){
  .review__item+.review__item--google{
    margin-top:30px
  }
}
.review__item-date{
  color:#6a6a6a
}
.review__item-header{
  margin-bottom:10px
}
.review__item-reviewer{
  font-weight:600;
  margin-right:10px;
  display:inline-block
}
.review__item-star{
  display:inline-block
}
.review__item-feedback{
  margin-top:8px
}
.review__item-option{
  margin-bottom:8px;
  color:#6a6a6a;
  font-weight:600;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
.review__item-content p{
  margin-bottom:8px
}
.review-star{
  fill:#fff;
  color:#FF6B07;
  cursor:pointer;
  transition:all .2s ease-in-out
}
.review-star:hover,.review-star.active{
  fill:#FF6B07
}
.review-star-no-hover{
  fill:#fff;
  color:#FF6B07;
  cursor:pointer;
  transition:all .2s ease-in-out
}
.review-star-no-hover.active{
  fill:#FF6B07
}
.review-like-reason{
  padding:8px;
  border:1px solid #dfdfdf;
  border-radius:1px;
  text-align:center;
  transition:all .2s ease-in-out;
  cursor:pointer;
  font-size:12px
}
@media(min-width: 375px){
  .review-like-reason{
    font-size:14px
  }
}
@media(min-width: 769px){
  .review-like-reason{
    font-size:16px
  }
}
.review-like-reason:hover{
  background-color:#f3f3f3
}
.review-like-reason.selected{
  color:#fff;
  font-weight:600;
  background-color:#2ec4dd;
  border-color:#2ec4dd
}
.review-board{
  border:1px solid #dfdfdf;
  padding:24px;
  align-items:center;
  display:block;
  background-color:#fff;
  border-radius:10px
}
@media(min-width: 769px){
  .review-board{
    display:flex
  }
}
.review-board__score{
  max-width:120px;
  margin:0 auto;
  margin-bottom:16px
}
@media(min-width: 769px){
  .review-board__score{
    margin:0;
    margin-right:32px
  }
}
.review-board__score-badge{
  text-align:center;
  padding:12px 8px;
  background-color:#2D4C5D;
  margin-bottom:8px
}
.review-board__score-cap{
  color:#E4E480
}
.review-board__score-average{
  font-size:28px;
  color:#E4E480;
  font-weight:600;
  line-height:1
}
.review-board__chart{
  flex-grow:1
}
.review-board__chart-item{
  display:flex;
  align-items:center
}
.review-board__chart-item+.review-board__chart-item{
  margin-top:12px
}
.review-board__chart-label{
  width:90px;
  font-weight:600;
  color:#9f9f9f;
  line-height:1
}
.review-board__chart-num{
  width:40px;
  line-height:1;
  text-align:right;
  font-weight:600;
  color:#2D4C5D
}
.review-board__chart-line{
  flex-grow:1;
  height:10px;
  background-color:#f3f3f3;
  border-radius:16px;
  position:relative
}
.review-board__chart-filled-line{
  background-color:#fdaa00;
  border-radius:16px;
  height:10px;
  width:0;
  position:absolute;
  top:0;
  left:0
}
.ribbon{
  position:absolute;
  width:128px;
  height:24px;
  line-height:26px;
  padding-left:11px;
  left:-10px;
  top:-11px;
  font-size:14px;
  font-weight:600;
  border-top-right-radius:8px;
  border-bottom-right-radius:8px;
  box-shadow:1px 2px 1px rgba(0,0,0,.32);
  display:flex;
  align-items:center
}
.ribbon:before{
  content:" ";
  position:absolute;
  height:0;
  width:0;
  bottom:-10px;
  left:0;
  border-top:10px solid #25a9bf;
  border-left:10px solid rgba(0,0,0,0)
}
@media(min-width: 375px){
  .ribbon{
    width:158px
  }
}
@media(min-width: 769px){
  .ribbon{
    width:140px;
    height:30px;
    line-height:30px;
    left:-26px;
    top:-14px;
    font-size:14px;
    text-align:center
  }
}
.ribbon--primary{
  background:#4a5ced;
  color:#fff
}
.ribbon--primary:before{
  border-top-color:#1c32e8
}
.ribbon__inner{
  position:relative
}
.ribbon-star{
  position:absolute;
  top:-6px;
  right:4px;
  text-align:center;
  border-top-left-radius:3px;
  background:#fdaa00;
  padding:2px 0;
  width:20px
}
@media(min-width: 769px){
  .ribbon-star{
    right:12px
  }
}
.ribbon-star--static{
  position:relative;
  display:inline-block;
  vertical-align:top;
  margin-left:16px
}
.ribbon-star--static:before{
  display:none
}
.ribbon-star--static:after{
  height:0;
  width:0;
  bottom:-4px;
  right:0;
  border-left:8px solid #fdaa00;
  border-right:8px solid #fdaa00;
  border-bottom:4px solid rgba(0,0,0,0)
}
.ribbon-star svg{
  width:16px;
  height:16px
}
.ribbon-star:before,.ribbon-star:after{
  content:"";
  position:absolute
}
.ribbon-star:before{
  height:0;
  width:0;
  left:-5px;
  top:0;
  border-bottom:6px solid #ca8800;
  border-left:6px solid rgba(0,0,0,0)
}
.ribbon-star:after{
  height:0;
  width:0;
  bottom:-4px;
  right:0;
  border-left:10px solid #fdaa00;
  border-right:10px solid #fdaa00;
  border-bottom:4px solid rgba(0,0,0,0)
}
.ribbon-rec{
  position:absolute;
  top:-12px;
  left:0;
  width:100%;
  font-size:14px;
  line-height:1;
  font-weight:600;
  text-align:center;
  border-radius:4px;
  box-shadow:1px 2px 1px rgba(0,0,0,.32);
  padding:4px 0
}
@media(min-width: 769px){
  .ribbon-rec{
    font-size:16px
  }
}
.ribbon-rec--primary{
  color:#fff;
  background-color:#2ec4dd
}
.ribbon-rec--secondary{
  color:#fff;
  background-color:#4a5ced
}
.ribbon-shield{
  position:absolute;
  z-index:30;
  top:12px;
  left:27px;
  line-height:1;
  border-radius:20px;
  font-size:0.875rem;
  font-weight:600;
  color:#fff;
  font-family:"Kobe", sans-serif;
  background-color:#FF6B07;
  padding:6px 10px;
  text-transform:lowercase
}
@media(min-width: 769px){
  .ribbon-shield{
    padding:5px 10px;
    font-size:1rem
  }
}
.ribbon-shield--yellow{
  background-color:#fdaa00;
  color:#fff
}
.ribbon-shield__img{
  width:16px;
  height:16px;
  margin-right:2px
}
.simple-rich-doc p{
  margin-bottom:0
}
.simple-rich-doc a{
  color:#2D4C5D;
  text-decoration:underline
}
.simple-rich-doc a:hover{
  font-weight:600
}
.simple-rich-doc ul{
  padding-left:20px;
  margin-bottom:0
}
.simple-rich-doc ul>br{
  display:none
}
.simple-rich-doc li{
  margin-bottom:8px
}
.simple-rich-doc span{
  color:#2D4C5D !important
}
.simple-rich-doc img{
  width:100%;
  border:2px solid #E4E480;
  border-radius:10px
}
.select-box__popup{
  position:absolute;
  z-index:100;
  width:200px;
  background-color:#fff;
  top:calc(100% + 4px);
  border:1px solid #dfdfdf;
  display:flex;
  height:100px;
  right:0;
  padding:16px
}
@media(min-width: 769px){
  .select-box__popup{
    right:51px
  }
}
.search-box__popup{
  z-index:100;
  background-color:#fff;
  overflow-y:auto;
  width:100vw;
  height:100%;
  left:0;
  top:0;
  border:0;
  padding:16px;
  position:fixed
}
@media(min-width: 992px){
  .search-box__popup{
    position:absolute;
    top:calc(100% + 4px);
    width:800px;
    height:370px;
    right:auto;
    left:0;
    border:1px solid #dfdfdf;
    padding:24px
  }

  .search-box__popup--hero{
    position:absolute;
    top:calc(100% + 4px);
    width:100%;
    height:370px;
    border:1px solid #dfdfdf;
    padding:24px
  }

  .search-box__popup--select{
    width:200px;
    height:100px;
    right:51px;
    left:auto
  }

  .search-box__popup--date{
    width:400px;
    height:430px;
    right:-249px;
    left:auto
  }
}
.search-box__popup-inner{
  position:relative;
  width:100%;
  height:100%
}
.search-box__nav{
  width:150px;
  flex:0 0 auto;
  display:none;
  flex-direction:column
}
@media(min-width: 769px){
  .search-box__nav{
    display:flex;
    flex-wrap:nowrap;
    overflow-x:hidden;
    overflow-y:auto
  }
}
.search-box__nav-item{
  flex:1 0 auto
}
.search-box__nav-item-inner{
  padding:16px 0;
  text-align:center;
  border-right:1px solid #dfdfdf;
  font-weight:600;
  color:#414141;
  position:relative;
  height:100%;
  cursor:pointer;
  display:block
}
.search-box__nav-item-inner:hover,.search-box__nav-item-inner.active{
  background-color:#2ec4dd;
  color:#fff;
  border-right-color:#2ec4dd
}
.search-box__nav-item-inner:hover:before,.search-box__nav-item-inner.active:before{
  width:0;
  height:0;
  content:"";
  z-index:2;
  border-left:9px solid #2ec4dd;
  border-bottom:6px solid rgba(0,0,0,0);
  border-top:6px solid rgba(0,0,0,0);
  position:absolute;
  right:-10px;
  top:22px
}
.search-box__nav-item+.search-box__nav-item{
  border-top:1px solid #dfdfdf
}
.search-box__result{
  width:100%;
  color:#2D4C5D
}
.search-box__result+.search-box__result{
  border-top:1px solid #D9D9D9;
  margin-top:16px;
  padding-top:16px
}
.search-box__result-item{
  padding:8px 0;
  font-size:14px;
  display:flex;
  align-items:center
}
.search-box__result-item-img{
  flex:0 0 auto;
  -o-object-fit:cover;
     object-fit:cover;
  width:52px;
  height:44px;
  border-radius:10px;
  margin-right:16px
}
.search-box__result-item-title{
  color:#2D4C5D
}
.search-box__result-item-subtitle{
  color:#ABABAB
}
.search-box__result-item-icon{
  border-radius:50%;
  width:64px;
  height:64px;
  line-height:64px;
  background:#E4E480;
  margin-right:20px;
  margin-left:4px;
  text-align:center
}
.search-box__result-item-icon img{
  width:24px;
  height:auto
}
.search-box__body{
  height:calc(100% - 213px);
  overflow-y:auto;
  display:flex;
  flex-direction:column
}
@media(min-width: 992px){
  .search-box__body{
    height:100%
  }
}
.search-box__footer{
  position:absolute;
  width:100%;
  left:0;
  bottom:-2px
}
@media(min-width: 992px){
  .search-box__footer{
    display:none
  }
}
.search-box__input{
  height:48px;
  border-radius:8px;
  border-right:0
}
.search-box__btn-clear{
  cursor:pointer
}
.search-box__btn-clear.active svg{
  display:inline-block
}
.search-box__btn-clear svg{
  display:none
}
.search-box__popup .tab-content>.tab-pane{
  display:block
}
@media(min-width: 769px){
  .search-box__popup .tab-content>.tab-pane{
    display:none
  }
}
@media(min-width: 769px){
  .search-box__popup .tab-content>.active{
    display:block
  }
}
.search-box__popup .fade:not(.show){
  opacity:1
}
@media(min-width: 769px){
  .search-box__popup .fade:not(.show){
    opacity:0
  }
}
.stacked-input-group__item{
  display:flex
}
.stacked-input-group__item:first-child .input-group-text{
  border-top-left-radius:8px
}
.stacked-input-group__item:first-child .form-control{
  border-top-right-radius:8px
}
.stacked-input-group__item:last-child .input-group:first-child .input-group-text{
  border-bottom-left-radius:8px
}
.stacked-input-group__item:last-child .input-group:last-child .input-group-text{
  border-left:0
}
.stacked-input-group__item:last-child .input-group:last-child .form-control{
  border-bottom-right-radius:8px
}
.stacked-input-group__item .input-group.selected svg{
  color:#2ec4dd
}
.stacked-input-group__item .input-group.selected input{
  color:#2ec4dd
}
.stacked-input-group__item .form-control,.stacked-input-group__item .input-group-text{
  padding-top:11px;
  padding-bottom:11px
}
.stacked-input-group__item .form-control{
  height:48px
}
.stacked-input-group__item+.stacked-input-group__item{
  margin-top:-1px
}
.section{
  padding-top:80px;
  padding-bottom:80px
}
@media(min-width: 769px){
  .section{
    padding-top:96px;
    padding-bottom:96px
  }
}
.section.section-blog{
  padding-top:32px;
  padding-bottom:32px
}
@media(min-width: 769px){
  .section.section-blog{
    padding-top:64px;
    padding-bottom:64px
  }
}
.section--next-to-footer{
  margin-bottom:-32px
}
.section__title{
  font-weight:600;
  font-size:24px;
  line-height:1.4;
  margin-bottom:32px
}
@media(min-width: 769px){
  .section__title{
    font-size:32px;
    margin-bottom:48px
  }
}
.section__side-title{
  text-align:left;
  margin-bottom:16px
}
.section__tagline{
  text-align:center
}
.section.travel-better{
  padding-bottom:24px;
  margin-top:160px
}
@media(min-width: 769px){
  .section.travel-better{
    padding-bottom:48px;
    margin-top:96px
  }
}
.section.travel-better img{
  margin-top:-166px
}
@media(min-width: 769px){
  .section.travel-better img{
    margin-top:-48px
  }
}
.section__img{
  width:auto;
  max-height:300px
}
@media(min-width: 769px){
  .section__img{
    max-height:480px
  }
}
.slick-arrow{
  position:absolute;
  z-index:10;
  top:50%;
  transform:translateY(-50%);
  color:#fff;
  font-weight:600;
  line-height:24px;
  font-size:18px;
  display:inline-block;
  width:24px;
  height:24px;
  text-align:center;
  cursor:pointer
}
.slick-prev{
  left:10px
}
.slick-next{
  right:10px
}
.slick-prev,.slick-next{
  width:48px;
  height:48px;
  font-size:24px;
  line-height:42px;
  background-color:#D9D9D9;
  border-radius:50%;
  transition:all .2s ease-in-out
}
.slick-prev:hover svg,.slick-next:hover svg{
  color:#2D4C5D
}
.slick-prev.slick-disabled,.slick-next.slick-disabled{
  display:none !important
}
.slick-prev svg,.slick-next svg{
  color:#2D4C5D;
  width:32px;
  height:32px
}
.slick-prev{
  left:5px
}
@media(min-width: 992px){
  .slick-prev{
    left:0
  }
}
.slick-next{
  right:5px
}
@media(min-width: 992px){
  .slick-next{
    right:0
  }
}
.slick-dots{
  position:absolute;
  width:100%;
  left:0;
  bottom:15px;
  padding:0;
  margin:0;
  text-align:center
}
.slick-dots li{
  font-size:0;
  display:inline-block;
  border:1px solid #D9D9D9;
  border-radius:50%;
  padding:4px;
  transition:all .2s ease-in-out;
  background-color:#D9D9D9
}
.slick-dots li.slick-active{
  border-color:#A9B3D3;
  background-color:#A9B3D3
}
.slick-dots li button{
  display:none
}
.slick-dots li+li{
  margin-left:10px
}
.slider-v2 .slick-arrow{
  position:absolute;
  z-index:10;
  top:auto;
  bottom:-60px;
  transform:translateX(-50%);
  color:#fff;
  line-height:28px;
  display:inline-block;
  width:28px;
  height:28px;
  text-align:center;
  cursor:pointer
}
.slider-v2 .slick-prev,.slider-v2 .slick-next{
  border-radius:unset;
  width:28px;
  height:28px;
  box-shadow:none;
  font-size:28px;
  line-height:34px;
  background-color:rgba(0,0,0,0)
}
.slider-v2 .slick-prev:hover svg,.slider-v2 .slick-next:hover svg{
  color:#2D4C5D
}
.slider-v2 .slick-prev.slick-disabled,.slider-v2 .slick-next.slick-disabled{
  opacity:.7;
  display:block !important;
  cursor:default
}
.slider-v2 .slick-prev.slick-disabled svg,.slider-v2 .slick-next.slick-disabled svg{
  color:#2D4C5D
}
.slider-v2 .slick-prev.slick-disabled svg:hover,.slider-v2 .slick-next.slick-disabled svg:hover{
  color:#2D4C5D
}
.slider-v2 .slick-prev svg,.slider-v2 .slick-next svg{
  color:#2D4C5D;
  width:28px;
  height:28px
}
.slider-v2 .slick-prev{
  right:auto;
  left:50%;
  margin-left:-31px
}
.slider-v2 .slick-next{
  right:auto;
  left:50%;
  margin-left:10px;
  padding-left:13px;
  text-align:left
}
.slider-v2 .slick-next:before{
  content:" ";
  position:absolute;
  left:0;
  top:1px;
  display:inline-block;
  height:32px;
  border-left:2px solid #fff;
  transform:rotate(35deg)
}
.slider-v2.slider-v2-green-dots .slick-dots li.slick-active{
  border-color:#E4E480;
  background-color:#E4E480
}
.slider-v2.slider-collection-best-tdd .slick-arrow{
  top:48%;
  transform:translateY(-50%)
}
.slider-v2.slider-collection-best-tdd .slick-prev,.slider-v2.slider-collection-best-tdd .slick-next{
  width:48px;
  height:48px;
  font-size:24px;
  line-height:42px;
  background-color:#D9D9D9;
  border-radius:50%;
  transition:all .2s ease-in-out
}
.slider-v2.slider-collection-best-tdd .slick-prev:hover svg,.slider-v2.slider-collection-best-tdd .slick-next:hover svg{
  color:#FF6B07
}
.slider-v2.slider-collection-best-tdd .slick-prev.slick-disabled,.slider-v2.slider-collection-best-tdd .slick-next.slick-disabled{
  display:none !important
}
.slider-v2.slider-collection-best-tdd .slick-prev svg,.slider-v2.slider-collection-best-tdd .slick-next svg{
  color:#2D4C5D;
  width:32px;
  height:32px
}
.slider-v2.slider-collection-best-tdd .slick-prev{
  left:5px;
  right:auto
}
@media(min-width: 992px){
  .slider-v2.slider-collection-best-tdd .slick-prev{
    left:22px
  }
}
.slider-v2.slider-collection-best-tdd .slick-next{
  left:auto;
  right:5px;
  padding-left:10px
}
.slider-v2.slider-collection-best-tdd .slick-next:before{
  display:none
}
@media(min-width: 992px){
  .slider-v2.slider-collection-best-tdd .slick-next{
    right:-4px
  }
}
.slider-v2.slider-collection-best-tdd .slick-dots{
  bottom:-36px
}
.table thead th{
  border-top:0;
  border-bottom:1px solid #dfdfdf
}
.timeline__item{
  display:flex;
  position:relative
}
.timeline__item::before{
  background:#6a6a6a;
  content:"";
  height:calc(100% - 16px);
  left:7px;
  position:absolute;
  top:19px;
  width:2px
}
.timeline__left{
  flex:0 0 auto;
  padding-top:3px
}
.timeline__title{
  font-weight:600;
  margin-bottom:15px
}
.timeline__content{
  flex:1 1 auto;
  padding-left:15px;
  padding-bottom:30px
}
@media(max-width: 769px){
  .timeline__content .media{
    flex-wrap:wrap
  }
}
.timeline__content .media img{
  width:300px;
  height:300px;
  -o-object-fit:cover;
     object-fit:cover;
  margin-right:16px
}
@media(min-width: 769px){
  .timeline__content .media img{
    width:200px;
    height:200px
  }
}
.timeline__icon{
  align-items:center;
  border-radius:50%;
  display:flex;
  justify-content:center;
  text-align:center;
  width:16px;
  height:16px
}
.popover.popover--timepicker .arrow{
  display:none
}
.timepicker{
  display:flex;
  align-items:start
}
.timepicker__input-container{
  position:relative;
  width:100px
}
@media(min-width: 769px){
  .timepicker__input-container{
    width:120px
  }
}
.timepicker__select{
  max-height:200px;
  overflow-y:auto
}
.timepicker__select-item{
  padding:0 2px
}
.timepicker__select-item:hover{
  background-color:#f3f3f3;
  cursor:pointer
}
.timepicker__select-item.active{
  font-weight:600;
  color:#2ec4dd
}
.timepicker__btn-clear{
  position:absolute;
  top:11px;
  right:12px;
  cursor:pointer
}
.toast-preview{
  z-index:100;
  position:fixed;
  bottom:105px;
  background-color:#fdaa00;
  color:#414141;
  font-weight:600;
  text-transform:uppercase;
  text-align:center;
  padding:8px 0;
  width:100%
}
.toast-warning{
  display:flex;
  justify-content:space-between;
  align-items:center;
  border-radius:4px;
  border:1px solid #fdaa00;
  background-color:#fcf8f6;
  padding:8px;
  font-size:12px
}
.tooltip{
  position:absolute;
  z-index:1070;
  display:block;
  margin:0;
  font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style:normal;
  font-weight:400;
  line-height:1.5;
  text-align:left;
  text-align:start;
  text-decoration:none;
  text-shadow:none;
  text-transform:none;
  letter-spacing:normal;
  word-break:normal;
  word-spacing:normal;
  white-space:normal;
  line-break:auto;
  font-size:0.875rem;
  word-wrap:break-word;
  opacity:0
}
.tooltip.show{
  opacity:.9
}
.tooltip .tooltip-arrow{
  position:absolute;
  display:block;
  width:.8rem;
  height:.4rem
}
.tooltip .tooltip-arrow::before{
  position:absolute;
  content:"";
  border-color:rgba(0,0,0,0);
  border-style:solid
}
.bs-tooltip-top,.bs-tooltip-auto[data-popper-placement^=top],.bs-tooltip-auto[x-placement^=top]{
  padding:.4rem 0
}
.bs-tooltip-top .tooltip-arrow,.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,.bs-tooltip-auto[x-placement^=top] .tooltip-arrow{
  bottom:0
}
.bs-tooltip-top .tooltip-arrow::before,.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,.bs-tooltip-auto[x-placement^=top] .tooltip-arrow::before{
  top:-1px;
  border-width:.4rem .4rem 0;
  border-top-color:#f4f4f4
}
.bs-tooltip-end,.bs-tooltip-auto[data-popper-placement^=right]{
  padding:0 .4rem
}
.bs-tooltip-end .tooltip-arrow,.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow{
  left:0;
  width:.4rem;
  height:.8rem
}
.bs-tooltip-end .tooltip-arrow::before,.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before{
  right:-1px;
  border-width:.4rem .4rem .4rem 0;
  border-right-color:#f4f4f4
}
.bs-tooltip-bottom,.bs-tooltip-auto[data-popper-placement^=bottom],.bs-tooltip-auto[x-placement^=bottom]{
  padding:.4rem 0
}
.bs-tooltip-bottom .tooltip-arrow,.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,.bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow{
  top:0
}
.bs-tooltip-bottom .tooltip-arrow::before,.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,.bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow::before{
  bottom:-1px;
  border-width:0 .4rem .4rem;
  border-bottom-color:#f4f4f4
}
.bs-tooltip-start,.bs-tooltip-auto[data-popper-placement^=left]{
  padding:0 .4rem
}
.bs-tooltip-start .tooltip-arrow,.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow{
  right:0;
  width:.4rem;
  height:.8rem
}
.bs-tooltip-start .tooltip-arrow::before,.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before{
  left:-1px;
  border-width:.4rem 0 .4rem .4rem;
  border-left-color:#f4f4f4
}
.tooltip-inner{
  max-width:200px;
  padding:.25rem .5rem;
  color:#fff;
  text-align:center;
  background-color:#000;
  border-radius:1px
}
.tooltip-left .tooltip-inner{
  text-align:left
}
.tooltip-inner{
  max-width:240px
}
.tooltip-inner--lg{
  max-width:400px
}
.bs-tooltip-top .tooltip-arrow::before,.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,.bs-tooltip-top .arrow::before,.bs-tooltip-auto[data-popper-placement^=top] .arrow::before,.bs-tooltip-auto[x-placement^=top] .tooltip-arrow::before,.bs-tooltip-auto[x-placement^=top] .arrow::before{
  border-top-color:#000
}
.tooltip-num-guest{
  z-index:70
}
.tooltip-num-guest.show{
  opacity:1
}
.tooltip-num-guest .tooltip-inner{
  background-color:#2ec4dd;
  max-width:400px;
  padding:12px 16px;
  font-size:12px;
  font-weight:600;
  border-radius:4px
}
@media(min-width: 769px){
  .tooltip-num-guest .tooltip-inner{
    padding:16px 24px;
    font-size:14px
  }
}
.tooltip-num-guest .tooltip-arrow::before{
  border-bottom-color:#2ec4dd
}
.tooltip-price{
  z-index:1070
}
.tooltip-price.show{
  opacity:1
}
.tooltip-price .tooltip-inner{
  background-color:#2ec4dd;
  max-width:320px;
  padding:12px 16px;
  font-size:12px;
  font-weight:600;
  border-radius:4px
}
@media(min-width: 769px){
  .tooltip-price .tooltip-inner{
    padding:16px 24px;
    font-size:14px
  }
}
.tooltip-price .tooltip-arrow::before{
  border-bottom-color:#2ec4dd;
  border-top-color:#2ec4dd
}
.tooltip-price .tooltip-close{
  position:absolute;
  top:4px;
  right:6px;
  color:#fff;
  font-size:24px;
  line-height:1;
  cursor:pointer
}
.tooltip-highlight .tooltip-inner{
  background-color:#414141
}
.tooltip-highlight .tooltip-arrow::before{
  border-bottom-color:#414141
}
.video-container{
  overflow:hidden;
  position:relative;
  width:100%;
  height:240px
}
@media(min-width: 769px){
  .video-container{
    height:400px
  }
}
.video-container iframe{
  width:220%;
  height:220%;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%)
}
.video-container--feature{
  height:200px
}
@media(min-width: 769px){
  .video-container--feature{
    height:320px
  }
}
.video-container--full iframe{
  width:100vw;
  height:100vh;
  position:fixed;
  z-index:1000;
  top:0;
  left:0;
  transform:none
}
.video-container.modal-about-video{
  margin:24px 0
}
@media(min-width: 769px){
  .video-container.modal-about-video{
    width:800px;
    height:600px;
    margin:24px auto
  }
}
.video-container.modal-about-video iframe{
  position:static;
  transform:none;
  display:block;
  margin:0 auto;
  width:100%;
  height:100%
}
.video-container.modal-about-video .video-control{
  display:none
}
.video-control{
  bottom:0;
  left:0;
  padding:12px;
  position:absolute;
  z-index:50;
  width:100%;
  display:none;
  align-items:center;
  background:rgba(0,0,0,.5)
}
.video-control.active{
  display:flex
}
.video-control svg{
  color:#fff;
  width:24px;
  height:24px;
  cursor:pointer
}
.video-control__sound{
  margin-left:24px
}
.video-control__duration{
  margin-left:24px;
  margin-right:24px;
  flex-grow:1;
  height:2px;
  background:#f3f3f3;
  position:relative
}
.video-control__timer{
  position:absolute;
  top:0;
  left:0;
  height:2px;
  width:0;
  background:#2ec4dd
}
.video-control--fixed{
  bottom:0;
  left:0;
  position:fixed;
  z-index:1200;
  display:flex
}
.video-control__btn-close{
  position:fixed;
  z-index:1200;
  top:16px;
  right:16px;
  padding:8px;
  background:rgba(0,0,0,.5);
  cursor:pointer
}
.video-control__btn-close svg{
  width:48px;
  height:48px;
  color:#fff
}
.video-overlay{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%
}
.video-overlay-with-control{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:calc(100% - 48px)
}
@media(min-width: 769px){
  .video-overlay-with-control{
    display:none
  }
}
.video-control__btn-lightbox{
  display:flex;
  align-items:center;
  position:absolute;
  top:12px;
  right:12px;
  z-index:50
}
@media(min-width: 769px){
  .video-control__btn-lightbox{
    top:auto;
    bottom:12px
  }
}
.video-control__btn-lightbox .btn,.video-control__btn-lightbox .uppy-FileInput-btn{
  background-color:#fff;
  font-size:14px;
  font-weight:600;
  border-radius:4px
}
.video-control__btn-lightbox .btn:hover,.video-control__btn-lightbox .uppy-FileInput-btn:hover{
  background-color:#000;
  color:#fff
}
.flatpickr-calendar .flatpickr-current-month input.cur-year,.flatpickr-calendar .flatpickr-current-month .flatpickr-monthDropdown-months{
  font-size:16px
}
.flatpickr-custom.flatpickr-calendar,.flatpickr-custom .flatpickr-calendar{
  padding:5px;
  width:316px;
  box-shadow:none
}
.flatpickr-custom.flatpickr-calendar:before,.flatpickr-custom.flatpickr-calendar:after,.flatpickr-custom .flatpickr-calendar:before,.flatpickr-custom .flatpickr-calendar:after{
  content:none
}
.flatpickr-custom.flatpickr-calendar.static.open,.flatpickr-custom .flatpickr-calendar.static.open{
  left:-56px
}
@media(min-width: 375px){
  .flatpickr-custom.flatpickr-calendar.static.open,.flatpickr-custom .flatpickr-calendar.static.open{
    left:-45px
  }
}
@media(min-width: 769px){
  .flatpickr-custom.flatpickr-calendar.static.open,.flatpickr-custom .flatpickr-calendar.static.open{
    left:-30px
  }
}
@media(max-width: 359px){
  .flatpickr-custom.flatpickr-calendar,.flatpickr-custom .flatpickr-calendar{
    left:-15px
  }
}
.flatpickr-custom .flatpickr-wrapper{
  width:100%;
  flex-grow:1
}
.flatpickr-custom .flatpickr-wrapper .form-control{
  border-radius:0;
  border-left:0
}
.flatpickr-custom .flatpickr-day{
  max-width:39px;
  height:36px;
  line-height:36px;
  margin:3px 2px;
  border-radius:2px;
  color:#2ec4dd;
  border:1px solid #2ec4dd
}
.flatpickr-custom .flatpickr-day.endRange{
  color:#fff !important;
  background:#2ec4dd !important;
  border-top-right-radius:8px !important;
  border-top-left-radius:0 !important;
  border-bottom-right-radius:8px !important;
  border-bottom-left-radius:0 !important
}
.flatpickr-custom .flatpickr-day.endRange:hover{
  border-color:#25a9bf !important
}
.flatpickr-custom .flatpickr-day.startRange:hover{
  border-color:#25a9bf !important
}
.flatpickr-custom .flatpickr-day.inRange{
  color:#fff !important
}
.flatpickr-custom .flatpickr-day.inOneDay{
  border-color:#414141;
  border-radius:10px !important
}
.flatpickr-custom .flatpickr-day.selected{
  background-color:#2ec4dd;
  color:#fff
}
.flatpickr-custom .flatpickr-day:focus,.flatpickr-custom .flatpickr-day:hover{
  background-color:#25a9bf;
  color:#fff
}
.flatpickr-custom .flatpickr-day.selected-checkout,.flatpickr-custom .flatpickr-day.seq-selected{
  background-color:#2ec4dd;
  border-color:#2ec4dd;
  color:#fff
}
.flatpickr-custom .flatpickr-day.selected-luck-checkout,.flatpickr-custom .flatpickr-day.secondary-seq-selected{
  background-color:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-custom .flatpickr-day.selected-luck-checkout.first-luck,.flatpickr-custom .flatpickr-day.secondary-seq-selected.first-luck{
  background-color:#414141;
  color:#fff
}
.flatpickr-custom .flatpickr-day.flatpickr-disabled:hover{
  cursor:not-allowed;
  color:#f3f3f3;
  background-color:#fff;
  border-color:#f3f3f3
}
.flatpickr-custom .flatpickr-day.inRange{
  box-shadow:none;
  background:#2ec4dd;
  border-color:#2ec4dd;
  color:#fff
}
.flatpickr-custom .flatpickr-day.nextMonthDay,.flatpickr-custom .flatpickr-day.prevMonthDay{
  visibility:hidden;
  height:0
}
.flatpickr-custom .flatpickr-day.flatpickr-day-secondary{
  color:#9f9f9f;
  border:1px solid #9f9f9f;
  text-decoration:line-through
}
.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.selected,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary:focus,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary:hover{
  background-color:#414141;
  color:#fff
}
.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.selected.invalid-checkout,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.selected.disabled-checkout,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary:focus.invalid-checkout,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary:focus.disabled-checkout,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary:hover.invalid-checkout,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary:hover.disabled-checkout{
  background-color:#fff;
  color:#f3f3f3
}
.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.selected-checkout,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.seq-selected{
  background-color:#2ec4dd;
  border-color:#2ec4dd;
  color:#fff
}
.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.selected-luck-checkout,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.secondary-seq-selected{
  background-color:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.selected-luck-checkout.first-luck,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.secondary-seq-selected.first-luck{
  background-color:#414141;
  color:#fff
}
.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.inRange{
  background:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.notAllowed.selected,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.notAllowed:focus,.flatpickr-custom .flatpickr-day.flatpickr-day-secondary.notAllowed:hover{
  background-color:#fff;
  color:#9f9f9f;
  border:1px solid #9f9f9f
}
.flatpickr-custom .flatpickr-day.disabled-checkin{
  color:#9f9f9f;
  border:1px solid #9f9f9f;
  text-decoration:line-through
}
.flatpickr-custom .flatpickr-day.disabled-checkin.selected,.flatpickr-custom .flatpickr-day.disabled-checkin:focus,.flatpickr-custom .flatpickr-day.disabled-checkin:hover{
  background-color:#414141;
  color:#fff
}
.flatpickr-custom .flatpickr-day.disabled-checkin.seq-selected{
  color:#fff;
  border-color:#2ec4dd
}
.flatpickr-custom .flatpickr-day.disabled-checkin.secondary-seq-selected{
  color:#fff
}
.flatpickr-custom .flatpickr-day.disabled-checkin.selected-checkout{
  background-color:#2ec4dd;
  border-color:#2ec4dd;
  color:#fff
}
.flatpickr-custom .flatpickr-day.disabled-checkin.selected-luck-checkout{
  background-color:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-custom .flatpickr-day.disabled-checkin.selected-luck-checkout.first-luck{
  background-color:#414141;
  color:#fff
}
.flatpickr-custom .flatpickr-day.disabled-checkin.notAllowed.selected,.flatpickr-custom .flatpickr-day.disabled-checkin.notAllowed:focus,.flatpickr-custom .flatpickr-day.disabled-checkin.notAllowed:hover{
  background-color:#fff;
  color:#9f9f9f;
  border:1px solid #9f9f9f
}
.flatpickr-custom .flatpickr-day.booked-checkout{
  color:#9f9f9f;
  border:1px solid #9f9f9f;
  text-decoration:line-through
}
.flatpickr-custom .flatpickr-day.booked-checkout:focus,.flatpickr-custom .flatpickr-day.booked-checkout:hover{
  background-color:#414141;
  color:#fff
}
.flatpickr-custom .flatpickr-day.booked-checkout.selected-luck-checkout{
  background-color:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-custom .flatpickr-day.booked-checkout.notAllowed.selected,.flatpickr-custom .flatpickr-day.booked-checkout.notAllowed:focus,.flatpickr-custom .flatpickr-day.booked-checkout.notAllowed:hover{
  background-color:#fff;
  color:#9f9f9f;
  border:1px solid #9f9f9f
}
.flatpickr-custom .flatpickr-day.enabled-checkout{
  color:#2ec4dd;
  border:1px solid #2ec4dd;
  text-decoration:none
}
.flatpickr-custom .flatpickr-day.enabled-checkout.seq-selected,.flatpickr-custom .flatpickr-day.enabled-checkout.selected-checkout,.flatpickr-custom .flatpickr-day.enabled-checkout:focus,.flatpickr-custom .flatpickr-day.enabled-checkout:hover{
  background-color:#2ec4dd;
  color:#fff
}
.flatpickr-custom .flatpickr-day.enabled-checkout.selected-luck-checkout{
  background-color:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-custom .flatpickr-day .flatpickr-day-inner{
  display:flex;
  flex-direction:column;
  justify-content:center;
  height:100%
}
.flatpickr-custom .flatpickr-day .flatpickr-day-date{
  width:100%;
  margin-bottom:2px;
  line-height:1;
  font-size:14px
}
@media(min-width: 769px){
  .flatpickr-custom .flatpickr-day .flatpickr-day-date{
    margin-bottom:4px
  }
}
.flatpickr-custom .flatpickr-day .flatpickr-day-price{
  line-height:1;
  width:100%;
  font-size:10px
}
@media(min-width: 769px){
  .flatpickr-custom .flatpickr-day .flatpickr-day-price{
    font-size:12px
  }
}
.flatpickr-custom .flatpickr-disabled{
  color:#f3f3f3;
  border-color:#f3f3f3
}
.flatpickr-custom .flatpickr-disabled.invalid-checkout,.flatpickr-custom .flatpickr-disabled.disabled-checkout{
  color:#dfdfdf;
  border-color:#dfdfdf
}
.flatpickr-custom .flatpickr-monthDropdown-months{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  cursor:default;
  font-size:16px;
  font-weight:600;
  color:#414141;
  opacity:1
}
.flatpickr-custom .flatpickr-current-month .flatpickr-monthDropdown-months:hover{
  background:inherit
}
.flatpickr-custom .flatpickr-current-month input.cur-year[disabled]{
  color:#414141;
  font-size:16px;
  font-weight:600
}
.flatpickr-custom .numInputWrapper:hover{
  background:inherit
}
.flatpickr-custom .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month{
  left:8px
}
.flatpickr-custom .flatpickr-months .flatpickr-next-month.flatpickr-next-month{
  right:8px
}
.flatpickr-custom .flatpickr-months .flatpickr-prev-month,.flatpickr-custom .flatpickr-months .flatpickr-next-month{
  border:1px solid #9f9f9f;
  height:30px;
  width:30px;
  padding:0;
  top:8px;
  line-height:28px;
  border-radius:50%;
  text-align:center;
  display:inline-block;
  color:#414141;
  fill:#414141
}
.flatpickr-custom .flatpickr-months .flatpickr-prev-month:hover,.flatpickr-custom .flatpickr-months .flatpickr-next-month:hover{
  border-color:#2ec4dd
}
.flatpickr-custom .flatpickr-months .flatpickr-prev-month:hover svg,.flatpickr-custom .flatpickr-months .flatpickr-next-month:hover svg{
  fill:#2ec4dd
}
.flatpickr-custom .flatpickr-months .flatpickr-prev-month.flatpickr-disabled,.flatpickr-custom .flatpickr-months .flatpickr-next-month.flatpickr-disabled{
  display:none
}
.flatpickr-custom .flatpickr-weekdays{
  margin:8px 0 4px
}
.flatpickr-custom .flatpickr-weekday{
  font-size:14px;
  color:#414141
}
@media(min-width: 375px){
  .flatpickr-custom.flatpickr-custom--md{
    position:relative;
    width:341px
  }

  .flatpickr-custom.flatpickr-custom--md .flatpickr-days{
    width:333px
  }

  .flatpickr-custom.flatpickr-custom--md .flatpickr-calendar{
    width:341px
  }

  .flatpickr-custom.flatpickr-custom--md .dayContainer{
    width:333px;
    min-width:333px;
    max-width:333px
  }

  .flatpickr-custom.flatpickr-custom--md .flatpickr-weekday{
    max-width:50px;
    margin:0 1px
  }

  .flatpickr-custom.flatpickr-custom--md .flatpickr-day{
    max-width:44px;
    height:40px;
    margin:1px;
    line-height:1;
    border-radius:10px
  }
}
@media(min-width: 769px){
  .flatpickr-custom.flatpickr-custom--md{
    position:relative;
    width:406px
  }

  .flatpickr-custom.flatpickr-custom--md .flatpickr-days{
    width:394px
  }

  .flatpickr-custom.flatpickr-custom--md .flatpickr-calendar{
    width:406px
  }

  .flatpickr-custom.flatpickr-custom--md .dayContainer{
    width:394px;
    min-width:394px;
    max-width:394px
  }

  .flatpickr-custom.flatpickr-custom--md .flatpickr-weekday{
    max-width:54px;
    margin:0 1px
  }

  .flatpickr-custom.flatpickr-custom--md .flatpickr-day{
    max-width:53px;
    height:48px;
    margin:1px;
    line-height:1;
    border-radius:10px
  }
}
@media(min-width: 375px){
  .flatpickr-custom.flatpickr-custom--xl{
    position:relative;
    width:341px
  }

  .flatpickr-custom.flatpickr-custom--xl .flatpickr-days{
    width:333px
  }

  .flatpickr-custom.flatpickr-custom--xl .flatpickr-calendar{
    width:341px
  }

  .flatpickr-custom.flatpickr-custom--xl .dayContainer{
    width:333px;
    min-width:333px;
    max-width:333px
  }

  .flatpickr-custom.flatpickr-custom--xl .flatpickr-weekday{
    max-width:50px;
    margin:0 1px
  }

  .flatpickr-custom.flatpickr-custom--xl .flatpickr-day{
    max-width:44px;
    height:40px;
    margin:1px;
    line-height:1;
    border-radius:10px
  }
}
@media(min-width: 769px){
  .flatpickr-custom.flatpickr-custom--xl{
    position:relative;
    width:488px
  }

  .flatpickr-custom.flatpickr-custom--xl .flatpickr-days{
    width:480px
  }

  .flatpickr-custom.flatpickr-custom--xl .flatpickr-calendar{
    width:488px
  }

  .flatpickr-custom.flatpickr-custom--xl .dayContainer{
    width:480px;
    min-width:480px;
    max-width:480px
  }

  .flatpickr-custom.flatpickr-custom--xl .flatpickr-weekday{
    max-width:68px;
    margin:0 1px
  }

  .flatpickr-custom.flatpickr-custom--xl .flatpickr-day{
    max-width:62px;
    height:56px;
    margin:1px;
    line-height:1;
    border-radius:10px
  }
}
.flatpickr-custom .numInputWrapper{
  width:54px
}
.flatpickr-custom .flatpickr-current-month input.cur-year{
  font-weight:600
}
.flatpickr-custom.flatpickr-calendar{
  padding:16px;
  width:341px;
  border:1px solid #dfdfdf
}
.flatpickr-custom.flatpickr-header-date .flatpickr-wrapper{
  display:flex;
  justify-content:center
}
.flatpickr-custom.flatpickr-header-date .flatpickr-day{
  border-radius:10px
}
.flatpickr-custom.flatpickr-with-selector .flatpickr-monthDropdown-months{
  cursor:pointer;
  -webkit-appearance:menulist;
     -moz-appearance:menulist;
          appearance:menulist
}
.flatpickr-custom.flatpickr-with-selector .numInputWrapper{
  margin-left:4px
}
.flatpickr-custom.flatpickr-with-selector .numInputWrapper span{
  right:-8px;
  border:0;
  opacity:1
}
.flatpickr-brand .flatpickr-calendar{
  background-color:rgba(0,0,0,0);
  padding:4px 0;
  width:336px;
  box-shadow:none
}
.flatpickr-brand .flatpickr-calendar:before,.flatpickr-brand .flatpickr-calendar:after{
  content:none
}
.flatpickr-brand .flatpickr-calendar.static.open{
  left:-56px
}
@media(min-width: 375px){
  .flatpickr-brand .flatpickr-calendar.static.open{
    left:-45px
  }
}
@media(min-width: 769px){
  .flatpickr-brand .flatpickr-calendar.static.open{
    left:-30px
  }
}
@media(max-width: 359px){
  .flatpickr-brand .flatpickr-calendar{
    left:-15px
  }
}
@media(min-width: 769px){
  .flatpickr-brand .flatpickr-calendar{
    width:462px
  }
}
.flatpickr-brand .flatpickr-days{
  width:336px
}
@media(min-width: 769px){
  .flatpickr-brand .flatpickr-days{
    width:462px
  }
}
.flatpickr-brand .dayContainer{
  width:336px;
  min-width:336px;
  max-width:336px
}
@media(min-width: 769px){
  .flatpickr-brand .dayContainer{
    width:462px;
    min-width:462px;
    max-width:462px
  }
}
.flatpickr-brand .flatpickr-wrapper{
  width:336px;
  flex-grow:1;
  display:block;
  margin:0 auto
}
@media(min-width: 769px){
  .flatpickr-brand .flatpickr-wrapper{
    width:462px;
    margin:0
  }
}
.flatpickr-brand .flatpickr-wrapper .form-control{
  border-radius:0;
  border-left:0
}
.flatpickr-brand .flatpickr-day{
  max-width:44px;
  height:44px;
  line-height:44px;
  margin:3px 2px;
  border-radius:10px;
  color:#2D4C5D;
  border:1px solid #2D4C5D;
  box-shadow:none !important
}
@media(min-width: 769px){
  .flatpickr-brand .flatpickr-day{
    max-width:62px;
    height:62px;
    line-height:62px
  }
}
.flatpickr-brand .flatpickr-day.startRange,.flatpickr-brand .flatpickr-day.endRange{
  color:#2D4C5D !important;
  background:#E4E480 !important;
  border-radius:10px !important
}
.flatpickr-brand .flatpickr-day.startRange:hover,.flatpickr-brand .flatpickr-day.endRange:hover{
  border-color:#E4E480 !important;
  opacity:.9
}
.flatpickr-brand .flatpickr-day.inRange{
  color:#2D4C5D !important
}
.flatpickr-brand .flatpickr-day.inOneDay{
  border-color:#2D4C5D;
  border-radius:10px !important
}
.flatpickr-brand .flatpickr-day.selected{
  background-color:#E4E480;
  color:#2D4C5D
}
.flatpickr-brand .flatpickr-day.selected:hover{
  border-color:#2D4C5D
}
.flatpickr-brand .flatpickr-day:focus,.flatpickr-brand .flatpickr-day:hover{
  background-color:#E4E480;
  color:#2D4C5D
}
.flatpickr-brand .flatpickr-day.selected-checkout,.flatpickr-brand .flatpickr-day.seq-selected{
  background-color:#E4E480;
  border-color:#2D4C5D;
  color:#2D4C5D
}
.flatpickr-brand .flatpickr-day.selected-luck-checkout,.flatpickr-brand .flatpickr-day.secondary-seq-selected{
  background-color:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-brand .flatpickr-day.selected-luck-checkout.first-luck,.flatpickr-brand .flatpickr-day.secondary-seq-selected.first-luck{
  background-color:#2D4C5D;
  color:#fff
}
.flatpickr-brand .flatpickr-day.flatpickr-disabled:hover{
  cursor:not-allowed;
  color:#D9D9D9;
  background-color:#F8F3E3;
  border-color:#D9D9D9
}
.flatpickr-brand .flatpickr-day.inRange{
  box-shadow:none;
  background:#E4E480;
  border-color:#2D4C5D;
  color:#2D4C5D
}
.flatpickr-brand .flatpickr-day.nextMonthDay,.flatpickr-brand .flatpickr-day.prevMonthDay{
  visibility:hidden;
  height:0
}
.flatpickr-brand .flatpickr-day.flatpickr-day-secondary{
  color:#D9D9D9;
  border:1px solid #D9D9D9;
  text-decoration:line-through
}
.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.selected,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary:focus,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary:hover{
  background-color:#2D4C5D;
  color:#fff
}
.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.selected.invalid-checkout,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.selected.disabled-checkout,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary:focus.invalid-checkout,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary:focus.disabled-checkout,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary:hover.invalid-checkout,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary:hover.disabled-checkout{
  background-color:#F8F3E3;
  color:#f8f7f4
}
.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.selected-checkout,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.seq-selected{
  background-color:#E4E480;
  border-color:#2D4C5D;
  color:#2D4C5D
}
.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.selected-luck-checkout,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.secondary-seq-selected{
  background-color:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.selected-luck-checkout.first-luck,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.secondary-seq-selected.first-luck{
  background-color:#2D4C5D;
  color:#fff
}
.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.inRange{
  background:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.notAllowed.selected,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.notAllowed:focus,.flatpickr-brand .flatpickr-day.flatpickr-day-secondary.notAllowed:hover{
  background-color:#F8F3E3;
  color:#9f9f9f;
  border:1px solid #9f9f9f
}
.flatpickr-brand .flatpickr-day.disabled-checkin{
  color:#9f9f9f;
  border:1px solid #9f9f9f;
  text-decoration:line-through
}
.flatpickr-brand .flatpickr-day.disabled-checkin.selected,.flatpickr-brand .flatpickr-day.disabled-checkin:focus,.flatpickr-brand .flatpickr-day.disabled-checkin:hover{
  background-color:#2D4C5D;
  color:#fff
}
.flatpickr-brand .flatpickr-day.disabled-checkin.seq-selected{
  color:#fff;
  border-color:#2D4C5D
}
.flatpickr-brand .flatpickr-day.disabled-checkin.secondary-seq-selected{
  color:#fff
}
.flatpickr-brand .flatpickr-day.disabled-checkin.selected-checkout{
  background-color:#E4E480;
  border-color:#2D4C5D;
  color:#2D4C5D
}
.flatpickr-brand .flatpickr-day.disabled-checkin.selected-luck-checkout{
  background-color:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-brand .flatpickr-day.disabled-checkin.selected-luck-checkout.first-luck{
  background-color:#2D4C5D;
  color:#fff
}
.flatpickr-brand .flatpickr-day.disabled-checkin.notAllowed.selected,.flatpickr-brand .flatpickr-day.disabled-checkin.notAllowed:focus,.flatpickr-brand .flatpickr-day.disabled-checkin.notAllowed:hover{
  background-color:#F8F3E3;
  color:#9f9f9f;
  border:1px solid #9f9f9f
}
.flatpickr-brand .flatpickr-day.booked-checkout{
  color:#9f9f9f;
  border:1px solid #9f9f9f;
  text-decoration:line-through
}
.flatpickr-brand .flatpickr-day.booked-checkout:focus,.flatpickr-brand .flatpickr-day.booked-checkout:hover{
  background-color:#2D4C5D;
  color:#fff
}
.flatpickr-brand .flatpickr-day.booked-checkout.selected-luck-checkout{
  background-color:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-brand .flatpickr-day.booked-checkout.notAllowed.selected,.flatpickr-brand .flatpickr-day.booked-checkout.notAllowed:focus,.flatpickr-brand .flatpickr-day.booked-checkout.notAllowed:hover{
  background-color:#fff;
  color:#9f9f9f;
  border:1px solid #9f9f9f
}
.flatpickr-brand .flatpickr-day.enabled-checkout{
  color:#2D4C5D;
  border:1px solid #2D4C5D;
  text-decoration:none
}
.flatpickr-brand .flatpickr-day.enabled-checkout.seq-selected,.flatpickr-brand .flatpickr-day.enabled-checkout.selected-checkout,.flatpickr-brand .flatpickr-day.enabled-checkout:focus,.flatpickr-brand .flatpickr-day.enabled-checkout:hover{
  background-color:#E4E480;
  color:#2D4C5D
}
.flatpickr-brand .flatpickr-day.enabled-checkout.selected-luck-checkout{
  background-color:#9f9f9f;
  border-color:#9f9f9f;
  color:#fff
}
.flatpickr-brand .flatpickr-day .flatpickr-day-inner{
  display:flex;
  flex-direction:column;
  justify-content:center;
  height:100%
}
.flatpickr-brand .flatpickr-day .flatpickr-day-date{
  width:100%;
  margin-bottom:2px;
  line-height:1;
  font-size:14px
}
@media(min-width: 769px){
  .flatpickr-brand .flatpickr-day .flatpickr-day-date{
    margin-bottom:4px
  }
}
.flatpickr-brand .flatpickr-day .flatpickr-day-price{
  line-height:1;
  width:100%;
  font-size:10px
}
@media(min-width: 769px){
  .flatpickr-brand .flatpickr-day .flatpickr-day-price{
    font-size:12px
  }
}
.flatpickr-brand .flatpickr-disabled{
  color:#D9D9D9;
  border-color:#D9D9D9
}
.flatpickr-brand .flatpickr-disabled.invalid-checkout,.flatpickr-brand .flatpickr-disabled.disabled-checkout{
  color:#D9D9D9;
  border-color:#D9D9D9
}
.flatpickr-brand .flatpickr-monthDropdown-months{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  cursor:default;
  font-size:16px;
  font-weight:600;
  color:#2D4C5D;
  opacity:1
}
.flatpickr-brand .flatpickr-current-month .flatpickr-monthDropdown-months:hover{
  background:inherit
}
.flatpickr-brand .flatpickr-current-month input.cur-year[disabled]{
  opacity:1;
  color:#2D4C5D;
  font-size:16px;
  font-weight:600
}
.flatpickr-brand .numInputWrapper:hover{
  background:inherit
}
.flatpickr-brand .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month{
  left:40px
}
@media(min-width: 769px){
  .flatpickr-brand .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month{
    left:100px
  }
}
.flatpickr-brand .flatpickr-months .flatpickr-next-month.flatpickr-next-month{
  right:40px
}
@media(min-width: 769px){
  .flatpickr-brand .flatpickr-months .flatpickr-next-month.flatpickr-next-month{
    right:100px
  }
}
.flatpickr-brand .flatpickr-months .flatpickr-prev-month,.flatpickr-brand .flatpickr-months .flatpickr-next-month{
  padding:0;
  top:8px;
  color:#2D4C5D;
  fill:#2D4C5D;
  height:24px
}
.flatpickr-brand .flatpickr-months .flatpickr-prev-month svg,.flatpickr-brand .flatpickr-months .flatpickr-next-month svg{
  vertical-align:middle;
  width:24px;
  height:24px
}
.flatpickr-brand .flatpickr-months .flatpickr-prev-month:hover,.flatpickr-brand .flatpickr-months .flatpickr-next-month:hover{
  border-color:#2D4C5D
}
.flatpickr-brand .flatpickr-months .flatpickr-prev-month:hover svg,.flatpickr-brand .flatpickr-months .flatpickr-next-month:hover svg{
  fill:#2D4C5D
}
.flatpickr-brand .flatpickr-months .flatpickr-prev-month.flatpickr-disabled,.flatpickr-brand .flatpickr-months .flatpickr-next-month.flatpickr-disabled{
  display:block;
  opacity:.3;
  cursor:not-allowed
}
.flatpickr-brand .flatpickr-weekdays{
  margin:8px 0 4px
}
.flatpickr-brand .flatpickr-weekday{
  font-size:14px;
  color:#2D4C5D
}
.flatpickr-brand .numInputWrapper{
  width:54px
}
.flatpickr-brand .flatpickr-current-month input.cur-year{
  color:#2D4C5D;
  font-weight:600
}
.flatpickr-brand .numInputWrapper span{
  right:-8px;
  border:0
}
.flatpickr-brand .flatpickr-current-month .numInputWrapper span.arrowUp:after{
  border-bottom-color:#2D4C5D
}
.flatpickr-brand .flatpickr-current-month .numInputWrapper span.arrowDown:after{
  border-top-color:#2D4C5D
}
.flatpickr-brand.flatpickr-header-date .flatpickr-wrapper{
  display:flex;
  justify-content:center
}
.flatpickr-brand.flatpickr-header-date .flatpickr-day{
  border-radius:10px
}
.flatpickr-brand.flatpickr-brand-sm .flatpickr-wrapper{
  width:336px
}
.flatpickr-brand.flatpickr-brand-sm .flatpickr-days{
  width:336px
}
.flatpickr-brand.flatpickr-brand-sm .flatpickr-calendar{
  width:336px
}
.flatpickr-brand.flatpickr-brand-sm .dayContainer{
  width:336px;
  min-width:336px;
  max-width:336px
}
.flatpickr-brand.flatpickr-brand-sm .flatpickr-weekday{
  max-width:50px;
  margin:0 1px
}
.flatpickr-brand.flatpickr-brand-sm .flatpickr-day{
  max-width:44px;
  height:44px;
  line-height:44px
}
.flatpickr-brand.flatpickr-brand-sm .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month{
  left:60px
}
.flatpickr-brand.flatpickr-brand-sm .flatpickr-months .flatpickr-next-month.flatpickr-next-month{
  right:60px
}
html:not([dir=rtl]) .noUi-horizontal .noUi-handle{
  right:-10px
}
.noUi-horizontal{
  height:5px;
  box-shadow:none
}
.noUi-horizontal .noUi-handle{
  width:24px;
  height:24px;
  top:-10px
}
.noUi-target{
  background:#9f9f9f;
  border-radius:4px;
  border:0
}
.noUi-connect{
  background:#2ec4dd
}
.noUi-handle{
  border:0;
  box-shadow:none;
  border-radius:50%;
  background-color:#2ec4dd;
  cursor:pointer
}
.noUi-handle:before,.noUi-handle:after{
  content:initial
}
.noUi-connect{
  background:#2ec4dd
}
.stripe-card-input.StripeElement{
  box-sizing:border-box;
  padding:11px 12px;
  border:1px solid #d9d9d9;
  box-shadow:none;
  border-radius:8px;
  background-color:#fff
}
.stripe-card-input.StripeElement--invalid{
  color:#ff5055;
  border-color:#ff5055;
  box-shadow:none
}
.stripe-card-input.StripeElement--focus{
  outline:none;
  border-color:#d9d9d9;
  box-shadow:none
}
.uppy-FileInput-container{
  margin-bottom:0
}
.uppy-FileInput-btn{
  font-family:"SofiaPro",sans-serif
}
.uppy-FileInput-btn:not(:disabled):not(.disabled):active,.uppy-FileInput-btn:active{
  box-shadow:none;
  color:#fff;
  background-color:#FF6B07
}
.uppy-Informer-custom .uppy-Informer{
  top:60px;
  bottom:auto
}
.uppy-Informer-custom .uppy-Informer p{
  background-color:#ff5055
}
.chat-fc-form-outer{
  font-family:"Outfit", sans-serif !important
}
.chat-fc-form-outer div.fc-form{
  padding-top:20px !important
}
.chat-fc-form-outer div.fc-form p{
  margin-bottom:24px !important;
  font-weight:400
}
.chat-fc-form-outer div.fc-form ul li{
  margin-bottom:24px !important
}
.chat-fc-form-outer div.fc-form ul li input{
  padding:8px 6px !important;
  color:#414141 !important;
  border-radius:10px !important
}
.chat-fc-form-outer div.fc-form ul li input:focus{
  box-shadow:none !important
}
.chat-fc-form-outer div.fc-form ul li label{
  color:#ABABAB !important;
  font-size:0.875rem !important
}
.chat-fc-form-outer div.fc-form a.fc-button{
  border-radius:10px !important
}
.account-mb-nav{
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
  z-index:100;
  background-color:#F8F3E3;
  padding:8px 0 4px
}
@media(min-width: 769px){
  .account-mb-nav{
    display:none
  }
}
.account-mb-nav__inner{
  display:flex;
  align-items:center;
  justify-content:center
}
.account-mb-nav__item{
  padding:0 24px;
  text-align:center
}
.account-mb-nav__link{
  display:inline-block;
  color:#2D4C5D
}
.account-mb-nav__link.active,.account-mb-nav__link:hover{
  color:#FF6B07
}
.account-mb-nav__link.active svg,.account-mb-nav__link:hover svg{
  color:#FF6B07
}
.account-hero-img{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3;
  width:100%;
  height:240px
}
.account-trip-receipt{
  border:1px solid #E4E480;
  border-radius:10px;
  padding:16px
}
.account-trip-receipt__item{
  align-items:center;
  display:flex;
  padding:8px 16px
}
.account-trip-receipt__label{
  flex-grow:1;
  font-size:14px;
  font-weight:600;
  padding-right:8px
}
@media(min-width: 769px){
  .account-trip-receipt__label{
    font-size:16px
  }
}
.account-trip-receipt__total,.account-trip-receipt__amount{
  margin-left:auto;
  width:130px;
  text-align:right
}
@media(min-width: 769px){
  .account-trip-receipt__total,.account-trip-receipt__amount{
    width:160px
  }
}
.account-trip-receipt__item--total .account-trip-receipt__amount,.account-trip-receipt__item--total .account-trip-receipt__label{
  font-size:18px;
  font-weight:600
}
@media(min-width: 769px){
  .account-trip-receipt__item--total .account-trip-receipt__amount,.account-trip-receipt__item--total .account-trip-receipt__label{
    font-size:20px
  }
}
.blog__excerpt{
  margin-bottom:24px;
  color:#6a6a6a
}
.blog__meta{
  margin-bottom:32px
}
@media(min-width: 769px){
  .blog__meta{
    display:flex;
    justify-content:space-between;
    align-items:center
  }
}
.blog__cover-img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:280px;
  margin-bottom:32px
}
@media(min-width: 769px){
  .blog__cover-img{
    margin-bottom:40px;
    height:500px
  }
}
.blog__footer{
  margin-top:32px;
  padding-top:32px;
  border-top:1px solid #dfdfdf
}
.blog__table-content{
  border:1px solid #E4E480;
  background-color:#fff;
  padding:16px;
  margin-bottom:32px;
  border-radius:10px
}
@media(min-width: 769px){
  .blog__table-content{
    padding:24px 32px
  }
}
.blog__table-content-title{
  font-weight:600;
  font-size:20px;
  margin-bottom:16px
}
.blog__table-content-list{
  padding-left:17px
}
.blog__table-content-list li+li{
  margin-top:12px
}
.blog__table-content-list a{
  cursor:pointer;
  color:#2D4C5D
}
.blog__table-content-list a:hover:not([href]):not([tabindex]){
  text-decoration:underline
}
.blog__list-item{
  padding-top:32px;
  padding-bottom:32px;
  display:flex;
  flex-direction:column;
  border-top:1px solid #dfdfdf
}
.blog__list-item:first-child{
  margin-top:16px
}
@media(min-width: 769px){
  .blog__list-item{
    flex-direction:row;
    padding-top:48px;
    padding-bottom:48px
  }

  .blog__list-item:first-child{
    margin-top:32px
  }
}
.blog__list-item-media{
  flex:0 0 auto;
  order:2;
  width:100%
}
@media(min-width: 769px){
  .blog__list-item-media{
    order:initial;
    width:40%
  }
}
.blog__list-item-media img{
  width:100%;
  height:220px
}
@media(min-width: 769px){
  .blog__list-item-media img{
    width:290px
  }
}
.blog__list-item-media iframe{
  width:100%
}
.blog__list-item-img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%
}
@media(min-width: 769px){
  .blog__list-item-media+.blog__list-item-text{
    margin-left:24px
  }
}
@media(min-width: 769px){
  .blog__list-item-text+.blog__list-item-media{
    margin-left:24px
  }
}
.blog__list-item-text{
  flex:0 0 auto;
  width:100%;
  order:1;
  margin-bottom:24px
}
@media(min-width: 769px){
  .blog__list-item-text{
    width:60%;
    margin-bottom:0;
    flex-basis:1%;
    flex-grow:1;
    order:initial;
    display:flex
  }
}
.blog__list-item-num{
  margin-top:-2px;
  line-height:1;
  font-weight:600;
  color:#2D4C5D;
  font-size:48px;
  text-align:center;
  margin-bottom:32px
}
@media(min-width: 769px){
  .blog__list-item-num{
    margin-bottom:0;
    text-align:left;
    flex:0 0 auto;
    width:52px;
    margin-right:20px
  }
}
.blog__list-item-num--black{
  color:#414141
}
.blog__list-item-body{
  flex:1 1 auto
}
.blog__list-item-desc a{
  color:#2D4C5D;
  text-decoration:underline
}
.blog__list-item-desc a:hover{
  font-weight:600
}
.blog__list-item-title{
  font-family:"Kobe", sans-serif;
  font-weight:600;
  font-size:20px;
  margin-bottom:16px;
  line-height:1.2
}
@media(min-width: 769px){
  .blog__list-item-title{
    font-size:24px
  }
}
.blog__list-item-btn-book{
  text-align:center
}
@media(min-width: 769px){
  .blog__list-item-btn-book{
    text-align:left
  }
}
.blog__list-item-btn-book a{
  text-transform:lowercase;
  display:inline-block;
  color:#2D4C5D;
  padding:6px 32px
}
@media(min-width: 769px){
  .blog__list-item-btn-book a{
    color:#2D4C5D;
    padding:0;
    border:0
  }
}
.blog__list-item-link{
  text-align:center
}
@media(min-width: 769px){
  .blog__list-item-link{
    display:block;
    text-align:left
  }
}
.blog__list-item-link a{
  display:inline-block;
  color:#414141;
  padding:6px 32px;
  border:1px solid #414141
}
@media(min-width: 769px){
  .blog__list-item-link a{
    padding:0;
    border:0;
    font-weight:600;
    text-decoration:underline;
    color:#414141
  }

  .blog__list-item-link a:hover{
    color:#2ec4dd
  }
}
.blog__body{
  font-size:1rem
}
@media(min-width: 769px){
  .blog__body{
    font-size:1.125rem
  }
}
.blog__body iframe{
  width:100%;
  max-width:640px;
  margin:0 auto;
  display:block
}
.blog__body a{
  color:#2D4C5D;
  background:linear-gradient(180deg, rgba(255, 255, 255, 0) 4.17%, rgba(255, 255, 255, 0) 30.21%, rgba(255, 255, 255, 0) 38.54%, #E4E480 46.88%)
}
.blog__body h1{
  margin-top:48px;
  margin-bottom:48px;
  font-size:28px
}
@media(min-width: 769px){
  .blog__body h1{
    margin-top:64px;
    margin-bottom:64px;
    font-size:32px
  }
}
.blog__body h2{
  margin-top:48px;
  margin-bottom:32px;
  font-size:24px
}
@media(min-width: 769px){
  .blog__body h2{
    margin-top:48px;
    margin-bottom:48px;
    font-size:28px
  }
}
.blog__body h3{
  margin-top:24px;
  margin-bottom:24px;
  font-size:22px
}
@media(min-width: 769px){
  .blog__body h3{
    margin-top:32px;
    margin-bottom:32px;
    font-size:24px
  }
}
.blog__body h4{
  font-size:18px
}
@media(min-width: 769px){
  .blog__body h4{
    font-size:22px
  }
}
.blog__body h5{
  font-size:18px
}
@media(min-width: 769px){
  .blog__body h5{
    font-size:20px
  }
}
.blog__body h6{
  font-size:16px
}
@media(min-width: 769px){
  .blog__body h6{
    font-size:18px
  }
}
.blog__body h1,.blog__body h2,.blog__body h3,.blog__body h4,.blog__body h5,.blog__body h6{
  font-weight:600
}
.blog__body h1,.blog__body h2,.blog__body h3{
  text-align:center;
  line-height:1.2
}
.blog__body h4,.blog__body h5,.blog__body h6,.blog__body ul,.blog__body ol,.blog__body div,.blog__body p{
  margin-bottom:24px
}
.blog__body ol li+li,.blog__body ul li+li{
  margin-top:8px
}
.blog__body .block-img{
  text-align:center
}
.blog__body .block-img__caption{
  margin-top:16px;
  font-style:italic;
  color:#9f9f9f
}
.blog__body img{
  -o-object-fit:cover;
     object-fit:cover;
  max-width:100%;
  height:auto
}
.blog__body .card-oembed{
  max-width:600px;
  margin:0 auto
}
.blog__body .card-oembed__title,.blog__body .card-oembed__subtitle,.blog__body .card-oembed__img{
  margin-bottom:8px
}
.card-oembed__img{
  opacity:1;
  transition:opacity .2s ease-in-out;
  margin-bottom:16px
}
.card-oembed__img img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:320px;
  border-radius:10px;
  border:2px solid #E4E480
}
@media(min-width: 769px){
  .card-oembed__img img{
    height:260px
  }
}
.card-oembed:hover .card-oembed__img{
  opacity:.7
}
.card-oembed--disabled:hover .card-oembed__img{
  opacity:1
}
.card-oembed__subtitle{
  display:none;
  color:#9f9f9f;
  font-size:0.875rem;
  margin-bottom:8px
}
.card-oembed__title{
  font-family:"Kobe", sans-serif;
  font-weight:600;
  color:#2D4C5D;
  margin-bottom:4px
}
.card-oembed__text{
  color:#2D4C5D
}
.section-blog__title{
  display:flex;
  align-items:center;
  margin-bottom:32px;
  padding-bottom:8px;
  position:relative;
  justify-content:center
}
@media(min-width: 769px){
  .section-blog__title{
    border-bottom:1px solid #dfdfdf;
    justify-content:space-between
  }
}
@media(max-width: 768px){
  .section-blog__title--yellow .section-blog__title-text{
    color:#fdaa00
  }

  .section-blog__title--yellow .section-blog__title-link svg{
    color:#fdaa00
  }
}
@media(max-width: 768px){
  .section-blog__title--cyan .section-blog__title-text{
    color:#2ec4dd
  }

  .section-blog__title--cyan .section-blog__title-link svg{
    color:#2ec4dd
  }
}
@media(max-width: 768px){
  .section-blog__title--purple .section-blog__title-text{
    color:#4a5ced
  }

  .section-blog__title--purple .section-blog__title-link svg{
    color:#4a5ced
  }
}
.section-blog__title-text{
  font-weight:600;
  font-size:24px;
  line-height:1;
  margin-bottom:0
}
@media(min-width: 769px){
  .section-blog__title-text{
    font-size:28px
  }
}
.section-blog__title-link{
  color:#9f9f9f
}
.section-blog__title-link:hover{
  color:#2ec4dd
}
.section-blog__title-link span{
  display:none
}
@media(min-width: 769px){
  .section-blog__title-link span{
    display:inline
  }

  .section-blog__title-link:after{
    display:none
  }
}
iframe.instagram-media{
  margin:0 auto !important
}
.normal-article{
  color:#2D4C5D;
  font-size:1rem
}
@media(min-width: 769px){
  .normal-article{
    font-size:1.125rem
  }
}
.normal-article h1,.normal-article h2,.normal-article h3,.normal-article h4,.normal-article h5,.normal-article h6,.normal-article div,.normal-article p,.normal-article ul{
  margin-bottom:24px
}
.normal-article div,.normal-article p{
  min-height:1px
}
.normal-article h1{
  line-height:1.2;
  font-size:1.75rem;
  margin-bottom:1.5rem
}
@media(min-width: 769px){
  .normal-article h1{
    font-size:3rem;
    margin-bottom:2rem
  }
}
.normal-article h2{
  line-height:1.2;
  font-size:1.5rem
}
@media(min-width: 769px){
  .normal-article h2{
    font-size:2rem
  }
}
.normal-article h3{
  line-height:1.2;
  font-size:1.25rem
}
@media(min-width: 769px){
  .normal-article h3{
    font-size:1.5rem
  }
}
.normal-article h4{
  line-height:1.4;
  font-size:1.125rem
}
@media(min-width: 769px){
  .normal-article h4{
    font-size:1.25rem
  }
}
.normal-article h5{
  line-height:1.4;
  font-size:1rem
}
@media(min-width: 769px){
  .normal-article h5{
    font-size:1.125rem
  }
}
.normal-article h6{
  line-height:1.4;
  font-size:0.875rem
}
@media(min-width: 769px){
  .normal-article h6{
    font-size:1rem
  }
}
.normal-article h1,.normal-article h2,.normal-article h3,.normal-article h4,.normal-article h5,.normal-article h6{
  color:#2D4C5D;
  font-weight:600;
  font-family:"Kobe", sans-serif
}
.normal-article h1 a,.normal-article h2 a,.normal-article h3 a,.normal-article h4 a,.normal-article h5 a,.normal-article h6 a{
  color:#2D4C5D
}
.normal-article ol>li,.normal-article ul>li{
  margin-bottom:12px
}
.normal-article pre{
  background-image:url("/images/purple_quote.png");
  background-position:16px top;
  background-repeat:no-repeat;
  background-size:36px 32px;
  padding-left:60px;
  display:block;
  margin:32px auto;
  width:100%;
  font-size:1.25rem;
  overflow:hidden;
  word-break:normal;
  white-space:normal;
  font-family:"Kobe", sans-serif;
  font-weight:600;
  border-left:8px solid #2D4C5D;
  font-size:1.25rem;
  color:#2D4C5D
}
@media(min-width: 769px){
  .normal-article pre{
    width:100%;
    font-size:2rem;
    background-position:32px top;
    background-size:55px 48px;
    padding-left:100px;
    border-left:8px solid #2D4C5D;
    margin:48px 0
  }
}
.normal-article a{
  color:#2D4C5D;
  background:linear-gradient(180deg, rgba(255, 255, 255, 0) 4.17%, rgba(255, 255, 255, 0) 30.21%, rgba(255, 255, 255, 0) 38.54%, #E4E480 46.88%)
}
.normal-article a.btn,.normal-article a.uppy-FileInput-btn{
  background-image:none
}
.normal-article img{
  display:block;
  width:100%;
  height:240px;
  border-radius:10px;
  margin-bottom:0.625rem;
  -o-object-fit:cover;
     object-fit:cover;
  border:2px solid #E4E480
}
@media(min-width: 769px){
  .normal-article img{
    height:420px
  }
}
.normal-article iframe{
  max-width:100%
}
.normal-article .block-img{
  margin:32px 0
}
@media(min-width: 769px){
  .normal-article .block-img{
    margin:40px 0
  }
}
.normal-article .block-img__caption{
  color:#9f9f9f;
  font-size:0.75rem
}
@media(min-width: 769px){
  .normal-article .block-img__caption{
    font-size:0.875rem
  }
}
.corporate-event .corporate-event-logo{
  padding:48px 0;
  width:250px;
  margin:0 auto;
  display:flex;
  align-items:center;
  justify-content:between
}
.corporate-event .corporate-event-logo img{
  width:1%;
  height:auto;
  display:block;
  flex:1 1 auto
}
.corporate-event .corporate-event-logo .close-icon{
  margin:0 24px;
  flex-shrink:0
}
.corporate-event .corporate-event-logo .ss-logo{
  width:78px;
  height:48px;
  flex-shrink:0
}
.corporate-event .corp-title{
  font-size:24px;
  font-weight:600;
  line-height:1
}
@media(min-width: 769px){
  .corporate-event .corp-title{
    font-size:36px
  }
}
.corporate-event .about-big-img img{
  width:100%;
  height:240px;
  -o-object-fit:cover;
     object-fit:cover
}
@media(min-width: 769px){
  .corporate-event .about-big-img img{
    height:400px
  }
}
.corporate-event .about-img{
  width:100%;
  height:240px;
  -o-object-fit:cover;
     object-fit:cover
}
@media(min-width: 769px){
  .corporate-event .about-img{
    height:400px
  }
}
.corporate-event .feature-img{
  width:100%;
  height:200px;
  -o-object-fit:cover;
     object-fit:cover
}
@media(min-width: 769px){
  .corporate-event .feature-img{
    height:320px
  }
}
.modal-about-img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:240px
}
@media(min-width: 769px){
  .modal-about-img{
    height:600px
  }
}
.event-hero{
  padding-top:32px;
  padding-bottom:32px;
  margin-bottom:32px;
  box-shadow:0 0 0 rgba(0,0,0,.1),0 4px 8px rgba(0,0,0,.2)
}
@media(min-width: 769px){
  .event-hero{
    padding-top:0;
    padding-bottom:0;
    margin-bottom:0;
    display:flex;
    align-items:stretch
  }
}
.event-hero .event-info{
  padding:0 15px
}
@media(min-width: 769px){
  .event-hero .event-info{
    width:500px;
    padding:60px 80px 0
  }
}
.event-hero .event-info h1{
  font-size:28px;
  font-weight:600;
  margin-bottom:8px
}
@media(min-width: 769px){
  .event-hero .event-info h1{
    font-size:48px;
    margin-bottom:16px
  }
}
.event-hero .event-banner{
  height:280px
}
@media(min-width: 769px){
  .event-hero .event-banner{
    flex-grow:1;
    height:600px
  }
}
.event-hero .event-banner img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:100%
}
.event-timer{
  display:flex;
  align-items:center
}
.event-timer .event-timer__item{
  border-radius:2px;
  background-color:#f3f3f3;
  text-align:center;
  padding:16px
}
.event-timer .event-timer__num{
  font-size:24px;
  font-weight:600;
  line-height:1;
  margin-bottom:4px
}
.event-timer .event-timer__item+.event-timer__item{
  margin-left:24px
}
.hero{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3;
  height:500px;
  width:100%;
  position:relative;
  background-position:center center
}
.hero--creator{
  background-image:url("/images/creator_community/hero.jpg");
  height:414px
}
@media(min-width: 769px){
  .hero--creator{
    height:640px
  }
}
.hero--tried-and-tested{
  background-image:url("/images/tried_and_tested/hero.jpg")
}
@media(min-width: 769px){
  .hero--tried-and-tested{
    background-position:center 30%
  }
}
.hero--tried-and-tested:after{
  content:" ";
  position:absolute;
  background-color:rgba(0,0,0,.2);
  width:100%;
  height:100%
}
.hero--tried-and-tested-2{
  background-image:url("/images/tried_and_tested/hero_2.png")
}
.hero--our-quality{
  background-image:url("/images/our_quality/hero.jpg")
}
.hero--our-quality:after{
  content:" ";
  position:absolute;
  background-color:rgba(0,0,0,.2);
  width:100%;
  height:100%
}
.hero__inner{
  width:100%;
  position:absolute;
  z-index:10;
  left:0;
  top:50%;
  transform:translateY(-50%)
}
.hero__inner--home{
  margin-top:65px
}
@media(min-width: 769px){
  .hero__inner--home{
    margin-top:0
  }
}
.hero__overlay{
  width:100%;
  height:290px;
  position:absolute;
  bottom:0;
  left:0;
  background:linear-gradient(1deg, rgba(65, 65, 65, 0.5) 40%, rgba(255, 255, 255, 0) 100%)
}
@media(min-width: 769px){
  .hero__overlay{
    display:none
  }
}
.hero__title{
  font-size:32px;
  font-weight:600;
  color:#fff
}
@media(min-width: 769px){
  .hero__title{
    font-size:50px
  }
}
.hero__tagline{
  color:#fff;
  font-size:16px;
  margin-bottom:16px
}
@media(min-width: 769px){
  .hero__tagline{
    font-size:20px;
    margin-bottom:32px
  }
}
.img-about-big{
  -o-object-fit:cover;
     object-fit:cover;
  height:400px;
  width:100%
}
.img-about-small{
  -o-object-fit:cover;
     object-fit:cover;
  height:300px;
  width:100%
}
.video-about{
  position:relative;
  padding-bottom:75%;
  height:0
}
.video-about img{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%
}
.container-about-video-float{
  margin-top:-150px;
  margin-bottom:40px
}
@media(min-width: 769px){
  .container-about-video-float{
    margin-bottom:80px
  }
}
.container-about-video-float .video-about{
  padding-bottom:100%
}
.container-about-video-float .img-about-big{
  height:170px
}
@media(min-width: 769px){
  .container-about-video-float .img-about-big{
    height:445px
  }
}
@media(min-width: 769px){
  .creator-video-top{
    padding-top:328px
  }
}
.creator-video{
  width:100%;
  margin-top:-48px;
  margin-bottom:48px
}
@media(min-width: 769px){
  .creator-video{
    width:352px;
    margin-left:auto;
    margin-right:auto;
    margin-top:-376px;
    margin-bottom:80px
  }
}
.creator-banner{
  width:100%;
  background:#2ec4dd;
  color:#fff;
  font-weight:600;
  line-height:1;
  padding:8px 0
}
.carousel-creator-video .slick-arrow{
  top:auto;
  bottom:-60px
}
.carousel-creator-video .slick-prev,.carousel-creator-video .slick-next{
  width:32px;
  height:32px;
  line-height:32px
}
.carousel-creator-video .slick-prev svg,.carousel-creator-video .slick-next svg{
  width:24px;
  height:24px
}
.carousel-creator-video .slick-prev.slick-disabled,.carousel-creator-video .slick-next.slick-disabled{
  display:inline-block !important;
  opacity:.5;
  cursor:default;
  pointer-events:none
}
.carousel-creator-video .slick-prev{
  left:auto;
  right:44px
}
.carousel-creator-video .slick-next{
  right:0
}
.carousel-item-creator-video{
  position:relative
}
.carousel-item-creator-video:after{
  content:" ";
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:#fff;
  opacity:.5
}
.carousel-item-creator-video.remove-overlay:after{
  content:none
}
.carousel-item-creator-video iframe{
  border-radius:8px
}
@media(max-width: 768px){
  .carousel-item-creator-video iframe{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%
  }
}
.creator-video-container{
  position:relative;
  padding:177.5% 0 0 0
}
@media(min-width: 769px){
  .creator-video-container{
    padding:0
  }
}
.customer-review{
  position:relative
}
.customer-review__content{
  background-color:#fff;
  padding:24px 16px
}
@media(min-width: 769px){
  .customer-review__content{
    position:absolute;
    width:480px;
    padding:32px 48px;
    left:120px;
    top:50%;
    transform:translateY(-50%)
  }

  .customer-review__content svg{
    width:48px;
    height:48px
  }
}
.customer-review__content svg{
  width:32px;
  height:32px
}
.customer-review__body{
  font-size:18px;
  font-weight:600;
  margin-bottom:24px
}
@media(min-width: 769px){
  .customer-review__body{
    font-size:24px
  }
}
.customer-review__img{
  height:260px;
  position:relative
}
@media(min-width: 769px){
  .customer-review__img{
    width:50%;
    height:600px;
    margin-left:50%
  }
}
.customer-review__img img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:100%
}
.customer-review__location{
  position:absolute;
  right:12px;
  bottom:12px
}
.carousel-collection-activity-review-container{
  width:calc(100% - 122px)
}
@media(min-width: 769px){
  .carousel-collection-activity-review-container{
    width:calc(100% - 150px)
  }
}
.gift-banner{
  text-align:center;
  width:100%;
  height:345px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  padding:20px
}
@media(min-width: 769px){
  .gift-banner{
    height:550px;
    padding-top:40px;
    padding-bottom:60px
  }
}
.gift-banner.gift-banner-card{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3;
  background-image:url("/images/gift_card/gift_card.png")
}
.gift-banner.gift-banner-exp{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3;
  background-image:url("/images/gift_card/gift_experience.jpg")
}
.sao-highlight{
  width:140px;
  padding-bottom:12px
}
@media(min-width: 769px){
  .sao-highlight{
    padding-bottom:0
  }
}
.sao-highlight__img{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3;
  width:100%;
  height:105px;
  margin-bottom:8px;
  border-radius:2px
}
.sao-highlight__title{
  text-align:center
}
.sao-highlight__icon svg{
  fill:#6a6a6a;
  stroke:#fff
}
.accom-datepicker-date-info{
  display:block;
  justify-content:flex-end;
  align-items:center
}
@media(min-width: 769px){
  .accom-datepicker-date-info{
    display:flex
  }
}
.nav-pills.nav-pills--accommodation{
  display:none
}
@media(min-width: 769px){
  .nav-pills.nav-pills--accommodation{
    display:flex
  }
}
.nav-pills.nav-pills--accommodation .nav-link{
  border-radius:4px;
  background-color:#fff;
  border:1px solid #9f9f9f;
  color:#9f9f9f;
  font-size:14px;
  min-width:94px;
  text-align:center
}
.nav-pills.nav-pills--accommodation .nav-link.active{
  border-color:#2ec4dd;
  color:#2ec4dd
}
.nav-pills.nav-pills--accommodation .nav-item+.nav-item{
  margin-left:16px
}
.list-accommodation{
  display:flex
}
.list-accommodation__item+.list-accommodation__item{
  margin-left:-1px
}
.list-accommodation__title{
  font-size:12px;
  font-weight:600;
  margin-bottom:4px;
  text-align:center;
  color:#9f9f9f
}
.list-accommodation__title svg{
  color:#9f9f9f
}
.list-accommodation__item{
  padding:8px;
  flex-grow:0;
  width:33.3333333333%;
  background-color:#fff;
  border:1px solid #dfdfdf
}
.list-accommodation__item:first-child{
  border-top-left-radius:4px;
  border-bottom-left-radius:4px
}
.list-accommodation__item:last-child{
  border-top-right-radius:4px;
  border-bottom-right-radius:4px
}
.list-accommodation__item.active .list-accommodation__title,.list-accommodation__item.active .list-accommodation__title svg{
  color:#2ec4dd
}
.list-accommodation__checkbox{
  text-align:center
}
.list-accommodation__img{
  -o-object-fit:cover;
     object-fit:cover;
  width:100%;
  height:80px;
  margin-bottom:6px;
  border-radius:4px;
  opacity:.7
}
.list-accommodation__item.active .list-accommodation__img{
  opacity:1
}
.modal-footer__datepicker{
  width:100%
}
@media(min-width: 769px){
  .modal-footer__datepicker{
    width:auto
  }
}
.popup-slide{
  position:fixed;
  top:0;
  height:0;
  width:100%;
  height:100%;
  z-index:1201;
  transform:translateY(100%);
  transition:transform .1s ease-in-out
}
.popup-slide.active{
  transform:translateY(0);
  background-color:rgba(65,65,65,.5)
}
.popup-slide__inner{
  position:relative;
  height:100%
}
.popup-slide__content{
  height:70%;
  background-color:#fff;
  position:absolute;
  width:100%;
  bottom:0;
  left:0
}
.popup-slide__header{
  display:flex;
  justify-content:space-between;
  font-weight:600;
  padding:12px
}
.popup-slide__body{
  max-height:calc(100% - 40px);
  overflow-y:auto
}
.carousel-option-media{
  margin-bottom:32px
}
@media(min-width: 769px){
  .carousel-option-media{
    margin-bottom:0
  }
}
.carousel-option-media .slick-dots{
  bottom:-28px
}
.carousel-option-media .slick-dots li+li{
  margin-left:6px
}
.carousel-option-media .slick-dots li{
  border-color:#6a6a6a;
  cursor:pointer;
  padding:3px
}
.carousel-option-media .slick-dots li.slick-active{
  background-color:#6a6a6a
}
.hide{
  display:none
}
.row.no-gutter{
  margin-right:0;
  margin-left:0
}
.row.no-gutter>.col,.row.no-gutter>[class*=col-]{
  padding-right:0;
  padding-left:0
}
.img-as-bg{
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  background-color:#f3f3f3
}
.img-profile,.img-profile-letter{
  width:60px;
  height:60px;
  border:1px inset rgba(0,0,0,.1);
  border-radius:50%
}
.img-profile.img-profile--sm,.img-profile--sm.img-profile-letter{
  width:24px;
  height:24px
}
.img-profile-letter{
  display:inline-block;
  background-color:blue;
  color:#fff;
  text-align:center;
  text-transform:uppercase;
  line-height:57px;
  font-size:20px
}
.img-profile-letter--black{
  background-color:#414141;
  border-color:#9f9f9f;
  color:#fff
}
.img-profile-letter.img-profile-letter--sm{
  width:24px;
  height:24px;
  font-size:14px;
  line-height:22px
}
.option-quick-view{
  position:absolute;
  top:0px;
  right:15px;
  z-index:20;
  width:52px;
  height:52px;
  padding-top:12px;
  padding-left:12px;
  text-align:center;
  cursor:pointer
}
.option-quick-view__btn{
  width:32px;
  height:32px;
  line-height:32px;
  border-radius:50%;
  background:#A9B3D3
}
.option-quick-view svg{
  color:#2D4C5D;
  width:20px;
  height:20px;
  margin-bottom:3px
}
.gm-style img{
  background-color:rgba(0,0,0,0)
}
.aligned-addon-price{
  padding-left:30px;
  margin-top:8px
}
@media(min-width: 769px){
  .aligned-addon-price{
    margin-top:0;
    padding-left:0
  }
}
.hide-scrollbar{
  -ms-overflow-style:none;
  scrollbar-width:none
}
.hide-scrollbar::-webkit-scrollbar{
  display:none
}
.u-bg-facebook{
  background-color:#1877f2
}
.u-bg-google{
  background-color:#e22809
}
.tw-pointer-events-none{
  pointer-events:none !important
}
.tw-invisible{
  visibility:hidden !important
}
.tw-static{
  position:static !important
}
.tw-fixed{
  position:fixed !important
}
.tw-absolute{
  position:absolute !important
}
.tw-relative{
  position:relative !important
}
.tw-sticky{
  position:sticky !important
}
.tw-top-0{
  top:0px !important
}
.tw-top-1{
  top:0.25rem !important
}
.tw-top-2{
  top:0.5rem !important
}
.tw-top-3{
  top:0.75rem !important
}
.tw-top-4{
  top:1rem !important
}
.tw-top-5{
  top:1.25rem !important
}
.tw-top-6{
  top:1.5rem !important
}
.tw-top-8{
  top:2rem !important
}
.tw-top-9{
  top:2.25rem !important
}
.tw-top-10{
  top:2.5rem !important
}
.tw-top-12{
  top:3rem !important
}
.tw-top-14{
  top:3.5rem !important
}
.tw-top-16{
  top:4rem !important
}
.tw-top-18{
  top:4.5rem !important
}
.tw-top-20{
  top:5rem !important
}
.tw-top-24{
  top:6rem !important
}
.tw-top-32{
  top:8rem !important
}
.tw-top-36{
  top:9rem !important
}
.tw-top-60{
  top:15rem !important
}
.tw-top-auto{
  top:auto !important
}
.tw-top-0\.5{
  top:0.125rem !important
}
.tw-top-1\.5{
  top:0.375rem !important
}
.tw--top-0{
  top:0px !important
}
.tw--top-1{
  top:-0.25rem !important
}
.tw--top-2{
  top:-0.5rem !important
}
.tw--top-3{
  top:-0.75rem !important
}
.tw--top-4{
  top:-1rem !important
}
.tw--top-5{
  top:-1.25rem !important
}
.tw--top-6{
  top:-1.5rem !important
}
.tw--top-7{
  top:-1.75rem !important
}
.tw--top-8{
  top:-2rem !important
}
.tw--top-10{
  top:-2.5rem !important
}
.tw--top-14{
  top:-3.5rem !important
}
.tw--top-16{
  top:-4rem !important
}
.tw--top-0\.5{
  top:-0.125rem !important
}
.tw-top-1\/2{
  top:50% !important
}
.tw-top-1\/3{
  top:33.333333% !important
}
.tw-right-0{
  right:0px !important
}
.tw-right-1{
  right:0.25rem !important
}
.tw-right-2{
  right:0.5rem !important
}
.tw-right-3{
  right:0.75rem !important
}
.tw-right-4{
  right:1rem !important
}
.tw-right-5{
  right:1.25rem !important
}
.tw-right-6{
  right:1.5rem !important
}
.tw-right-7{
  right:1.75rem !important
}
.tw-right-8{
  right:2rem !important
}
.tw-right-10{
  right:2.5rem !important
}
.tw-right-14{
  right:3.5rem !important
}
.tw-right-18{
  right:4.5rem !important
}
.tw-right-20{
  right:5rem !important
}
.tw--right-2{
  right:-0.5rem !important
}
.tw--right-3{
  right:-0.75rem !important
}
.tw--right-4{
  right:-1rem !important
}
.tw--right-5{
  right:-1.25rem !important
}
.tw--right-6{
  right:-1.5rem !important
}
.tw--right-8{
  right:-2rem !important
}
.tw--right-10{
  right:-2.5rem !important
}
.tw--right-14{
  right:-3.5rem !important
}
.tw--right-24{
  right:-6rem !important
}
.tw--right-28{
  right:-7rem !important
}
.tw--right-36{
  right:-9rem !important
}
.tw--right-44{
  right:-11rem !important
}
.tw-bottom-0{
  bottom:0px !important
}
.tw-bottom-1{
  bottom:0.25rem !important
}
.tw-bottom-2{
  bottom:0.5rem !important
}
.tw-bottom-3{
  bottom:0.75rem !important
}
.tw-bottom-4{
  bottom:1rem !important
}
.tw-bottom-5{
  bottom:1.25rem !important
}
.tw-bottom-6{
  bottom:1.5rem !important
}
.tw-bottom-10{
  bottom:2.5rem !important
}
.tw-bottom-12{
  bottom:3rem !important
}
.tw-bottom-16{
  bottom:4rem !important
}
.tw--bottom-0{
  bottom:0px !important
}
.tw--bottom-1{
  bottom:-0.25rem !important
}
.tw--bottom-2{
  bottom:-0.5rem !important
}
.tw--bottom-4{
  bottom:-1rem !important
}
.tw--bottom-5{
  bottom:-1.25rem !important
}
.tw--bottom-6{
  bottom:-1.5rem !important
}
.tw--bottom-7{
  bottom:-1.75rem !important
}
.tw--bottom-8{
  bottom:-2rem !important
}
.tw--bottom-14{
  bottom:-3.5rem !important
}
.tw--bottom-36{
  bottom:-9rem !important
}
.tw--bottom-88{
  bottom:-20rem !important
}
.tw--bottom-1\.5{
  bottom:-0.375rem !important
}
.tw-left-0{
  left:0px !important
}
.tw-left-2{
  left:0.5rem !important
}
.tw-left-3{
  left:0.75rem !important
}
.tw-left-4{
  left:1rem !important
}
.tw-left-5{
  left:1.25rem !important
}
.tw-left-6{
  left:1.5rem !important
}
.tw-left-8{
  left:2rem !important
}
.tw-left-10{
  left:2.5rem !important
}
.tw-left-11{
  left:2.75rem !important
}
.tw-left-12{
  left:3rem !important
}
.tw-left-14{
  left:3.5rem !important
}
.tw-left-20{
  left:5rem !important
}
.tw-left-3\.5{
  left:0.875rem !important
}
.tw--left-1{
  left:-0.25rem !important
}
.tw--left-2{
  left:-0.5rem !important
}
.tw--left-3{
  left:-0.75rem !important
}
.tw--left-4{
  left:-1rem !important
}
.tw--left-5{
  left:-1.25rem !important
}
.tw--left-6{
  left:-1.5rem !important
}
.tw--left-8{
  left:-2rem !important
}
.tw--left-10{
  left:-2.5rem !important
}
.tw--left-20{
  left:-5rem !important
}
.tw--left-24{
  left:-6rem !important
}
.tw--left-28{
  left:-7rem !important
}
.tw--left-1\.5{
  left:-0.375rem !important
}
.tw--left-2\.5{
  left:-0.625rem !important
}
.tw-left-1\/2{
  left:50% !important
}
.tw-z-0{
  z-index:0 !important
}
.tw-z-10{
  z-index:10 !important
}
.tw-z-20{
  z-index:20 !important
}
.tw-z-50{
  z-index:50 !important
}
.tw-z-90{
  z-index:90 !important
}
.tw-z-100{
  z-index:100 !important
}
.tw-z-101{
  z-index:101 !important
}
.tw-z-1051{
  z-index:1051 !important
}
.tw-z-1052{
  z-index:1052 !important
}
.tw-z-1200{
  z-index:1200 !important
}
.tw-z-1300{
  z-index:1300 !important
}
.tw-order-1{
  order:1 !important
}
.tw-order-2{
  order:2 !important
}
.tw-order-3{
  order:3 !important
}
.tw-order-first{
  order:-9999 !important
}
.tw-m-0{
  margin:0px !important
}
.tw-m-auto{
  margin:auto !important
}
.tw-mx-0{
  margin-left:0px !important;
  margin-right:0px !important
}
.tw-mx-1{
  margin-left:0.25rem !important;
  margin-right:0.25rem !important
}
.tw-mx-2{
  margin-left:0.5rem !important;
  margin-right:0.5rem !important
}
.tw-mx-4{
  margin-left:1rem !important;
  margin-right:1rem !important
}
.tw-mx-5{
  margin-left:1.25rem !important;
  margin-right:1.25rem !important
}
.tw-mx-6{
  margin-left:1.5rem !important;
  margin-right:1.5rem !important
}
.tw-mx-14{
  margin-left:3.5rem !important;
  margin-right:3.5rem !important
}
.tw-mx-auto{
  margin-left:auto !important;
  margin-right:auto !important
}
.tw-mx-0\.5{
  margin-left:0.125rem !important;
  margin-right:0.125rem !important
}
.tw-mx-1\.5{
  margin-left:0.375rem !important;
  margin-right:0.375rem !important
}
.tw-mx-2\.5{
  margin-left:0.625rem !important;
  margin-right:0.625rem !important
}
.tw--mx-0{
  margin-left:0px !important;
  margin-right:0px !important
}
.tw--mx-1{
  margin-left:-0.25rem !important;
  margin-right:-0.25rem !important
}
.tw--mx-2{
  margin-left:-0.5rem !important;
  margin-right:-0.5rem !important
}
.tw--mx-3{
  margin-left:-0.75rem !important;
  margin-right:-0.75rem !important
}
.tw--mx-4{
  margin-left:-1rem !important;
  margin-right:-1rem !important
}
.tw--mx-5{
  margin-left:-1.25rem !important;
  margin-right:-1.25rem !important
}
.tw--mx-0\.5{
  margin-left:-0.125rem !important;
  margin-right:-0.125rem !important
}
.tw--mx-1\.5{
  margin-left:-0.375rem !important;
  margin-right:-0.375rem !important
}
.tw--mx-3\.75{
  margin-left:-0.9375rem !important;
  margin-right:-0.9375rem !important
}
.tw-my-1{
  margin-top:0.25rem !important;
  margin-bottom:0.25rem !important
}
.tw-my-3{
  margin-top:0.75rem !important;
  margin-bottom:0.75rem !important
}
.tw-my-4{
  margin-top:1rem !important;
  margin-bottom:1rem !important
}
.tw-my-5{
  margin-top:1.25rem !important;
  margin-bottom:1.25rem !important
}
.tw-my-6{
  margin-top:1.5rem !important;
  margin-bottom:1.5rem !important
}
.tw-my-7{
  margin-top:1.75rem !important;
  margin-bottom:1.75rem !important
}
.tw-my-8{
  margin-top:2rem !important;
  margin-bottom:2rem !important
}
.tw-my-10{
  margin-top:2.5rem !important;
  margin-bottom:2.5rem !important
}
.tw-my-12{
  margin-top:3rem !important;
  margin-bottom:3rem !important
}
.tw-my-16{
  margin-top:4rem !important;
  margin-bottom:4rem !important
}
.tw-mt-0{
  margin-top:0px !important
}
.tw-mt-1{
  margin-top:0.25rem !important
}
.tw-mt-2{
  margin-top:0.5rem !important
}
.tw-mt-3{
  margin-top:0.75rem !important
}
.tw-mt-4{
  margin-top:1rem !important
}
.tw-mt-5{
  margin-top:1.25rem !important
}
.tw-mt-6{
  margin-top:1.5rem !important
}
.tw-mt-8{
  margin-top:2rem !important
}
.tw-mt-10{
  margin-top:2.5rem !important
}
.tw-mt-12{
  margin-top:3rem !important
}
.tw-mt-14{
  margin-top:3.5rem !important
}
.tw-mt-15{
  margin-top:3.75rem !important
}
.tw-mt-16{
  margin-top:4rem !important
}
.tw-mt-0\.5{
  margin-top:0.125rem !important
}
.tw-mt-1\.5{
  margin-top:0.375rem !important
}
.tw-mt-3\.75{
  margin-top:0.9375rem !important
}
.tw--mt-1{
  margin-top:-0.25rem !important
}
.tw--mt-4{
  margin-top:-1rem !important
}
.tw--mt-8{
  margin-top:-2rem !important
}
.tw--mt-10{
  margin-top:-2.5rem !important
}
.tw--mt-px{
  margin-top:-1px !important
}
.tw-mr-0{
  margin-right:0px !important
}
.tw-mr-1{
  margin-right:0.25rem !important
}
.tw-mr-2{
  margin-right:0.5rem !important
}
.tw-mr-3{
  margin-right:0.75rem !important
}
.tw-mr-4{
  margin-right:1rem !important
}
.tw-mr-5{
  margin-right:1.25rem !important
}
.tw-mr-6{
  margin-right:1.5rem !important
}
.tw-mr-7{
  margin-right:1.75rem !important
}
.tw-mr-8{
  margin-right:2rem !important
}
.tw-mr-11{
  margin-right:2.75rem !important
}
.tw-mr-0\.5{
  margin-right:0.125rem !important
}
.tw-mr-1\.5{
  margin-right:0.375rem !important
}
.tw--mr-1{
  margin-right:-0.25rem !important
}
.tw--mr-3{
  margin-right:-0.75rem !important
}
.tw--mr-3\.75{
  margin-right:-0.9375rem !important
}
.tw-mb-0{
  margin-bottom:0px !important
}
.tw-mb-1{
  margin-bottom:0.25rem !important
}
.tw-mb-2{
  margin-bottom:0.5rem !important
}
.tw-mb-3{
  margin-bottom:0.75rem !important
}
.tw-mb-4{
  margin-bottom:1rem !important
}
.tw-mb-5{
  margin-bottom:1.25rem !important
}
.tw-mb-6{
  margin-bottom:1.5rem !important
}
.tw-mb-7{
  margin-bottom:1.75rem !important
}
.tw-mb-8{
  margin-bottom:2rem !important
}
.tw-mb-10{
  margin-bottom:2.5rem !important
}
.tw-mb-11{
  margin-bottom:2.75rem !important
}
.tw-mb-12{
  margin-bottom:3rem !important
}
.tw-mb-14{
  margin-bottom:3.5rem !important
}
.tw-mb-16{
  margin-bottom:4rem !important
}
.tw-mb-28{
  margin-bottom:7rem !important
}
.tw-mb-0\.5{
  margin-bottom:0.125rem !important
}
.tw-mb-1\.5{
  margin-bottom:0.375rem !important
}
.tw-mb-2\.5{
  margin-bottom:0.625rem !important
}
.tw--mb-8{
  margin-bottom:-2rem !important
}
.tw-ml-0{
  margin-left:0px !important
}
.tw-ml-1{
  margin-left:0.25rem !important
}
.tw-ml-2{
  margin-left:0.5rem !important
}
.tw-ml-3{
  margin-left:0.75rem !important
}
.tw-ml-4{
  margin-left:1rem !important
}
.tw-ml-5{
  margin-left:1.25rem !important
}
.tw-ml-6{
  margin-left:1.5rem !important
}
.tw-ml-7{
  margin-left:1.75rem !important
}
.tw-ml-11{
  margin-left:2.75rem !important
}
.tw-ml-16{
  margin-left:4rem !important
}
.tw-ml-auto{
  margin-left:auto !important
}
.tw-ml-0\.5{
  margin-left:0.125rem !important
}
.tw-ml-2\.5{
  margin-left:0.625rem !important
}
.tw--ml-0{
  margin-left:0px !important
}
.tw--ml-1{
  margin-left:-0.25rem !important
}
.tw--ml-2{
  margin-left:-0.5rem !important
}
.tw--ml-3{
  margin-left:-0.75rem !important
}
.tw--ml-0\.5{
  margin-left:-0.125rem !important
}
.tw-block{
  display:block !important
}
.tw-inline-block{
  display:inline-block !important
}
.tw-inline{
  display:inline !important
}
.tw-flex{
  display:flex !important
}
.tw-grid{
  display:grid !important
}
.tw-hidden{
  display:none !important
}
.tw-h-1{
  height:0.25rem !important
}
.tw-h-2{
  height:0.5rem !important
}
.tw-h-3{
  height:0.75rem !important
}
.tw-h-4{
  height:1rem !important
}
.tw-h-5{
  height:1.25rem !important
}
.tw-h-6{
  height:1.5rem !important
}
.tw-h-7{
  height:1.75rem !important
}
.tw-h-8{
  height:2rem !important
}
.tw-h-9{
  height:2.25rem !important
}
.tw-h-10{
  height:2.5rem !important
}
.tw-h-11{
  height:2.75rem !important
}
.tw-h-12{
  height:3rem !important
}
.tw-h-14{
  height:3.5rem !important
}
.tw-h-15{
  height:3.75rem !important
}
.tw-h-16{
  height:4rem !important
}
.tw-h-18{
  height:4.5rem !important
}
.tw-h-20{
  height:5rem !important
}
.tw-h-24{
  height:6rem !important
}
.tw-h-28{
  height:7rem !important
}
.tw-h-32{
  height:8rem !important
}
.tw-h-36{
  height:9rem !important
}
.tw-h-40{
  height:10rem !important
}
.tw-h-44{
  height:11rem !important
}
.tw-h-48{
  height:12rem !important
}
.tw-h-52{
  height:13rem !important
}
.tw-h-56{
  height:14rem !important
}
.tw-h-60{
  height:15rem !important
}
.tw-h-72{
  height:18rem !important
}
.tw-h-80{
  height:20rem !important
}
.tw-h-88{
  height:20rem !important
}
.tw-h-96{
  height:24rem !important
}
.tw-h-auto{
  height:auto !important
}
.tw-h-2\.5{
  height:0.625rem !important
}
.tw-h-full{
  height:100% !important
}
.tw-h-screen{
  height:100vh !important
}
.tw-max-h-8{
  max-height:2rem !important
}
.tw-max-h-40{
  max-height:10rem !important
}
.tw-max-h-full{
  max-height:100% !important
}
.tw-max-h-sm{
  max-height:480px !important
}
.tw-max-h-md{
  max-height:600px !important
}
.tw-min-h-xs{
  min-height:300px !important
}
.tw-w-2{
  width:0.5rem !important
}
.tw-w-3{
  width:0.75rem !important
}
.tw-w-4{
  width:1rem !important
}
.tw-w-5{
  width:1.25rem !important
}
.tw-w-6{
  width:1.5rem !important
}
.tw-w-7{
  width:1.75rem !important
}
.tw-w-8{
  width:2rem !important
}
.tw-w-9{
  width:2.25rem !important
}
.tw-w-10{
  width:2.5rem !important
}
.tw-w-11{
  width:2.75rem !important
}
.tw-w-12{
  width:3rem !important
}
.tw-w-14{
  width:3.5rem !important
}
.tw-w-15{
  width:3.75rem !important
}
.tw-w-16{
  width:4rem !important
}
.tw-w-18{
  width:4.5rem !important
}
.tw-w-20{
  width:5rem !important
}
.tw-w-24{
  width:6rem !important
}
.tw-w-28{
  width:7rem !important
}
.tw-w-32{
  width:8rem !important
}
.tw-w-36{
  width:9rem !important
}
.tw-w-40{
  width:10rem !important
}
.tw-w-44{
  width:11rem !important
}
.tw-w-52{
  width:13rem !important
}
.tw-w-56{
  width:14rem !important
}
.tw-w-60{
  width:15rem !important
}
.tw-w-72{
  width:18rem !important
}
.tw-w-80{
  width:20rem !important
}
.tw-w-96{
  width:24rem !important
}
.tw-w-auto{
  width:auto !important
}
.tw-w-2\.5{
  width:0.625rem !important
}
.tw-w-1\/2{
  width:50% !important
}
.tw-w-1\/3{
  width:33.333333% !important
}
.tw-w-3\/4{
  width:75% !important
}
.tw-w-1\/5{
  width:20% !important
}
.tw-w-2\/5{
  width:40% !important
}
.tw-w-3\/5{
  width:60% !important
}
.tw-w-full{
  width:100% !important
}
.tw-w-screen{
  width:100vw !important
}
.tw-max-w-xs{
  max-width:280px !important
}
.tw-max-w-sm{
  max-width:400px !important
}
.tw-max-w-md{
  max-width:450px !important
}
.tw-max-w-lg{
  max-width:32rem !important
}
.tw-max-w-xl{
  max-width:600px !important
}
.tw-max-w-2xl{
  max-width:700px !important
}
.tw-max-w-3xl{
  max-width:800px !important
}
.tw-max-w-4xl{
  max-width:960px !important
}
.tw-max-w-5xl{
  max-width:64rem !important
}
.tw-max-w-6xl{
  max-width:1140px !important
}
.tw-max-w-8xl{
  max-width:1400px !important
}
.tw-max-w-full{
  max-width:100% !important
}
.tw-flex-1{
  flex:1 1 0% !important
}
.tw-flex-auto{
  flex:1 1 auto !important
}
.tw-flex-shrink-0{
  flex-shrink:0 !important
}
.tw-flex-grow-0{
  flex-grow:0 !important
}
.tw-flex-grow{
  flex-grow:1 !important
}
.tw-transform{
  --tw-translate-x:0 !important;
  --tw-translate-y:0 !important;
  --tw-rotate:0 !important;
  --tw-skew-x:0 !important;
  --tw-skew-y:0 !important;
  --tw-scale-x:1 !important;
  --tw-scale-y:1 !important;
  transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}
.tw--translate-x-1\/2{
  --tw-translate-x:-50% !important
}
.tw--translate-y-1\/2{
  --tw-translate-y:-50% !important
}
.tw-rotate-45{
  --tw-rotate:45deg !important
}
.tw-rotate-90{
  --tw-rotate:90deg !important
}
.tw-rotate-180{
  --tw-rotate:180deg !important
}
.tw--rotate-180{
  --tw-rotate:-180deg !important
}
.tw--rotate-90{
  --tw-rotate:-90deg !important
}
@keyframes tw-spin{
  to{
    transform:rotate(360deg)
  }
}
@keyframes tw-ping{
  75%, 100%{
    transform:scale(2);
    opacity:0
  }
}
@keyframes tw-pulse{
  50%{
    opacity:.5
  }
}
@keyframes tw-bounce{
  0%, 100%{
    transform:translateY(-25%);
    animation-timing-function:cubic-bezier(0.8,0,1,1)
  }

  50%{
    transform:none;
    animation-timing-function:cubic-bezier(0,0,0.2,1)
  }
}
.tw-cursor-pointer{
  cursor:pointer !important
}
.tw-cursor-not-allowed{
  cursor:not-allowed !important
}
.tw-resize-none{
  resize:none !important
}
.tw-list-none{
  list-style-type:none !important
}
.tw-list-decimal{
  list-style-type:decimal !important
}
.tw-appearance-none{
  -webkit-appearance:none !important;
     -moz-appearance:none !important;
          appearance:none !important
}
.tw-grid-cols-2{
  grid-template-columns:repeat(2, minmax(0, 1fr)) !important
}
.tw-flex-row{
  flex-direction:row !important
}
.tw-flex-col{
  flex-direction:column !important
}
.tw-flex-wrap{
  flex-wrap:wrap !important
}
.tw-flex-nowrap{
  flex-wrap:nowrap !important
}
.tw-items-start{
  align-items:flex-start !important
}
.tw-items-end{
  align-items:flex-end !important
}
.tw-items-center{
  align-items:center !important
}
.tw-items-stretch{
  align-items:stretch !important
}
.tw-justify-start{
  justify-content:flex-start !important
}
.tw-justify-end{
  justify-content:flex-end !important
}
.tw-justify-center{
  justify-content:center !important
}
.tw-justify-between{
  justify-content:space-between !important
}
.tw-justify-around{
  justify-content:space-around !important
}
.tw-gap-4{
  gap:1rem !important
}
.tw-overflow-hidden{
  overflow:hidden !important
}
.tw-overflow-x-auto{
  overflow-x:auto !important
}
.tw-overflow-y-auto{
  overflow-y:auto !important
}
.tw-overflow-x-hidden{
  overflow-x:hidden !important
}
.tw-overflow-y-hidden{
  overflow-y:hidden !important
}
.tw-truncate{
  overflow:hidden !important;
  text-overflow:ellipsis !important;
  white-space:nowrap !important
}
.tw-whitespace-nowrap{
  white-space:nowrap !important
}
.tw-break-words{
  overflow-wrap:break-word !important
}
.tw-rounded-none{
  border-radius:0px !important
}
.tw-rounded{
  border-radius:0.25rem !important
}
.tw-rounded-md{
  border-radius:8px !important
}
.tw-rounded-lg{
  border-radius:10px !important
}
.tw-rounded-xl{
  border-radius:0.75rem !important
}
.tw-rounded-2xl{
  border-radius:20px !important
}
.tw-rounded-3xl{
  border-radius:1.5rem !important
}
.tw-rounded-full{
  border-radius:9999px !important
}
.tw-rounded-4xl{
  border-radius:30px !important
}
.tw-rounded-l-2xl{
  border-top-left-radius:20px !important;
  border-bottom-left-radius:20px !important
}
.tw-rounded-tl-none{
  border-top-left-radius:0px !important
}
.tw-rounded-tl{
  border-top-left-radius:0.25rem !important
}
.tw-rounded-tl-md{
  border-top-left-radius:8px !important
}
.tw-rounded-tl-lg{
  border-top-left-radius:10px !important
}
.tw-rounded-tl-2xl{
  border-top-left-radius:20px !important
}
.tw-rounded-tl-4xl{
  border-top-left-radius:30px !important
}
.tw-rounded-tr-md{
  border-top-right-radius:8px !important
}
.tw-rounded-tr-lg{
  border-top-right-radius:10px !important
}
.tw-rounded-tr-2xl{
  border-top-right-radius:20px !important
}
.tw-rounded-tr-4xl{
  border-top-right-radius:30px !important
}
.tw-rounded-br-sm{
  border-bottom-right-radius:0.125rem !important
}
.tw-rounded-br-md{
  border-bottom-right-radius:8px !important
}
.tw-rounded-br-lg{
  border-bottom-right-radius:10px !important
}
.tw-rounded-br-2xl{
  border-bottom-right-radius:20px !important
}
.tw-rounded-br-4xl{
  border-bottom-right-radius:30px !important
}
.tw-rounded-bl-none{
  border-bottom-left-radius:0px !important
}
.tw-rounded-bl-sm{
  border-bottom-left-radius:0.125rem !important
}
.tw-rounded-bl{
  border-bottom-left-radius:0.25rem !important
}
.tw-rounded-bl-md{
  border-bottom-left-radius:8px !important
}
.tw-rounded-bl-lg{
  border-bottom-left-radius:10px !important
}
.tw-rounded-bl-2xl{
  border-bottom-left-radius:20px !important
}
.tw-rounded-bl-4xl{
  border-bottom-left-radius:30px !important
}
.tw-border-0{
  border-width:0px !important
}
.tw-border-2{
  border-width:2px !important
}
.tw-border-4{
  border-width:4px !important
}
.tw-border{
  border-width:1px !important
}
.tw-border-t-0{
  border-top-width:0px !important
}
.tw-border-t-2{
  border-top-width:2px !important
}
.tw-border-t{
  border-top-width:1px !important
}
.tw-border-r-0{
  border-right-width:0px !important
}
.tw-border-r{
  border-right-width:1px !important
}
.tw-border-b-0{
  border-bottom-width:0px !important
}
.tw-border-b-2{
  border-bottom-width:2px !important
}
.tw-border-b{
  border-bottom-width:1px !important
}
.tw-border-l-0{
  border-left-width:0px !important
}
.tw-border-l-2{
  border-left-width:2px !important
}
.tw-border-l-4{
  border-left-width:4px !important
}
.tw-border-l{
  border-left-width:1px !important
}
.tw-border-solid{
  border-style:solid !important
}
.tw-border-dashed{
  border-style:dashed !important
}
.tw-border-transparent{
  border-color:transparent !important
}
.tw-border-white{
  --tw-border-opacity:1 !important;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity)) !important
}
.tw-border-orange{
  --tw-border-opacity:1 !important;
  border-color:rgba(255, 107, 7, var(--tw-border-opacity)) !important
}
.tw-border-grey-lighter{
  --tw-border-opacity:1 !important;
  border-color:rgba(217, 217, 217, var(--tw-border-opacity)) !important
}
.tw-border-purple{
  --tw-border-opacity:1 !important;
  border-color:rgba(169, 179, 211, var(--tw-border-opacity)) !important
}
.tw-border-black{
  --tw-border-opacity:1 !important;
  border-color:rgba(65, 65, 65, var(--tw-border-opacity)) !important
}
.tw-border-brand-navy{
  --tw-border-opacity:1 !important;
  border-color:rgba(45, 76, 93, var(--tw-border-opacity)) !important
}
.tw-border-brand-green{
  --tw-border-opacity:1 !important;
  border-color:rgba(228, 228, 128, var(--tw-border-opacity)) !important
}
.hover\:tw-border-grey-lighter:hover{
  --tw-border-opacity:1 !important;
  border-color:rgba(217, 217, 217, var(--tw-border-opacity)) !important
}
.hover\:tw-border-brand-navy:hover{
  --tw-border-opacity:1 !important;
  border-color:rgba(45, 76, 93, var(--tw-border-opacity)) !important
}
.focus\:tw-border-brand-navy:focus{
  --tw-border-opacity:1 !important;
  border-color:rgba(45, 76, 93, var(--tw-border-opacity)) !important
}
.tw-bg-transparent{
  background-color:transparent !important
}
.tw-bg-white{
  --tw-bg-opacity:1 !important;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity)) !important
}
.tw-bg-orange-light{
  --tw-bg-opacity:1 !important;
  background-color:rgba(244, 200, 122, var(--tw-bg-opacity)) !important
}
.tw-bg-orange{
  --tw-bg-opacity:1 !important;
  background-color:rgba(255, 107, 7, var(--tw-bg-opacity)) !important
}
.tw-bg-orange-dark{
  --tw-bg-opacity:1 !important;
  background-color:rgba(255, 218, 191, var(--tw-bg-opacity)) !important
}
.tw-bg-yellow-lightest{
  --tw-bg-opacity:1 !important;
  background-color:rgba(252, 248, 246, var(--tw-bg-opacity)) !important
}
.tw-bg-grey-lightest{
  --tw-bg-opacity:1 !important;
  background-color:rgba(248, 247, 244, var(--tw-bg-opacity)) !important
}
.tw-bg-grey-lighter{
  --tw-bg-opacity:1 !important;
  background-color:rgba(217, 217, 217, var(--tw-bg-opacity)) !important
}
.tw-bg-red{
  --tw-bg-opacity:1 !important;
  background-color:rgba(255, 80, 85, var(--tw-bg-opacity)) !important
}
.tw-bg-purple{
  --tw-bg-opacity:1 !important;
  background-color:rgba(169, 179, 211, var(--tw-bg-opacity)) !important
}
.tw-bg-black{
  --tw-bg-opacity:1 !important;
  background-color:rgba(65, 65, 65, var(--tw-bg-opacity)) !important
}
.tw-bg-brand-navy{
  --tw-bg-opacity:1 !important;
  background-color:rgba(45, 76, 93, var(--tw-bg-opacity)) !important
}
.tw-bg-brand-green{
  --tw-bg-opacity:1 !important;
  background-color:rgba(228, 228, 128, var(--tw-bg-opacity)) !important
}
.tw-bg-brand-yellow{
  --tw-bg-opacity:1 !important;
  background-color:rgba(248, 243, 227, var(--tw-bg-opacity)) !important
}
.hover\:tw-bg-brand-green:hover{
  --tw-bg-opacity:1 !important;
  background-color:rgba(228, 228, 128, var(--tw-bg-opacity)) !important
}
.tw-bg-opacity-40{
  --tw-bg-opacity:0.4 !important
}
.tw-bg-opacity-50{
  --tw-bg-opacity:0.5 !important
}
.tw-bg-cover{
  background-size:cover !important
}
.tw-bg-center{
  background-position:center !important
}
.tw-bg-no-repeat{
  background-repeat:no-repeat !important
}
.tw-bg-repeat-x{
  background-repeat:repeat-x !important
}
.tw-object-contain{
  -o-object-fit:contain !important;
     object-fit:contain !important
}
.tw-object-cover{
  -o-object-fit:cover !important;
     object-fit:cover !important
}
.tw-p-0{
  padding:0px !important
}
.tw-p-1{
  padding:0.25rem !important
}
.tw-p-2{
  padding:0.5rem !important
}
.tw-p-3{
  padding:0.75rem !important
}
.tw-p-4{
  padding:1rem !important
}
.tw-p-5{
  padding:1.25rem !important
}
.tw-p-6{
  padding:1.5rem !important
}
.tw-p-8{
  padding:2rem !important
}
.tw-p-9{
  padding:2.25rem !important
}
.tw-p-1\.5{
  padding:0.375rem !important
}
.tw-px-0{
  padding-left:0px !important;
  padding-right:0px !important
}
.tw-px-1{
  padding-left:0.25rem !important;
  padding-right:0.25rem !important
}
.tw-px-2{
  padding-left:0.5rem !important;
  padding-right:0.5rem !important
}
.tw-px-3{
  padding-left:0.75rem !important;
  padding-right:0.75rem !important
}
.tw-px-4{
  padding-left:1rem !important;
  padding-right:1rem !important
}
.tw-px-5{
  padding-left:1.25rem !important;
  padding-right:1.25rem !important
}
.tw-px-6{
  padding-left:1.5rem !important;
  padding-right:1.5rem !important
}
.tw-px-8{
  padding-left:2rem !important;
  padding-right:2rem !important
}
.tw-px-9{
  padding-left:2.25rem !important;
  padding-right:2.25rem !important
}
.tw-px-10{
  padding-left:2.5rem !important;
  padding-right:2.5rem !important
}
.tw-px-12{
  padding-left:3rem !important;
  padding-right:3rem !important
}
.tw-px-15{
  padding-left:3.75rem !important;
  padding-right:3.75rem !important
}
.tw-px-16{
  padding-left:4rem !important;
  padding-right:4rem !important
}
.tw-px-20{
  padding-left:5rem !important;
  padding-right:5rem !important
}
.tw-px-24{
  padding-left:6rem !important;
  padding-right:6rem !important
}
.tw-px-48{
  padding-left:12rem !important;
  padding-right:12rem !important
}
.tw-px-0\.5{
  padding-left:0.125rem !important;
  padding-right:0.125rem !important
}
.tw-px-1\.5{
  padding-left:0.375rem !important;
  padding-right:0.375rem !important
}
.tw-px-2\.5{
  padding-left:0.625rem !important;
  padding-right:0.625rem !important
}
.tw-px-3\.75{
  padding-left:0.9375rem !important;
  padding-right:0.9375rem !important
}
.tw-py-0{
  padding-top:0px !important;
  padding-bottom:0px !important
}
.tw-py-1{
  padding-top:0.25rem !important;
  padding-bottom:0.25rem !important
}
.tw-py-2{
  padding-top:0.5rem !important;
  padding-bottom:0.5rem !important
}
.tw-py-3{
  padding-top:0.75rem !important;
  padding-bottom:0.75rem !important
}
.tw-py-4{
  padding-top:1rem !important;
  padding-bottom:1rem !important
}
.tw-py-5{
  padding-top:1.25rem !important;
  padding-bottom:1.25rem !important
}
.tw-py-6{
  padding-top:1.5rem !important;
  padding-bottom:1.5rem !important
}
.tw-py-8{
  padding-top:2rem !important;
  padding-bottom:2rem !important
}
.tw-py-9{
  padding-top:2.25rem !important;
  padding-bottom:2.25rem !important
}
.tw-py-12{
  padding-top:3rem !important;
  padding-bottom:3rem !important
}
.tw-py-14{
  padding-top:3.5rem !important;
  padding-bottom:3.5rem !important
}
.tw-py-16{
  padding-top:4rem !important;
  padding-bottom:4rem !important
}
.tw-py-20{
  padding-top:5rem !important;
  padding-bottom:5rem !important
}
.tw-py-24{
  padding-top:6rem !important;
  padding-bottom:6rem !important
}
.tw-py-1\.5{
  padding-top:0.375rem !important;
  padding-bottom:0.375rem !important
}
.tw-py-2\.5{
  padding-top:0.625rem !important;
  padding-bottom:0.625rem !important
}
.tw-pt-0{
  padding-top:0px !important
}
.tw-pt-1{
  padding-top:0.25rem !important
}
.tw-pt-2{
  padding-top:0.5rem !important
}
.tw-pt-3{
  padding-top:0.75rem !important
}
.tw-pt-4{
  padding-top:1rem !important
}
.tw-pt-5{
  padding-top:1.25rem !important
}
.tw-pt-6{
  padding-top:1.5rem !important
}
.tw-pt-7{
  padding-top:1.75rem !important
}
.tw-pt-8{
  padding-top:2rem !important
}
.tw-pt-10{
  padding-top:2.5rem !important
}
.tw-pt-12{
  padding-top:3rem !important
}
.tw-pt-14{
  padding-top:3.5rem !important
}
.tw-pt-16{
  padding-top:4rem !important
}
.tw-pt-20{
  padding-top:5rem !important
}
.tw-pt-0\.5{
  padding-top:0.125rem !important
}
.tw-pt-2\.5{
  padding-top:0.625rem !important
}
.tw-pt-3\.75{
  padding-top:0.9375rem !important
}
.tw-pr-0{
  padding-right:0px !important
}
.tw-pr-1{
  padding-right:0.25rem !important
}
.tw-pr-2{
  padding-right:0.5rem !important
}
.tw-pr-4{
  padding-right:1rem !important
}
.tw-pr-12{
  padding-right:3rem !important
}
.tw-pr-16{
  padding-right:4rem !important
}
.tw-pr-20{
  padding-right:5rem !important
}
.tw-pb-0{
  padding-bottom:0px !important
}
.tw-pb-1{
  padding-bottom:0.25rem !important
}
.tw-pb-2{
  padding-bottom:0.5rem !important
}
.tw-pb-3{
  padding-bottom:0.75rem !important
}
.tw-pb-4{
  padding-bottom:1rem !important
}
.tw-pb-5{
  padding-bottom:1.25rem !important
}
.tw-pb-6{
  padding-bottom:1.5rem !important
}
.tw-pb-7{
  padding-bottom:1.75rem !important
}
.tw-pb-8{
  padding-bottom:2rem !important
}
.tw-pb-10{
  padding-bottom:2.5rem !important
}
.tw-pb-12{
  padding-bottom:3rem !important
}
.tw-pb-14{
  padding-bottom:3.5rem !important
}
.tw-pb-16{
  padding-bottom:4rem !important
}
.tw-pb-20{
  padding-bottom:5rem !important
}
.tw-pb-24{
  padding-bottom:6rem !important
}
.tw-pb-px{
  padding-bottom:1px !important
}
.tw-pb-0\.5{
  padding-bottom:0.125rem !important
}
.tw-pb-1\.5{
  padding-bottom:0.375rem !important
}
.tw-pb-2\.5{
  padding-bottom:0.625rem !important
}
.tw-pl-0{
  padding-left:0px !important
}
.tw-pl-1{
  padding-left:0.25rem !important
}
.tw-pl-2{
  padding-left:0.5rem !important
}
.tw-pl-4{
  padding-left:1rem !important
}
.tw-pl-5{
  padding-left:1.25rem !important
}
.tw-pl-6{
  padding-left:1.5rem !important
}
.tw-pl-7{
  padding-left:1.75rem !important
}
.tw-pl-8{
  padding-left:2rem !important
}
.tw-pl-12{
  padding-left:3rem !important
}
.tw-pl-16{
  padding-left:4rem !important
}
.tw-pl-20{
  padding-left:5rem !important
}
.tw-pl-24{
  padding-left:6rem !important
}
.tw-text-left{
  text-align:left !important
}
.tw-text-center{
  text-align:center !important
}
.tw-text-right{
  text-align:right !important
}
.tw-align-top{
  vertical-align:top !important
}
.tw-align-middle{
  vertical-align:middle !important
}
.tw-align-bottom{
  vertical-align:bottom !important
}
.tw-font-styleSans{
  font-family:"Kobe", sans-serif !important
}
.tw-font-sans{
  font-family:"Outfit", sans-serif !important
}
.tw-font-serif{
  font-family:"Gliker", ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important
}
.tw-text-xs{
  font-size:0.75rem !important;
  line-height:1.4 !important
}
.tw-text-sm{
  font-size:0.875rem !important;
  line-height:1.4 !important
}
.tw-text-base{
  font-size:1rem !important;
  line-height:1.4 !important
}
.tw-text-lg{
  font-size:1.125rem !important;
  line-height:1.4 !important
}
.tw-text-xl{
  font-size:1.25rem !important;
  line-height:1.4 !important
}
.tw-text-2xl{
  font-size:1.5rem !important;
  line-height:1.4 !important
}
.tw-text-3xl{
  font-size:1.75rem !important;
  line-height:1.4 !important
}
.tw-text-4xl{
  font-size:2rem !important;
  line-height:1.4 !important
}
.tw-text-5xl{
  font-size:2.25rem !important;
  line-height:1.4 !important
}
.tw-text-6xl{
  font-size:3rem !important;
  line-height:1 !important
}
.tw-text-8xl{
  font-size:4rem !important;
  line-height:1 !important
}
.tw-font-normal{
  font-weight:400 !important
}
.tw-font-semibold{
  font-weight:600 !important
}
.hover\:tw-font-semibold:hover{
  font-weight:600 !important
}
.tw-uppercase{
  text-transform:uppercase !important
}
.tw-lowercase{
  text-transform:lowercase !important
}
.tw-normal-case{
  text-transform:none !important
}
.tw-italic{
  font-style:italic !important
}
.tw-leading-4{
  line-height:1rem !important
}
.tw-leading-5{
  line-height:1.25rem !important
}
.tw-leading-6{
  line-height:1.5rem !important
}
.tw-leading-7{
  line-height:1.75rem !important
}
.tw-leading-8{
  line-height:2rem !important
}
.tw-leading-none{
  line-height:1 !important
}
.tw-leading-tight{
  line-height:1.25 !important
}
.tw-leading-snug{
  line-height:1.375 !important
}
.tw-text-white{
  --tw-text-opacity:1 !important;
  color:rgba(255, 255, 255, var(--tw-text-opacity)) !important
}
.tw-text-green-light{
  --tw-text-opacity:1 !important;
  color:rgba(52, 211, 153, var(--tw-text-opacity)) !important
}
.tw-text-green{
  --tw-text-opacity:1 !important;
  color:rgba(33, 150, 83, var(--tw-text-opacity)) !important
}
.tw-text-orange{
  --tw-text-opacity:1 !important;
  color:rgba(255, 107, 7, var(--tw-text-opacity)) !important
}
.tw-text-yellow{
  --tw-text-opacity:1 !important;
  color:rgba(253, 170, 0, var(--tw-text-opacity)) !important
}
.tw-text-grey-dark{
  --tw-text-opacity:1 !important;
  color:rgba(106, 106, 106, var(--tw-text-opacity)) !important
}
.tw-text-grey-lighter{
  --tw-text-opacity:1 !important;
  color:rgba(217, 217, 217, var(--tw-text-opacity)) !important
}
.tw-text-grey-light{
  --tw-text-opacity:1 !important;
  color:rgba(159, 159, 159, var(--tw-text-opacity)) !important
}
.tw-text-grey{
  --tw-text-opacity:1 !important;
  color:rgba(171, 171, 171, var(--tw-text-opacity)) !important
}
.tw-text-red{
  --tw-text-opacity:1 !important;
  color:rgba(255, 80, 85, var(--tw-text-opacity)) !important
}
.tw-text-purple{
  --tw-text-opacity:1 !important;
  color:rgba(169, 179, 211, var(--tw-text-opacity)) !important
}
.tw-text-cyan{
  --tw-text-opacity:1 !important;
  color:rgba(46, 196, 221, var(--tw-text-opacity)) !important
}
.tw-text-black{
  --tw-text-opacity:1 !important;
  color:rgba(65, 65, 65, var(--tw-text-opacity)) !important
}
.tw-text-brand-navy{
  --tw-text-opacity:1 !important;
  color:rgba(45, 76, 93, var(--tw-text-opacity)) !important
}
.tw-text-brand-green{
  --tw-text-opacity:1 !important;
  color:rgba(228, 228, 128, var(--tw-text-opacity)) !important
}
.hover\:tw-text-orange:hover{
  --tw-text-opacity:1 !important;
  color:rgba(255, 107, 7, var(--tw-text-opacity)) !important
}
.hover\:tw-text-black:hover{
  --tw-text-opacity:1 !important;
  color:rgba(65, 65, 65, var(--tw-text-opacity)) !important
}
.hover\:tw-text-brand-navy:hover{
  --tw-text-opacity:1 !important;
  color:rgba(45, 76, 93, var(--tw-text-opacity)) !important
}
.hover\:tw-text-brand-green:hover{
  --tw-text-opacity:1 !important;
  color:rgba(228, 228, 128, var(--tw-text-opacity)) !important
}
.focus\:tw-text-brand-navy:focus{
  --tw-text-opacity:1 !important;
  color:rgba(45, 76, 93, var(--tw-text-opacity)) !important
}
.tw-underline{
  text-decoration:underline !important
}
.tw-line-through{
  text-decoration:line-through !important
}
.hover\:tw-underline:hover{
  text-decoration:underline !important
}
.tw-placeholder-current::-moz-placeholder{
  color:currentColor !important
}
.tw-placeholder-current::placeholder{
  color:currentColor !important
}
.tw-placeholder-grey::-moz-placeholder{
  --tw-placeholder-opacity:1 !important;
  color:rgba(171, 171, 171, var(--tw-placeholder-opacity)) !important
}
.tw-placeholder-grey::placeholder{
  --tw-placeholder-opacity:1 !important;
  color:rgba(171, 171, 171, var(--tw-placeholder-opacity)) !important
}
.tw-placeholder-brand-navy::-moz-placeholder{
  --tw-placeholder-opacity:1 !important;
  color:rgba(45, 76, 93, var(--tw-placeholder-opacity)) !important
}
.tw-placeholder-brand-navy::placeholder{
  --tw-placeholder-opacity:1 !important;
  color:rgba(45, 76, 93, var(--tw-placeholder-opacity)) !important
}
.tw-placeholder-opacity-100::-moz-placeholder{
  --tw-placeholder-opacity:1 !important
}
.tw-placeholder-opacity-100::placeholder{
  --tw-placeholder-opacity:1 !important
}
.tw-opacity-20{
  opacity:0.2 !important
}
.tw-opacity-30{
  opacity:0.3 !important
}
*, ::before, ::after{
  --tw-shadow:0 0 #0000
}
.tw-shadow-md{
  --tw-shadow:0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}
.tw-shadow-lg{
  --tw-shadow:0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}
.tw-outline-none{
  outline:2px solid transparent !important;
  outline-offset:2px !important
}
*, ::before, ::after{
  --tw-ring-inset:var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow:0 0 #0000;
  --tw-ring-shadow:0 0 #0000
}
.tw-transition-none{
  transition-property:none !important
}
.tw-transition-transform{
  transition-property:transform !important;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration:150ms !important
}
.tw-font-styleSans{
  font-family:"Kobe", sans-serif;
  letter-spacing:normal
}
@media (min-width: 375px){
  .xs\:custom-scrollbar{
    scrollbar-color:rgba(0,0,0,0) rgba(0,0,0,0);
    scrollbar-width:thin !important
  }

  .xs\:custom-scrollbar::-webkit-scrollbar{
    display:initial;
    width:6px;
    height:6px
  }

  .xs\:custom-scrollbar::-webkit-scrollbar-track{
    background-color:rgba(0,0,0,0)
  }

  .xs\:custom-scrollbar::-webkit-scrollbar-thumb{
    background-color:rgba(0,0,0,0)
  }

  .xs\:custom-scrollbar:hover{
    scrollbar-color:#D9D9D9 #fdfcfc
  }

  .xs\:custom-scrollbar:hover::-webkit-scrollbar-track{
    background-color:#f8f7f4;
    border-radius:8px
  }

  .xs\:custom-scrollbar:hover::-webkit-scrollbar-thumb{
    background-color:#D9D9D9;
    border-radius:8px
  }

  .xs\:custom-scrollbar-no-hover{
    scrollbar-color:#D9D9D9 #fdfcfc;
    scrollbar-width:thin !important
  }

  .xs\:custom-scrollbar-no-hover::-webkit-scrollbar{
    display:initial;
    width:6px;
    height:6px
  }

  .xs\:custom-scrollbar-no-hover::-webkit-scrollbar-track{
    background-color:#f8f7f4;
    border-radius:8px
  }

  .xs\:custom-scrollbar-no-hover::-webkit-scrollbar-thumb{
    background-color:#D9D9D9;
    border-radius:8px
  }

  .xs\:no-scrollbar{
    -ms-overflow-style:none;
    scrollbar-width:none
  }

  .xs\:no-scrollbar::-webkit-scrollbar{
    display:none
  }
}
@media (min-width: 576px){
  .sm\:custom-scrollbar{
    scrollbar-color:rgba(0,0,0,0) rgba(0,0,0,0);
    scrollbar-width:thin !important
  }

  .sm\:custom-scrollbar::-webkit-scrollbar{
    display:initial;
    width:6px;
    height:6px
  }

  .sm\:custom-scrollbar::-webkit-scrollbar-track{
    background-color:rgba(0,0,0,0)
  }

  .sm\:custom-scrollbar::-webkit-scrollbar-thumb{
    background-color:rgba(0,0,0,0)
  }

  .sm\:custom-scrollbar:hover{
    scrollbar-color:#D9D9D9 #fdfcfc
  }

  .sm\:custom-scrollbar:hover::-webkit-scrollbar-track{
    background-color:#f8f7f4;
    border-radius:8px
  }

  .sm\:custom-scrollbar:hover::-webkit-scrollbar-thumb{
    background-color:#D9D9D9;
    border-radius:8px
  }

  .sm\:custom-scrollbar-no-hover{
    scrollbar-color:#D9D9D9 #fdfcfc;
    scrollbar-width:thin !important
  }

  .sm\:custom-scrollbar-no-hover::-webkit-scrollbar{
    display:initial;
    width:6px;
    height:6px
  }

  .sm\:custom-scrollbar-no-hover::-webkit-scrollbar-track{
    background-color:#f8f7f4;
    border-radius:8px
  }

  .sm\:custom-scrollbar-no-hover::-webkit-scrollbar-thumb{
    background-color:#D9D9D9;
    border-radius:8px
  }

  .sm\:no-scrollbar{
    -ms-overflow-style:none;
    scrollbar-width:none
  }

  .sm\:no-scrollbar::-webkit-scrollbar{
    display:none
  }

  .sm\:tw-flex{
    display:flex !important
  }
}
@media (min-width: 769px){
  .md\:custom-scrollbar{
    scrollbar-color:rgba(0,0,0,0) rgba(0,0,0,0);
    scrollbar-width:thin !important
  }

  .md\:custom-scrollbar::-webkit-scrollbar{
    display:initial;
    width:6px;
    height:6px
  }

  .md\:custom-scrollbar::-webkit-scrollbar-track{
    background-color:rgba(0,0,0,0)
  }

  .md\:custom-scrollbar::-webkit-scrollbar-thumb{
    background-color:rgba(0,0,0,0)
  }

  .md\:custom-scrollbar:hover{
    scrollbar-color:#D9D9D9 #fdfcfc
  }

  .md\:custom-scrollbar:hover::-webkit-scrollbar-track{
    background-color:#f8f7f4;
    border-radius:8px
  }

  .md\:custom-scrollbar:hover::-webkit-scrollbar-thumb{
    background-color:#D9D9D9;
    border-radius:8px
  }

  .md\:custom-scrollbar-no-hover{
    scrollbar-color:#D9D9D9 #fdfcfc;
    scrollbar-width:thin !important
  }

  .md\:custom-scrollbar-no-hover::-webkit-scrollbar{
    display:initial;
    width:6px;
    height:6px
  }

  .md\:custom-scrollbar-no-hover::-webkit-scrollbar-track{
    background-color:#f8f7f4;
    border-radius:8px
  }

  .md\:custom-scrollbar-no-hover::-webkit-scrollbar-thumb{
    background-color:#D9D9D9;
    border-radius:8px
  }

  .md\:no-scrollbar{
    -ms-overflow-style:none;
    scrollbar-width:none
  }

  .md\:no-scrollbar::-webkit-scrollbar{
    display:none
  }

  .md\:tw-static{
    position:static !important
  }

  .md\:tw-relative{
    position:relative !important
  }

  .md\:tw-sticky{
    position:sticky !important
  }

  .md\:tw-top-0{
    top:0px !important
  }

  .md\:tw-top-2{
    top:0.5rem !important
  }

  .md\:tw-top-6{
    top:1.5rem !important
  }

  .md\:tw-top-10{
    top:2.5rem !important
  }

  .md\:tw-top-20{
    top:5rem !important
  }

  .md\:tw-top-24{
    top:6rem !important
  }

  .md\:tw-top-32{
    top:8rem !important
  }

  .md\:tw-top-40{
    top:10rem !important
  }

  .md\:tw--top-3{
    top:-0.75rem !important
  }

  .md\:tw--top-6{
    top:-1.5rem !important
  }

  .md\:tw--top-12{
    top:-3rem !important
  }

  .md\:tw--top-14{
    top:-3.5rem !important
  }

  .md\:tw--top-18{
    top:-4.5rem !important
  }

  .md\:tw-top-1\/2{
    top:50% !important
  }

  .md\:tw-right-6{
    right:1.5rem !important
  }

  .md\:tw-right-10{
    right:2.5rem !important
  }

  .md\:tw-right-14{
    right:3.5rem !important
  }

  .md\:tw-right-28{
    right:7rem !important
  }

  .md\:tw--right-14{
    right:-3.5rem !important
  }

  .md\:tw-bottom-4{
    bottom:1rem !important
  }

  .md\:tw-left-6{
    left:1.5rem !important
  }

  .md\:tw-left-16{
    left:4rem !important
  }

  .md\:tw-left-32{
    left:8rem !important
  }

  .md\:tw-left-auto{
    left:auto !important
  }

  .md\:tw--left-2{
    left:-0.5rem !important
  }

  .md\:tw--left-6{
    left:-1.5rem !important
  }

  .md\:tw--left-10{
    left:-2.5rem !important
  }

  .md\:tw--left-14{
    left:-3.5rem !important
  }

  .md\:tw--left-40{
    left:-10rem !important
  }

  .md\:tw--left-60{
    left:-15rem !important
  }

  .md\:tw-order-1{
    order:1 !important
  }

  .md\:tw-order-2{
    order:2 !important
  }

  .md\:tw-order-3{
    order:3 !important
  }

  .md\:tw-order-last{
    order:9999 !important
  }

  .md\:tw-mx-0{
    margin-left:0px !important;
    margin-right:0px !important
  }

  .md\:tw-mx-2{
    margin-left:0.5rem !important;
    margin-right:0.5rem !important
  }

  .md\:tw-mx-4{
    margin-left:1rem !important;
    margin-right:1rem !important
  }

  .md\:tw-mx-5{
    margin-left:1.25rem !important;
    margin-right:1.25rem !important
  }

  .md\:tw-mx-8{
    margin-left:2rem !important;
    margin-right:2rem !important
  }

  .md\:tw-mx-12{
    margin-left:3rem !important;
    margin-right:3rem !important
  }

  .md\:tw-mx-auto{
    margin-left:auto !important;
    margin-right:auto !important
  }

  .md\:tw--mx-1{
    margin-left:-0.25rem !important;
    margin-right:-0.25rem !important
  }

  .md\:tw--mx-2{
    margin-left:-0.5rem !important;
    margin-right:-0.5rem !important
  }

  .md\:tw--mx-3{
    margin-left:-0.75rem !important;
    margin-right:-0.75rem !important
  }

  .md\:tw--mx-4{
    margin-left:-1rem !important;
    margin-right:-1rem !important
  }

  .md\:tw--mx-10{
    margin-left:-2.5rem !important;
    margin-right:-2.5rem !important
  }

  .md\:tw--mx-3\.75{
    margin-left:-0.9375rem !important;
    margin-right:-0.9375rem !important
  }

  .md\:tw-my-0{
    margin-top:0px !important;
    margin-bottom:0px !important
  }

  .md\:tw-my-3{
    margin-top:0.75rem !important;
    margin-bottom:0.75rem !important
  }

  .md\:tw-my-6{
    margin-top:1.5rem !important;
    margin-bottom:1.5rem !important
  }

  .md\:tw-my-8{
    margin-top:2rem !important;
    margin-bottom:2rem !important
  }

  .md\:tw-my-10{
    margin-top:2.5rem !important;
    margin-bottom:2.5rem !important
  }

  .md\:tw-my-12{
    margin-top:3rem !important;
    margin-bottom:3rem !important
  }

  .md\:tw-my-16{
    margin-top:4rem !important;
    margin-bottom:4rem !important
  }

  .md\:tw-mt-0{
    margin-top:0px !important
  }

  .md\:tw-mt-3{
    margin-top:0.75rem !important
  }

  .md\:tw-mt-4{
    margin-top:1rem !important
  }

  .md\:tw-mt-5{
    margin-top:1.25rem !important
  }

  .md\:tw-mt-6{
    margin-top:1.5rem !important
  }

  .md\:tw-mt-8{
    margin-top:2rem !important
  }

  .md\:tw-mt-10{
    margin-top:2.5rem !important
  }

  .md\:tw-mt-12{
    margin-top:3rem !important
  }

  .md\:tw-mt-16{
    margin-top:4rem !important
  }

  .md\:tw-mt-20{
    margin-top:5rem !important
  }

  .md\:tw-mt-24{
    margin-top:6rem !important
  }

  .md\:tw-mt-36{
    margin-top:9rem !important
  }

  .md\:tw-mt-48{
    margin-top:12rem !important
  }

  .md\:tw-mr-0{
    margin-right:0px !important
  }

  .md\:tw-mr-2{
    margin-right:0.5rem !important
  }

  .md\:tw-mr-3{
    margin-right:0.75rem !important
  }

  .md\:tw-mr-4{
    margin-right:1rem !important
  }

  .md\:tw-mr-6{
    margin-right:1.5rem !important
  }

  .md\:tw-mr-8{
    margin-right:2rem !important
  }

  .md\:tw-mr-14{
    margin-right:3.5rem !important
  }

  .md\:tw-mr-15{
    margin-right:3.75rem !important
  }

  .md\:tw-mr-16{
    margin-right:4rem !important
  }

  .md\:tw-mr-20{
    margin-right:5rem !important
  }

  .md\:tw-mr-24{
    margin-right:6rem !important
  }

  .md\:tw-mb-0{
    margin-bottom:0px !important
  }

  .md\:tw-mb-1{
    margin-bottom:0.25rem !important
  }

  .md\:tw-mb-2{
    margin-bottom:0.5rem !important
  }

  .md\:tw-mb-3{
    margin-bottom:0.75rem !important
  }

  .md\:tw-mb-4{
    margin-bottom:1rem !important
  }

  .md\:tw-mb-5{
    margin-bottom:1.25rem !important
  }

  .md\:tw-mb-6{
    margin-bottom:1.5rem !important
  }

  .md\:tw-mb-8{
    margin-bottom:2rem !important
  }

  .md\:tw-mb-9{
    margin-bottom:2.25rem !important
  }

  .md\:tw-mb-10{
    margin-bottom:2.5rem !important
  }

  .md\:tw-mb-12{
    margin-bottom:3rem !important
  }

  .md\:tw-mb-14{
    margin-bottom:3.5rem !important
  }

  .md\:tw-mb-15{
    margin-bottom:3.75rem !important
  }

  .md\:tw-mb-16{
    margin-bottom:4rem !important
  }

  .md\:tw-mb-20{
    margin-bottom:5rem !important
  }

  .md\:tw-mb-24{
    margin-bottom:6rem !important
  }

  .md\:tw-mb-32{
    margin-bottom:8rem !important
  }

  .md\:tw-mb-2\.5{
    margin-bottom:0.625rem !important
  }

  .md\:tw-ml-0{
    margin-left:0px !important
  }

  .md\:tw-ml-2{
    margin-left:0.5rem !important
  }

  .md\:tw-ml-4{
    margin-left:1rem !important
  }

  .md\:tw-ml-6{
    margin-left:1.5rem !important
  }

  .md\:tw-ml-8{
    margin-left:2rem !important
  }

  .md\:tw-ml-12{
    margin-left:3rem !important
  }

  .md\:tw-ml-15{
    margin-left:3.75rem !important
  }

  .md\:tw-ml-16{
    margin-left:4rem !important
  }

  .md\:tw-ml-20{
    margin-left:5rem !important
  }

  .md\:tw-ml-24{
    margin-left:6rem !important
  }

  .md\:tw-ml-32{
    margin-left:8rem !important
  }

  .md\:tw--ml-3{
    margin-left:-0.75rem !important
  }

  .md\:tw--ml-3\.75{
    margin-left:-0.9375rem !important
  }

  .md\:tw-block{
    display:block !important
  }

  .md\:tw-inline-block{
    display:inline-block !important
  }

  .md\:tw-inline{
    display:inline !important
  }

  .md\:tw-flex{
    display:flex !important
  }

  .md\:tw-hidden{
    display:none !important
  }

  .md\:tw-h-1{
    height:0.25rem !important
  }

  .md\:tw-h-4{
    height:1rem !important
  }

  .md\:tw-h-5{
    height:1.25rem !important
  }

  .md\:tw-h-6{
    height:1.5rem !important
  }

  .md\:tw-h-8{
    height:2rem !important
  }

  .md\:tw-h-10{
    height:2.5rem !important
  }

  .md\:tw-h-11{
    height:2.75rem !important
  }

  .md\:tw-h-12{
    height:3rem !important
  }

  .md\:tw-h-14{
    height:3.5rem !important
  }

  .md\:tw-h-15{
    height:3.75rem !important
  }

  .md\:tw-h-16{
    height:4rem !important
  }

  .md\:tw-h-18{
    height:4.5rem !important
  }

  .md\:tw-h-20{
    height:5rem !important
  }

  .md\:tw-h-24{
    height:6rem !important
  }

  .md\:tw-h-28{
    height:7rem !important
  }

  .md\:tw-h-32{
    height:8rem !important
  }

  .md\:tw-h-36{
    height:9rem !important
  }

  .md\:tw-h-40{
    height:10rem !important
  }

  .md\:tw-h-44{
    height:11rem !important
  }

  .md\:tw-h-48{
    height:12rem !important
  }

  .md\:tw-h-52{
    height:13rem !important
  }

  .md\:tw-h-56{
    height:14rem !important
  }

  .md\:tw-h-60{
    height:15rem !important
  }

  .md\:tw-h-64{
    height:16rem !important
  }

  .md\:tw-h-72{
    height:18rem !important
  }

  .md\:tw-h-80{
    height:20rem !important
  }

  .md\:tw-h-88{
    height:20rem !important
  }

  .md\:tw-h-96{
    height:24rem !important
  }

  .md\:tw-h-auto{
    height:auto !important
  }

  .md\:tw-h-1\.5{
    height:0.375rem !important
  }

  .md\:tw-h-full{
    height:100% !important
  }

  .md\:tw-max-h-sm{
    max-height:480px !important
  }

  .md\:tw-max-h-md{
    max-height:600px !important
  }

  .md\:tw-w-4{
    width:1rem !important
  }

  .md\:tw-w-5{
    width:1.25rem !important
  }

  .md\:tw-w-6{
    width:1.5rem !important
  }

  .md\:tw-w-10{
    width:2.5rem !important
  }

  .md\:tw-w-12{
    width:3rem !important
  }

  .md\:tw-w-14{
    width:3.5rem !important
  }

  .md\:tw-w-15{
    width:3.75rem !important
  }

  .md\:tw-w-16{
    width:4rem !important
  }

  .md\:tw-w-20{
    width:5rem !important
  }

  .md\:tw-w-24{
    width:6rem !important
  }

  .md\:tw-w-32{
    width:8rem !important
  }

  .md\:tw-w-36{
    width:9rem !important
  }

  .md\:tw-w-40{
    width:10rem !important
  }

  .md\:tw-w-44{
    width:11rem !important
  }

  .md\:tw-w-48{
    width:12rem !important
  }

  .md\:tw-w-52{
    width:13rem !important
  }

  .md\:tw-w-56{
    width:14rem !important
  }

  .md\:tw-w-60{
    width:15rem !important
  }

  .md\:tw-w-64{
    width:16rem !important
  }

  .md\:tw-w-72{
    width:18rem !important
  }

  .md\:tw-w-80{
    width:20rem !important
  }

  .md\:tw-w-88{
    width:20rem !important
  }

  .md\:tw-w-96{
    width:24rem !important
  }

  .md\:tw-w-auto{
    width:auto !important
  }

  .md\:tw-w-1\/2{
    width:50% !important
  }

  .md\:tw-w-1\/3{
    width:33.333333% !important
  }

  .md\:tw-w-2\/3{
    width:66.666667% !important
  }

  .md\:tw-w-1\/4{
    width:25% !important
  }

  .md\:tw-w-3\/4{
    width:75% !important
  }

  .md\:tw-w-2\/5{
    width:40% !important
  }

  .md\:tw-w-3\/5{
    width:60% !important
  }

  .md\:tw-w-4\/5{
    width:80% !important
  }

  .md\:tw-w-5\/6{
    width:83.333333% !important
  }

  .md\:tw-w-full{
    width:100% !important
  }

  .md\:tw-max-w-none{
    max-width:none !important
  }

  .md\:tw-max-w-sm{
    max-width:400px !important
  }

  .md\:tw-max-w-md{
    max-width:450px !important
  }

  .md\:tw-max-w-lg{
    max-width:32rem !important
  }

  .md\:tw-max-w-xl{
    max-width:600px !important
  }

  .md\:tw-max-w-2xl{
    max-width:700px !important
  }

  .md\:tw-max-w-3xl{
    max-width:800px !important
  }

  .md\:tw-max-w-5xl{
    max-width:64rem !important
  }

  .md\:tw-max-w-6xl{
    max-width:1140px !important
  }

  .md\:tw-max-w-full{
    max-width:100% !important
  }

  .md\:tw-flex-grow-0{
    flex-grow:0 !important
  }

  .md\:tw-transform-none{
    transform:none !important
  }

  .md\:tw--translate-y-10{
    --tw-translate-y:-2.5rem !important
  }

  .md\:tw--translate-y-1\/2{
    --tw-translate-y:-50% !important
  }

  .md\:tw-grid-cols-4{
    grid-template-columns:repeat(4, minmax(0, 1fr)) !important
  }

  .md\:tw-flex-row{
    flex-direction:row !important
  }

  .md\:tw-flex-col{
    flex-direction:column !important
  }

  .md\:tw-flex-nowrap{
    flex-wrap:nowrap !important
  }

  .md\:tw-items-end{
    align-items:flex-end !important
  }

  .md\:tw-items-center{
    align-items:center !important
  }

  .md\:tw-justify-start{
    justify-content:flex-start !important
  }

  .md\:tw-justify-center{
    justify-content:center !important
  }

  .md\:tw-justify-between{
    justify-content:space-between !important
  }

  .md\:tw-rounded{
    border-radius:0.25rem !important
  }

  .md\:tw-rounded-2xl{
    border-radius:20px !important
  }

  .md\:tw-rounded-4xl{
    border-radius:30px !important
  }

  .md\:tw-rounded-tl-none{
    border-top-left-radius:0px !important
  }

  .md\:tw-rounded-tl-lg{
    border-top-left-radius:10px !important
  }

  .md\:tw-rounded-tr-lg{
    border-top-right-radius:10px !important
  }

  .md\:tw-rounded-br-none{
    border-bottom-right-radius:0px !important
  }

  .md\:tw-rounded-br-lg{
    border-bottom-right-radius:10px !important
  }

  .md\:tw-rounded-br-4xl{
    border-bottom-right-radius:30px !important
  }

  .md\:tw-rounded-bl-lg{
    border-bottom-left-radius:10px !important
  }

  .md\:tw-rounded-bl-4xl{
    border-bottom-left-radius:30px !important
  }

  .md\:tw-border-0{
    border-width:0px !important
  }

  .md\:tw-border-2{
    border-width:2px !important
  }

  .md\:tw-border-t{
    border-top-width:1px !important
  }

  .md\:tw-border-b{
    border-bottom-width:1px !important
  }

  .md\:tw-border-l-2{
    border-left-width:2px !important
  }

  .md\:tw-border-l{
    border-left-width:1px !important
  }

  .md\:tw-bg-grey-lighter{
    --tw-bg-opacity:1 !important;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity)) !important
  }

  .md\:tw-bg-brand-yellow{
    --tw-bg-opacity:1 !important;
    background-color:rgba(248, 243, 227, var(--tw-bg-opacity)) !important
  }

  .md\:tw-object-contain{
    -o-object-fit:contain !important;
       object-fit:contain !important
  }

  .md\:tw-object-cover{
    -o-object-fit:cover !important;
       object-fit:cover !important
  }

  .md\:tw-p-0{
    padding:0px !important
  }

  .md\:tw-p-4{
    padding:1rem !important
  }

  .md\:tw-p-5{
    padding:1.25rem !important
  }

  .md\:tw-p-6{
    padding:1.5rem !important
  }

  .md\:tw-p-7{
    padding:1.75rem !important
  }

  .md\:tw-p-8{
    padding:2rem !important
  }

  .md\:tw-p-10{
    padding:2.5rem !important
  }

  .md\:tw-px-0{
    padding-left:0px !important;
    padding-right:0px !important
  }

  .md\:tw-px-1{
    padding-left:0.25rem !important;
    padding-right:0.25rem !important
  }

  .md\:tw-px-2{
    padding-left:0.5rem !important;
    padding-right:0.5rem !important
  }

  .md\:tw-px-3{
    padding-left:0.75rem !important;
    padding-right:0.75rem !important
  }

  .md\:tw-px-4{
    padding-left:1rem !important;
    padding-right:1rem !important
  }

  .md\:tw-px-6{
    padding-left:1.5rem !important;
    padding-right:1.5rem !important
  }

  .md\:tw-px-8{
    padding-left:2rem !important;
    padding-right:2rem !important
  }

  .md\:tw-px-9{
    padding-left:2.25rem !important;
    padding-right:2.25rem !important
  }

  .md\:tw-px-10{
    padding-left:2.5rem !important;
    padding-right:2.5rem !important
  }

  .md\:tw-px-12{
    padding-left:3rem !important;
    padding-right:3rem !important
  }

  .md\:tw-px-16{
    padding-left:4rem !important;
    padding-right:4rem !important
  }

  .md\:tw-px-20{
    padding-left:5rem !important;
    padding-right:5rem !important
  }

  .md\:tw-px-32{
    padding-left:8rem !important;
    padding-right:8rem !important
  }

  .md\:tw-px-40{
    padding-left:10rem !important;
    padding-right:10rem !important
  }

  .md\:tw-px-3\.75{
    padding-left:0.9375rem !important;
    padding-right:0.9375rem !important
  }

  .md\:tw-py-1{
    padding-top:0.25rem !important;
    padding-bottom:0.25rem !important
  }

  .md\:tw-py-2{
    padding-top:0.5rem !important;
    padding-bottom:0.5rem !important
  }

  .md\:tw-py-3{
    padding-top:0.75rem !important;
    padding-bottom:0.75rem !important
  }

  .md\:tw-py-4{
    padding-top:1rem !important;
    padding-bottom:1rem !important
  }

  .md\:tw-py-5{
    padding-top:1.25rem !important;
    padding-bottom:1.25rem !important
  }

  .md\:tw-py-6{
    padding-top:1.5rem !important;
    padding-bottom:1.5rem !important
  }

  .md\:tw-py-8{
    padding-top:2rem !important;
    padding-bottom:2rem !important
  }

  .md\:tw-py-10{
    padding-top:2.5rem !important;
    padding-bottom:2.5rem !important
  }

  .md\:tw-py-16{
    padding-top:4rem !important;
    padding-bottom:4rem !important
  }

  .md\:tw-py-20{
    padding-top:5rem !important;
    padding-bottom:5rem !important
  }

  .md\:tw-py-24{
    padding-top:6rem !important;
    padding-bottom:6rem !important
  }

  .md\:tw-py-40{
    padding-top:10rem !important;
    padding-bottom:10rem !important
  }

  .md\:tw-pt-0{
    padding-top:0px !important
  }

  .md\:tw-pt-3{
    padding-top:0.75rem !important
  }

  .md\:tw-pt-5{
    padding-top:1.25rem !important
  }

  .md\:tw-pt-6{
    padding-top:1.5rem !important
  }

  .md\:tw-pt-8{
    padding-top:2rem !important
  }

  .md\:tw-pt-10{
    padding-top:2.5rem !important
  }

  .md\:tw-pt-12{
    padding-top:3rem !important
  }

  .md\:tw-pt-14{
    padding-top:3.5rem !important
  }

  .md\:tw-pt-16{
    padding-top:4rem !important
  }

  .md\:tw-pt-24{
    padding-top:6rem !important
  }

  .md\:tw-pt-32{
    padding-top:8rem !important
  }

  .md\:tw-pt-36{
    padding-top:9rem !important
  }

  .md\:tw-pt-48{
    padding-top:12rem !important
  }

  .md\:tw-pt-3\.75{
    padding-top:0.9375rem !important
  }

  .md\:tw-pr-0{
    padding-right:0px !important
  }

  .md\:tw-pr-4{
    padding-right:1rem !important
  }

  .md\:tw-pr-6{
    padding-right:1.5rem !important
  }

  .md\:tw-pr-10{
    padding-right:2.5rem !important
  }

  .md\:tw-pr-18{
    padding-right:4.5rem !important
  }

  .md\:tw-pr-20{
    padding-right:5rem !important
  }

  .md\:tw-pr-24{
    padding-right:6rem !important
  }

  .md\:tw-pr-28{
    padding-right:7rem !important
  }

  .md\:tw-pb-0{
    padding-bottom:0px !important
  }

  .md\:tw-pb-1{
    padding-bottom:0.25rem !important
  }

  .md\:tw-pb-2{
    padding-bottom:0.5rem !important
  }

  .md\:tw-pb-3{
    padding-bottom:0.75rem !important
  }

  .md\:tw-pb-4{
    padding-bottom:1rem !important
  }

  .md\:tw-pb-5{
    padding-bottom:1.25rem !important
  }

  .md\:tw-pb-12{
    padding-bottom:3rem !important
  }

  .md\:tw-pb-20{
    padding-bottom:5rem !important
  }

  .md\:tw-pb-24{
    padding-bottom:6rem !important
  }

  .md\:tw-pb-1\.5{
    padding-bottom:0.375rem !important
  }

  .md\:tw-pb-3\.75{
    padding-bottom:0.9375rem !important
  }

  .md\:tw-pl-0{
    padding-left:0px !important
  }

  .md\:tw-pl-3{
    padding-left:0.75rem !important
  }

  .md\:tw-pl-5{
    padding-left:1.25rem !important
  }

  .md\:tw-pl-6{
    padding-left:1.5rem !important
  }

  .md\:tw-pl-8{
    padding-left:2rem !important
  }

  .md\:tw-pl-10{
    padding-left:2.5rem !important
  }

  .md\:tw-pl-16{
    padding-left:4rem !important
  }

  .md\:tw-pl-18{
    padding-left:4.5rem !important
  }

  .md\:tw-pl-20{
    padding-left:5rem !important
  }

  .md\:tw-pl-40{
    padding-left:10rem !important
  }

  .md\:tw-text-left{
    text-align:left !important
  }

  .md\:tw-text-center{
    text-align:center !important
  }

  .md\:tw-font-styleSans{
    font-family:"Kobe", sans-serif !important
  }

  .md\:tw-text-sm{
    font-size:0.875rem !important;
    line-height:1.4 !important
  }

  .md\:tw-text-base{
    font-size:1rem !important;
    line-height:1.4 !important
  }

  .md\:tw-text-lg{
    font-size:1.125rem !important;
    line-height:1.4 !important
  }

  .md\:tw-text-xl{
    font-size:1.25rem !important;
    line-height:1.4 !important
  }

  .md\:tw-text-2xl{
    font-size:1.5rem !important;
    line-height:1.4 !important
  }

  .md\:tw-text-3xl{
    font-size:1.75rem !important;
    line-height:1.4 !important
  }

  .md\:tw-text-4xl{
    font-size:2rem !important;
    line-height:1.4 !important
  }

  .md\:tw-text-5xl{
    font-size:2.25rem !important;
    line-height:1.4 !important
  }

  .md\:tw-text-6xl{
    font-size:3rem !important;
    line-height:1 !important
  }

  .md\:tw-text-8xl{
    font-size:4rem !important;
    line-height:1 !important
  }

  .md\:tw-leading-6{
    line-height:1.5rem !important
  }

  .md\:tw-leading-none{
    line-height:1 !important
  }

  .md\:tw-text-white{
    --tw-text-opacity:1 !important;
    color:rgba(255, 255, 255, var(--tw-text-opacity)) !important
  }

  .md\:tw-text-brand-navy{
    --tw-text-opacity:1 !important;
    color:rgba(45, 76, 93, var(--tw-text-opacity)) !important
  }

  .md\:tw-text-brand-green{
    --tw-text-opacity:1 !important;
    color:rgba(228, 228, 128, var(--tw-text-opacity)) !important
  }

  .md\:tw-underline{
    text-decoration:underline !important
  }

  .md\:tw-no-underline{
    text-decoration:none !important
  }

  .md\:tw-opacity-20{
    opacity:0.2 !important
  }
}
@media (min-width: 992px){
  .lg\:custom-scrollbar{
    scrollbar-color:rgba(0,0,0,0) rgba(0,0,0,0);
    scrollbar-width:thin !important
  }

  .lg\:custom-scrollbar::-webkit-scrollbar{
    display:initial;
    width:6px;
    height:6px
  }

  .lg\:custom-scrollbar::-webkit-scrollbar-track{
    background-color:rgba(0,0,0,0)
  }

  .lg\:custom-scrollbar::-webkit-scrollbar-thumb{
    background-color:rgba(0,0,0,0)
  }

  .lg\:custom-scrollbar:hover{
    scrollbar-color:#D9D9D9 #fdfcfc
  }

  .lg\:custom-scrollbar:hover::-webkit-scrollbar-track{
    background-color:#f8f7f4;
    border-radius:8px
  }

  .lg\:custom-scrollbar:hover::-webkit-scrollbar-thumb{
    background-color:#D9D9D9;
    border-radius:8px
  }

  .lg\:custom-scrollbar-no-hover{
    scrollbar-color:#D9D9D9 #fdfcfc;
    scrollbar-width:thin !important
  }

  .lg\:custom-scrollbar-no-hover::-webkit-scrollbar{
    display:initial;
    width:6px;
    height:6px
  }

  .lg\:custom-scrollbar-no-hover::-webkit-scrollbar-track{
    background-color:#f8f7f4;
    border-radius:8px
  }

  .lg\:custom-scrollbar-no-hover::-webkit-scrollbar-thumb{
    background-color:#D9D9D9;
    border-radius:8px
  }

  .lg\:no-scrollbar{
    -ms-overflow-style:none;
    scrollbar-width:none
  }

  .lg\:no-scrollbar::-webkit-scrollbar{
    display:none
  }

  .lg\:tw--right-2{
    right:-0.5rem !important
  }

  .lg\:tw--right-7{
    right:-1.75rem !important
  }

  .lg\:tw-left-4{
    left:1rem !important
  }

  .lg\:tw-left-12{
    left:3rem !important
  }

  .lg\:tw--left-7{
    left:-1.75rem !important
  }

  .lg\:tw-order-1{
    order:1 !important
  }

  .lg\:tw-order-2{
    order:2 !important
  }

  .lg\:tw--mx-2{
    margin-left:-0.5rem !important;
    margin-right:-0.5rem !important
  }

  .lg\:tw-my-16{
    margin-top:4rem !important;
    margin-bottom:4rem !important
  }

  .lg\:tw-mt-0{
    margin-top:0px !important
  }

  .lg\:tw-mr-16{
    margin-right:4rem !important
  }

  .lg\:tw-mb-0{
    margin-bottom:0px !important
  }

  .lg\:tw-mb-2{
    margin-bottom:0.5rem !important
  }

  .lg\:tw-mb-4{
    margin-bottom:1rem !important
  }

  .lg\:tw-mb-8{
    margin-bottom:2rem !important
  }

  .lg\:tw-mb-12{
    margin-bottom:3rem !important
  }

  .lg\:tw-ml-6{
    margin-left:1.5rem !important
  }

  .lg\:tw-block{
    display:block !important
  }

  .lg\:tw-flex{
    display:flex !important
  }

  .lg\:tw-hidden{
    display:none !important
  }

  .lg\:tw-h-72{
    height:18rem !important
  }

  .lg\:tw-h-96{
    height:24rem !important
  }

  .lg\:tw-h-full{
    height:100% !important
  }

  .lg\:tw-w-32{
    width:8rem !important
  }

  .lg\:tw-w-40{
    width:10rem !important
  }

  .lg\:tw-w-44{
    width:11rem !important
  }

  .lg\:tw-w-72{
    width:18rem !important
  }

  .lg\:tw-w-96{
    width:24rem !important
  }

  .lg\:tw-w-2\/4{
    width:50% !important
  }

  .lg\:tw-flex-grow{
    flex-grow:1 !important
  }

  .lg\:tw-justify-start{
    justify-content:flex-start !important
  }

  .lg\:tw-justify-center{
    justify-content:center !important
  }

  .lg\:tw-bg-transparent{
    background-color:transparent !important
  }

  .lg\:tw-bg-brand-yellow{
    --tw-bg-opacity:1 !important;
    background-color:rgba(248, 243, 227, var(--tw-bg-opacity)) !important
  }

  .lg\:tw-px-2{
    padding-left:0.5rem !important;
    padding-right:0.5rem !important
  }

  .lg\:tw-px-4{
    padding-left:1rem !important;
    padding-right:1rem !important
  }

  .lg\:tw-px-8{
    padding-left:2rem !important;
    padding-right:2rem !important
  }

  .lg\:tw-px-44{
    padding-left:11rem !important;
    padding-right:11rem !important
  }

  .lg\:tw-py-0{
    padding-top:0px !important;
    padding-bottom:0px !important
  }

  .lg\:tw-py-3{
    padding-top:0.75rem !important;
    padding-bottom:0.75rem !important
  }

  .lg\:tw-py-24{
    padding-top:6rem !important;
    padding-bottom:6rem !important
  }

  .lg\:tw-pt-16{
    padding-top:4rem !important
  }

  .lg\:tw-pr-24{
    padding-right:6rem !important
  }

  .lg\:tw-text-left{
    text-align:left !important
  }

  .lg\:tw-text-base{
    font-size:1rem !important;
    line-height:1.4 !important
  }

  .lg\:tw-text-xl{
    font-size:1.25rem !important;
    line-height:1.4 !important
  }

  .lg\:tw-text-4xl{
    font-size:2rem !important;
    line-height:1.4 !important
  }

  .lg\:tw-text-6xl{
    font-size:3rem !important;
    line-height:1 !important
  }

  .lg\:tw-leading-none{
    line-height:1 !important
  }
}
@media (min-width: 1200px){
  .xl\:custom-scrollbar{
    scrollbar-color:rgba(0,0,0,0) rgba(0,0,0,0);
    scrollbar-width:thin !important
  }

  .xl\:custom-scrollbar::-webkit-scrollbar{
    display:initial;
    width:6px;
    height:6px
  }

  .xl\:custom-scrollbar::-webkit-scrollbar-track{
    background-color:rgba(0,0,0,0)
  }

  .xl\:custom-scrollbar::-webkit-scrollbar-thumb{
    background-color:rgba(0,0,0,0)
  }

  .xl\:custom-scrollbar:hover{
    scrollbar-color:#D9D9D9 #fdfcfc
  }

  .xl\:custom-scrollbar:hover::-webkit-scrollbar-track{
    background-color:#f8f7f4;
    border-radius:8px
  }

  .xl\:custom-scrollbar:hover::-webkit-scrollbar-thumb{
    background-color:#D9D9D9;
    border-radius:8px
  }

  .xl\:custom-scrollbar-no-hover{
    scrollbar-color:#D9D9D9 #fdfcfc;
    scrollbar-width:thin !important
  }

  .xl\:custom-scrollbar-no-hover::-webkit-scrollbar{
    display:initial;
    width:6px;
    height:6px
  }

  .xl\:custom-scrollbar-no-hover::-webkit-scrollbar-track{
    background-color:#f8f7f4;
    border-radius:8px
  }

  .xl\:custom-scrollbar-no-hover::-webkit-scrollbar-thumb{
    background-color:#D9D9D9;
    border-radius:8px
  }

  .xl\:no-scrollbar{
    -ms-overflow-style:none;
    scrollbar-width:none
  }

  .xl\:no-scrollbar::-webkit-scrollbar{
    display:none
  }

  .xl\:tw-flex{
    display:flex !important
  }

  .xl\:tw-bg-contain{
    background-size:contain !important
  }
}
/* purgecss start ignore */

.cart_popup_cartContainer__wqIxw{
  height:100%;
  top:0px
}

@media(min-width: 769px){
  .cart_popup_cartContainer__wqIxw{
    top:72px;
    height:calc(100% - 72px)
  }
}

.cart_popup_cartWithNoHeaderContainer__ySm_J{
  height:100%;
  top:0px
}

@media(min-width: 769px){
  .cart_popup_cartWithNoHeaderContainer__ySm_J{
    top:64px;
    height:calc(100% - 64px)
  }
}

.cart_popup_cartWithEventHeaderContainer__fbDyE{
  height:100%;
  top:0px
}

@media(min-width: 769px){
  .cart_popup_cartWithEventHeaderContainer__fbDyE{
    top:57px;
    height:calc(100% - 57px)
  }
}

/* purgecss end ignore */
/* purgecss start ignore */

.stepper_input__ZZLPE:disabled{
  background-color:#fff
}

/* purgecss end ignore */
/* purgecss start ignore */

.header_header__Rp35z{
  height:54px
}

@media(min-width: 769px){
  .header_header__Rp35z{
    height:72px
  }
}

/* purgecss end ignore */
