@import 'src/styles/new_font_face';
@import 'src/styles/vendors/slick-style';
@import 'flatpickr/dist/flatpickr.css';
@import 'nouislider/dist/nouislider.css';
@import 'intl-tel-input/build/css/intlTelInput';

@import 'src/styles/variables';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
// @import 'bootstrap/scss/code';
@import 'bootstrap/scss/grid';
// @import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/input-group';
@import 'bootstrap/scss/custom-forms';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
// @import 'bootstrap/scss/jumbotron';
@import 'bootstrap/scss/alert';
// @import 'bootstrap/scss/progress';
@import 'bootstrap/scss/media';
// @import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
// @import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/utilities';
// @import 'bootstrap/scss/print';


@import 'mixins';

@import 'src/styles/base';
@import 'src/styles/components/alert';
@import 'src/styles/components/badge';
@import 'src/styles/components/booking_form';
@import 'src/styles/components/breadcrumb';
@import 'src/styles/components/button';
@import 'src/styles/components/card';
@import 'src/styles/components/carousel';
@import 'src/styles/components/chapter';
@import 'src/styles/components/circle';
@import 'src/styles/components/faq';
@import 'src/styles/components/form';
@import 'src/styles/components/gift_card';
@import 'src/styles/components/hero';
@import 'src/styles/components/img_grid';
@import 'src/styles/components/lightbox';
@import 'src/styles/components/list';
@import 'src/styles/components/map';
@import 'src/styles/components/media';
@import 'src/styles/components/minimal_option';
@import 'src/styles/components/modal';
@import 'src/styles/components/nav';
@import 'src/styles/components/notice_box';
@import 'src/styles/components/option_datepicker';
@import 'src/styles/components/page_nav';
@import 'src/styles/components/pagination';
@import 'src/styles/components/popup';
@import 'src/styles/components/popover';
@import 'src/styles/components/referral';
@import 'src/styles/components/review';
@import 'src/styles/components/ribbon';
@import 'src/styles/components/rich_doc';
@import 'src/styles/components/search_popup';
@import 'src/styles/components/section';
@import 'src/styles/components/slick';
@import 'src/styles/components/table';
@import 'src/styles/components/timeline';
@import 'src/styles/components/timepicker';
@import 'src/styles/components/toast';
@import 'src/styles/components/tooltip';
@import 'src/styles/components/video';

@import 'src/styles/vendors/flatpickr';
@import 'src/styles/vendors/nouislider';
@import 'src/styles/vendors/stripe';
@import 'src/styles/vendors/uppy';
@import 'src/styles/vendors/fc_prechat_form';

@import 'src/styles/pages/account_all';
@import 'src/styles/pages/blogs_all';
@import 'src/styles/pages/corporate_events_show';
@import 'src/styles/pages/others';
@import 'src/styles/pages/pages_index';
@import 'src/styles/pages/gifts_index';
@import 'src/styles/pages/supplier_activity_options_show';

@import 'src/styles/utils';
@tailwind utilities;

.tw-font-styleSans {
  font-family: theme('fontFamily.styleSans');
  letter-spacing: normal;
}
